/*eslint-disable */
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import  Axios  from 'axios';
import bookmarkExportOptionsData from './data';
import { sendNotification } from '../../components/Notification/logic';
import { getCookie } from '../../../utils';

function BookmarkExportOptions({
  bookmarkFilters, bookmarkFilterUsers, projectId, setOpenExportPopover, sortByOptions,
}) {

  function convertToLowerCaseWithUnderscores(filters) {
    return filters.map((filter) => filter.toLowerCase().replace(/ /g, '_'));
  }

  const handleExportClick = (sortOption) => {
    setOpenExportPopover(false);
    const filename1 = 'bookmark-list-records.csv';
    const filename2 = 'bookmark-list-records.json';
    const params = {
      Accept: sortOption.value === 'json' ? 'application/json' : 'text/csv',
    };

    const body = {
      project_id: projectId,
      asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
      sort: sortByOptions,
      export: true,
      file_type: sortOption.value,
      user_ids: bookmarkFilterUsers,
    };
    if(sortOption.value === 'csv'){
    Axios({
      url: `${process.env.apiUrl}/common/v0/workspace/bookmark/getBookmark?${queryString.stringify(params)}`,
      method: 'POST',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
      data: body,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        return null;
      })
      .catch((e) => {
        return e;
      });
    }
    if(sortOption.value === 'json'){
      Axios({
        url: `${process.env.apiUrl}/common/v0/workspace/bookmark/getBookmark?${queryString.stringify(params)}`,
        method: 'POST',
        responseType: 'blob', 
        headers: {
          Authorization: getCookie('accessToken'),
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
        },
        data: body,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/json' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename2);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); 
          return null;
        })
        .catch((e) => {
          
          return e;
        });
    }
  };

  return (
    <div className="bookmark-export-container">
      <div className="bookmark-export-container-body">
        {bookmarkExportOptionsData.map((i) => (
          <div className="bookmark-export-container-body-item" role="presentation" onClick={() => handleExportClick(i)}>
            <div className="bookmark-export-container-body-item-title">
              {i.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookmarkExportOptions;
