/* eslint-disable no-debugger */
/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { trackOutSideEvents, wordLimit } from "../../../utils";
import SvgLoader from "../SvgLoader";
import {
  shareSaveSearchAction,
  shareSaveSearchRefreshAction,
} from "../../container/SavedSearches/logic";
import { refreshFilterAction } from "../../container/PowerSearch/logic";
import Mixpanel from "../../../utils/mixpanel";
import { Popover, Modal, Button, Input } from "antd";
import SavedSearches from "../../container/SavedSearches";
import { sendNotification } from "../Notification/logic";

function ResultViewTags({
  reqBody,
  filtersData,
  terminalName,
  view,
  totalCount,
  semanticQueryRequest,
  url,
  q,
  f,
  hashApiCall,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const shareSaveSearch = useSelector((state) => state.shareSaveSearch);
  const hashGenerator = useSelector((state) => state.hashGenerator);
  const [searchParams] = useSearchParams();
  const [miniView, setMiniView] = useState(true);
  const ctrTags = useAnimation();
  const [tags, setTags] = useState([]);
  const [modifyIconHover, setModifyIconHover] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [message, setMessage] = useState("");

  const tagsRef = useRef(null);
  const query = searchParams.get("query");
  const semanticQuery = searchParams.get("semanticquery");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isEmpty = (obj) => Object.keys(obj).length === 0;
  const nonEmptyRequestBody = !isEmpty(reqBody.body)
    ? reqBody
    : semanticQueryRequest;

  const queryUrl = {
    url: url,
    q: q,
    f: f,
  };
  useEffect(() => {
    const temp = [];

    if (semanticQuery) {
      JSON.parse(semanticQuery)?.forEach((item) => {
        item.currentFilter?.forEach((element) => {
          if (item.type === "date-range" && element !== "") {
            const date = new Date(element * 1000);
            const month =
              String(date.getMonth() + 1).length === 1
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
            const tempDate1 =
              String(date.getDate()).length === 1
                ? `0${date.getDate()}`
                : date.getDate();

            const tempDate = `${date.getFullYear()}-${month}-${tempDate1}`;
            temp.push(tempDate);
          } else if (element !== "") {
            temp.push(element);
          }
        });
      });

      setTags([...temp]);
    }

    if (query) {
      JSON.parse(query)?.forEach((item) => {
        if (item.name === "gender") {
          if (item.currentGender) {
            temp.push(item.currentGender[0]);
          }
        } else {
          item.currentFilter?.forEach((element) => {
            if (item.type === "date-range" && element !== "") {
              const date = new Date(element * 1000);
              const month =
                String(date.getMonth() + 1).length === 1
                  ? `0${date.getMonth() + 1}`
                  : date.getMonth() + 1;
              const tempDate1 =
                String(date.getDate()).length === 1
                  ? `0${date.getDate()}`
                  : date.getDate();
              const tempDate = `${date.getFullYear()}-${month}-${tempDate1}`;
              temp.push(tempDate);
            } else if (element !== "" && item.type !== "date-range") {
              temp.push(element);
            }
          });
        }
      });

      setTags([...temp]);
    }
  }, []);

  useEffect(() => {
    trackOutSideEvents(tagsRef, () => setMiniView(true));
  }, []);

  const showShareModal = () => {
    // const temp = [];
    // temp.push(item?.doc_id);
    // temp.push(item?.type);

    // setBookmarkCardSelected(temp);
    setOpenShareModal(!openShareModal);
  };

  const handleInviteClick = () => {
    dispatch(
      shareSaveSearchAction({
        body: {
          email_ids: emailTags,
          terminal_name: terminalName,
          view: view,
          query: nonEmptyRequestBody,
          filters: filtersData,
          count: totalCount,
          comment: message,
          query_url: queryUrl,
          hash: hashGenerator?.data?.hash,
        },
      })
    );
    setOpenShareModal(!openShareModal);
  };

  useEffect(() => {
    if (shareSaveSearch.flag) {
      if (shareSaveSearch.data.message.includes("not")) {
        dispatch(
          sendNotification({
            type: "SAVE_SEARCH_SHARED_FAILURE",
            notificationType: "error",
            message: shareSaveSearch.data.message,
            showNotification: true,
          })
        );
      } else {
        dispatch(
          sendNotification({
            type: "SAVE_SEARCH_SHARED_SUCCESS",
            notificationType: "success",
            message: shareSaveSearch.data.message,
            showNotification: true,
          })
        );
      }
    }
  }, [JSON.stringify(shareSaveSearch)]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && currentEmail) {
      if (!emailTags.includes(currentEmail)) {
        setIsEnterPressed(true);
        setEmailTags([...emailTags, currentEmail]);
        setCurrentEmail("");
      }
      setEmailError("email id already selected")
      e.preventDefault(); // Prevent form submission
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
    if (emailRegex.test(e.target.value) || e.target.value === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email format");
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  const handleModifyClick = () => {
    dispatch(refreshFilterAction());
    if (
      location.pathname === "/searchAndExplore/results" ||
      location.pathname === "/searchAndExplore/analytics"
    ) {
      if (semanticQuery?.length) {
        Mixpanel.track("search_modify_event", {
          action: "Modify click",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
          modify_query: JSON.parse(semanticQuery),
          search_type: "Semantic Search",
        });

        Mixpanel.track("page_exit_event", {
          action: "page leave event",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
        });

        navigate(
          `/searchAndExplore?semanticquery=${encodeURIComponent(semanticQuery)}`
        );
      } else {
        Mixpanel.track("search_modify_event", {
          action: "Modify click",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
          modify_query: JSON.parse(query),
          search_type: "Power Search",
        });

        Mixpanel.track("page_exit_event", {
          action: "page leave event",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
        });

        navigate(`/searchAndExplore?query=${encodeURIComponent(query)}`);
      }
    } else if (
      location.pathname === "/clinicalTrials/searchResults" ||
      location.pathname === "/clinicalTrials/analyticsResults"
    ) {
      if (semanticQuery?.length) {
        Mixpanel.track("search_modify_event", {
          action: "Modify click",
          page: `${
            location.pathname === "/clinicalTrials/searchResults"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Clinical Trials",
          modify_query: JSON.parse(semanticQuery),
          search_type: "Semantic Search",
        });

        Mixpanel.track("page_exit_event", {
          action: "page leave event",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
        });

        navigate(
          `/clinicalTrials?semanticquery=${encodeURIComponent(semanticQuery)}`
        );
      } else {
        Mixpanel.track("search_modify_event", {
          action: "Modify click",
          page: `${
            location.pathname === "/clinicalTrials/searchResults"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Clinical Trials",
          modify_query: JSON.parse(query),
          search_type: "Power Search",
        });

        Mixpanel.track("page_exit_event", {
          action: "page leave event",
          page: `${
            location.pathname === "/searchAndExplore/results"
              ? "List View Page"
              : "Analytics Page"
          }`,
          terminal_name: "Search and Explore",
        });

        navigate(`/clinicalTrials?query=${encodeURIComponent(query)}`);
      }
    } else if (
      location.pathname === "/intervention/results" ||
      location.pathname === "/intervention/analytics"
    ) {
      Mixpanel.track("search_modify_event", {
        action: "Modify click",
        page: `${
          location.pathname === "/intervention/results"
            ? "List View Page"
            : "Analytics Page"
        }`,
        terminal_name: "Intervention",
        modify_query: JSON.parse(query),
      });

      Mixpanel.track("page_exit_event", {
        action: "page leave event",
        page: `${
          location.pathname === "/intervention/results"
            ? "List View Page"
            : "Analytics Page"
        }`,
        terminal_name: "Intervention",
      });

      navigate(`/intervention?query=${query}`);
    }
  };

  const renderTags = () =>
    tags.map((item) => (
      <div className="tags" key={item}>
        <div className="tag-label" title={item}>
          {wordLimit(item, 30)}
        </div>
      </div>
    ));

  const renderSixTags = (index) => (
    <div className="tags" title={tags[index]}>
      <div className="tag-label" title={tags[index]}>
        {wordLimit(tags[index], 30)}
      </div>
    </div>
  );

  const renderMiniView = () => (
    <>
      {renderSixTags(0)}
      {renderSixTags(1)}
      {renderSixTags(2)}
      {renderSixTags(3)}
      {renderSixTags(4)}
      {renderSixTags(5)}
      {tags.length - 6 ? (
        <div className="tags" style={{ width: 50 }}>{`+${
          tags.length - 6
        }`}</div>
      ) : null}
    </>
  );

  return (
    <div
      className="ct-header-tags"
      aria-hidden
      ref={tagsRef}
      onClick={() => setMiniView(false)}
    >
      {tags.length ? (
        <motion.div
          initial={{ x: 0 }}
          animate={ctrTags}
          className={`tags-data ${
            miniView ? "mini-view-tags" : "full-view-tags"
          }`}
        >
          {(!miniView || tags.length < 6) && renderTags()}
          {miniView && tags.length > 6 ? renderMiniView() : null}
        </motion.div>
      ) : null}
      {!location.search.includes("workspace") ? (
        <div
          aria-hidden
          className="edit"
          onMouseEnter={() => setModifyIconHover(true)}
          onClick={() => handleModifyClick()}
          onBlur={() => setModifyIconHover(false)}
          onMouseOut={() => setModifyIconHover(false)}
        >
          <SvgLoader
            width={32}
            height={32}
            hoverIconName="edit-icon"
            svgName={`${modifyIconHover ? "edit-icon" : "edit-icon-hover"}`}
          />
          <span className="edit-icon" />
          <span
            aria-hidden
            className="edit-text"
            onMouseEnter={() => setModifyIconHover(true)}
            onBlur={() => setModifyIconHover(false)}
            onMouseOut={() => setModifyIconHover(false)}
          >
            Modify
          </span>
        </div>
      ) : null}
      {!location.search.includes("workspace") ? (
        <Popover
          overlayClassName="bookmark-popover"
          placement="bottom"
          open={showSavedSearches}
          // onOpenChange={(open) => setShowSavedSearches(open)}
          content={
            <SavedSearches
              setShowSavedSearches={setShowSavedSearches}
              reqBody={reqBody}
              onClose={() => setShowSavedSearches(!showSavedSearches)}
              filtersData={filtersData}
              terminalName={terminalName}
              view={view}
              totalCount={totalCount}
              semanticQueryRequest={semanticQueryRequest}
              url={url}
              q={q}
              f={f}
              hashApiCall={hashApiCall}
            />
          }
        >
          <div
            className={
              hashGenerator?.data?.isSaveSearch === true
                ? "saved-search-active"
                : "saved-searches-icon"
            }
            onClick={() => {
              setShowSavedSearches(true);
            }}
            role="presentation"
          />
        </Popover>
      ) : null}
      {!location.search.includes("workspace") ? (
        <div
          className="share-icon"
          role="presentation"
          onClick={() => showShareModal()}
        />
      ) : null}

      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => setOpenShareModal(false)}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginBottom: "10px",
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    value={currentEmail}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Email ID"
                  />
                  {emailError && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input
                  placeholder="Message (Optional)"
                  onChange={handleMessageChange}
                />
              </div>
              <div className="invite-btn">
                <Button
                  disabled={
                    !isEnterPressed || emailError || emailTags.length === 0
                  }
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Invite
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ResultViewTags;
