/* eslint-disable no-unused-vars */
// /*eslint-disable */
// import { React, useState, useEffect } from 'react';
// import {
//   Popover, Input, Button, Checkbox, Select
// } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import { ToastContainer, toast } from 'react-toastify';
// import { useLocation } from 'react-router-dom';
// import { createProjectAction, getProjectsAction } from '../WorkSpace/logic';
// import Loader from '../../components/Loader';
// import { setCookie } from '../../../utils';

// function HeaderProjectDropdown({ projectId, projectName, onProjectChange, setOpenProjectDropdown, openProjectDropdown}) {
//   const dispatch = useDispatch();
//   const createProject = useSelector(
//     (state) => state.createProject,
//   );
//   const projectsData = useSelector(
//     (state) => state.projectsData,
//   );

//   const [openCreateProject, setOpenCreateProject] = useState(false);
//   const [inputText, setInputText] = useState('');
//   const [selectedProjectId, setSelectedProjectId] = useState(null);
//   const [selectedProjectName, setSelectedProjectName] = useState(null);

//   //   const [openProjectOptions, setOpenProjectOptions] = useState(false);
//   const [openRenameProject, setOpenRenameProject] = useState(false);

//   useEffect(() => {
//     if (projectId) {
//         setSelectedProjectName(projectName);
//         setSelectedProjectId(projectId);
//     }
//   }, []);

//   const handleChange = (value) => {
//     setInputText(value);
//   };
//   const handleClick = () => {
//     // dispatch(createProjectAction(
//     //   {
//     //     body: {
//     //       project_name: inputText,
//     //     },
//     //   },
//     // ));
//     setOpenCreateProject(!openCreateProject);
//   };

//   useEffect(() => {
//     if (createProject.flag) {
//       toast(`${inputText} Project created successfully`, {
//         position: 'top-center',
//         autoClose: 2000,
//         pauseOnHover: false,
//         draggable: true,
//         newestOnTop: true,
//         type: 'success',
//         hideProgressBar: false,
//         closeButton: true,
//         theme: 'dark',
//       });
//     }
//   }, [JSON.stringify(createProject)]);

//   const apiCall = () => {
//     dispatch(getProjectsAction(
//       {
//         params: {
//           doc_id: '',
//         },
//       },
//     ));
//   };

//   useEffect(() => {
//     apiCall();
//   }, []);

// //   useEffect(() => {
// //     if (projectsData.flag) {

// //     }
// //   }, [JSON.stringify(projectsData)]);

// //   useEffect(() => {
// //     if (createBookmark.flag) {
// //       apiCall();
// //     }
// //   }, [JSON.stringify(createBookmark)]);

//   useEffect(() => {
//     if (createProject.flag) {
//       apiCall();
//     }
//   }, [JSON.stringify(createProject)]);

//   const renderRenameProject = (data) => (
//     <div className="create-project-sec">
//       <div className="project-header">
//         <div className="project-title"> Rename Folder</div>
//         <div className="close-icon" role="presentation" onClick={() => setOpenRenameProject(!openRenameProject)} />
//       </div>
//       <div className="project-content">
//         <Input onChange={(e) => handleChange(e.target.value)} />
//       </div>
//       <div className="btn-section">
//         <Button type="primary" className="cancel-btn" onClick={() => setOpenRenameProject(!openRenameProject)}>Cancel</Button>

//       </div>
//     </div>
//   );

//   const renderCreateProject = () => (
//     <div className="create-project-sec">
//       <div className="project-header">
//         <div className="project-title"> New project</div>
//         <div className="close-icon" role="presentation" onClick={() => setOpenCreateProject(!openCreateProject)} />
//       </div>
//       <div className="project-content">
//         <Input onChange={(e) => handleChange(e.target.value)} />
//       </div>
//       <div className="btn-section">
//         <Button type="primary" className="cancel-btn" onClick={() => setOpenCreateProject(!openCreateProject)}>Cancel</Button>
//         <Button type="primary" role="presentation" onClick={handleClick}>Create</Button>
//         <ToastContainer />
//       </div>
//     </div>
//   );

//   const handleCheckboxChange = (projectId, projectName) => {
//         setSelectedProjectId(projectId);
//         setSelectedProjectName(projectName);};

// //   const handleOptionClick = (idx) => {
// //     setOpenProjectOptions(!openProjectOptions);
// //   };
//   return (
//     <div className="bookmark">
//       <div className="bookmark-container">
//         <div className="bookmark-title">Switch Project</div>

//       </div>
//       <Loader loading={projectsData.loading}>
//         <div className="analytics-container-content-sidebar-link--child">
//           {projectsData?.data?.map((i, contentIndex) => (
//             <div key={i.id} className="analytics-container-content-sidebar-link">
//               <div className="folder-icon" />
//               <div className="project-content" role='presentation' onClick={onProjectChange(selectedProjectId, selectedProjectName)}>
//                 <Checkbox onChange={() => handleCheckboxChange(i?.projectId, i?.projectName)} checked={i?.projectId === selectedProjectId} />
//                 <div className="analytics-container-content-sidebar-link-title">
//                   <div className="analytics-container-content-sidebar-link-title-main">{i?.projectName}</div>
//                 </div>
//                 {/* <Popover overlayClassName="proj-options-popover" placement="bottom" open={openProjectOptions} content={renderProjectOptions(i)}>
//                                 <div className="three-dots-icon" role="presentation" onClick={() => handleOptionClick(i)}>
//                                 </div>
//                             </Popover> */}

//               </div>

//             </div>
//           ))}
//            {/* <Select
//       defaultValue="Default"
//       style={{
//         width: 120,
//       }}
//       onChange={handleCheckboxChange}
//       options={projectsData?.data?.map((i) => ({
//         label: i?.projectName,
//         value: i?.projectName,
//       }))}
//     /> */}
//         </div>
//         {/* <div className="analytics-container-content-sidebar-link-footer">
//           <Button type="primary" className="ok-btn" onClick={() => onProjectChange(selectedProjectId, selectedProjectName)}>Ok</Button>
//         </div> */}
//       </Loader>
//       {/* <Popover overlayClassName="create-proj-popover" placement="bottomRight" open={openCreateProject} content={renderCreateProject()}>
//           <div className="create-project" role="presentation" onClick={() => setOpenCreateProject(!openCreateProject)}>
//             <div className="add-project" />
//             <div className="project-name">Create New Project</div>
//           </div>
//         </Popover> */}
//     </div>
//   );
// }

// export default HeaderProjectDropdown;

import { React, useState, useEffect } from 'react';
import {
  Popover, Input, Button, Radio, RadioChangeEvent, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { createProjectAction, getProjectsAction } from '../WorkSpace/logic';
import Loader from '../../components/Loader';
import { setCookie } from '../../../utils';
import { sendNotification } from '../../components/Notification/logic';

function HeaderProjectDropdown({
  projectId, projectName, onProjectChange, setOpenProjectDropdown,
}) {
  const dispatch = useDispatch();
  const createProject = useSelector(
    (state) => state.createProject,
  );
  const projectsData = useSelector(
    (state) => state.projectsData,
  );

  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);

  const [openRenameProject, setOpenRenameProject] = useState(false);

  useEffect(() => {
    if (projectId) {
      setSelectedProjectName(projectName);
      setSelectedProjectId(projectId);
    }
  }, []);

  const handleChange = (value) => {
    setInputText(value);
  };

  const handleClick = () => {
    // dispatch(createProjectAction(
    //   {
    //     body: {
    //       project_name: inputText,
    //     },
    //   },
    // ));
    setOpenCreateProject(!openCreateProject);
  };

  const apiCall = () => {
    dispatch(getProjectsAction(
      {
        params: {
          doc_id: '',
        },
      },
    ));
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    if (createProject.flag) {
      apiCall();
    }
  }, [JSON.stringify(createProject)]);

  const handleRadioChange = (e) => {
    const selectedProject = projectsData.data.find((project) => project.projectId === e.target.value);
    setSelectedProjectId(e.target.value);
    setSelectedProjectName(selectedProject.projectName);
    onProjectChange(e.target.value, selectedProject.projectName);
  };

  const renderRenameProject = (data) => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> Rename Folder</div>
        <div className="close-icon" role="presentation" onClick={() => setOpenRenameProject(!openRenameProject)} />
      </div>
      <div className="project-content">
        <Input onChange={(e) => handleChange(e.target.value)} />
      </div>
      <div className="btn-section">
        <Button type="primary" className="cancel-btn" onClick={() => setOpenRenameProject(!openRenameProject)}>Cancel</Button>

      </div>
    </div>
  );

  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New project</div>
        <div className="close-icon" role="presentation" onClick={() => setOpenCreateProject(!openCreateProject)} />
      </div>
      <div className="project-content">
        <Input onChange={(e) => handleChange(e.target.value)} />
      </div>
      <div className="btn-section">
        <Button type="primary" className="cancel-btn" onClick={() => setOpenCreateProject(!openCreateProject)}>Cancel</Button>
        <Button type="primary" role="presentation" onClick={handleClick}>Create</Button>
        {/* <ToastContainer /> */}
      </div>
    </div>
  );

  return (
    <div className="bookmark">
      <div className="bookmark-container">
        <div className="bookmark-title">Switch Project</div>
        <div className="cross-btn" role="presentation" onClick={() => setOpenProjectDropdown(false)} />
      </div>
      {/* <Loader loading={projectsData.loading}>
        <div className="analytics-container-content-sidebar-link--child">
          <Radio.Group value={selectedProjectId} onChange={handleRadioChange}>
            {projectsData?.data?.map((i, contentIndex) => (
              <div key={i.id} className={selectedProjectId === i.projectId ? 'analytics-container-content-sidebar-link analytics-container-content-sidebar-link-selected' : 'analytics-container-content-sidebar-link'}>
                <Radio value={i.projectId} />
                <div className="project-content">
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">{i.projectName}</div>
                  </div>
                </div>
              </div>
            ))}
          </Radio.Group>
        </div>
      </Loader> */}
      <Loader loading={projectsData.loading}>
        <div className="analytics-container-content-sidebar-link--child">
          <Radio.Group value={selectedProjectId} onChange={handleRadioChange}>
            {projectsData?.data?.map((i, contentIndex) => (
              // Check if the project name is not "Shared Documents"
              i.projectName !== 'Shared Documents' && (
              <div key={i.id} className={selectedProjectId === i.projectId ? 'analytics-container-content-sidebar-link analytics-container-content-sidebar-link-selected' : 'analytics-container-content-sidebar-link'}>
                <Radio value={i.projectId} />
                <div className="project-content">
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">{i.projectName}</div>
                  </div>
                </div>
              </div>
              )
            ))}
          </Radio.Group>
        </div>
      </Loader>
    </div>
  );
}

export default HeaderProjectDropdown;
