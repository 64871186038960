const sponserToggleFields = [
  { label: 'All', key: 'all' },
  { label: 'Industry', key: 'industrial' },
  { label: 'Research Institute', key: 'research-institutes' },
  { label: 'University', key: 'universities' },
  { label: 'Hospital', key: 'hospitals' },
  { label: 'Federal Agency', key: 'federal-agencies' },
  { label: 'Physician', key: 'physician' },
  { label: 'Other', key: 'other' },
];

export const keyFieldMap = {
  industrial: 'Industry',
  'research-institutes': 'Research Institute',
  universities: 'University',
  hospitals: 'Hospital',
  'federal-agencies': 'Federal Agency',
  physician: 'Physician',
  other: 'Other',
  all: 'All',
};

export default sponserToggleFields;
