import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_PATENTS_DATA = 'GET_PATENTS_DATA';
const GET_PATENTS_DATA_SUCCESS = 'GET_PATENTS_DATA_SUCCESS';
const GET_PATENTS_DATA_FAILURE = 'GET_PATENTS_DATA_FAILURE';
const GET_PATENTS_DATA_REFRESH = 'GET_PATENTS_DATA_REFRESH';

export const getPatentsDataAction = createAction(GET_PATENTS_DATA);

const getPatentsDataSuccess = createAction(GET_PATENTS_DATA_SUCCESS);

const getPatentsDataFaliure = createAction(GET_PATENTS_DATA_FAILURE);

export const getPatentsDataRefresh = createAction(GET_PATENTS_DATA_REFRESH);

export const getPatentsDataEpic = (actions$) => actions$.pipe(
  ofType(GET_PATENTS_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/intervention/v0/analytics/patent-list?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getPatentsDataSuccess(res)),
    catchError((err) => of(getPatentsDataFaliure(err))),
  )),
);

const ongetPatentsDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const ongetPatentsDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetPatentsDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetPatentsDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getPatentsDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getPatentsDataAction, (state) => ongetPatentsDataAction(state))
      .addCase(getPatentsDataSuccess, (state, action) => ongetPatentsDataSuccess(state, action.payload))
      .addCase(getPatentsDataFaliure, (state) => ongetPatentsDataFailure(state))
      .addCase(getPatentsDataRefresh, () => ongetPatentsDataRefresh())
      .addDefaultCase((state) => state);
  },
);
