import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get Partner details
const ADD_USER_CONCENT = 'ADD_USER_CONCENT';
const ADD_USER_CONCENT_SUCCESS = 'ADD_USER_CONCENT_SUCCESS';
const ADD_USER_CONCENT_FAILURE = 'ADD_USER_CONCENT_FAILURE';
const ADD_USER_CONCENT_REFRESH = 'ADD_USER_CONCENT_REFRESH';

export const addUserConcentAction = createAction(ADD_USER_CONCENT);

const addUserConcentSuccess = createAction(ADD_USER_CONCENT_SUCCESS);

const addUserConcentFailure = createAction(ADD_USER_CONCENT_FAILURE);

export const addUserConcentRefreshAction = createAction(ADD_USER_CONCENT_REFRESH);

export const addUserConcentEpic = (actions$) => actions$.pipe(
  ofType(ADD_USER_CONCENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/register?${action.payload.headers}`, 'PUT', action.payload.params)).pipe(
    map((res) => addUserConcentSuccess(res)),
    catchError((err) => of(addUserConcentFailure(err))),
  )),
);

const addUserConcentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addUserConcentAction, (state) => {
      state.loading = true;
    })
      .addCase(addUserConcentSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(addUserConcentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(addUserConcentRefreshAction, (state) => {
        state.data = {};
        state.loading = false;
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

export default addUserConcentReducer;
