import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import Logout from '../Logout';
import webAuth from '../../../utils/webAuth';

function ProfileModal() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/workspace');
  };

  return (
    <div className="pofile-sec">
      <div className="sign-out-sec pofile-sec-btn" role="presentation" onClick={() => handleClick()}>
        <div className="navbar-group-right-item">
          <div className="workspace-icon" />
          {' '}
          Workspace
        </div>
      </div>
      <Logout className="sign-out-sec pofile-sec-btn" webAuth={webAuth} />
    </div>
  );
}

export default ProfileModal;
