/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Outlet, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Status from '../../../common/container/Status';
import Notification from '../../../common/components/Notification';
import Footer from '../../../common/components/Footer';
import CookieSupport from '../../../common/components/CookieSupport';
import {
  eliminateCookies, getCookie, getTempSessionId, setCookie,
} from '../../../utils';
import Loader from '../../../common/components/Loader';
import ChatBot from '../../../common/container/Chatbot';
import LoginModalV2 from '../LoginModalV2';
import { getLicenceAction } from './logic';
import TrialStatusModal from '../../components/TrialStatusModal';
import TrailPeriodExpiredModal from '../../components/TrailPeriodExpiredModal';
import RegectedModal from '../../components/RejectedModal';
import Mixpanel from '../../../utils/mixpanel';

function Main() {
  const [isBotOpen, setIsBotOpen] = useState(false);
  const [checkLandingPage, setCheckLandingPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const getLicence = useSelector((state) => state.getLicence);

  window.clarity('set', 'userId', getTempSessionId());

  useEffect(() => {
    if (getLicence.flag && JSON.stringify(getLicence.data) !== '{}') {
      if (getLicence.data[0].license_type === 'trial') {
        setCookie(getLicence.data[0].trial_end_date, 'expiresIN');
        const currentTimestamp = Date.now();
        if (Number(getLicence.data[0].trial_end_date) <= currentTimestamp) {
          setCookie(true, 'trialPeriodExpired');
        }
      } else {
        setCookie(getLicence.data[0].license_end_date, 'expiresIN');
        const currentTimestamp = Date.now();
        if (Number(getLicence.data[0].license_end_date) <= currentTimestamp) {
          setCookie(true, 'trialPeriodExpired');
        }
      }
    }

    if (getLicence.flag && JSON.stringify(getLicence.data) === '{}') {
      setCookie('indifinate', 'expiresIN');
    }
  }, [JSON.stringify(getLicence)]);

  const lightFooterAllowedPaths = ['/clinicalTrials/analyticsResults', '/clinicalTrials/searchResults', '/intervention/results', '/intervention/analytics', '/explore/analytics', '/explore/results', '/privacyPolicy', '/userPolicy', '/workspace'];

  const classInPaths = ['/home', '/clinicalTrials', '/explore', '/intervention'];

  useEffect(() => {
    if (getCookie('pageLoading') === 'true') {
      setTimeout(() => {
        setCookie(false, 'pageLoading');
        navigate(0);
      }, 4000);
    }
  }, []);

  useEffect(() => {
    const utm_source = searchParams.get('utm_source') || '';
    const utm_medium = searchParams.get('utm_medium') || '';
    const utm_campaign = searchParams.get('utm_campaign') || '';
    const utm_content = searchParams.get('utm_content') || '';
    const utm_term = searchParams.get('utm_term') || '';

    if (utm_source !== '') {
      const utmParams = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      };

      console.log('check this part', utmParams);

      setCookie(JSON.stringify(utmParams), 'utmParams');

      Mixpanel.track('utm_tracking_event', {
        action: 'utm tracking for tracking user source',
        page_name: 'Landing Page',
        utmParams,
      });
    }
  }, []);

  useEffect(() => {
    eliminateCookies();
  }, []);

  useEffect(() => {
    eliminateCookies();
  }, [document.cookie]);

  const renderBot = () => {
    setIsBotOpen(!isBotOpen);
  };

  useEffect(() => {
    if (getCookie('accessToken') && !getCookie('expiresIN')) {
      dispatch(getLicenceAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
    }
  }, []);

  useEffect(() => {
    if (getCookie('expiresIN') && getCookie('expirseIN') !== 'indifinate') {
      const currentTimestamp = Date.now();
      if (Number(getCookie('expiresIN')) <= currentTimestamp) {
        setCookie(true, 'trialPeriodExpired');
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      setCheckLandingPage(true);
    }
  }, [window.location.pathname]);

  const type = lightFooterAllowedPaths.includes(location.pathname) ? 'light' : 'dark';

  const handleMainClassName = classInPaths.includes(location.pathname) ? 'new-bg-img app-container' : window.location.pathname === '/clinicalTrials/analyticsResults' || window.location.pathname === '/' ? 'app-container-analytics' : 'app-container';

  const notAllowedModalPaths = ['/', '/home', '/clinicalTrials', '/explore', '/intervention'];

  return (
    <div className="app">
      <Loader error={false} loading={getCookie('pageLoading') === 'true' && window.location.pathname === '/'}>
        <div className={`${handleMainClassName}`}>
          {!location.pathname.includes(notAllowedModalPaths) ? <LoginModalV2 /> : null}
          <Outlet />
          {checkLandingPage
            && (
              <div className="sample-large full-height-no-header-footer login-page-main">
                <div className="login-page" />
              </div>
            )}
        </div>
        <Status />
        <Notification />
        <CookieSupport />
        {!getCookie('accessToken') || location.pathname.includes('/access') || location.pathname.includes('/contact') || location.pathname.includes('/privacyPolicy') || location.pathname.includes('/userPolicy') || location.pathname.includes('/admin') || location.pathname.includes('/redirect') ? (
          null
        ) : (
          <Popover overlayClassName="chatbot-popover" placement="topRight" open={isBotOpen} content={<ChatBot setIsBotOpen={setIsBotOpen} />}>
            <div className={`chatbot-container footer-main${type === 'light' ? '-light' : ''} disp-flex vcenter`}>
              <div className="chatbot-icon" role="presentation" onClick={() => renderBot()} />
            </div>
          </Popover>
        )}
        <TrialStatusModal />
        <TrailPeriodExpiredModal />
        <RegectedModal />
        {location.pathname !== '/redirect' ? <Footer type={type} /> : null}
      </Loader>
    </div>
  );
}

export default Main;
