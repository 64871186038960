import { combineEpics } from 'redux-observable';
import { getExploreSearchResultsEpic, getMoreExploreSearchResultsEpic, getExploreSearchSummaryEpic } from './ExploreList/logic';
import { getTrendingTopicsDataEpic } from './TrendingTopics/logic';
import { getWordCloudDataEpic } from './WordCloud/logic';
import { getLiteratureSummaryDataEpic } from './LiteratureSummaryCard/logic';
import { getGrantDataEpic } from './GrantBarGraph/logic';
import { getCtSectionListEpic, getCtRelatedArticlesEpic, getDocsSEEpic } from '../components/PreviewSectionCard/logic';
import { getQuestionsDataEpic } from './RecommendedQuestions/logic';

const rootSearchAndExploreEpic = combineEpics(
  getExploreSearchResultsEpic,
  getMoreExploreSearchResultsEpic,
  getExploreSearchSummaryEpic,
  getTrendingTopicsDataEpic,
  getWordCloudDataEpic,
  getLiteratureSummaryDataEpic,
  getGrantDataEpic,
  getCtSectionListEpic,
  getCtRelatedArticlesEpic,
  getQuestionsDataEpic,
  getDocsSEEpic,
);

export default rootSearchAndExploreEpic;
