import mixpanel from 'mixpanel-browser';
import CryptoJS from 'crypto-js';
import { getCookie, getTempSessionId } from '.';

const envCheck = process.env.type !== 'development';

export const encrypt = (text) => CryptoJS.AES.encrypt(text.toLowerCase(), 'ontosight-sky').toString();

const Mixpanel = {
  identify: (id) => {
    if (envCheck) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (envCheck) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (envCheck) {
      if (getCookie('accessToken')) {
        mixpanel.track(name, {
          ...props,
          pseudonymised_email_id: encrypt(getCookie('userEmail')),
          temporary_session: getTempSessionId(),
          user_logged_in: true,
        });
      } else if (getCookie('userEmail')) {
        mixpanel.track(name, {
          ...props,
          pseudonymised_email_id: encrypt(getCookie('userEmail')),
          temporary_session: getTempSessionId(),
          user_logged_in: false,
        });
      } else {
        mixpanel.track(name, { ...props, temporary_session: getTempSessionId() });
      }
    }
  },
  people: {
    set: (props) => {
      if (envCheck) {
        mixpanel.people.set(props);
      }
    },
  },
};

export default Mixpanel;
