/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SvgLoader from '../SvgLoader';
import { persistor } from '../../../store';
import Mixpanel from '../../../utils/mixpanel';

function replaceQuotes(str) {
  return str.replace(/"/g, "'");
}

function SemanticSearch({
  setShow, setGoBtnDissable, goClick, goBtnDissable, setIsSemanticSearch, show,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [semanticValue, setSemanticValue] = useState('');
  const semanticQuery = searchParams.get('semanticquery');

  const handleChange = (val) => {
    setSemanticValue(replaceQuotes(val));
    if (val?.length > 2) {
      setIsSemanticSearch(true);
      setGoBtnDissable(false);
    } else {
      setIsSemanticSearch(false);
      setGoBtnDissable(true);
    }
  };

  useEffect(() => {
    if (semanticQuery?.length) {
      const query = JSON.parse(semanticQuery)[0];
      setSemanticValue(query.currentFilter[0]);
      setShow('semantic');
    }
  }, []);

  useEffect(() => {
    if (show === 'power' && semanticQuery?.length) {
      setSemanticValue('');
      setGoBtnDissable(true);
      setIsSemanticSearch(false);
      navigate(location.pathname, { replace: true });
    }
  }, [show]);

  useEffect(() => {
    if (semanticQuery?.length) {
      setGoBtnDissable(false);
      setIsSemanticSearch(true);
    }
  }, [JSON.stringify(semanticValue)]);

  const semanticApiCall = () => {
    setIsSemanticSearch(false);
    const tempQuery = [{
      field_name: 'semantic_search',
      currentFilter: [encodeURIComponent(semanticValue)],
      type: 'sematic',
    }];
    if (!goBtnDissable && location.pathname === '/explore' && semanticValue !== '') {
      Mixpanel.track('search_type_event', {
        action: 'go click semantic search',
        page: 'Search Page',
        terminal_name: 'Search and Explore',
        type_of_search: 'semantic search',
        query: semanticValue,
      });

      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: 'Search Page',
        terminal_name: 'Search and Explore',
      });

      navigate(`/explore/analytics?semanticquery=${JSON.stringify(tempQuery)}`);
      persistor.purge();
    } else if (!goBtnDissable && semanticValue !== '') {
      Mixpanel.track('search_type_event', {
        action: 'go click semantic search',
        page: 'Search Page',
        terminal_name: 'Clinical Trials',
        type_of_search: 'semantic search',
        query: semanticValue,
      });

      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: 'Search Page',
        terminal_name: 'Clinical Trials',
      });

      navigate(`/clinicalTrials/analyticsResults?semanticquery=${JSON.stringify(tempQuery)}`);
      persistor.pause();
    }
  };

  useEffect(() => {
    if (goClick) {
      semanticApiCall();
    }
  }, [goClick]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      semanticApiCall();
    }
  };

  return (
    <div className="senmantic-search">
      <div className="senmantic-search-input-container">
        <input
          onFocus={() => setShow('semantic')}
          className="senmantic-search-input"
          placeholder="Example: Atopic Dermatitis in Germany since 2015"
          value={semanticValue}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {semanticValue?.length
          ? <SvgLoader className="cancel-search-icon" width={24} height={24} svgName="cancel-search-icon" onClick={() => { setSemanticValue(''); }} />
          : <SvgLoader className="search-icon" width={24} height={24} svgName="search-icon" />}
      </div>
    </div>
  );
}

SemanticSearch.propTypes = {
  setShow: PropTypes.func.isRequired,
  goClick: PropTypes.bool.isRequired,
  setIsSemanticSearch: PropTypes.func.isRequired,
  setGoBtnDissable: PropTypes.func.isRequired,
  goBtnDissable: PropTypes.bool.isRequired,
  show: PropTypes.string.isRequired,
};

export default SemanticSearch;
