import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_LICENCE = 'GET_LICENCE';
const GET_LICENCE_SUCCESS = 'GET_LICENCE_SUCCESS';
const GET_LICENCE_FAILURE = 'GET_LICENCE_FAILURE';
const GET_LICENCE_REFRESH = 'GET_LICENCE_REFRESH';

export const getLicenceAction = createAction(GET_LICENCE);

const getLicenceSuccess = createAction(GET_LICENCE_SUCCESS);

const getLicenceFaliure = createAction(GET_LICENCE_FAILURE);

export const getLicenceRefreshAction = createAction(GET_LICENCE_REFRESH);

export const getLicenceEpic = (actions$) => actions$.pipe(
  ofType(GET_LICENCE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/getLicense?${action.payload.headers}`, 'get')).pipe(
    map((res) => getLicenceSuccess(res)),
    catchError((err) => of(getLicenceFaliure(err))),
  )),
);

const ongetLicenceAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetLicenceSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  flag: true,
});

const ongetLicenceFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetLicenceRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

const getLicenceReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getLicenceAction, (state) => ongetLicenceAction(state))
      .addCase(getLicenceSuccess, (state, action) => ongetLicenceSuccess(state, action.payload))
      .addCase(getLicenceFaliure, (state) => ongetLicenceFailure(state))
      .addCase(getLicenceRefreshAction, () => ongetLicenceRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default getLicenceReducer;
