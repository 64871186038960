import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_WORDCLOUD_DATA = 'GET_WORDCLOUD_DATA';
const GET_WORDCLOUD_DATA_SUCCESS = 'GET_WORDCLOUD_DATA_SUCCESS';
const GET_WORDCLOUD_DATA_FAILURE = 'GET_WORDCLOUD_DATA_FAILURE';
const GET_WORDCLOUD_DATA_REFRESH = 'GET_WORDCLOUD_DATA_REFRESH';

export const getWordCloudDataAction = createAction(GET_WORDCLOUD_DATA);

const getWordCloudDataSuccess = createAction(GET_WORDCLOUD_DATA_SUCCESS);

const getWordCloudDataFaliure = createAction(GET_WORDCLOUD_DATA_FAILURE);

export const getWordCloudDataRefresh = createAction(GET_WORDCLOUD_DATA_REFRESH);

export const getWordCloudDataEpic = (actions$) => actions$.pipe(
  ofType(GET_WORDCLOUD_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/word-cloud?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getWordCloudDataSuccess(res)),
    catchError((err) => of(getWordCloudDataFaliure(err))),
  )),
);

const ongetWordCloudDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const ongetWordCloudDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetWordCloudDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetWordCloudDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getWordCloudDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getWordCloudDataAction, (state) => ongetWordCloudDataAction(state))
      .addCase(getWordCloudDataSuccess, (state, action) => ongetWordCloudDataSuccess(state, action.payload))
      .addCase(getWordCloudDataFaliure, (state) => ongetWordCloudDataFailure(state))
      .addCase(getWordCloudDataRefresh, () => ongetWordCloudDataRefresh())
      .addDefaultCase((state) => state);
  },
);
