import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Modal, Form, Select, Input, Dropdown,
  DatePicker,
  Menu,
} from 'antd';
import queryString from 'query-string';
import Header from '../../../common/components/Header';
import Footer from '../../../common/components/Footer';
import { debounceCall, getCookie } from '../../../utils';
import SvgLoader from '../../../common/components/SvgLoader';
import {
  getOrganizationValuesAction, inviteUsersAction, getUsersListAction, inviteUsersRefreshAction, createOrganizationAction, createOrganizationRefreshAction, editOrganizationAction,
  editOrganizationRefreshAction, updateUserStatusAction, getUsersListRefreshAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import { addLicenseAction, openLoginModalAction } from '../LoginModalV2/logic';
import { sendNotification } from '../../../common/components/Notification/logic';
import Notification from '../../../common/components/Notification';
import LoginModalV2 from '../LoginModalV2';
import AdminDropdown from '../AdminDropdown';

const { Search } = Input;

function Admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUpdateConfirmationModalOpen, setIsUpdateConfirmationModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('user_list');
  const [searchText, setSearchText] = useState('');
  const [currentDropdown, setCurrentDropdown] = useState(-1);
  const [formValue, setFormValue] = useState({
    organizationId: '',
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    organizationName: '',
    job: '',
    organizationType: '',
    domain: '',
  });

  const organizationValues = useSelector((state) => state.organizationValues);
  const usersList = useSelector((state) => state.usersList);
  const inviteUsers = useSelector((state) => state.inviteUsers);
  const createOrganization = useSelector((state) => state.createOrganization);
  const editOrganization = useSelector((state) => state.editOrganization);
  const userStatusUpdate = useSelector((state) => state.userStatusUpdate);
  const LicenseStatus = useSelector((state) => state.LicenseStatus);

  const [form] = Form.useForm();

  useEffect(() => {
    if (userStatusUpdate.flag) {
      if (userStatusUpdate.data.message === 'Status updated successfully') {
        dispatch(sendNotification({
          type: 'USER_UPDATE_SUCCESS',
          notificationType: 'success',
          message: 'Status updated successfully',
          showNotification: true,
        }));
        setTimeout(() => {
          dispatch(getUsersListRefreshAction());
          dispatch(getUsersListAction(queryString.stringify({ from: 1, size: 200 })));
        }, 200);
      }
    }
  }, [JSON.stringify(userStatusUpdate)]);

  useEffect(() => {
    if (LicenseStatus.flag) {
      dispatch(sendNotification({
        type: 'USER_Licence_SUCCESS',
        notificationType: 'success',
        message: 'Licence updated successfully',
        showNotification: true,
      }));
      setCurrentDropdown(-1);
    }
  }, [JSON.stringify(LicenseStatus)]);

  const showModal = () => {
    setIsModalOpen(true);
    setFormValue({
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      job: '',
      organizationType: '',
      organizationName: '',
      domain: '',
    });
  };

  const apiCall = () => {
    if (selectedTopic === 'user_list') {
      const request = {
        from: 1,
        size: 200,
      };
      if (getCookie('accessToken') && getCookie('permissions')?.includes('ot-admin')) {
        dispatch(getUsersListAction(queryString.stringify(request)));
      }
    } else if (selectedTopic === 'organization_list') {
      if (searchText) {
        const request = {

          search: searchText,
        };
        if (getCookie('accessToken') && getCookie('permissions')?.includes('ot-admin')) {
          dispatch(getOrganizationValuesAction(queryString.stringify(request)));
        }
      } else {
        const request = {
          search: '',

        };
        if (getCookie('accessToken') && getCookie('permissions')?.includes('ot-admin')) {
          dispatch(getOrganizationValuesAction(queryString.stringify(request)));
        }
      }
    }
  };

  useEffect(() => {
    if (inviteUsers.flag) {
      apiCall();
    }
  }, [JSON.stringify(inviteUsers)]);

  useEffect(() => {
    if (searchText !== '') {
      apiCall();
    }
  }, [searchText]);

  useEffect(() => {
    if (createOrganization.flag) {
      apiCall();
    }
  }, [JSON.stringify(createOrganization)]);

  useEffect(() => {
    if (editOrganization.flag) {
      apiCall();
    }
    if (editOrganization?.data?.error?.statusCode === 409) {
      dispatch(editOrganizationRefreshAction());
      setIsFailureModalOpen(true);
    }
  }, [JSON.stringify(editOrganization)]);

  useEffect(() => {
    apiCall();
  }, [selectedTopic]);

  useEffect(() => {
    if (getCookie('accessToken') && !getCookie('permissions')?.includes('ot-admin')) {
      navigate('/');
      navigate(0);
    }

    if (!getCookie('accessToken') && getCookie('userEmail')) {
      debounceCall(() => dispatch(openLoginModalAction()), 200);
    } else {
      dispatch(getOrganizationValuesAction());
    }
  }, []);

  useEffect(() => {
    if (inviteUsers.flag) {
      dispatch(sendNotification({
        type: 'GET_USERS_LIST_SUCCESS',
        notificationType: 'success',
        message: 'Invite Sent Successfully',
        showNotification: true,
      }));
    }
  }, [JSON.stringify(inviteUsers)]);

  useEffect(() => {
    if (inviteUsers.error) {
      setFormValue({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        job: '',
      });
    }
  }, [JSON.stringify(inviteUsers)]);

  const handleEditCancel = () => {
    setIsUpdateModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(false);
    setIsUpdateConfirmationModalOpen(false);
    setIsFailureModalOpen(false);
    setFormValue({
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      job: '',
    });
    form.resetFields();
  };
  const formValueChange = (e, keyName) => {
    if (keyName === 'firstName') {
      setFormValue({
        ...formValue,
        firstName: e.target.value,
      });
    }
    if (keyName === 'lastName') {
      setFormValue({
        ...formValue,
        lastName: e.target.value,
      });
    }
    if (keyName === 'email') {
      setFormValue({
        ...formValue,
        email: e.target.value,
      });
    }
    if (keyName === 'job') {
      setFormValue({
        ...formValue,
        job: e.target.value,
      });
    }
    if (keyName === 'organizationName') {
      setFormValue({
        ...formValue,
        organizationName: e.target.value,
      });
    }
    if (keyName === 'organizationType') {
      setFormValue({
        ...formValue,
        organizationType: e.target.value,
      });
    }
    if (keyName === 'domain') {
      setFormValue({
        ...formValue,
        domain: e.target.value,
      });
    }
  };

  const handleOrganizationChange = (value) => {
    setFormValue({
      ...formValue,
      organization: value,
    });
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const handleClick = () => {
    if (selectedTopic === 'user_list') {
      setFormValue({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        job: '',
      });
      setIsModalOpen(false);
      form.resetFields();
      dispatch(inviteUsersAction({
        body: {
          name: `${formValue.firstName} ${formValue.lastName}`,
          email: formValue.email,
          organization_id: formValue.organization,
          job_title: formValue.job,
          roles: [
            'clinical_trials',
            'search_and_explore',
            'intervention',
          ],
        },
      }));
    } else if (selectedTopic === 'organization_list') {
      dispatch(createOrganizationAction({
        body: {
          organization_name: formValue.organizationName,
          organization_type: formValue.organizationType,
          domain: formValue.domain,
        },
      }));
      setFormValue({
        organizationName: '',
        organizationType: '',
        domain: '',
      });
      setIsModalOpen(false);
      form.resetFields();
    }
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  useEffect(() => {
    if (inviteUsers.flag) {
      dispatch(inviteUsersRefreshAction());
      setIsConfirmationModalOpen(true);
      setFormValue({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        job: '',
      });
      setIsModalOpen(false);
    } else if (inviteUsers?.data?.error?.statusCode === 409) {
      dispatch(inviteUsersRefreshAction());
      setIsFailureModalOpen(true);
      setIsModalOpen(false);
    }
  }, [JSON.stringify(inviteUsers)]);

  useEffect(() => {
    if (createOrganization.flag) {
      dispatch(createOrganizationRefreshAction());
      setIsConfirmationModalOpen(true);
      setFormValue({
        organizationType: '',
        organizationName: '',
        domain: '',
      });
      setIsModalOpen(false);
    } else if (createOrganization?.data?.error?.statusCode === 409) {
      dispatch(createOrganizationRefreshAction());
      setIsFailureModalOpen(true);
      setIsModalOpen(false);
    }
  }, [JSON.stringify(createOrganization)]);

  useEffect(() => {
    if (editOrganization.flag) {
      dispatch(editOrganizationRefreshAction());
      setIsUpdateConfirmationModalOpen(true);
      setIsUpdateModalOpen(false);
    }
  }, [JSON.stringify(editOrganization)]);

  function capitalizeFirstLetter(str) {
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  const onChangeInput = (e) => {
    if (e.target.value !== '') {
      setSearchText(e.target.value);
      const request = {

        search: e.target.value,

      };
      dispatch(getOrganizationValuesAction(queryString.stringify(request)));
    } else {
      setSearchText('');
      const request = {

        search: '',

      };
      dispatch(getOrganizationValuesAction(queryString.stringify(request)));
    }
  };

  const renderUserFullName = (item) => {
    if (item.first_name && item.last_name) {
      return `${capitalizeFirstLetter(item.first_name)} ${capitalizeFirstLetter(item.last_name)}`;
    }

    if (item.first_name) {
      return capitalizeFirstLetter(item.first_name);
    }

    if (item.last_name) {
      return capitalizeFirstLetter(item.last_name);
    }
    return '-';
  };

  const handleMenuClick = (e, item) => {
    if (e.key === 'activate') {
      dispatch(updateUserStatusAction({
        headers: queryString.stringify({
          email: item.email,
          status: 'approved',
        }),
      }));
    }

    if (e.key === 'deactivate') {
      dispatch(updateUserStatusAction({
        headers: queryString.stringify({
          email: item.email,
          status: 'rejected',
        }),
      }));
    }
  };

  const handleDatePickerClick = (index) => {
    setCurrentDropdown(index);
  };

  const handleOnChangeDatePicker = (date, item) => {
    const timestamp = date.valueOf();
    dispatch(addLicenseAction({
      body: {
        email: item.email,
        license_type: 'licensed',
        trial_start_date: 0,
        trial_end_date: 0,
        license_start_date: Date.now(),
        license_end_date: timestamp,
        is_active: true,
        details: 'trial or org',
      },
    }));
  };

  const ctrDropdownRender = (item, index) => {
    if (item.status === 'pending') {
      return (
        <Menu onClick={(e) => handleMenuClick(e, item)}>
          <Menu.Item key="activate"><div className="activate-box">Approve</div></Menu.Item>
          <Menu.Item key="deactivate"><div className="deative-box">Reject</div></Menu.Item>
        </Menu>
      );
    }

    if (item.status === 'rejected') {
      return (
        <Menu onClick={(e) => handleMenuClick(e, item)}>
          <Menu.Item key="activate"><div className="activate-box">Activate</div></Menu.Item>
        </Menu>
      );
    }

    if (item.status === 'NA') {
      return (
        <Menu onClick={(e) => handleMenuClick(e, item)}>
          <Menu.Item key="update"><DatePicker onClick={() => handleDatePickerClick(index)} placeholder="Update expiry date" onChange={(e) => handleOnChangeDatePicker(e, item)} /></Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu onClick={(e) => handleMenuClick(e, item)}>
        <Menu.Item key="deactivate"><div className="deative-box">Deactivate</div></Menu.Item>
        <Menu.Item key="update"><DatePicker onClick={() => handleDatePickerClick(index)} placeholder="Update expiry date" onChange={(e) => handleOnChangeDatePicker(e, item)} /></Menu.Item>
      </Menu>
    );
  };

  const dropdownRender = (item, index) => (
    <div>
      {ctrDropdownRender(item, index)}
    </div>
  );

  const renderUsersList = () => (
    <>
      <div className="upper-container">
        <div className="users-list-count">
          List of users :
          {' '}
          {usersList?.data?.length}
        </div>
        <div className="btn-container">
          {/* <Button type="primary" onClick={showModal}>
            Invite User
          </Button> */}
          <Modal
            title="Invite User"
            style={{
              height: '100vh',
            }}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            className="admin-invite-user-modal"
          >
            <div className="invite-user">
              <Form
                form={form}
                name="invite-user-form"
                layout="vertical"
              >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the first name!',
                    },
                  ]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="First Name"
                    value={formValue.firstName}
                    onChange={(e) => formValueChange(e, 'firstName')}
                  />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the last name!',
                    },
                  ]}
                >
                  <Input
                    required
                    className="input-wrapper"
                    placeholder="Last Name"
                    value={formValue.lastName}
                    onChange={(e) => formValueChange(e, 'lastName')}
                  />

                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input the email!' },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Email"
                    value={formValue.email}
                    onChange={(e) => formValueChange(e, 'email')}
                  />

                </Form.Item>

                <Form.Item
                  name="role"
                  label="Organization Name"
                  rules={[
                    { required: true, message: 'Please Select the organization name!' },
                  ]}
                >
                  <Select
                    onChange={(e) => handleOrganizationChange(e)}
                    value={formValue.organization}
                    placeholder="Organization Name"
                  >

                    {organizationValues.data.map((itm) => (
                      <Select.Option value={itm.organization_id}>{itm.organization_name}</Select.Option>

                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Job Title"
                  name="job_title"
                  rules={[
                    { required: true, message: 'Please input the Job Title!' },
                    {
                      message: 'Please enter a valid email address!',
                    },
                  ]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Job Title"
                    value={formValue.job}
                    onChange={(e) => formValueChange(e, 'job')}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="default"
                    htmlType="button"
                    disabled={formValue.firstName === '' || formValue.lastName === '' || formValue.email === '' || formValue.job === '' || formValue?.organization?.length === 0}
                    onClick={handleClick}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Form>
            </div>
          </Modal>
          {isConfirmationModalOpen && (
            <Modal
              open={isConfirmationModalOpen}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              className="admin-invite-user-success-modal"
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <div className="invite-success-icon">
                <SvgLoader svgName="success-signup" width={120} height={120} style={{ display: 'flex' }} />

              </div>
              <div className="invite-success-msg">Invite has been sent successfully</div>
            </Modal>
          )}

          {isFailureModalOpen && (
            <Modal
              open={isFailureModalOpen}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              className="admin-invite-user-success-modal"
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <div className="invite-success-icon">
                <SvgLoader svgName="failure" width={120} height={120} style={{ display: 'flex' }} />

              </div>
              <div className="invite-success-msg">User already invited</div>
            </Modal>
          )}

        </div>
      </div>
      <div className="lower-container-users">
        <div className="table-header">
          <div className="table-name">Name</div>
          <div className="table-email">Email ID</div>
          <div className="table-user-type">User Type</div>
          <div className="table-orgainization">Organization</div>
          <div className="table-access-provided-date">Access provided on</div>
          <div className="table-user-activity-status">
            User Activity Status
          </div>
          <div className="table-deactivation-date">
            Deactivation Planned on
          </div>
          <div className="table-first-reminder">First Reminder</div>
          <div className="table-second-reminder">Second Reminder</div>
          <div className="table-deactivated-on">Deactivated on</div>
        </div>
        <Loader
          error={usersList.error}
          loading={usersList.loading}
          height="250px"
          className="white-loader"
          message="No records found"
        >
          {usersList.data.map((itm, index) => (
            <div className="table-body">
              <div className="table-value-name">{renderUserFullName(itm)}</div>
              <div className="table-value-email">{itm.email}</div>
              <div className="table-value-user-type">{capitalizeFirstLetter(itm.user_type)}</div>
              <div className="table-value-orgainization">{itm.organization_name}</div>
              <div className="table-value-access-provided-date">
                {formatDate(itm.access_provided_on)}
              </div>
              <div className="table-value-user-activity-status">
                <div className={itm.status === 'not_active' ? ('table-value-user-activity-status-not-active') : (itm.status === 'disabled' ? ('table-value-user-activity-status-disabled') : ('table-value-user-activity-status-active'))}>

                  {capitalizeFirstLetter(itm.status)}
                </div>
              </div>
              <div className="table-value-deactivation-date">
                {formatDate(itm.planned_deactivation_on)}
              </div>
              <div className="table-value-first-reminder">{formatDate(itm.first_remainder_on)}</div>
              <div className="table-value-second-reminder">
                {formatDate(itm.second_remainder_on)}
              </div>
              <div className="table-value-deactivated-on">{itm.deleted_on === null ? ('-') : (formatDate(itm.deleted_on))}</div>
              {
                itm.status === 'NA' || itm.status === 'active' || itm.status === 'approved'
                  ? (
                    <Dropdown onOpenChange={() => setCurrentDropdown(index)} open={currentDropdown === index} dropdownRender={() => dropdownRender(itm, index)} trigger={['click']}>
                      <div aria-hidden onClick={() => setCurrentDropdown(index)} className="three-dots pointer" />
                    </Dropdown>
                  )
                  : (
                    <Dropdown dropdownRender={() => dropdownRender(itm, index)} trigger={['click']}>
                      <div aria-hidden onClick={() => setCurrentDropdown(index)} className="three-dots pointer" />
                    </Dropdown>
                  )

              }
            </div>
          ))}
        </Loader>

      </div>
    </>
  );

  const handleUpdateClick = () => {
    setIsUpdateModalOpen(false);
    const request = {
      headers: queryString.stringify({
        organization_id: formValue.organizationId,
      }),
      body: {

        organization_name: formValue.organizationName,
        organization_type: formValue.organizationType,
        domain: formValue.domain,

      },
    };
    dispatch(editOrganizationAction(request));
  };

  const handleUpArrowClickOrgId = () => {
    const request = {

      sort: 'organization_id',
      order: 'asc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };
  const handleUpArrowClickOrgName = () => {
    const request = {

      sort: 'organization_name',
      order: 'asc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };
  const handleUpArrowClickOrgType = () => {
    const request = {

      sort: 'organization_type',
      order: 'asc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };

  const handleDownArrowClickOrgType = () => {
    const request = {

      sort: 'organization_type',
      order: 'desc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };

  const handleDownArrowClickOrgId = () => {
    const request = {

      sort: 'organization_id',
      order: 'desc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };
  const handleDownArrowClickOrgName = () => {
    const request = {

      sort: 'organization_name',
      order: 'desc',
      search: searchText,

    };
    dispatch(getOrganizationValuesAction(queryString.stringify(request)));
  };

  const options = [
    { key: 'external', label: 'External' },
    { key: 'internal', label: 'Internal' },
  ];

  const handleEditClick = (itm) => {
    setFormValue({
      ...formValue, organizationName: itm.organization_name, organizationType: itm.organization_type, domain: itm.domain, organizationId: itm.organization_id,
    });
    setIsUpdateModalOpen(true);
  };

  const handleOrgTypeUpdate = (value) => {
    setFormValue({ ...formValue, organizationType: value });
  };

  const handlePrefilled = () => {
    if (formValue.organizationType === 'internal') {
      return { key: 'internal', label: 'Internal' };
    }
    return { key: 'external', label: 'External' };
  };

  const renderOrganizationsList = () => (
    <>
      <div className="upper-container">
        <div className="users-list-count">
          List of Organizations :
          {' '}
          {organizationValues?.data?.length}
        </div>
        <div className="search-bar intervention-search">
          <div className="intervention-search-content">
            <Search
              placeholder="Enter organization"
              allowClear
              size="large"
              maxLength="256"
              onChange={onChangeInput}
            />
          </div>
        </div>
        <div className="btn-container">
          <Button type="primary" onClick={showModal}>
            Create organization
          </Button>
          <Modal
            title="Create organization"
            style={{
              height: '100vh',
            }}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            className="admin-invite-user-modal"
          >
            <div className="invite-user">
              <Form
                form={form}
                name="invite-user-form"
                layout="vertical"
              >
                <Form.Item
                  label="Organization name"
                  name="organizationName"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the organization name!',
                    },
                  ]}
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Organization name"
                    value={formValue.organization}
                    onChange={(e) => formValueChange(e, 'organizationName')}
                  />
                </Form.Item>

                <Form.Item
                  label="Organization Type"
                  name="organizationType"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the organization type!',
                    },
                  ]}
                >
                  <AdminDropdown options={options} handleOrgTypeUpdate={handleOrgTypeUpdate} />
                </Form.Item>

                <Form.Item
                  label="Domain"
                  name="domain"
                >
                  <Input
                    className="input-wrapper"
                    placeholder="Domain"
                    value={formValue.domain}
                    onChange={(e) => formValueChange(e, 'domain')}
                  />

                </Form.Item>

                <Form.Item>
                  <Button
                    type="default"
                    htmlType="button"
                    disabled={formValue.organizationName === '' || formValue.organizationType === ''}
                    onClick={handleClick}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Form>
            </div>
          </Modal>
          {isConfirmationModalOpen && (
            <Modal
              open={isConfirmationModalOpen}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              className="admin-invite-user-success-modal"
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <div className="invite-success-icon">
                <SvgLoader svgName="success-signup" width={120} height={120} style={{ display: 'flex' }} />

              </div>
              <div className="invite-success-msg">Organization created successfully</div>
            </Modal>
          )}
          {isFailureModalOpen && (
            <Modal
              open={isFailureModalOpen}
              onCancel={handleCancel}
              okButtonProps={{ style: { display: 'none' } }}
              className="admin-invite-user-success-modal"
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <div className="invite-success-icon">
                <SvgLoader svgName="failure" width={120} height={120} style={{ display: 'flex' }} />

              </div>
              <div className="invite-success-msg">Organization already exists</div>
            </Modal>
          )}
        </div>
      </div>
      <div className="lower-container-organization">
        <div className="table-header">
          <div className="table-org-id-container">
            <div className="table-org-id">Organization ID</div>
            <div className="up-arrow" onClick={handleUpArrowClickOrgId} role="presentation" />
            <div className="down-arrow" onClick={handleDownArrowClickOrgId} role="presentation" />
          </div>
          <div className="table-org-name-container">
            <div className="table-org-name">Organization name</div>
            <div className="up-arrow" onClick={handleUpArrowClickOrgName} role="presentation" />
            <div className="down-arrow" onClick={handleDownArrowClickOrgName} role="presentation" />
          </div>
          <div className="table-org-type-container">
            <div className="table-org-type">Organization type</div>
            <div className="up-arrow" onClick={handleUpArrowClickOrgType} role="presentation" />
            <div className="down-arrow" onClick={handleDownArrowClickOrgType} role="presentation" />
          </div>
          <div className="table-org-domain">Domain</div>
          <div className="edit-icon" />
        </div>
        <Loader
          error={organizationValues.error}
          loading={organizationValues.loading}
          height="250px"
          className="white-loader"
          message="No records found"
        >
          {organizationValues.data.map((itm) => (
            <div className="table-body">
              <div className="table-value-org-id">{itm?.organization_id}</div>
              <div className="table-value-org-name">{itm?.organization_name}</div>
              <div className="table-value-org-type">{itm?.organization_type}</div>
              <div className="table-value-org-domain">{itm?.domain}</div>
              <div className="edit-icon">
                <SvgLoader width={32} height={32} svgName="edit-icon-hover" onClick={() => handleEditClick(itm)} />

              </div>
            </div>
          ))}
        </Loader>

      </div>
    </>
  );

  return (
    <div className="admin-page">
      <div className="admin-main-page">
        <Header className="results-header-light" type="light" />
      </div>
      <div className="page-body">
        <div className="page-body-card">
          <div className="subtopics">
            <button
              type="button"
              className={selectedTopic === 'user_list' ? 'selected' : ''}
              onClick={() => handleTopicClick('user_list')}
            >
              Users List
            </button>
            <button
              type="button"
              className={selectedTopic === 'organization_list' ? 'selected' : ''}
              onClick={() => handleTopicClick('organization_list')}
            >
              Organizations List
            </button>
          </div>
          <div className="line" />
          {selectedTopic === 'user_list' ? renderUsersList() : renderOrganizationsList()}
        </div>
      </div>
      <div className="admin-footer">
        <Footer />
      </div>
      <Notification />
      <LoginModalV2 />
      {isUpdateModalOpen && (
        <Modal
          title="Edit Organization"
          style={{
            height: '100vh',
            border: 'none',
          }}
          open={isUpdateModalOpen}
          onCancel={handleEditCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          className="admin-invite-user-modal"
        >
          <div className="invite-user">
            <Form
              form={form}
              name="invite-user-form"
              layout="vertical"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please input the organization name!',
                  },
                ]}
                label="Organization name"
              >
                <Input
                  required
                  className="input-wrapper"
                  placeholder="Organization name"
                  value={formValue?.organizationName}
                  onChange={(e) => formValueChange(e, 'organizationName')}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please input the organization name!',
                  },
                ]}
                label="Organization Type "
              >
                {/* <Select
                  required
                  className="input-wrapper"
                  placeholder="Select Organization Type"
                  value={formValue?.organizationType}
                  onChange={(value) => formValueChange({ target: { value } }, 'organizationType')}
                >
                  <Select.Option value="internal">Internal</Select.Option>
                  <Select.Option value="external">External</Select.Option>

                </Select> */}
                <AdminDropdown options={options} prefilled={() => handlePrefilled()} handleOrgTypeUpdate={handleOrgTypeUpdate} />

              </Form.Item>

              <Form.Item
                label="Domain"
              >
                <Input
                  className="input-wrapper"
                  placeholder="Domain"
                  value={formValue?.domain}
                  onChange={(e) => formValueChange(e, 'domain')}
                />

              </Form.Item>

              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  disabled={formValue.organizationName === '' || formValue.organizationType === ''}
                  onClick={() => handleUpdateClick()}
                >
                  Update
                </Button>

              </Form.Item>
            </Form>
          </div>
        </Modal>
      )}
      {isUpdateConfirmationModalOpen && (
        <Modal
          open={isUpdateConfirmationModalOpen}
          onCancel={handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          className="admin-invite-user-success-modal"
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className="invite-success-icon">
            <SvgLoader svgName="success-signup" width={120} height={120} style={{ display: 'flex' }} />

          </div>
          <div className="invite-success-msg">Organization updated successfully</div>
        </Modal>
      )}
      {isFailureModalOpen && (
      <Modal
        open={isFailureModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        className="admin-invite-user-success-modal"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className="invite-success-icon">
          <SvgLoader svgName="failure" width={120} height={120} style={{ display: 'flex' }} />

        </div>
        <div className="invite-success-msg">Organization already exists</div>
      </Modal>
      )}
    </div>
  );
}

export default Admin;
