import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import { debounceCall, modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import AnalyticsContainer from '../../container/AnalyticsContainer';
import { getSearchResultsAction } from '../../../common/container/List/logic';
import { showBackButtonAction } from '../../container/Geographical/logic';
import Mixpanel from '../../../utils/mixpanel';

function CTAnalyticsPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.searchResult);
  const showBackButton = useSelector((state) => state.showBackButton);
  const query = searchParams.get('query');
  const sort = searchParams.get('sort');
  const semanticQuery = searchParams.get('semanticquery');
  const filters = searchParams.get('filters');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), false, 'ctfilters');
  const autoSuggestUrl = '/clinical-trials/v0/search/getFieldValues';
  const terminalName = 'clinical_trials';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q);
  const out = merge(requestData, filtersData);
  const projId = getCookie('projectId');
  const params = {
    headers: {
      terminal_name: terminalName,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  if (sort) {
    params.headers.sort = sort;
  }

  useEffect(() => {
    debounceCall(() => {
      Mixpanel.track('page_entry_event', {
        action: 'User has entered Clinck Analytics page',
        terminal_name: 'clinical_trials',
        page: 'Analytics Page',
      });
    }, 100);
  }, []);

  if (semanticQuery) {
    const nq = JSON.parse(semanticQuery)[0].currentFilter[0];
    params.headers.natural_query = nq;
  }

  const apiCall = () => {
    const request = {
      apiUrl: '/clinical-trials/v0/search/getSearchResults',
      ...params,
      headers: queryString.stringify({
        ...params.headers,
        page: 1,
        size: 1,
        project_id: projId,
      }),
    };
    debounceCall(() => dispatch(getSearchResultsAction(request)), 50);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" />
      <div className="ct-results-page">
        <div className="card-view-nav">
          <div className="ct-results-total-count-header">
            {(showBackButton.isExpanded)
              ? (
                <div
                  className="back-btn-container"
                  role="presentation"
                  onClick={() => {
                    dispatch(showBackButtonAction(false));
                  }}
                >
                  <span className="back-arrow" />
                  <span>Back</span>
                </div>
              )
              : null}
            <div className="cl-results-info-text">{`${searchResult?.data?.total ? searchResult?.data?.total : 0} Results`}</div>
          </div>
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="analytics-container">
          <AnalyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default CTAnalyticsPage;
