const sponserToggleFields = [
  { label: 'All', key: 'all' },
  { label: 'Industry', key: 'industrial' },
  { label: 'Research Institute', key: 'research-institutes' },
  { label: 'unversety', key: 'universities' },
  { label: 'Hospital', key: 'hospitals' },
  { label: 'Federal Agency', key: 'federal-agencies' },
  { label: 'Physician', key: 'physician' },
  { label: 'Other', key: 'other' },
];

export const keyFieldMap = {
  industrial: 'Industry',
  'research-institutes': 'Research Institute',
  universities: 'unversety',
  hospitals: 'Hospital',
  'federal-agencies': 'Federal Agency',
  physician: 'Physician',
  other: 'Other',
  all: 'All',
};

export const keyFieldMapRevers = {
  Industry: 'industrial',
  'Research Institute': 'research-institute',
  unversety: 'universities',
  Hospital: 'hospitals',
  'Federal Agency': 'federal-agencies',
  Physician: 'physician',
  Other: 'other',
  All: 'all',
};

export const Mapfields = {
  Industry: 'industrial',
  'Research Institutes': 'research-institutes',
  Universities: 'universities',
  Hospitals: 'hospitals',
  'Federal Agency': 'federal-agencies',
  Physician: 'physician',
  Other: 'other',
  All: 'all',
};

export default sponserToggleFields;
