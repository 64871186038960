import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_ENDPOINTS_GRAPH_DATA = 'GET_ENDPOINTS_GRAPH_DATA';
const GET_ENDPOINTS_GRAPH_DATA_SUCCESS = 'GET_ENDPOINTS_GRAPH_DATA_SUCCESS';
const GET_ENDPOINTS_GRAPH_DATA_FAILURE = 'GET_ENDPOINTS_GRAPH_DATA_FAILURE';
const GET_ENDPOINTS_GRAPH_DATA_REFRESH = 'GET_ENDPOINTS_GRAPH_DATA_REFRESH';

export const getEndpointsGraphDataAction = createAction(GET_ENDPOINTS_GRAPH_DATA);

const getEndpointsGraphDataSuccess = createAction(GET_ENDPOINTS_GRAPH_DATA_SUCCESS);

const getEndpointsGraphDataFaliure = createAction(GET_ENDPOINTS_GRAPH_DATA_FAILURE);

export const getEndpointsGraphDataRefreshAction = createAction(GET_ENDPOINTS_GRAPH_DATA_REFRESH);

export const getEndpointsGraphDataEpic = (actions$) => actions$.pipe(
  ofType(GET_ENDPOINTS_GRAPH_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/clinical-trials/v0/search/getEndpointsInsights?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getEndpointsGraphDataSuccess(res)),
    catchError((err) => of(getEndpointsGraphDataFaliure(err))),
  )),
);

const ongetEndpointsGraphDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetEndpointsGraphDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  labelPhases: response.labelPhases,
  loading: false,
  flag: true,
  error: false,
});

const ongetEndpointsGraphDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetEndpointsGraphDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getEndpointsGraphDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getEndpointsGraphDataAction, (state) => ongetEndpointsGraphDataAction(state))
      .addCase(getEndpointsGraphDataSuccess, (state, action) => ongetEndpointsGraphDataSuccess(state, action.payload))
      .addCase(getEndpointsGraphDataFaliure, (state) => ongetEndpointsGraphDataFailure(state))
      .addCase(getEndpointsGraphDataRefreshAction, () => ongetEndpointsGraphDataRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_ENDPOINTS_STATUS_DATA = 'GET_ENDPOINTS_STATUS_DATA';
const GET_ENDPOINTS_STATUS_DATA_SUCCESS = 'GET_ENDPOINTS_STATUS_DATA_SUCCESS';
const GET_ENDPOINTS_STATUS_DATA_FAILURE = 'GET_ENDPOINTS_STATUS_DATA_FAILURE';
const GET_ENDPOINTS_STATUS_DATA_REFRESH = 'GET_ENDPOINTS_STATUS_DATA_REFRESH';

export const getEndpointsStatusDataAction = createAction(GET_ENDPOINTS_STATUS_DATA);

const getEndpointsStatusDataSuccess = createAction(GET_ENDPOINTS_STATUS_DATA_SUCCESS);

const getEndpointsStatusDataFaliure = createAction(GET_ENDPOINTS_STATUS_DATA_FAILURE);

export const getEndpointsStatusDataRefreshAction = createAction(GET_ENDPOINTS_STATUS_DATA_REFRESH);

export const getEndpointsStatusDataEpic = (actions$) => actions$.pipe(
  ofType(GET_ENDPOINTS_STATUS_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/clinical-trials/v0/search/getEndpointBubbleInsights?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getEndpointsStatusDataSuccess(res)),
    catchError((err) => of(getEndpointsStatusDataFaliure(err))),
  )),
);

const ongetEndpointsStatusDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetEndpointsStatusDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  labelPhases: response.labelPhases,
  loading: false,
  flag: true,
  error: false,
});

const ongetEndpointsStatusDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetEndpointsStatusDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getEndpointsStatusDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getEndpointsStatusDataAction, (state) => ongetEndpointsStatusDataAction(state))
      .addCase(getEndpointsStatusDataSuccess, (state, action) => ongetEndpointsStatusDataSuccess(state, action.payload))
      .addCase(getEndpointsStatusDataFaliure, (state) => ongetEndpointsStatusDataFailure(state))
      .addCase(getEndpointsStatusDataRefreshAction, () => ongetEndpointsStatusDataRefresh())
      .addDefaultCase((state) => state);
  },
);
