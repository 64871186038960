import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SPONSOR_DATA = 'GET_SPONSOR_DATA';
const GET_SPONSOR_DATA_SUCCESS = 'GET_SPONSOR_DATA_SUCCESS';
const GET_SPONSOR_DATA_FAILURE = 'GET_SPONSOR_DATA_FAILURE';

export const getSponsorDataAction = createAction(GET_SPONSOR_DATA);

const getSponsorDataSuccess = createAction(GET_SPONSOR_DATA_SUCCESS);

const getSponsorDataFaliure = createAction(GET_SPONSOR_DATA_FAILURE);

export const getSponsorDataEpic = (actions$) => actions$.pipe(
  ofType(GET_SPONSOR_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action?.payload?.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getSponsorDataSuccess(res)),
    catchError((err) => of(getSponsorDataFaliure(err))),
  )),
);

const ongetSponsorDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSponsorDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetSponsorDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const getSponsorDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSponsorDataAction, (state) => ongetSponsorDataAction(state))
      .addCase(getSponsorDataSuccess, (state, action) => ongetSponsorDataSuccess(state, action.payload))
      .addCase(getSponsorDataFaliure, (state) => ongetSponsorDataFailure(state))
      .addDefaultCase((state) => state);
  },
);

export default getSponsorDataReducer;
