const stackFormateKeys = [
  'Phase 0',
  'Early Phase 1',
  'Phase 1',
  'Phase 2',
  'Phase 3',
  'Phase 4',
  'Phase 1 / Phase 2',
  'Phase 1 / Phase 3',
  'Phase 1 / Phase 4',
  'Phase 1 / Phase 2 / Phase 3',
  'Phase 1 / Phase 2 / Phase 4',
  'Phase 1 / Phase 3 / Phase 4',
  'Phase 1 / Phase 2 / Phase 3 / Phase 4',
  'Phase 2 / Phase 3 / Phase 4',
  'Phase 2 / Phase 3',
  'Phase 2 / Phase 4',
  'Phase 3 / Phase 4',
  'Not applicable',
  'Other',
];

export default stackFormateKeys;
