import React from 'react';
import Header from '../../../common/components/Header';
import SearchComponent from '../../../common/components/SearchComponent';

function CTLandingPage() {
  const apiUrl = '/clinical-trials/v0/search/getFields';
  const autoSuggestUrl = '/clinical-trials/v0/search/getFieldValues';
  return (
    <div className="main-page">
      <Header className="header-home" />
      <div className="home-bg-img">
        <div className="blue-bg-eclips-main" />
        <div className="blue-bg-eclips-left" />
        <div className="blue-bg-eclips-right" />
        <div className="blue-bg-eclips-left-bottom" />
        <div className="blue-bg-eclips-right-bottom" />
      </div>
      <SearchComponent terminalName="Clinical Trials" apiUrl={apiUrl} autoSuggestUrl={autoSuggestUrl} />
    </div>
  );
}

export default CTLandingPage;
