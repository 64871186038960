import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import HeaderV2 from '../HeaderV2';
import { getQuestionsAction, addPreferencesAction } from './logic';
import {
  checkPermission, debounceCall, getCookie, setCookie,
} from '../../../utils';
import Loader from '../../../common/components/Loader';
import SvgLoader from '../../../common/components/SvgLoader';
import { getTerminalsAction } from '../Selection/logic';
import { appPermission, terminalsPath } from './data';

const inActiveCards = ['Tell us about yourself', 'What are your areas of interest?', 'Recommended modules'];

function Persona() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [involvedInQuestoins, setInvolvedInQuestions] = useState([]);
  const [questionIdInvolved, setQuestionInvolved] = useState('');
  const [selectedInvolvedQuestions, setSelectedInvolvedQuestions] = useState([]);
  const [intersets, setIntersets] = useState([]);
  const [questIdInterest, setQuestionIdInterest] = useState('');
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [dissableQuestionsNextBtn, setDissableQuestionsNextBtn] = useState(true);
  const [currentActiveCard, setCurrentActiveCard] = useState(inActiveCards[0]);
  const [dissableInterestsNextBtn, setDissableInterestsBtn] = useState(true);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [recommendedTerminals, setRecommendedTerminals] = useState([]);
  const [exploreTerminals, setExploreTerminals] = useState([]);

  const questions = useSelector((state) => state.questions);
  const preferencesStatus = useSelector((state) => state.preferencesStatus);
  const terminals = useSelector((state) => state.terminals);

  useEffect(() => {
    if (terminals.flag) {
      const recommendedTemp = terminals.data.filter((item) => item.recommended === true);
      const notRecommendedTemp = terminals.data.filter((item) => !item.recommended);
      setRecommendedTerminals([...recommendedTemp]);
      setExploreTerminals([...notRecommendedTemp]);
    }
  }, [JSON.stringify(terminals)]);

  useEffect(() => {
    if (preferencesStatus.flag && completedSteps === 2) {
      setCookie(true, 'personaBuild');
      dispatch(getTerminalsAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
    }
  }, [JSON.stringify(preferencesStatus)]);

  useEffect(() => {
    setDissableQuestionsNextBtn(!(selectedInvolvedQuestions.length >= 1));
  }, [selectedInvolvedQuestions]);

  useEffect(() => {
    setDissableInterestsBtn(!(selectedInterests.length >= 4 && selectedInterests.length <= 8));
  }, [selectedInterests]);

  useEffect(() => {
    if (questions.flag) {
      setQuestionInvolved(questions.data[0].question_id);
      setQuestionIdInterest(questions.data[1].question_id);
      setInvolvedInQuestions([...questions.data[0].options]);
      setIntersets([...questions.data[1].options]);
    }
  }, [JSON.stringify(questions)]);

  useEffect(() => {
    debounceCall(() => dispatch(getQuestionsAction()), 100);
  }, []);

  const checkSelectedQuestions = (value) => {
    let flag = false;
    selectedInvolvedQuestions.forEach((item) => {
      if (item.display_value === value.display_value) {
        flag = true;
      }
    });
    return flag;
  };

  const handleInvolvedQuestionsClick = (item) => {
    if (checkSelectedQuestions(item)) {
      const temp = selectedInvolvedQuestions.filter((value) => item.display_value !== value.display_value);
      setSelectedInvolvedQuestions([...temp]);
    } else {
      setSelectedInvolvedQuestions([...selectedInvolvedQuestions, { ...item }]);
    }
  };

  const checkInterests = (value) => {
    let flag = false;
    selectedInterests.forEach((item) => {
      if (item.display_value === value.display_value) {
        flag = true;
      }
    });
    return flag;
  };

  const handleInterestsClick = (item) => {
    if (checkInterests(item)) {
      const temp = selectedInterests.filter((value) => item.display_value !== value.display_value);
      setSelectedInterests([...temp]);
    } else {
      setSelectedInterests([...selectedInterests, { ...item }]);
    }
  };

  const handleInvolvedQuestionsSubmit = () => {
    if (!dissableQuestionsNextBtn) {
      dispatch(addPreferencesAction({
        body: {
          email: getCookie('userEmail'),
          question_id: questionIdInvolved,
          preferences: selectedInvolvedQuestions,
        },
      }));
      setCurrentActiveCard(inActiveCards[1]);
      setCompletedSteps(1);
    }
  };

  const handleInterestQuestions = () => {
    if (!dissableInterestsNextBtn) {
      dispatch(addPreferencesAction({
        body: {
          email: getCookie('userEmail'),
          question_id: questIdInterest,
          preferences: selectedInterests,
        },
      }));
      setCurrentActiveCard(inActiveCards[2]);
      setCompletedSteps(2);
    }
  };

  const check = (appName) => checkPermission(appName);

  const getTerminalClass = ({ status, terminal }) => {
    if (status === 'Coming Soon') {
      return 'comming-soon';
    }

    return check(terminal) ? 'active' : 'locked';
  };

  const handleTerminalCardCllick = (item) => {
    if (getTerminalClass(item) !== 'comming-soon') {
      navigate(terminalsPath[item.terminal]);
    }
  };

  const appClassNames = {
    'ot-ct-access': 'clinical-trials',
    'ot-intervention-access': 'intervention',
    'ot-disease-access': 'disease',
    'ot-therapy-access': 'therapy',
    'ot-protein-access': 'protein',
    'ot-drug-access': 'drug',
    'ot-cl-access': 'competitive-landscape',
    'ot-pl-access': 'preclinical-landscape',
    'ot-ra-access': 'research-analytics',
    'ot-gene-access': 'gene',
    'ot-search-exp-access': 'search-and-explore',
  };

  const renderInvolvedInQuestions = () => involvedInQuestoins.map((item) => (
    <div aria-hidden className="question-ctr pointer" onClick={() => handleInvolvedQuestionsClick(item)}>
      <input checked={checkSelectedQuestions(item)} className="checkbox-questions pointer" type="checkbox" />
      <div className="questions-display-name">{item.display_value}</div>
    </div>
  ));

  const renderAboutYourself = () => (
    <div className="expanded-ctr">
      <div className="header-text">Tell us about yourself</div>
      <div className="sub-text">I am primarily involved in:</div>
      <div className="involved-questions">
        <Loader height={380} loading={questions.loading} error={questions.error} noData={questions.data.length === 0}>
          {renderInvolvedInQuestions()}
        </Loader>
      </div>
      <div className="questions-btn-ctr">
        <button onClick={() => handleInvolvedQuestionsSubmit()} type="submit" className={`questions-btn-submit${dissableQuestionsNextBtn ? '-dissabled' : ''}`}>Next</button>
      </div>
    </div>
  );

  const renderInterests = () => intersets.map((item) => (
    <div aria-hidden onClick={() => (!checkInterests(item) ? handleInterestsClick(item) : null)} className={`interest${checkInterests(item) ? '-selected' : ''}`}>
      <span>{item.display_value}</span>
      {checkInterests(item) ? <SvgLoader onClick={() => handleInterestsClick(item)} width={24} height={24} svgName="persona-cross" /> : null}
    </div>
  ));

  const renderAreaOfInterests = () => (
    <div className="expanded-ctr">
      <div className="header-text">What are your areas of interest?</div>
      <div className="sub-text">Select 4 - 8 areas to proceed</div>
      <div className="involved-interests">
        <Loader height={380} loading={questions.loading} error={questions.error} noData={questions.data.length === 0}>
          {renderInterests()}
        </Loader>
      </div>
      <div className="questions-btn-ctr">
        <button className="back-btn-persona" type="submit" onClick={() => setCurrentActiveCard(inActiveCards[0])}>Back</button>
        <button onClick={() => handleInterestQuestions()} type="submit" className={`questions-btn-submit${dissableInterestsNextBtn ? '-dissabled' : ''}`}>Next</button>
      </div>
    </div>
  );

  const renderRecommendedTerminals = () => recommendedTerminals.map((item) => (
    <div aria-hidden onClick={() => handleTerminalCardCllick(item)} className={`recommended-terminal ${getTerminalClass(item) !== 'comming-soon' ? 'pointer' : ''} ${appClassNames[item.terminal]}`}>
      <div className="recommended-terminal-content-logo">
        <div className="recommended-terminal-content-logo-img" />
      </div>
      <div className="recommended-terminal-content">
        <div className="rec-terminal-name">{appPermission[item.terminal]}</div>
        <div className="rec-terminal-des">{item.description}</div>
      </div>
    </div>
  ));

  const renderExploreTerminals = () => exploreTerminals.map((item) => (
    <div aria-hidden onClick={() => handleTerminalCardCllick(item)} className={`explore-terminal pointer ${appClassNames[item.terminal]}`}>
      <div className="explore-terminal-content-logo">
        <div className="explore-terminal-content-logo-img" />
      </div>
      <div className="explore-terminal-content">
        <div className="rec-terminal-name">{appPermission[item.terminal]}</div>
        <div className="rec-terminal-des">{item.description}</div>
      </div>
    </div>
  ));

  const renderPreferences = () => (
    <div className="expanded-ctr">
      <div className="header-text">Thank you for helping us with your preferences!</div>
      <div className="sub-text">Here are the recommended terminals for you</div>
      <div className="recommendation-ctr">
        <Loader height={200} loading={terminals.loading} error={terminals.error} noData={terminals.data.length === 0}>
          {renderRecommendedTerminals()}
        </Loader>
      </div>
      <div className="sub-text">You may like to explore</div>
      <div className="explore-ctr">
        <Loader height={200} loading={terminals.loading} error={terminals.error} noData={terminals.data.length === 0}>
          {renderExploreTerminals()}
        </Loader>
      </div>
    </div>
  );

  return (
    <div className="persona-ctr">
      <HeaderV2 />
      <div className="persona-sub-ctr">
        <div className="progress-bar-ctr">
          <div className="progress-text">Start</div>
          <div className="progress-bars">
            <div className={`${completedSteps >= 1 ? 'hor-bar active' : 'hor-bar'}`} />
            <div className={`${completedSteps >= 2 ? 'hor-bar active' : 'hor-bar'}`} />
            <div className={`${completedSteps >= 3 ? 'hor-bar active' : 'hor-bar'}`} />
          </div>
          <div className="progress-text">Finish</div>
        </div>
        <div className="personas">
          {currentActiveCard === inActiveCards[0]
            ? renderAboutYourself()
            : <div className="inactive-card">{inActiveCards[0]}</div>}
          {currentActiveCard === inActiveCards[1]
            ? renderAreaOfInterests()
            : <div className="inactive-card">{inActiveCards[1]}</div>}
          {currentActiveCard === inActiveCards[2]
            ? renderPreferences()
            : <div className="inactive-card">{inActiveCards[2]}</div>}
        </div>
      </div>
    </div>
  );
}

export default Persona;
