import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootEpic from './epics';
import notificationMiddleWare from './utils/middleware';
import rootReducer from './reducer';
import { clinicalTrialsReducerWhiteList } from './clinicalTrials/container/reducer';
import { searchAndExploreReducerWhiteList } from './searchAndExplore/container/reducer';

const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [...clinicalTrialsReducerWhiteList, ...searchAndExploreReducerWhiteList],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [epicMiddleware, notificationMiddleWare],
});

export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);
