import { combineEpics } from 'redux-observable';
import { requestDemoEpic } from './ModalRequestDemo/logic';
import { getTerminalsEpic } from './Selection/logic';
import { requestOtpEpic } from './LoginModal/logics';
import { checkUserEpic, registerUserEpic } from './TryOntosightModal/logics';
import { addUserConcentEpic } from './Declarations/logic';
import { getQuestionsEpic, addPreferencesEpic } from './Persona/logic';
import { addLicenseEpic, verifyOTPEpic } from './LoginModalV2/logic';
import { getLicenceEpic } from './Main/logic';
import { updateUserStatusEpic } from './Admin/logic';

const rootAppEpic = combineEpics(
  requestDemoEpic,
  getTerminalsEpic,
  requestOtpEpic,
  checkUserEpic,
  registerUserEpic,
  addUserConcentEpic,
  getQuestionsEpic,
  addPreferencesEpic,
  addLicenseEpic,
  getLicenceEpic,
  updateUserStatusEpic,
  verifyOTPEpic,
);

export default rootAppEpic;
