import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InterventionGraphHandler from '../../components/InterventionGraphHandler';

function InterventionAnalyticsContainer({ params }) {
  const interventionWidgets = useSelector((state) => state.interventionWidgets.interventionWidgets);
  const [currentExpanded, setCurrentExpanded] = useState('');

  const renderGraphs = () => interventionWidgets.map((item) => (
    <InterventionGraphHandler
      key={item.value}
      params={params}
      selected={item}
      currentExpanded={currentExpanded}
      setExpanded={setCurrentExpanded}
    />
  ));

  return (
    <div className="widget-container">
      {renderGraphs()}
    </div>
  );
}

InterventionAnalyticsContainer.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default InterventionAnalyticsContainer;
