import React from 'react';
import PropTypes from 'prop-types';

function EligibilitySection({ data }) {
  return (
    <div className="detail-container eligibility study-details">
      <div className="header">ELIGIBILITY</div>
      <div className="study-details-container">
        <div className="header">GENERAL</div>
        <div className="study-details-box">
          <div className="box-container">
            <div className="box-list">
              <div className="box-list-title">Accepts Healthy Volunteers</div>
              <div className="box-list-value">{data?.eligibility?.accepts_healthy_volunteers || '-'}</div>
            </div>
            <div className="box-list">
              <div className="box-list-title">Genders Eligible for Study</div>
              <div className="box-list-value">{data?.eligibility?.genders_eligible_for_study || '-'}</div>
            </div>
            <div className="box-list">
              <div className="box-list-title">Lower age limit for study</div>
              <div className="box-list-value">{data?.eligibility?.lower_age_limit_for_study || '-'}</div>
            </div>
          </div>
          <div className="box-container">
            <div className="box-list">
              <div className="box-list-title">Upper age limit for study</div>
              <div className="box-list-value">{data?.eligibility?.upper_age_limit_for_study || '-'}</div>
            </div>
            <div className="box-list">
              <div className="box-list-title">Study Population</div>
              <div className="box-list-value">{data?.study_population || '-'}</div>
            </div>
          </div>
        </div>
      </div>
      {data?.criteria?.inclusion_criteria?.length ? (
        <div className="eligibility-list">
          <div className="header">INCLUSION CRITERIA</div>
          <div className="list-value">
            {data?.criteria?.inclusion_criteria?.map((inclusionCriteria) => (
              <div key={inclusionCriteria}>{inclusionCriteria}</div>
            ))}
          </div>
        </div>
      ) : null}
      {data?.criteria?.exclusion_criteria?.length ? (
        <div className="eligibility-list">
          <div className="header">EXCLUSION CRITERIA</div>
          <div className="list-value">
            {data?.criteria?.exclusion_criteria?.map((exclusionCriteria) => (
              <div key={exclusionCriteria}>{exclusionCriteria}</div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

EligibilitySection.propTypes = {
  data: PropTypes.instanceOf(Object),
};

EligibilitySection.defaultProps = {
  data: {},
};

export default EligibilitySection;
