import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { getSearchResultsAction, getMoreSearchResultsAction } from './logic';
import Loader from '../../components/Loader';
import LazyLoadDiv from '../../components/LazyLoadDiv';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function List({
  children, data, params, naturalQuery,
}) {
  const dispatch = useDispatch();
  const searchResult = useSelector((state) => state.searchResult);
  const [page, setPage] = useState(1);
  const [searchParams] = useSearchParams();
  const deepdivepage = searchParams.get('deepdivepage');

  const apiCall = () => {
    const request = {
      ...params,
      headers: queryString.stringify({
        ...params.headers,
        page,
      }),
    };
    debounceCall(() => {
      dispatch(getSearchResultsAction(request));
    }, 100);
  };

  useEffect(() => {
    if (naturalQuery?.length) {
      params.headers = { ...params.headers, natural_query: JSON.parse(naturalQuery)[0]?.currentFilter[0] };
      apiCall();
    } else {
      apiCall();
    }
  }, []);

  const loadMoreListData = () => {
    if (naturalQuery?.length) {
      const request = {
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          page: page + 1,
          natural_query: JSON.parse(naturalQuery)[0]?.currentFilter[0],
        }),
        body: {
          ...params.body,
        },
      };
      debounceCall(() => {
        dispatch(getMoreSearchResultsAction(request));
      }, 100);
    } else {
      const request = {
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          page: page + 1,
        }),
      };
      debounceCall(() => {
        dispatch(getMoreSearchResultsAction(request));
      }, 100);
    }
    setPage(page + 1);
  };

  return (
    <div className="list-container">
      <Loader
        error={searchResult?.error}
        loading={!deepdivepage && searchResult.loading}
        noData={data?.length === 0}
        height="calc(100vh - 185px)"
        width="100%"
        className="white-loader"
      >
        <LazyLoadDiv
          className="explore-list-results"
          id="document-list"
          total={searchResult?.data?.total}
          currentTotal={(data || []).length}
          height="calc(100vh - 168px)"
          loadMoreRows={loadMoreListData}
        >
          {children}
        </LazyLoadDiv>
      </Loader>
    </div>
  );
}

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  data: PropTypes.PropTypes.instanceOf(Array).isRequired,
  naturalQuery: PropTypes.string,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({
      page: PropTypes.number.isRequired,
    }).isRequired,
    body: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  sort: PropTypes.PropTypes.shape({
    body: PropTypes.PropTypes.shape({
      sort: PropTypes.instanceOf(Object),
    }),
  }),
};

List.defaultProps = {
  naturalQuery: '',
  sort: ({
    body: ({
      sort: {},
    }),
  }),
};

export default List;
