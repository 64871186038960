import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import ExploreAnatyticsContainer from '../../container/ExploreAnalyticsContainer';
import { getExploreSearchResultsAction, getExploreSearchResultsRefreshAction } from '../../container/ExploreList/logic';
import { modifyDataForGetResultsQuery, getCookie } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function SEAnalyticsPage() {
  const dispatch = useDispatch();
  const getExploreSearchResults = useSelector((state) => state.getExploreSearchResults);
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');
  const sort = searchParams.get('sort');
  const autoSuggestUrl = '/explore/v0/search/getFieldValues';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters, true));
  const projId = getCookie('projectId');

  const out = merge(requestData, filtersData);

  const params = {
    headers: {
      from: 1,
      size: 10,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  useEffect(() => {
    if (semanticQuery?.length) {
      const request = {
        apiUrl: '/explore/v0/search/getSearchResults',
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          semantic_search: JSON.parse(semanticQuery)[0]?.currentFilter,
          from: 1,
          size: 1,
          project_id: projId,

        }),
        body: params.body,
      };
      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      debounceCall(() => {
        dispatch(getExploreSearchResultsAction(request));
      }, 50);
    } else {
      const request = {
        apiUrl: '/explore/v0/search/getSearchResults',
        headers: queryString.stringify({
          from: 1,
          size: 10,
        }),
        body: params.body,
      };

      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      debounceCall(() => {
        dispatch(getExploreSearchResultsAction(request));
      }, 50);
    }

    return () => {
      dispatch(getExploreSearchResultsRefreshAction({}));
    };
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" />
      <div className="se-results-page">
        <div className="explore-results-nav">
          <div className="explore-results-text">
            {getExploreSearchResults?.data?.total ? `${getExploreSearchResults?.data?.total} Results found` : null}
          </div>
          {/* <SearchNavOptions isExplorePage /> */}
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="search-analytics-container">
          <ExploreAnatyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default SEAnalyticsPage;
