import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import CardDetail from '../../container/CardDetail';
import Loader from '../../../common/components/Loader';
import { getSearchResultsRefreshAction } from '../../../common/container/List/logic';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import ResultsCard from '../../components/ResultsCard';
import SearchNavOptions from '../../container/SearchNavOptions';
import { getCookie, modifyDataForGetResultsQuery } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function CTsearchResultPage() {
  const [currentCardDetailIndex, setCurrentCardDetailIndex] = useState({});
  const searchResult = useSelector((state) => state.searchResult);
  const [searchParams] = useSearchParams();
  const [searchResultTotal, setSearchResultTotal] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [drawerWidth, setDrawerWidth] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');
  const workspaceSemanticQuery = searchParams.get('workspaceSemanticQuery');
  const wq = JSON.parse(workspaceQuery);
  const wf = JSON.parse(workspaceFilters);
  const sort = searchParams.get('sort');
  const apiUrl = '/clinical-trials/v0/search/getSearchResults';
  const autoSuggestUrl = '/clinical-trials/v0/search/getFieldValues';
  const terminalName = 'clinical_trials';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q);
  const endpointQuery = searchParams.get('endpointQuery');
  const geoGraphQuery = searchParams.get('geoGraphQuery');
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const url = location.pathname + location.search;
  const statusFilters = searchParams.get('statusQuery');
  const sq = JSON.parse(statusFilters);
  const sponsors = searchParams.get('sponsorQuery');
  const deepdivequery = searchParams.get('deepdivequery');
  const deepdivepage = searchParams.get('deepdivepage');
  const sponsorData = modifyDataForGetResultsQuery(JSON.parse(sponsors));
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters), false, 'ctfilters');
  const deepdiveQueryData = modifyDataForGetResultsQuery(JSON.parse(deepdivequery));
  const statusData = modifyDataForGetResultsQuery(sq);
  const projId = getCookie('projectId');
  const currentFilter = semanticQuery !== null ? (JSON.parse(semanticQuery)[0]?.currentFilter[0]) : (null);
  const semanticQueryRequest = {
    body: {
      body: currentFilter,
    },
  };
  let workspaceQ = {};
  if (wq !== null || wf !== null) {
    workspaceQ = merge(wq || {}, wf || {}, filtersData);
  }

  // const isEmpty = (obj) => Object.keys(obj).length === 0;
  // const nonEmptyRequestBody = !isEmpty(requestData.body)
  //   ? requestData
  //   : semanticQueryRequest;

  const out = merge(requestData, statusData, filtersData, sponsorData, deepdiveQueryData);
  let view = '';
  const semanticOut = merge(semanticQueryRequest, filtersData);

  const nonEmptyRequestBody = semanticQuery ? semanticOut.body : out.body;
  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  const params = {
    apiUrl,
    headers: {
      terminal_name: terminalName,
      page: 1,
      size: 20,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  if (JSON.stringify(workspaceQ) !== '{}') {
    params.body.filters = { ...workspaceQ.body };
  }

  if (sort && sort !== 'null' && sort !== '{}') {
    params.body = { ...params.body, sort: [JSON.parse(sort)] };
  }

  if (endpointQuery) {
    params.body.filters = { ...out.body, ...JSON.parse(endpointQuery).filters };
  }

  if (geoGraphQuery) {
    params.body.filters = { ...out.body, ...JSON.parse(geoGraphQuery).filters };
  }

  if (workspaceSemanticQuery) {
    const nq = JSON.parse(workspaceSemanticQuery).body.body;
    params.headers.natural_query = nq;
  }
  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: nonEmptyRequestBody,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    if (getCookie('projectId')) {
      hashApiCall();
    }
  }, [getCookie('projectId')]);

  useEffect(() => () => {
    dispatch(getSearchResultsRefreshAction({}));
  }, []);

  useEffect(() => {
    if (searchResult?.flag) {
      setSearchResultTotal(searchResult?.data?.total);
    }
  }, [JSON.stringify(searchResult)]);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={searchResultTotal} semanticQueryRequest={semanticQueryRequest} url={url} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="ct-results-page">
        <div className="card-view-nav">
          {!searchResult?.loading
            ? (
              <div className="ct-results-total-count-header">
                {(location.search).includes('deepdivequery')
                  ? (
                    <div
                      className="back-btn-container"
                      role="presentation"
                      onClick={() => {
                        if (semanticQuery) {
                          Mixpanel.track('page_exit_event', {
                            action: 'page leave event',
                            page: 'List View Page',
                            terminal_name: 'Clinical Trials',
                          });
                          navigate(`/clinicalTrials/analyticsResults?semanticquery=${semanticQuery}`);
                          navigate(0);
                        } else {
                          navigate(`/clinicalTrials/analyticsResults?query=${query}`);
                          navigate(0);
                        }
                      }}
                    >
                      <span className="back-arrow" />
                      <span>Back</span>
                    </div>
                  )
                  : null}
                <div className="cl-results-info-text">
                  {`${searchResultTotal} Results`}
                </div>
              </div>
            )
            : <div className="cl-results-info-text" />}
          <SearchNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="ct-results-page-content">
          <div className="ct-results-page-left-cl">
            <ResultsCard
              apiUrl={apiUrl}
              terminalName={terminalName}
              query={query}
              setCardIndex={setCurrentCardDetailIndex}
              params={params}
              currentIndex={currentCardDetailIndex}
              naturalQuery={semanticQuery}
              sort={sort}
            />
          </div>
          <div className="ct-results-page-right-sb">
            <Loader
              error={searchResult?.error}
              loading={!deepdivepage && searchResult.loading}
              noData={searchResultTotal === 0}
              width="100%"
              height="calc(100vh - 185px)"
              className="white-loader"
            >
              <CardDetail data={currentCardDetailIndex} setDrawerWidth={setDrawerWidth} />
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTsearchResultPage;
