/* eslint-disable */
import React, { useEffect, useState } from 'react';
import BookmarkCard from './BookmarkCardOne';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader';
import { Drawer } from 'antd';
import { getBookmarkAction } from '../../Bookmark/logic';
import queryString from 'query-string';
import BookmarkCardOne from './BookmarkCardOne';
import BookmarkCardTwo from './BookmarkCardTwo';
import { getExploreSearchSummaryAction } from '../../../../searchAndExplore/container/ExploreList/logic';
import ExploreSummaryCard from '../../../../searchAndExplore/components/ExploreSummaryCard';
import CardDetail from '../../../../clinicalTrials/container/CardDetail';


function BookmarkList({ projectId, apiCall , bookmarkFilters, filterData}) {
  const dispatch = useDispatch();
  // const bookmarkData = useSelector(
  //   (state) => state.bookmarkData,
  // );
  // const bookmarkData = dummyData;
  const getExploreSearch = useSelector((state) => state.getExploreSearchSummary);
  const bookmarkData = useSelector(
    (state) => state.bookmarkData
  );

  const [selectedCard, setSelectedCard] = useState({});
  const [previewCardData, setPreviewCardData] = useState({});
  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData.map(filter => filter.toLowerCase().replace(/ /g, '_'));
}



  const onDrawerClose = () => {
    setSelectedCard({});
  }

  useEffect(() => {
    if (getExploreSearch?.flag) {
      setPreviewCardData(getExploreSearch?.data?.data);
    }
  }, [JSON.stringify(getExploreSearch)]);

  useEffect(() => {
    if (selectedCard && Object?.keys(selectedCard).length) {
      const url = `/explore/v0/search/${selectedCard.type}/resource/${selectedCard.doc_id}`;
      dispatch(getExploreSearchSummaryAction({
        params: {
          url,
          headers: queryString.stringify({
            asset_class: selectedCard.type,
            view: 'preView',
            sub_asset_class: selectedCard?.type === 'regulatory_data' ? selectedCard?.sub_type : undefined,
          }),
        },
      }));
    }
  }, [selectedCard]);


  useEffect(() => {
    if (projectId != '') {

      dispatch(getBookmarkAction(
        {
            body: {
                project_id: projectId,
                asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters)
            },
        },
    ));
    }
  }, [projectId], [bookmarkFilters]);


  const renderSummaryCard = () => (
    <ExploreSummaryCard activeList={previewCardData} />
  );



  const renderBookmarkList = () => {
    
    if (projectId === "" || bookmarkData?.data?.length === 0) return <div className='no-bookmark-data'>No Bookmarks Found</div>
    return bookmarkData?.data?.map((item) => {
      // if (item.terminal_name === 'd2')
      //   // return <BookmarkCardOne data={item} />;
      //   return <div />
      return <BookmarkCardTwo data={item} setSelectedCard={setSelectedCard} projectId={projectId} apiCall={apiCall} />
    })
  };


  return (
    // <Loader loading={bookmarkData.loading || !bookmarkData.flag} error={bookmarkData.error}>
    <div className="list-container">
      {/* <BookmarkCard listData={data} />
        <BookmarkCard listData={data} /> */}
      <Loader loading={bookmarkData?.loading || !bookmarkData?.flag} error={false} >
        {renderBookmarkList()}
      </Loader>
      {/* <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={onDrawerClose}
        width={600}
        open={Object.keys(selectedCard).length > 0}
        key="right"
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
        {previewCardData?.type === 'clinical_trials'
          ? <CardDetail data={previewCardData} />
          : renderSummaryCard()}
      </Drawer> */}
    </div>
    // </Loader>
  )
}

export default BookmarkList


