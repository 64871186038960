import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_EXPLORE_DEEP_DIVE_DETAILS = 'GET_EXPLORE_DEEP_DIVE_DETAILS';
const GET_EXPLORE_DEEP_DIVE_DETAILS_SUCCESS = 'GET_EXPLORE_DEEP_DIVE_DETAILS_SUCCESS';
const GET_EXPLORE_DEEP_DIVE_DETAILS_FAILURE = 'GET_EXPLORE_DEEP_DIVE_DETAILS_FAILURE';

export const getExploreDeepDiveDetailsAction = createAction(GET_EXPLORE_DEEP_DIVE_DETAILS);

const getExploreDeepDiveDetailsSuccess = createAction(GET_EXPLORE_DEEP_DIVE_DETAILS_SUCCESS);

const getExploreDeepDiveDetailsFaliure = createAction(GET_EXPLORE_DEEP_DIVE_DETAILS_FAILURE);

const onGetExploreDeepDiveDetailsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetExploreDeepDiveDetailsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetExploreDeepDiveDetailsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getExploreDeepDiveDetailsEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPLORE_DEEP_DIVE_DETAILS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params.url}?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getExploreDeepDiveDetailsSuccess(res)),
    catchError((err) => of(getExploreDeepDiveDetailsFaliure(err))),
  )),
);

export const getExploreDeepDiveDetailsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExploreDeepDiveDetailsAction, (state) => onGetExploreDeepDiveDetailsAction(state))
      .addCase(getExploreDeepDiveDetailsSuccess, (state, action) => onGetExploreDeepDiveDetailsSuccess(state, action.payload))
      .addCase(getExploreDeepDiveDetailsFaliure, (state) => onGetExploreDeepDiveDetailsFailure(state))
      .addDefaultCase((state) => state);
  },
);

const GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS = 'GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS';
const GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_SUCCESS = 'GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_SUCCESS';
const GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_FAILURE = 'GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_FAILURE';

export const getExploreDeepDiveMentionsDetailsAction = createAction(GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS);

const getExploreDeepDiveMentionsDetailsSuccess = createAction(GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_SUCCESS);

const getExploreDeepDiveMentionsDetailsFaliure = createAction(GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS_FAILURE);

const onGetExploreDeepDiveMentionsDetailsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetExploreDeepDiveMentionsDetailsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetExploreDeepDiveMentionsDetailsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getExploreDeepDiveMentionsDetailsEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPLORE_DEEP_DIVE_MENTIONS_DETAILS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params.url}?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getExploreDeepDiveMentionsDetailsSuccess(res)),
    catchError((err) => of(getExploreDeepDiveMentionsDetailsFaliure(err))),
  )),
);

export const getExploreDeepDiveMentionsDetailsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExploreDeepDiveMentionsDetailsAction, (state) => onGetExploreDeepDiveMentionsDetailsAction(state))
      .addCase(getExploreDeepDiveMentionsDetailsSuccess, (state, action) => onGetExploreDeepDiveMentionsDetailsSuccess(state, action.payload))
      .addCase(getExploreDeepDiveMentionsDetailsFaliure, (state) => onGetExploreDeepDiveMentionsDetailsFailure(state))
      .addDefaultCase((state) => state);
  },
);

const GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS = 'GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS';
const GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_SUCCESS = 'GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_SUCCESS';
const GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_FAILURE = 'GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_FAILURE';

export const getExploreDeepDiveHistoryDetailsAction = createAction(GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS);

const getExploreDeepDiveHistoryDetailsSuccess = createAction(GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_SUCCESS);

const getExploreDeepDiveHistoryDetailsFaliure = createAction(GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS_FAILURE);

const onGetExploreDeepDiveHistoryDetailsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetExploreDeepDiveHistoryDetailsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetExploreDeepDiveHistoryDetailsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getExploreDeepDiveHistoryDetailsEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPLORE_DEEP_DIVE_HISTORY_DETAILS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params.url}?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getExploreDeepDiveHistoryDetailsSuccess(res)),
    catchError((err) => of(getExploreDeepDiveHistoryDetailsFaliure(err))),
  )),
);

export const getExploreDeepDiveHistoryDetailsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExploreDeepDiveHistoryDetailsAction, (state) => onGetExploreDeepDiveHistoryDetailsAction(state))
      .addCase(getExploreDeepDiveHistoryDetailsSuccess, (state, action) => onGetExploreDeepDiveHistoryDetailsSuccess(state, action.payload))
      .addCase(getExploreDeepDiveHistoryDetailsFaliure, (state) => onGetExploreDeepDiveHistoryDetailsFailure(state))
      .addDefaultCase((state) => state);
  },
);
