import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_NEWSLETTER_DATA = 'GET_NEWSLETTER_DATA';
const GET_NEWSLETTER_DATA_SUCCESS = 'GET_NEWSLETTER_DATA_SUCCESS';
const GET_NEWSLETTER_DATA_FAILURE = 'GET_NEWSLETTER_DATA_FAILURE';
const GET_NEWSLETTER_DATA_REFRESH = 'GET_NEWSLETTER_DATA_REFRESH';

export const getNewsLetterDataAction = createAction(GET_NEWSLETTER_DATA);

const getNewsLetterDataSuccess = createAction(GET_NEWSLETTER_DATA_SUCCESS);

const getNewsLetterDataFaliure = createAction(GET_NEWSLETTER_DATA_FAILURE);

export const getNewsLetterDataRefreshAction = createAction(GET_NEWSLETTER_DATA_REFRESH);

export const getNewsLetterDataEpic = (actions$) => actions$.pipe(
  ofType(GET_NEWSLETTER_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/news-letter?size=5&${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getNewsLetterDataSuccess(res)),
    catchError((err) => of(getNewsLetterDataFaliure(err))),
  )),
);

const ongetNewsLetterDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetNewsLetterDataSuccess = (state, { response }) => {
  if (response.data.output) {
    return {
      ...state,
      data: [...response.data.output],
      loading: false,
      flag: true,
      error: false,
      minYear: response.minYear,
      maxYear: response.maxYear,
    };
  }

  return {
    ...state,
    flag: false,
    status: response.statusCode,
    loading: false,
    showNotification: true,
    error: true,
  };
};

const ongetNewsLetterDataFailure = (state, payload) => ({
  ...state,
  flag: false,
  status: payload,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetNewsLetterDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getNewsLetterDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getNewsLetterDataAction, (state) => ongetNewsLetterDataAction(state))
      .addCase(getNewsLetterDataSuccess, (state, action) => ongetNewsLetterDataSuccess(state, action.payload))
      .addCase(getNewsLetterDataFaliure, (state, action) => ongetNewsLetterDataFailure(state, action.payload))
      .addCase(getNewsLetterDataRefreshAction, () => ongetNewsLetterDataRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_NEWSLETTER_EXPANDED_DATA = 'GET_NEWSLETTER_EXPANDED_DATA';
const GET_NEWSLETTER_EXPANDED_DATA_SUCCESS = 'GET_NEWSLETTER_EXPANDED_DATA_SUCCESS';
const GET_NEWSLETTER_EXPANDED_DATA_FAILURE = 'GET_NEWSLETTER_EXPANDED_DATA_FAILURE';
const GET_NEWSLETTER_EXPANDED_DATA_REFRESH = 'GET_NEWSLETTER_EXPANDED_DATA_REFRESH';

export const getNewsLetterDataExpandedAction = createAction(GET_NEWSLETTER_EXPANDED_DATA);

const getNewsLetterDataExpandedSuccess = createAction(GET_NEWSLETTER_EXPANDED_DATA_SUCCESS);

const getNewsLetterDataExpandedFaliure = createAction(GET_NEWSLETTER_EXPANDED_DATA_FAILURE);

export const getNewsLetterDataExpandedRefreshAction = createAction(GET_NEWSLETTER_EXPANDED_DATA_REFRESH);

export const getNewsLetterDataExpandedEpic = (actions$) => actions$.pipe(
  ofType(GET_NEWSLETTER_EXPANDED_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/news-letter?size=20&${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getNewsLetterDataExpandedSuccess(res)),
    catchError((err) => of(getNewsLetterDataExpandedFaliure(err))),
  )),
);

const ongetNewsLetterDataExpandedAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetNewsLetterDataExpandedSuccess = (state, { response }) => {
  if (response.data.output) {
    return {
      ...state,
      data: [...response.data.output],
      loading: false,
      flag: true,
      error: false,
      minYear: response.minYear,
      maxYear: response.maxYear,
    };
  }
  return {
    ...state,
    flag: false,
    status: response.statusCode,
    loading: false,
    showNotification: true,
    error: true,
  };
};

const ongetNewsLetterDataExpandedFailure = (state, payload) => ({
  ...state,
  flag: false,
  status: payload,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetNewsLetterDataExpandedRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getNewsLetterDataExpandedReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getNewsLetterDataExpandedAction, (state) => ongetNewsLetterDataExpandedAction(state))
      .addCase(getNewsLetterDataExpandedSuccess, (state, action) => ongetNewsLetterDataExpandedSuccess(state, action.payload))
      .addCase(getNewsLetterDataExpandedFaliure, (state, action) => ongetNewsLetterDataExpandedFailure(state, action.payload))
      .addCase(getNewsLetterDataExpandedRefreshAction, () => ongetNewsLetterDataExpandedRefresh())
      .addDefaultCase((state) => state);
  },
);
