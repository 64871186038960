/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Calender from '../Calender';
import AutoSuggestMultiSelect from '../AutoSuggestMultiSelect';
import MultiSelect from '../MultiSelect';
import AutoSuggest from '../AutoSuggest';
import PsDropdown from '../PsDropdown';
import FreeTextSearch from '../FreeTextSearch';

function InputTypeHandler(props) {
  const { type } = props;

  const renderInputs = () => {
    switch (type) {
      case 'autosuggest-multi':
        return <AutoSuggestMultiSelect className={['autosuggest-multi', 'autosuggest-panel', 'autosuggest-options']} {...props} />;
      case 'multiselect':
        return <MultiSelect className={['ps-multi', 'ps-multi-panel', 'ps-option']} {...props} />;
      case 'date-range':
        return <Calender {...props} />;
      case 'dropdown':
        return <PsDropdown className={['ps-dropdown', 'ps-panel', 'ps-option']} {...props} />;
      case 'autosuggest':
        return <AutoSuggest className={['autosuggest-multi', 'autosuggest-panel', 'autosuggest-options']} {...props} />;
      case 'free_text':
        return <FreeTextSearch {...props} />;
      default:
        return <AutoSuggestMultiSelect className={['autosuggest-multi', 'autosuggest-panel', 'autosuggest-options']} {...props} />;
    }
  };

  return (
    <div className="multi-select-section">
      {renderInputs()}
    </div>
  );
}

InputTypeHandler.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InputTypeHandler;
