/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Tooltip, Input, Button, Modal, Popover } from "antd";
import { useSearchParams } from "react-router-dom";
import List from "../../../common/container/List";
import SvgLoader from "../../../common/components/SvgLoader";
import { dateFormat, getCookie, wordLimit } from "../../../utils";
import Mixpanel from "../../../utils/mixpanel";
import Bookmark from "../../../common/container/Bookmark";
import { getProjectsAction } from "../../../common/container/WorkSpace/logic";
import {
  deleteBookmarkAction,
  shareBookmarkAction,
  shareBookmarkRefreshAction,
  deleteBookmarkRefreshAction,
} from "../../../common/container/Bookmark/logic";
import { sendNotification } from "../../../common/components/Notification/logic";

function ResultsCard({ setCardIndex, currentIndex, ...rest }) {
  const dispatch = useDispatch();
  const shareBookmark = useSelector((state) => state.shareBookmark);
  const createBookmark = useSelector((state) => state.createBookmark);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const semanticQuery = searchParams.get("semanticquery");
  const searchResult = useSelector((state) => state.searchResult);
  const [searchResultData, setSearchResultData] = useState([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [selectedCard, setIsSelectedCard] = useState([]);
  const [bookmarkId, setBookmarkid] = useState([]);
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);

  const apiCall = (docId) => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: docId,
        },
      })
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && currentEmail) {
      // Prevent adding empty tags
      if (!emailTags.includes(currentEmail)) {
        setEmailTags([...emailTags, currentEmail]);
        setCurrentEmail("");
        setIsEnterPressed(true);
      }
      e.preventDefault(); // Prevent form submission
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
    if (emailRegex.test(e.target.value) || e.target.value === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email format");
    }
  };
  const getClassName = (str) => {
    const modifiedStr = str.replace(/ /g, "-").toLowerCase();
    return modifiedStr;
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  const showShareModal = (item) => {
    const temp = [];
    temp.push(item?.doc_id);
    temp.push(item?.type);

    setIsSelectedCard(temp);
    setOpenShareModal(!openShareModal);
  };

  useEffect(() => {
    if (searchResult?.flag) {
      setSearchResultData(searchResult?.data?.data);
      if (currentIndex && !Object.keys(currentIndex)?.length) {
        setCardIndex(searchResult?.data?.data[0]);
      }
      const arr = [];
      searchResult?.data?.data?.map((item) => {
        if (item?.isBookmark) {
          arr.push(item?.doc_id);
        }
      });
      setBookmarkid(arr);
    }
  }, [JSON.stringify(searchResult)]);

  const trackEvent = (data) => {
    Mixpanel.track("trialsListViewEvent", {
      action: "list view card click",
      search_type: `${semanticQuery ? "Semantic Search" : "Power Search"}`,
      query: JSON.parse(semanticQuery || query),
      page: "List View Page",
      terminal_name: "Clinical Trials",
      document_data: data,
    });
    setCardIndex(data);
  };

  const renderTooltipValue = (dataset) => dataset?.map((item) => `${item} `);

  const handleTooltipformate = (dataset) => {
    const data = renderTooltipValue(dataset);
    let temp = "";
    if (data.length === 1) {
      return data;
    }

    data.forEach((item, i) => {
      if (i === data.length - 1) {
        temp += `${item}`;
      } else {
        temp += `${item}, `;
      }
    });

    return temp;
  };

  const renderClass = ({ prediction_score }) => {
    if (prediction_score > 65) {
      return "pos-high";
    }
    if (prediction_score > 35 && prediction_score <= 65) {
      return "pos-med";
    }
    return "pos-low";
  };

  const renderField = (value, dataset, len = 0) => (
    <span className="filter">
      <span className="filter-value" title={handleTooltipformate(dataset)}>
        {" "}
        {wordLimit(value, 20)}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ""}`}</span>
    </span>
  );
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const renderSubDetailsDocument = ({
    event_sponsor,
    indication,
    intervention,
    study_start_date,
  }) => (
    <div className="sub-details-container">
      {event_sponsor?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          {renderField(event_sponsor[0], event_sponsor, event_sponsor.length)}
        </div>
      ) : null}
      {indication?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indication[0], indication, indication.length)}
        </div>
      ) : null}
      {intervention?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(intervention[0], intervention, intervention.length)}
        </div>
      ) : null}
      {study_start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {" "}
            {dateFormat(study_start_date * 1000)}
          </span>
        </div>
      ) : null}
    </div>
  );

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div
          className={
            predictionScore >= 65
              ? "high"
              : predictionScore >= 35 && predictionScore < 65
              ? "medium"
              : "low"
          }
        />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>
    </div>
  );

  const handleInviteClick = () => {
    dispatch(
      shareBookmarkAction({
        body: {
          email_ids: emailTags,
          doc_id: selectedCard[0],
          asset_class: selectedCard[1],
        },
      })
    );
    setOpenShareModal(!openShareModal);
    setEmailTags([]);
  };

  useEffect(() => {
    if (shareBookmark.flag) {
      if (shareBookmark.data.message.includes("not")) {
        dispatch(
          sendNotification({
            type: "BOOKMARK_SHARED_FAILURE",
            notificationType: "error",
            message: shareBookmark.data.message,
            showNotification: true,
          })
        );
      } else {
        dispatch(
          sendNotification({
            type: "BOOKMARK_SHARED_SUCCESS",
            notificationType: "success",
            message: shareBookmark.data.message,
            showNotification: true,
          })
        );
      }
    }
  }, [JSON.stringify(shareBookmark)]);

  const renderListValues = () =>
    searchResultData?.map((item) => (
      <div
        aria-hidden
        onClick={() => trackEvent(item)}
        className={`card-details ${
          currentIndex?.trial_id === item?.trial_id ? "active" : ""
        }`}
        key={item.laymen_title}
      >
        <div className="status">
          <div className="status-content">
            <div className="normal">{item.source}</div>
            {item.trial_id || item.phase || item.status ? (
              <div className="dot" />
            ) : null}
            <div className="normal">{item.trial_id}</div>
            {item.phase || item.status ? <div className="dot" /> : null}
            <div className="normal">{item.phase}</div>
            {item.status ? <div className="dot" /> : null}
            <div className={getClassName(item.status)}>
              {item.status === "Completed" ? (
                <div className="completed-icon" />
              ) : null}
              {item.status}
            </div>
            {item.prediction_score ? <div className="dot" /> : null}
            {item.prediction_score ? (
              <Tooltip
                placement="left"
                overlayClassName="prediction-tooltip position-zero"
                title={() => renderTooltip(item.prediction_score)}
                arrow={false}
              >
                <div
                  role="presentation"
                  className={renderClass(item)}
                  id={`score${item.trial_id}`}
                >
                  <SvgLoader
                    width={11}
                    height={8}
                    svgName={renderClass(item)}
                  />
                  PoS
                </div>
              </Tooltip>
            ) : null}
          </div>

          <div className="icon-section">
            <Popover
              overlayClassName="bookmark-popover"
              placement="bottomRight"
              open={activeBookmarkId === item?.trial_id}
              trigger="click"
              onOpenChange={(open) =>
                setActiveBookmarkId(open ? item?.trial_id : null)
              }
              content={
                <Bookmark
                  docId={item.doc_id}
                  trialId={item.trial_id}
                  onClose={() => setActiveBookmarkId(null)}
                  type={item.type}
                  bookmarkId={bookmarkId}
                  setBookmarkid={setBookmarkid}
                
                />
              }
            >
              <div
                className={
                  item?.isBookmark === true || bookmarkId.includes(item?.doc_id)
                    ? "bookmark-icon-active-list"
                    : "bookmark-icon"
                }
                role="presentation"
                onClick={() => {
                  {
                    apiCall(item?.doc_id);
                  }
                }}
              />
            </Popover>
            <div
              className="share-icon"
              role="presentation"
              onClick={() => showShareModal(item)}
            />
          </div>
        </div>
        <div className="title">
          {item.scientific_title || item.laymen_title}
        </div>
        {renderSubDetailsDocument(item)}
      </div>
    ));

  return (
    <>
      <List {...rest} data={searchResultData}>
        {renderListValues()}
      </List>
      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => setOpenShareModal(false)}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  marginBottom: "10px",
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    //  mode="tags"
                    value={currentEmail}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Email ID"
                    //   style={{ flex: '0 0 auto', marginLeft: '5px' }}
                  />
                  {emailError && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input placeholder="Message (Optional)" />
              </div>
              <div className="invite-btn">
                <Button
                  disabled={
                    !isEnterPressed || emailError || emailTags.length === 0
                  }
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Invite
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

ResultsCard.propTypes = {
  setCardIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.instanceOf(Object).isRequired,
};

export default ResultsCard;
