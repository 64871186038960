/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { getTypesValuesAction, updateCurrentDropdownAction, getTypesValuesRefreshAction } from '../../container/PowerSearchBox/logic';
import { debounceCall, modifyDataForDropdown, wordLimit } from '../../../utils';
import Loader from '../Loader';
import { updateFilterAction, goBtnZIndexUpdateAction } from '../../container/PowerSearch/logic';
import { selectFilterAction } from '../../container/FilterModal/logics';

function FilterMultiAutosuggest({
  className, selectedValue, id, apiUrl, currentSelected, isDefault, assetClasse, resetSection, filterType, element, update, subAssetClasse,
}) {
  const dispatch = useDispatch();
  const multiSelectRef = useRef(null);
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('filters');

  const [input, setInput] = useState('');
  const [openDropdown, setOpendDropDown] = useState(false);
  const [tags, setTags] = useState([]);
  const [keys, setKeys] = useState([]);
  const [synonymKeys, setSynonymKeys] = useState([]);
  const [checkFocus, setCheckFocus] = useState(false);
  const [miniView, setMiniView] = useState(false);
  const currentDropdown = useSelector((state) => state.currentDropdown);
  const optionValues = useSelector((state) => state.optionValues);
  const currentFilters = useSelector((state) => state.filters);
  const options = modifyDataForDropdown(optionValues.data, 'key', 'value');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const f = JSON.parse(filters);
    if (f) {
      f.forEach((item) => {
        const SECondition = item.name === selectedValue && !isDefault && item.assetClass === assetClasse;
        const others = item.name === selectedValue && !isDefault;
        const Condition = filterType === 'SE' ? SECondition : others;
        if (Condition) {
          setTags([...item.currentFilter]);
          setKeys([...item.currentKeys]);
          setSynonymKeys([...item.currentSynonymKey]);
          dispatch(updateFilterAction({
            filterOption: { currentFilter: [...item.currentFilter], currentKeys: [...item.currentKeys], currentSynonymKey: synonymKeys[0]?.includes('&') ? encodeURIComponent(synonymKeys[0]) : synonymKeys[0] },
            id,
          }));
          setMiniView(true);
        }
      });
    }
  }, [update]);

  useEffect(() => {
    if (currentFilters.filters[id]?.currentFilter?.length) {
      const tempTags = currentFilters.filters[id].currentFilter.map((item) => decodeURIComponent(item));
      const tempKeys = currentFilters.filters[id]?.currentKeys.map((item) => decodeURIComponent(item));
      setTags([...tempTags]);
      setKeys([...tempKeys]);
    }
  }, [JSON.stringify(currentFilters)]);

  useEffect(() => {
    if (resetSection === assetClasse) {
      setOpendDropDown(false);
      setTags([...[]]);
      setKeys([...[]]);
      setSynonymKeys([...[]]);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [...[]], currentKeys: [...[]], currentSynonymKey: [...[]] },
        id,
      }));
    }
  }, [resetSection]);

  useEffect(() => {
    dispatch(goBtnZIndexUpdateAction(openDropdown ? -1 : 0));
  }, [openDropdown]);

  useEffect(() => {
    if (options.length && id === currentDropdown.id && optionValues.fieldName === selectedValue) {
      setOpendDropDown(true);
    }
  }, [JSON.stringify(options), currentDropdown]);

  useEffect(() => {
    if (currentSelected === selectedValue) {
      const tempTags = tags.map((item) => encodeURIComponent(item));
      const tempKeys = keys.map((item) => encodeURIComponent(item));
      const tempSynonymKeys = synonymKeys.map((item) => encodeURIComponent(item));

      dispatch(updateFilterAction({
        filterOption: { currentFilter: tempTags, currentKeys: tempKeys, currentSynonymKey: tempSynonymKeys },
        id,
      }));
      setOpendDropDown(false);
    }
  }, [tags]);

  useEffect(() => {
    const handler = (event) => {
      if (!multiSelectRef.current) {
        return;
      }

      if (!multiSelectRef.current.contains(event.target)) {
        setMiniView(true);
        setOpendDropDown(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (input.length > 2 && currentSelected === selectedValue) {
      setShowLoader(false);
      let request = {};
      if (filterType === 'SE') {
        if (assetClasse === 'regulatory_data') {
          request = {
            apiUrl,
            params: queryString.stringify({
              field_query: input,
              field_name: selectedValue,
              asset_class: element.spa ? assetClasse : 'ot_search_assetclasses',
              size: 50,
              sub_asset_class: subAssetClasse,
            }),
          };
        } else {
          request = {
            apiUrl,
            params: queryString.stringify({
              field_query: input,
              field_name: selectedValue,
              asset_class: element.spa ? assetClasse : 'ot_search_assetclasses',
              size: 50,
            }),
            headers: {
              Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
            },
          };
        }
      } else if (filterType === 'CT') {
        request = {
          apiUrl,
          params: queryString.stringify({
            field_query: input,
            field_name: selectedValue,
            terminal_name: 'clinical_trials',
          }),
          headers: {
            Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
          },
        };
      }
      debounceCall(() => dispatch(getTypesValuesAction(request)), 300);
    }
  }, [input, currentSelected]);

  const handleChange = (event) => {
    event.preventDefault();
    setInput(event.target.value);
    setOpendDropDown(true);
    setShowLoader(true);
    dispatch(getTypesValuesRefreshAction({}));
    dispatch(updateCurrentDropdownAction(id));
    debounceCall(() => updateCurrentDropdownAction(id), 300);
    if (!event.target.value?.length) {
      setShowLoader(false);
      setOpendDropDown(false);
    }
  };

  const handleOptionClick = (value) => {
    if (tags.indexOf(value.label) !== -1) {
      const temp = tags.filter((item) => item !== value.label);
      const tempKeys = keys.filter((item) => item !== value.value);
      let tempSynonymKeys;
      if (value.synonyms.length !== 0) {
        tempSynonymKeys = synonymKeys.filter((item) => item !== value.key);
      }
      setTags([...temp]);
      setKeys([...tempKeys]);
      setSynonymKeys([...tempSynonymKeys]);
    } else {
      setTags([...tags, value.label]);
      setKeys([...keys, value.value]);
      setSynonymKeys([...synonymKeys, value.key]);
    }
    setInput('');
    setCheckFocus(true);
    setOpendDropDown(false);
  };

  const handleDivClick = () => {
    setMiniView(false);
    dispatch(selectFilterAction(selectedValue));
    dispatch(updateCurrentDropdownAction(id));
  };

  const handleCrossOptionClick = (i) => {
    handleOptionClick({
      label: tags[i],
      value: keys[i],
      synonyms: synonymKeys[i],
    });
  };

  const renderTags = () => tags.map((item, i) => (
    <div className={`tags ${miniView ? '' : 'adjust-tag-size'}`} key={item}>
      <div className="tag-label" title={item}>{wordLimit(item, 20)}</div>
      <SvgLoader className="cross-icon" onClick={() => handleCrossOptionClick(i)} width={28} height={28} svgName="cross" />
    </div>
  ));

  const renderDropdowns = () => options.map((item) => (
    <div aria-hidden key={item.value} onClick={() => handleOptionClick(item)} className={`options ${className[2]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
      {item.label}
      {tags.indexOf(item.label) > -1 && <SvgLoader width={24} height={24} svgName="tick" />}
    </div>
  ));

  const renderMiniView = () => (
    <>
      <div className="tags" title={tags[0]}>
        {wordLimit(tags[0], 20)}
        <SvgLoader className="cross-icon" onClick={() => handleCrossOptionClick(0)} width={28} height={28} svgName="cross" />
      </div>
      {tags.length - 1 ? <div className="tags" style={{ width: 50 }}>{`+${tags.length - 1}`}</div> : null}
    </>
  );

  return (
    <div aria-hidden ref={multiSelectRef} className="multi-select-filter" onClick={() => handleDivClick()}>
      <div className="multi-select-wrap">
        {tags.length
          ? (
            <div className={`multi-select-div ${className[0]}`}>
              <div className="tags-data">
                {!miniView && renderTags()}
                {(miniView)
                  ? renderMiniView() : null}
              </div>
            </div>
          ) : null}
        {(!miniView || !tags.length) && <input autoFocus={checkFocus} className="multi-input" onChange={handleChange} value={input} />}
      </div>
      <div className="input-div">
        {openDropdown
            && (
              <Panel className={`panel ${className[1]}`}>
                {renderDropdowns()}
                <Loader
                  error={optionValues.error}
                  loading={(!optionValues.flag && optionValues.loading) || showLoader}
                  noData={optionValues.data?.length === 0}
                  height={50}
                  className="white-loader"
                />
              </Panel>
            )}
      </div>
    </div>
  );
}

FilterMultiAutosuggest.propTypes = {
  className: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  apiUrl: PropTypes.string.isRequired,
  currentSelected: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  assetClasse: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  resetSection: PropTypes.string.isRequired,
  update: PropTypes.bool.isRequired,
  element: PropTypes.PropTypes.shape({
    spa: PropTypes.bool,
  }),
};

FilterMultiAutosuggest.defaultProps = {
  element: ({
    spa: false,
  }),
};

export default FilterMultiAutosuggest;
