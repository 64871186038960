import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import DeepDiveSideBar from '../../../clinicalTrials/container/DeepDiveSideBar';
import DeepDiveContainer from '../../../clinicalTrials/container/DeepDiveContainer';
import { getExploreDeepDiveDetailsAction, getExploreDeepDiveMentionsDetailsAction, getExploreDeepDiveHistoryDetailsAction } from '../../../clinicalTrials/container/DeepDiveContainer/logic';
import Loader from '../../../common/components/Loader';

function SEDeepDivePage({ setShowDeepdive, showDataFor, setshowDeepdiveFor }) {
  const dispatch = useDispatch();
  const exploreDeepDiveDetails = useSelector((state) => state.getExploreDeepDiveDetails);
  const [selectedOption, setSelectedOption] = useState('about');
  const isSideBarClicked = useRef(false);

  useEffect(() => {
    const url = `/explore/v0/search/${showDataFor.type}/resource/${showDataFor.doc_id}`;
    dispatch(getExploreDeepDiveDetailsAction({
      params: {
        url,
        headers: queryString.stringify({
          asset_class: showDataFor.type,
          view: 'deepDiveView',
        }),
      },
    }));

    dispatch(getExploreDeepDiveMentionsDetailsAction({
      params: {
        url: '/explore/v0/search/clinical_trials/related',
        headers: queryString.stringify({
          from: 1,
          size: 50,
          trial_id: showDataFor?.trial_id,
          relation: 'mentioned_data',
        }),
      },
    }));
    dispatch(getExploreDeepDiveHistoryDetailsAction({
      params: {
        url: '/explore/v0/search/clinical_trials/related',
        headers: queryString.stringify({
          from: 1,
          size: 50,
          trial_id: showDataFor?.trial_id,
          relation: 'history_change_data',
        }),
      },
    }));
  }, []);

  const handleBackClick = () => {
    setShowDeepdive(false);
    setshowDeepdiveFor({});
  };

  return (
    <div className="clinical-trials-deep-dive-page">
      <div className="back-btn-container" role="presentation" onClick={() => handleBackClick()}>
        <span className="back-arrow" />
        <span>Back</span>
      </div>
      <Loader loading={exploreDeepDiveDetails.loading} error={exploreDeepDiveDetails.error}>
        <div className="clinical-trials-deep-dive-container" style={{ display: 'flex' }}>
          <DeepDiveSideBar setSelectedOption={setSelectedOption} selectedOption={selectedOption} isSideBarClicked={isSideBarClicked} />
          <DeepDiveContainer selectedOption={selectedOption} setSelectedOption={setSelectedOption} isSideBarClicked={isSideBarClicked} showDataFor={showDataFor} />
        </div>
      </Loader>
    </div>
  );
}

SEDeepDivePage.propTypes = {
  setShowDeepdive: PropTypes.func.isRequired,
  showDataFor: PropTypes.instanceOf(Object).isRequired,
  setshowDeepdiveFor: PropTypes.func.isRequired,
};

export default SEDeepDivePage;
