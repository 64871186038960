import React from 'react';
import knowledgeAreas from './data';
import SvgLoader from '../../../common/components/SvgLoader';

function KnowledgeArea() {
  const renderKnowledgeAreas = () => knowledgeAreas.map((item) => (
    <div className="flex-row ka-element">
      <div className="logo-ctr">
        <SvgLoader width={36} height={36} svgName={item.svgName} />
      </div>
      <div className="flex-col ka-texts">
        <div className="ka-title-text">{item.title}</div>
        <div className="ka-ccontext-text">{item.context}</div>
      </div>
    </div>
  ));

  return (
    <div className="knowledge-ctr">
      <div className="ka-left-ctr">
        <div className="ka-context">
          <div className="ka-text">Our Knowledge Areas</div>
          <div className="ka-ctr">{renderKnowledgeAreas()}</div>
        </div>
      </div>
      <div className="ka-image-ctr" />
    </div>
  );
}

export default KnowledgeArea;
