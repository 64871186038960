import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_INTERVENTION_SEARCH_RESULSTS = 'GET_INTERVENTION_SEARCH_RESULSTS';
const GET_INTERVENTION_SEARCH_RESULSTS_SUCCESS = 'GET_INTERVENTION_SEARCH_RESULSTS_SUCCESS';
const GET_INTERVENTION_SEARCH_RESULSTS_FAILURE = 'GET_INTERVENTION_SEARCH_RESULSTS_FAILURE';
const GET_MORE_INTERVENTION_SEARCH_RESULSTS = 'GET_MORE_INTERVENTION_SEARCH_RESULSTS';
const GET_MORE_INTERVENTION_SEARCH_RESULSTS_SUCCESS = 'GET_MORE_INTERVENTION_SEARCH_RESULSTS_SUCCESS';
const GET_INTERVENTION_SEARCH_RESULSTS_REFRESH = 'GET_INTERVENTION_SEARCH_RESULSTS_REFRESH';

export const getInterventionSearchResultsAction = createAction(GET_INTERVENTION_SEARCH_RESULSTS);

export const getMoreInterventionSearchResultsAction = createAction(GET_MORE_INTERVENTION_SEARCH_RESULSTS);

const getInterventionSearchResultsSuccess = createAction(GET_INTERVENTION_SEARCH_RESULSTS_SUCCESS);

const getMoreInterventionSearchResultsSuccess = createAction(GET_MORE_INTERVENTION_SEARCH_RESULSTS_SUCCESS);

const getInterventionSearchResultsFaliure = createAction(GET_INTERVENTION_SEARCH_RESULSTS_FAILURE);

export const getInterventionSearchResultsRefreshAction = createAction(GET_INTERVENTION_SEARCH_RESULSTS_REFRESH);

export const getInterventionSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_INTERVENTION_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getInterventionSearchResultsSuccess(res)),
    catchError((err) => of(getInterventionSearchResultsFaliure(err))),
  )),
);

export const getMoreInterventionSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_INTERVENTION_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreInterventionSearchResultsSuccess(res)),
    catchError((err) => of(getInterventionSearchResultsFaliure(err))),
  )),
);

const onGetInterventionSearchResultsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetMoreInterventionSearchResultsAction = (state) => ({
  ...state.data,
  loading: false,
  error: false,
});

const onGetSearchResultsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetMoreSearchResultsSuccess = (state, { response }) => (
  {
    data: {
      total: response?.total,
      data: [
        ...state.data,
        ...response.data,
      ],
    },
    loading: false,
    flag: true,
  });

const onGetSearchResultsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onInterventionSearchResultRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});
export const getInterventionSearchResultsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getInterventionSearchResultsAction, (state) => onGetInterventionSearchResultsAction(state))
      .addCase(getInterventionSearchResultsSuccess, (state, action) => onGetSearchResultsSuccess(state, action.payload))
      .addCase(getInterventionSearchResultsFaliure, (state) => onGetSearchResultsFailure(state))
      .addCase(getMoreInterventionSearchResultsAction, (state) => onGetMoreInterventionSearchResultsAction(state))
      .addCase(getMoreInterventionSearchResultsSuccess, (state, action) => onGetMoreSearchResultsSuccess(state, action.payload))
      .addCase(getInterventionSearchResultsRefreshAction, () => onInterventionSearchResultRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const GET_INTERVENTION_SUMMARY = 'GET_INTERVENTION_SUMMARY';
const GET_INTERVENTION_SUMMARY_SUCCESS = 'GET_INTERVENTION_SUMMARY_SUCCESS';
const GET_INTERVENTION_SUMMARY_FAILURE = 'GET_INTERVENTION_SUMMARY_FAILURE';

export const getInterventionSummaryAction = createAction(GET_INTERVENTION_SUMMARY);

const getInterventionSummarySuccess = createAction(GET_INTERVENTION_SUMMARY_SUCCESS);

const getInterventionSummaryFaliure = createAction(GET_INTERVENTION_SUMMARY_FAILURE);

const onGetInterventionSummaryAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetInterventionSummarySuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetInterventionSummaryFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getInterventionSummaryEpic = (actions$) => actions$.pipe(
  ofType(GET_INTERVENTION_SUMMARY),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params}`, 'GET')).pipe(
    map((res) => getInterventionSummarySuccess(res)),
    catchError((err) => of(getInterventionSummaryFaliure(err))),
  )),
);

export const getInterventionSummaryReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getInterventionSummaryAction, (state) => onGetInterventionSummaryAction(state))
      .addCase(getInterventionSummarySuccess, (state, action) => onGetInterventionSummarySuccess(state, action.payload))
      .addCase(getInterventionSummaryFaliure, (state) => onGetInterventionSummaryFailure(state))
      .addDefaultCase((state) => state);
  },
);
