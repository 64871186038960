import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { modifyDataForDropdown, trackOutSideEvents } from '../../../utils';
import { getTypesValuesAction, getTypesValuesRefreshAction } from '../../container/PowerSearchBox/logic';
import { updateFilterAction } from '../../container/PowerSearch/logic';
import { selectFilterAction } from '../../container/FilterModal/logics';
import Loader from '../Loader';

function FilterMultiSelect({
  selectedValue, id, apiUrl, isDefault, filterType, update, assetClasse, resetSection, element,
}) {
  const divEl = useRef(null);
  const multiSelectDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const filters = searchParams.get('filters');
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentKeyOptions, setCurrentKeyOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const optionValues = useSelector((state) => state.optionValues);
  const currentFilters = useSelector((state) => state.filters);
  const options = modifyDataForDropdown(optionValues.data, 'key', 'value');

  const handleDefault = () => {
    setSelectAllSelected(false);
    setCurrentOptions([...[]]);
    setCurrentKeyOptions([...[]]);
  };

  useEffect(() => {
    trackOutSideEvents(multiSelectDropdownRef, () => setOpen(false));
  }, []);

  useEffect(() => {
    if (resetSection === assetClasse) {
      setCurrentOptions([...[]]);
      setCurrentKeyOptions([...[]]);
      setOpen(false);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [], currentKeys: [] },
        id,
      }));
    }
  }, [isDefault, resetSection, assetClasse]);

  useEffect(() => {
    if (currentFilters.filters[id]?.currentFilter?.length) {
      setCurrentOptions([...currentFilters.filters[id].currentFilter]);
      setCurrentKeyOptions([...currentFilters.filters[id].currentKeys]);
    }
  }, [JSON.stringify(currentFilters)]);

  useEffect(() => {
    const f = JSON.parse(filters);
    if (f) {
      f.forEach((item) => {
        const SECondition = item.name === selectedValue && !isDefault && item.assetClass === assetClasse;
        const others = item.name === selectedValue && !isDefault;
        const Condition = filterType === 'SE' ? SECondition : others;
        if (Condition) {
          setCurrentOptions([...item.currentFilter]);
          setCurrentKeyOptions([...item.currentKeys]);
          dispatch(updateFilterAction({
            filterOption: { currentFilter: [...item.currentFilter], currentKeys: [...item.currentKeys] },
            id,
          }));
        }
      });
    }
  }, [update]);

  useEffect(() => {
    if (currentOptions.length === options.length && options.length !== 0) {
      setSelectAllSelected(true);
    } else {
      setSelectAllSelected(false);
    }
  }, [JSON.stringify(currentOptions)]);

  useEffect(() => {
    dispatch(updateFilterAction({
      filterOption: { currentFilter: currentOptions, currentKeys: currentKeyOptions },
      id,
    }));
  }, [JSON.stringify(currentOptions)]);

  const handleOptionClick = (option) => {
    if (currentOptions.indexOf(option.label) > -1) {
      const temp = currentOptions.filter((item) => item !== option.label);
      const tempKeys = currentKeyOptions.filter((item) => item !== option.value);
      setCurrentOptions([...temp]);
      setCurrentKeyOptions([...tempKeys]);
    } else {
      setCurrentOptions([...currentOptions, option.label]);
      setCurrentKeyOptions([...currentKeyOptions, option.value]);
    }
  };

  const handleSelectAllClick = () => {
    if (selectAllSelected) {
      handleDefault();
    } else {
      const temp = options.map((item) => item.label);
      const tempKeys = options.map((item) => item.value);
      setCurrentOptions([...temp]);
      setCurrentKeyOptions([...tempKeys]);
      setSelectAllSelected(!selectAllSelected);
    }
  };

  const apiCall = () => {
    let request = {};
    if (filterType === 'SE') {
      dispatch(getTypesValuesRefreshAction());
      request = {
        apiUrl,
        params: queryString.stringify({
          field_name: selectedValue,
          asset_class: element.spa ? assetClasse : 'ot_search_assetclasses',
          size: 50,
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
      dispatch(getTypesValuesAction(request));
    } else if (filterType === 'CT') {
      dispatch(getTypesValuesRefreshAction());
      request = {
        apiUrl,
        params: queryString.stringify({
          field_name: selectedValue,
          terminal_name: 'clinical_trials',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
      dispatch(getTypesValuesAction(request));
    }
  };

  const handleClick = (e) => {
    dispatch(getTypesValuesRefreshAction());
    dispatch(selectFilterAction(selectedValue));
    setOpen(true);
    e.stopPropagation();
    apiCall();
  };

  const handleCrossOptionClick = (e, value) => {
    e.stopPropagation();
    const temp = [];
    const tempKeys = [];
    let valueIndex = -1;

    currentOptions.forEach((item, i) => {
      if (item !== value) {
        temp.push(item);
      }

      if (item === value) {
        valueIndex = i;
      }
    });

    currentKeyOptions.forEach((item, i) => {
      if (i !== valueIndex) {
        tempKeys.push(item);
      }
    });
    setCurrentKeyOptions([...tempKeys]);
    setCurrentOptions([...temp]);
  };

  const renderOptions = () => options.map((option, i) => {
    if (selectedValue === 'asset_classes' && i >= 2 && semanticQuery) {
      return null;
    }

    return (
      <div aria-hidden key={option.key} onClick={() => handleOptionClick(option)} className={`option ${currentOptions.includes(option.label) ? 'active' : ''}`}>
        <input checked={currentOptions.includes(option.label)} onChange={() => handleOptionClick(option)} className="option-checkbox" type="checkbox" />
        <span className="option-text">{option.label}</span>
      </div>
    );
  });

  const renderDropdown = () => (
    <div aria-hidden onClick={(e) => e.stopPropagation()} className="multiselecte-dropdown style-for-md" ref={multiSelectDropdownRef}>
      <Loader loading={optionValues.loading} error={optionValues.error} noData={optionValues.data?.length === 0} height={100}>
        <div className={`dropdown-height ${(selectedValue === 'asset_classes' && semanticQuery) ? 'semantic' : ''}`}>
          <div className="multiselecte-default">

            <div aria-hidden className={`multiselecte-default-options ${selectAllSelected ? 'active' : ''}`} onClick={() => handleSelectAllClick()}>
              <input checked={selectAllSelected} onChange={() => handleSelectAllClick()} className="checkbox-select-all-md" type="checkbox" />
              Select All
            </div>
            <div aria-hidden onClick={() => handleDefault()} className="multiselecte-default-options">
              <div>Clear All</div>
            </div>

          </div>
          <div className="option-container">
            {renderOptions()}
          </div>
        </div>
      </Loader>
    </div>
  );

  const renderTags = () => currentOptions.map((item) => (
    <div className="tags" key={item}>
      <div className="tag-label" title={item}>{item}</div>
      <SvgLoader className="cross-icon" onClick={(e) => handleCrossOptionClick(e, item)} width={28} height={28} svgName="cross" />
    </div>
  ));

  return (
    <div
      aria-hidden
      ref={divEl}
      className="dropdown"
    >
      <Panel
        className="panel-dropdown filter-display multi-select-filter"
        onClick={(e) => handleClick(e)}
      >
        {currentOptions.length
          ? (
            <div className="multi-select-wrap adjust-filter-multiselect">
              <div className="multi-select-div">
                <div className="tags-data">
                  {renderTags()}
                </div>
              </div>
            </div>
          )
          : <div>Select...</div>}
        {!currentOptions.length ? <SvgLoader width={24} height={24} svgName="down-arrow" /> : null}
        {open ? renderDropdown() : null}
      </Panel>
    </div>
  );
}

FilterMultiSelect.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  filterType: PropTypes.string.isRequired,
  update: PropTypes.bool.isRequired,
  resetSection: PropTypes.string.isRequired,
  assetClasse: PropTypes.string.isRequired,
  element: PropTypes.PropTypes.shape({
    spa: PropTypes.bool,
  }),
};

FilterMultiSelect.defaultProps = {
  element: {
    spa: false,
  },
};

export default FilterMultiSelect;
