import { combineEpics } from 'redux-observable';
import { getPowerSearchTypesEpic } from './PowerSearch/logic';
import { getTypesValuesEpic } from './PowerSearchBox/logic';
import { getSearchResultsEpic, getMoreSearchResultsEpic } from './List/logic';
import {
  getOrganizationValuesEpic, inviteUsersEpic, getUsersListEpic, createOrganizationEpic, editOrganizationEpic,
} from '../../app/container/Admin/logic';
import { getTimeLineDataEpic } from './TimeLine/logic';
import { getChatbotAnswerEpic } from './Chatbot/logic';
import { postSubmitFormEpic } from './ContactUsForm/logics';
import {
  getProjectsEpic, createProjectEpic, updateProjectEpic, shareProjectEpic,
} from './WorkSpace/logic';
import {
  createBookmarkEpic, getBookmarkEpic, deleteBookmarkEpic, shareBookmarkEpic,
} from './Bookmark/logic';
import { archiveProjectEpic } from './ProjectDropdown/logic';
import { createSavedSearchEpic } from './SavedSearches/logic';

const rootCommonEpic = combineEpics(
  getSearchResultsEpic,
  getPowerSearchTypesEpic,
  getTypesValuesEpic,
  getMoreSearchResultsEpic,
  getOrganizationValuesEpic,
  inviteUsersEpic,
  getUsersListEpic,
  getTimeLineDataEpic,
  getChatbotAnswerEpic,
  postSubmitFormEpic,
  getProjectsEpic,
  createProjectEpic,
  updateProjectEpic,
  createBookmarkEpic,
  archiveProjectEpic,
  getBookmarkEpic,
  shareProjectEpic,
  createOrganizationEpic,
  editOrganizationEpic,
  createSavedSearchEpic,
  deleteBookmarkEpic,
  shareBookmarkEpic,
);

export default rootCommonEpic;
