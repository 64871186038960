import { createAction, createReducer } from '@reduxjs/toolkit';

const WIDGETS = 'WIDGETS';

const INITIAL_WIDGETS_DATA = {
  interventionWidgets: [

    {
      label: 'Patents',
      value: 'patents',
      type: 'patents',
      size: 'medium',
    },
    {
      label: 'comming-soon',
      value: 'coming-sson',
      type: 'comming-soon',
      size: 'medium',
    },
    {
      label: 'Collapsible Graph',
      value: 'collapsible_graph',
      type: 'collapsible_graph',
      size: 'large',
    },

  ],
};

export const createWigetsAction = createAction(WIDGETS);

const onCreateWigetsAction = (state, payload) => {
  state.interventionWidgets = [...payload];
  return state;
};

export const interventionWidgetsReducer = createReducer(
  INITIAL_WIDGETS_DATA,
  (builder) => {
    builder.addCase(createWigetsAction, (state, action) => onCreateWigetsAction(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
