import React from 'react';
import PropTypes from 'prop-types';
import SvgLoader from '../../../common/components/SvgLoader';

function RecentTrials({ currentExpanded, selected }) {
  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }
  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">Recent Events</div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          <SvgLoader onClick={{}} width={22} height={22} svgName="expand" hoverIconName="expand-hover" />
          {/* <SvgLoader width={22} height={22} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
    </div>
  );
}

RecentTrials.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  // setExpanded: PropTypes.func.isRequired,
  currentExpanded: PropTypes.string.isRequired,
  // params: PropTypes.PropTypes.shape({
  //   headers: PropTypes.PropTypes.shape({}).isRequired,
  // }).isRequired,
};

export default RecentTrials;
