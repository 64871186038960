/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import QueryString from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import { modifeyNumber } from '../../../utils';
import { getPhaseGraphDataAction, getPhaseGraphDataRefresh } from './logic';
import Loader from '../../../common/components/Loader';
import TrendLineOptions from './data';
import MultiSelectDropdown from '../../components/MultiSelectDropdown';
import Mixpanel from '../../../utils/mixpanel';

const phaseOption = [{
  field_name: 'Phase',
  label: 'Phase',
  display_name: 'Phase',
  value: 'phase',
  type: 'multiselect',
  name: 'phase',
  currentFilter: [],
}];

const statusOption = [{
  currentKeys: [],
  label: 'Status',
  display_name: 'Status',
  value: 'status',
  type: 'multiselect',
  name: 'status',
  currentFilter: [],
}];

function PhaseGraph({
  params, selected, setExpanded, currentExpanded,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isStackBar, setIsStackBar] = useState(false);
  const [trendsLineOptionShow, setTrendsLineOptionsShow] = useState(false);
  const [currentTrendLineOption, setCurrentTrendLineOption] = useState([]);
  const [tooltipData, setTooltipData] = useState({
    phaseName: '',
    trialCount: 0,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [showStackBarTooltip, setShowStackBarTooltip] = useState(false);
  const [stackBarTooltipData, setShowStackBarTooltipData] = useState({});
  const [stackBarTooltipId, setStackBarTooltipId] = useState('');

  const phaseData = useSelector((state) => state.phaseGraphData);
  const searchResult = useSelector((state) => state.searchResult);

  const stackPhaseValues = ['Completed', 'Discontinued', 'In Progress', 'Not active', 'Other', 'Planned Recruitment'];

  const colors = d3.quantize(d3.interpolateRgb(d3.rgb(82, 82, 250), d3.rgb(117, 117, 251)), phaseData?.data?.length);

  const colorsOdd = d3.quantize(d3.interpolateRgb(d3.rgb(186, 186, 253), d3.rgb(220, 220, 254)), phaseData?.data?.length);

  const colorScale = d3.scaleOrdinal()
    .domain(phaseData?.data?.map((element) => element.name))
    .range(colors);

  const colorScaleOdd = d3.scaleOrdinal()
    .domain(phaseData?.data?.map((element) => element.name))
    .range(colorsOdd);

  const stackColorsScale = d3.scaleOrdinal()
    .domain(stackPhaseValues)
    .range(['#00BF6E', '#517CB6', '#FABE01', '#3FC8D1', '#FE8B4E', '#7D5F90', '#D25F91', '#C7B879']);

  const renderClassName = (value) => {
    const valArr = value.split(' ');
    let temp = '';
    valArr.forEach((item) => {
      if (item !== '/') {
        temp += item;
      }
    });
    return temp;
  };

  const handleOnBarClick = (value) => {
    const q = JSON.parse(query);
    const newQuery = [];
    let flag = true;
    if (q) {
      newQuery.push({
        currentFilter: [value],
        currentKeys: [value],
        display_name: 'Phase',
        field_name: 'Phase',
        label: 'Phase',
        name: 'phase',
        type: 'multiselect',
        value: 'phase',
      });
      flag = false;
    }

    if (semanticQuery) {
      newQuery.push({
        currentFilter: [value],
        currentKeys: [value],
        display_name: 'Phase',
        field_name: 'Phase',
        label: 'Phase',
        name: 'phase',
        type: 'multiselect',
        value: 'phase',
      });
      flag = false;
    }

    if (flag) {
      phaseOption[0].currentFilter = [value];
      phaseOption[0].currentKeys = [value];
      newQuery.push(...phaseOption);
    }

    Mixpanel.track('widget_deepdive_event', {
      action: 'User clicked bar for list view search',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
      barClickedValue: value,
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const colorScaleTrendLine = d3.scaleOrdinal()
    .domain(['Cumulative Planned Enrolment', 'Average Planned Enrolment', 'Cumulative Actual Enrolment', 'Average Actual Enrolment'])
    .range(['#76B812', '#007719', '#FFBD00', '#C74032']);

  const numberOfTicks = 4;
  const baseWidth = window.innerWidth - 150;

  const handleArrowClick = ({ statusName, phaseName }) => {
    const q = JSON.parse(query);
    const p = JSON.parse(semanticQuery);
    const newQuery = [];
    let flagIntervention = true;
    let flagPhase = true;
    if (q) {
      q.forEach((item) => {
        if (item.name === 'status') {
          item.currentFilter = [statusName];
          item.currentKeys = [statusName];
          flagIntervention = false;
        }

        if (item.name === 'phase') {
          item.currentFilter = [phaseName];
          item.currentKeys = [phaseName];
          flagPhase = false;
        }
        newQuery.push(item);
      });
    }

    if (semanticQuery) {
      p.forEach((item) => {
        if (item.name === 'status') {
          item.currentFilter = [statusName];
          item.currentKeys = [statusName];
          flagIntervention = false;
        }

        if (item.name === 'phase') {
          item.currentFilter = [phaseName];
          item.currentKeys = [phaseName];
          flagPhase = false;
        }
        newQuery.push(item);
      });
    }

    if (flagIntervention) {
      statusOption[0].currentFilter = [statusName];
      statusOption[0].currentKeys = [statusName];
      newQuery.push(...statusOption);
    }

    if (flagPhase) {
      phaseOption[0].currentFilter = [phaseName];
      phaseOption[0].currentKeys = [phaseName];
      newQuery.push(...phaseOption);
    }

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const draw = (dataset, stackValues = []) => {
    const stack = d3.stack().keys(stackValues);
    const stackData = stack(dataset);
    const maxTotalStatus = d3.max(dataset.map((item) => item.totalStats));
    let maxEnrolomentValue = 0;

    dataset.forEach((val) => {
      val?.enrolments.forEach((item) => {
        currentTrendLineOption.forEach((itemValue) => {
          if (item.name === itemValue && item.value > maxEnrolomentValue) {
            maxEnrolomentValue = item.value;
          }
        });
      });
    });

    const dimensions = {
      width: isExpanded ? baseWidth : baseWidth / 2,
      height: isExpanded ? 350 : 250,
      margin: {
        top: 20,
        left: isExpanded ? 80 : 60,
        right: isExpanded ? 80 : 20,
        bottom: isExpanded ? 100 : 30,
      },
    };

    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const svg = d3.select(`${isExpanded ? '#phase-expanded' : '#phase'}`).append('svg')
      .attr('id', `${isExpanded ? 'phase-graph-expanded' : 'phase-graph'}`)
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const ctr = svg.append('g')
      .attr('transform', `translate(${dimensions.margin.left}, ${dimensions.margin.top})`);

    const getNearestMax = (number) => {
      if (number < 10) {
        return number;
      }
      const length = String(Math.round(number)).length - 1;
      const x = number / (10 ** length);
      let z = 0;
      if (String(x).includes('.') && String(x).slice(2, 3) < 5) {
        z = `${String(x).slice(0, 2)}5`;
      } else {
        z = Math.ceil(x);
      }
      const y = z * 10 ** length;
      return y;
    };

    const yScale = d3.scaleLinear()
      .domain([0, getNearestMax(maxTotalStatus)])
      .range([dimensions.ctrHeight, 0]);

    ctr.append('g').call(d3.axisLeft(yScale).ticks(numberOfTicks).tickFormat((d) => modifeyNumber(d)))
      .call((g) => g.select('.domain').remove())
      .classed('y-axis', true);

    const xScale = d3.scaleBand()
      .domain(dataset.map((item) => item.name))
      .range([0, dimensions.ctrWidth])
      .padding(0.5);

    const handleXAxisLabel = (val) => {
      if (val.indexOf('/') !== '-1') {
        let temp = '';
        const tempArr = val.split('/');
        tempArr.forEach((item, i) => {
          if (i === 0) {
            temp += item;
          }
          if (item.indexOf('Phase') !== '-1' && i !== 0) {
            temp += `/ ${item.trim().slice(-1)} `;
          }
        });
        return temp;
      }
      return val;
    };

    const xAxisGroup = ctr.append('g').attr('transform', `translate(0, ${dimensions.ctrHeight})`)
      .call(d3.axisBottom(xScale).tickFormat((d) => handleXAxisLabel(d)))
      .classed('x-axis', true);

    if (isExpanded) {
      xAxisGroup.selectAll('text')
        .attr('x', (d) => -(handleXAxisLabel(d)).length * 3 - 20)
        .attr('y', 0)
        .attr('transform', 'rotate(-45)');
    }

    const bars = ctr.append('g');

    const stacked = ctr.append('g');

    const stackedTwo = ctr.append('g');

    const generateId = (str) => `${str.toLowerCase().replace(/[ /]/g, '-').trim()}`;

    const handleColorOfBars = (name, index) => {
      if (index % 2 !== 0) {
        return colorScale(name);
      }
      return colorScaleOdd(name);
    };

    bars.append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft()
        .scale(yScale)
        .tickSize(-dimensions.ctrWidth, 0, 0)
        .ticks(numberOfTicks)
        .tickFormat(''));

    bars.append('line')
      .attr('stroke', '#DCDCDC')
      .attr('x1', dimensions.ctrWidth)
      .attr('y1', 0)
      .attr('x2', dimensions.ctrWidth)
      .attr('y2', dimensions.ctrHeight)
      .attr('stroke-width', 1);

    const handleColor = (d, i) => {
      if (dataset.length === 1) {
        return '#5252FA';
      }
      return handleColorOfBars(d.name, i);
    };

    bars.selectAll('rect')
      .data(dataset)
      .join('rect')
      .attr('x', dataset.length === 1 && !isExpanded ? (d) => xScale(d.name) + 50 : (d) => xScale(d.name))
      .attr('rx', 8)
      .attr('class', 'pointer')
      .attr('id', (d) => `phase-${renderClassName(d.name)}`)
      .attr('y', (d) => yScale(d.totalStats))
      .attr('ry', 8)
      .attr('fill', (d, i) => handleColor(d, i))
      .attr('height', (d) => dimensions.ctrHeight - yScale(d.totalStats))
      .attr('width', dataset.length === 1 && !isExpanded ? 80 : xScale.bandwidth())
      .on('click', (event, datum) => {
        handleOnBarClick(datum.name);
      })
      .on('mouseenter', (event, datum) => {
        setTooltipData({ ...{ phaseName: datum.name, trialCount: datum.totalStats } });
        setShowTooltip(true);
      })
      .on('mouseleave', () => {
        setShowTooltip(false);
      });

    const stackBars = stacked.selectAll('g')
      .data(stackData)
      .join('g')
      .style('fill', (d) => stackColorsScale(d.key));

    const stackBarsTwo = stackedTwo.selectAll('g')
      .data(stackData)
      .join('g')
      .attr('class', 'stackedTw0')
      .style('fill', (d) => stackColorsScale(d.key));

    if (isExpanded) {
      stackBars.selectAll('rect')
        .data((d) => d)
        .join('rect')
        .attr('class', 'pointer')
        .attr('height', (d) => (yScale(d[0]) - yScale(d[1]) ? yScale(d[0]) - yScale(d[1]) : 0))
        .attr('width', dataset.length === 1 && !isExpanded ? 80 : xScale.bandwidth())
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('x', dataset.length === 1 && !isExpanded ? (d) => xScale(d.data.name) + 50 : (d) => xScale(d.data.name))
        .attr('y', (d) => yScale(d[1]));
    }

    const handleY = (d) => {
      if (d[1] === d.data.totalStats) {
        return yScale(d[1]) + 2;
      }
      return yScale(d[1]);
    };

    const handleHeight = (d) => {
      if (d[0] === 0) {
        return yScale(d[0]) - yScale(d[1]) - 2;
      }
      if (d[1] === d.data.totalStats) {
        return yScale(d[0]) - yScale(d[1]) - 2;
      }
      return yScale(d[0]) - yScale(d[1]);
    };

    if (isExpanded) {
      stackBarsTwo.selectAll('rect')
        .data((d) => d.map((item) => ({ ...item, statusName: d.key })))
        .join('rect')
        .attr('class', 'pointer')
        .attr('id', (d) => `phase-${generateId(`${d.statusName}-${d.data.name}`)}`)
        .attr('height', (d) => (handleHeight(d) ? handleHeight(d) >= 0 ? handleHeight(d) : 0 : 0))
        .attr('width', dataset.length === 1 && !isExpanded ? 80 : xScale.bandwidth())
        .attr('x', dataset.length === 1 && !isExpanded ? (d) => xScale(d.data.name) + 50 : (d) => xScale(d.data.name))
        .attr('y', (d) => handleY(d))
        .on('click', (event, datum) => {
          handleArrowClick({
            statusName: datum.statusName,
            phaseName: datum.data.name,
          });
        })
        .on('mouseenter', (event, datum) => {
          setShowStackBarTooltip(true);
          setStackBarTooltipId(`phase-${generateId(`${datum.statusName}-${datum.data.name}`)}`);
          setShowStackBarTooltipData({
            statusName: datum.statusName,
            phaseName: datum.data.name,
            trialCount: datum[1] - datum[0],
          });
        });
    }

    stacked.append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft()
        .scale(yScale)
        .tickSize(-dimensions.ctrWidth, 0, 0)
        .ticks(numberOfTicks)
        .tickFormat(''));

    stacked.append('line')
      .attr('stroke', '#DCDCDC')
      .attr('x1', dimensions.ctrWidth)
      .attr('y1', 0)
      .attr('x2', dimensions.ctrWidth)
      .attr('y2', dimensions.ctrHeight)
      .attr('stroke-width', 1);

    const trendLineGroup = ctr.append('g');

    if (currentTrendLineOption.length) {
      const yScaleTrendLine = d3.scaleLinear()
        .domain([0, getNearestMax(maxEnrolomentValue)])
        .range([dimensions.ctrHeight, 0]);

      const yAxisRight = d3.axisRight(yScaleTrendLine).ticks(numberOfTicks).tickFormat((d) => modifeyNumber(d, 0));

      ctr.append('g').call(yAxisRight)
        .style('transform', `translateX(${dimensions.ctrWidth}px)`)
        .call((g) => g.select('.domain').remove())
        .classed('y-axis', true);

      const linePoints = {};

      currentTrendLineOption.forEach((item) => {
        dataset.forEach((val) => {
          val?.enrolments.forEach((enrolement) => {
            if (enrolement.name === item) {
              if (linePoints[item]) {
                linePoints[item].push({
                  phaseName: val.name,
                  enrolementValue: enrolement.value,
                });
              } else {
                linePoints[item] = [{
                  phaseName: val.name,
                  enrolementValue: enrolement.value,
                }];
              }

              trendLineGroup.append('circle')
                .attr('r', 7.5)
                .attr('fill', colorScaleTrendLine(enrolement.name))
                .attr('stroke', 'white')
                .attr('stroke-width', 2)
                .attr('cx', xScale(val.name) + xScale.bandwidth() / 2)
                .attr('cy', yScaleTrendLine(enrolement.value));
            }
          });
        });
      });

      currentTrendLineOption.forEach((enrol) => {
        linePoints[enrol].forEach((item, i) => {
          if (i !== 0) {
            trendLineGroup.append('line')
              .style('stroke', colorScaleTrendLine(enrol))
              .style('stroke-width', 2)
              .attr('x1', xScale(linePoints[enrol][i - 1].phaseName) + xScale.bandwidth() / 2)
              .attr('y1', yScaleTrendLine(linePoints[enrol][i - 1].enrolementValue))
              .attr('x2', xScale(item.phaseName) + xScale.bandwidth() / 2)
              .attr('y2', yScaleTrendLine(item.enrolementValue));
          }
        });
      });
    }

    if (!isStackBar) {
      stacked.attr('display', 'none');
      stackedTwo.attr('display', 'none');
      bars.attr('display', 'block');
    } else {
      bars.attr('display', 'none');
      stacked.attr('display', 'block');
      stackedTwo.attr('display', 'block');
    }
  };

  useEffect(() => {
    if ((currentExpanded === '' && searchResult.flag) || currentExpanded === selected.label) {
      dispatch(getPhaseGraphDataRefresh());
      const request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getInsightsByPhase',
        headers: QueryString.stringify({
          ...params.headers,
          field_name: 'phase',
          isStackedBar: isStackBar,
          trendLines: true,
        }),
      };
      dispatch(getPhaseGraphDataAction(request));
    }
  }, [isStackBar, currentExpanded, JSON.stringify(searchResult)]);

  useEffect(() => {
    if (isExpanded) {
      setIsStackBar(true);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (phaseData.flag && phaseData.data.length) {
      const data = [];
      phaseData.data.forEach((item) => {
        const temp = {
          name: item.name,
          totalStats: item.trialCount,
          enrolments: item.enrolments,
        };
        item.status.forEach((ele) => {
          temp[Object.keys(ele)] = ele[Object.keys(ele)];
        });
        data.push({
          ...temp,
        });
      });
      d3.select('#phase-graph-expanded').remove();
      d3.select('#phase-graph').remove();
      draw(isExpanded ? data : data.slice(0, 5), stackPhaseValues);
    }
  }, [JSON.stringify(phaseData), isExpanded, isStackBar, currentExpanded, JSON.stringify(currentTrendLineOption)]);

  const handleLegendclick = (value) => {
    const newQuery = [];
    let flag = true;

    if (query) {
      const tempQ = JSON.parse(query);
      tempQ.forEach((item) => {
        if (item.name === 'status') {
          newQuery.push({
            ...item,
            currentFilter: [value],
            currentKeys: [value],
          });
        } else {
          newQuery.push(item);
        }
      });
      flag = false;
    }

    if (semanticQuery) {
      const tempQ = JSON.parse(semanticQuery);
      tempQ.forEach((item) => {
        if (item.name === 'status') {
          newQuery.push({
            ...item,
            currentFilter: [value],
            currentKeys: [value],
          });
        } else {
          newQuery.push(item);
        }
      });
      flag = false;
    }

    if (flag) {
      statusOption[0].currentFilter = [value];
      statusOption[0].currentKeys = [value];
      newQuery.push(...statusOption);
    }

    Mixpanel.track('widget_deepdive_event', {
      action: 'status legends click in expanded view for list view search',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
      barClickedValue: value,
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const renderLegends = () => stackPhaseValues.map((item) => (
    <div aria-hidden key={item} className="legend" onClick={() => handleLegendclick(item)}>
      <div className="circle" style={{ backgroundColor: stackColorsScale(item) }} />
      <span className="legend-name">{item}</span>
    </div>
  ));

  const renderTrendLineLegends = () => currentTrendLineOption.map((item) => (
    <div key={item} className="legend">
      <div className="circle" style={{ backgroundColor: colorScaleTrendLine(item) }} />
      <span className="legend-name">{item}</span>
    </div>
  ));

  const style = {
    borderRadius: '8px',
    boxShadow: '0px 14px 44px 0px #7e7ecb4d',
  };

  const handleMouseLeave = () => {
    setShowStackBarTooltip(false);
    setShowStackBarTooltipData({ ...{} });
  };

  const handleShrinkClick = () => {
    setIsExpanded(false);
    setIsStackBar(false);
    setExpanded('');
    setCurrentTrendLineOption([...[]]);
    Mixpanel.track('collapseWidgetEvent', {
      action: 'collapse Phase widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(true);
    setExpanded(selected.label);
    Mixpanel.track('expandWidgetEvent', {
      action: 'expand Phase widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const trackStackBarToggle = (flag) => {
    setIsStackBar((current) => !current);
    Mixpanel.track('stackBarToggleEvent', {
      action: `toggle stack bar ${!flag ? 'on' : 'off'}`,
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  const renderStackBarTooltip = () => (
    <Tooltip id="tooltip-intervnetion-stack" isOpen={showStackBarTooltip} anchorSelect={`#${stackBarTooltipId}`} place="left">
      <div>
        <div className="tooltip tooltip-graph">
          <div className="tooltip-content tooltip-content-graph">
            <div className="tooltip-content-graph-content">
              <div className="tooltip-value">{stackBarTooltipData.trialCount}</div>
              <div className="tooltip-label">{stackBarTooltipData.phaseName}</div>
              <div className="tooltip-label">{stackBarTooltipData.statusName}</div>
              <div className="tooltip-btn">
                <div aria-hidden className="arrow-button" type="submit" onClick={() => handleArrowClick(stackBarTooltipData)}>
                  <span className="right-arrow-black pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );

  const renderTooltip = () => (
    <div>
      <Tooltip style={style} anchorSelect={`#phase-${renderClassName(tooltipData.phaseName)}`} isOpen={showTooltip} place="top">
        <div className="endpoints-detail-container">
          <div className="blue-vertical-line-geo" />
          <div className="endpoints-detail-sub-container">
            <div className="geo-detail">
              <span className="geo-trial">{tooltipData.trialCount}</span>
              <span className="endpoint-type adjust-trials-pos">Trials</span>
              <span className="geo-country">{tooltipData.phaseName}</span>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );

  const renderExpandedView = () => (
    <div className="Widget expanded" onMouseLeave={() => handleMouseLeave()}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">{selected.label}</div>
        </div>
        <div className="icons">
          <div aria-hidden className="trend-line" onClick={() => setTrendsLineOptionsShow(true)}>
            <span className="trend-line-text">Trend Line</span>
            <SvgLoader width={12} height={7.4} svgName={`${trendsLineOptionShow ? 'down-arrow-phase-hover' : 'down-arrow-phase'}`} hoverIconName="down-arrow-phase-hover" />
            <MultiSelectDropdown open={trendsLineOptionShow} setOpen={setTrendsLineOptionsShow} currentOptions={currentTrendLineOption} setCurrentOptions={setCurrentTrendLineOption} options={TrendLineOptions} feature="Phase graph" />
          </div>
          <div className="stack-bar-option">
            <input checked={isStackBar} onChange={() => trackStackBarToggle(isStackBar)} className="checkbox-input pointer" type="checkbox" />
            <span className="text">Stacked Bar</span>
          </div>
          <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
          {/* <SvgLoader width={24} height={24} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <div id="phase-expanded" />
      <div className="legends-trendline">{(currentTrendLineOption.length) ? renderTrendLineLegends() : null}</div>
      {renderStackBarTooltip()}
      <div className="legends">{(phaseData?.data?.length && isStackBar) ? renderLegends() : null}</div>
      {phaseData?.data?.length && renderTooltip()}
      <div className="drag-expaned-icon" />
    </div>
  );

  if (isExpanded) {
    return (
      <div
        className="expanded-widget"
      >
        <Loader
          error={phaseData.error}
          loading={phaseData.loading}
          noData={phaseData.data?.length === 0 && phaseData.flag}
          height="320px"
          width="calc(100vw - 40px)"
          className="white-loader"
          message="No records found"
        >
          {renderExpandedView()}
        </Loader>
      </div>
    );
  }

  return (
    <div
      className="Widget"
    >
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">{selected.label}</div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          <SvgLoader onClick={() => handleExpandClick()} width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" />
          {/* <SvgLoader width={22} height={22} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <Loader
        error={phaseData.error}
        loading={phaseData.loading || searchResult.loading}
        noData={phaseData.data?.length === 0 && phaseData.flag}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div id="phase" />
        {phaseData?.data?.length && renderTooltip()}
        <div className="drag-expaned-icon" />
      </Loader>
    </div>
  );
}

PhaseGraph.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  setExpanded: PropTypes.func.isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default PhaseGraph;
