/*eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Popover, Modal, Input, Button, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import {
  updateProjectAction,
  getProjectsAction,
  getProjectsRefreshAction,
  shareProjectAction,
  updateProjectRefreshAction,
} from "../WorkSpace/logic";
import { useDispatch, useSelector } from "react-redux";
import { archiveProjectAction } from "./logic";
import { getBookmarkAction } from "../Bookmark/logic";
import { sendNotification } from "../../components/Notification/logic";

function ProjectDropdown({
  updatedProjectDates,
  sharedDocumentId,
  projectsData,
  openExpandedProject,
  renderProjects,
  setProjectId = { setProjectId },
  projectId,
  bookmarkFilters,
  filterData,
}) {
  const dispatch = useDispatch();
  const updateProject = useSelector((state) => state.updateProject);
  const archiveProject = useSelector((state) => state.archiveProject);
  const shareProject = useSelector((state) => state.shareProject);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openProjectOptions, setOpenProjectOptions] = useState(-1);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRenameProject, setOpenRenameProject] = useState(false);
  const [inputText, setInputText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [inputError, setInputError] = useState("");
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [dummyData, setDummyData] = useState({});

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData.map((filter) => filter.toLowerCase().replace(/ /g, "_"));
  }

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: "",
        },
      })
    );
  };
  const showShareModal = () => {
    setOpenShareModal(!openShareModal);
  };
  const showDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setOpenShareModal(!openShareModal);
  };

  const handleRenameClick = (inputVal, data) => {
    setOpenRenameProject(!openRenameProject);
    dispatch(updateProjectRefreshAction());
    dispatch(
      updateProjectAction({
        params: {
          project_name: inputText,
          project_id: data?.projectId,
        },
      })
    );
    setOpenProjectOptions(-1);
  };
  useEffect(() => {
    if (updateProject.flag || archiveProject.flag) {
      apiCall();
    }
  }, [JSON.stringify(updateProject), JSON.stringify(archiveProject)]);

  useEffect(() => {
    if (updateProject.flag) {
      dispatch(updateProjectRefreshAction())
      dispatch(sendNotification({
        type: 'UPDATE_PROJECT_1_SUCCESS',
        notificationType: 'success',
        message: 'Project renamed successfully',
        showNotification: true,
    }))
    }
  }, [JSON.stringify(updateProject)]);

  useEffect(() => {
    if (archiveProject.flag) {
      dispatch(
        sendNotification({
          type: "PROJECT_ARCHIVE_SUCCESS",
          notificationType: "success",
          message: "Project archived successfully",
          showNotification: true,
        })
      );
    }
  }, [JSON.stringify(archiveProject)]);
  const handleChange = (value) => {
    setInputText(value);

    if (value.length > 3) {
      setInputError("");
    } else {
      setInputError("Please enter more than 3 characters");
    }
  };
  const renderRenameProject = (data) => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> Rename project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => {
            setOpenRenameProject(false);
            // setOpenProjectOptions(-1);
          }}
        />
      </div>
      <div className="project-content">
        <Input
          onChange={(e) => handleChange(e.target.value)}
          value={inputText}
        />
        {inputError && <div style={{ color: "red" }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => {
            setOpenRenameProject(false);
            // setOpenProjectOptions(-1);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={() => handleRenameClick(inputText, data)}
          disabled={inputText.length <= 3}
        >
          Rename
        </Button>
      </div>
    </div>
  );

  const handleArchiveClick = (data) => {
    dispatch(
      archiveProjectAction({
        params: {
          project_id: data?.projectId,
        },
      })
    );
    setOpenProjectOptions(-1);
  };

  const handleClick = (data, idx, objIdx) => {
    setOpenProjectOptions((prev) => {
      if (prev === objIdx * 10000 + idx) return -1;
      return objIdx * 10000 + idx;
    });
    setDummyData(data);
  };

  const handleInviteClick = () => {
    // let sharedIds = [];
    // sharedIds.push(emailTags);
    if (!emailError) {
      dispatch(
        shareProjectAction({
          body: {
            project_id: dummyData.projectId,
            shared_ids: emailTags,
          },
        })
      );
      if (!dummyData?.sharedusers || dummyData.sharedusers.length === 0) {
        setOpenShareModal(!openShareModal);
        setOpenProjectOptions(-1);
      }
    }
    setOpenProjectOptions(-1);
  };

  useEffect(() => {
    if (shareProject.flag) {
      // toast(shareProject.data.message, {
      //   position: "top-center",
      //   autoClose: 2000,
      //   pauseOnHover: false,
      //   draggable: true,
      //   newestOnTop: true,
      //   type: "success",
      //   hideProgressBar: false,
      //   closeButton: true,
      //   theme: "dark",
      // });
      dispatch(
        sendNotification({
          type: "PROJECT_SHARED_SUCCESS",
          notificationType: "success",
          message: shareProject.data.message,
          showNotification: true,
        })
      );
      apiCall();
      setCurrentEmail("");
      setEmailTags([]);
      setOpenShareModal(false);
    }
  }, [JSON.stringify(shareProject)]);

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
    if (emailRegex.test(e.target.value) || e.target.value === "") {
      setEmailError("");
    } else {
      setEmailError("Invalid email format");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && currentEmail) {
      if (!emailTags.includes(currentEmail)) {
        setEmailTags([...emailTags, currentEmail]);
        setCurrentEmail("");
        setIsEnterPressed(true);
      }
      e.preventDefault();
    }
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  useEffect(() => {
    if (shareProject.flag) {
      apiCall();
      setCurrentEmail("");
      setEmailTags([]);
    }
  }, [JSON.stringify(shareProject)]);
  const renderProjectOptions = (data) => (
    <div className="project-options-section">
      <div
        className="project-options"
        role="presentation"
        onClick={showShareModal}
      >
        <div className="share-btn" />
        <div className="share">Share</div>
      </div>

      {data?.category !== "Default" ? (
        <Popover
          overlayClassName="create-proj-popover"
          placement="bottomLeft"
          open={openRenameProject}
          content={renderRenameProject(data)}
        >
          <div
            className="project-options"
            role="presentation"
            onClick={() => {
              setOpenRenameProject(!openRenameProject);
            }}
          >
            <div className="rename-btn" />
            <div className="rename">Rename</div>
          </div>
        </Popover>
      ) : null}

      <div
        className="project-options"
        role="presentation"
        onClick={() => handleArchiveClick(data)}
      >
        <div className="archive-btn" />
        <div className="archive">Archive</div>
      </div>
    </div>
  );

  return (
    <div className="analytics-container-content-sidebar-link-main">
      {Object.keys(updatedProjectDates).map((key, index) => {
        if (key === "Shared Documents") return null;

        return (
          <>
            <div key={updatedProjectDates}>
              <div className="analytics-container-content-sidebar-link">
                <div className="analytics-container-content-sidebar-link-title">
                  {key}
                </div>
                <span
                  className={
                    openExpandedProject.includes(index)
                      ? "drop-icon"
                      : "dropdown-arrow"
                  }
                  role="presentation"
                  onClick={() => renderProjects(index)}
                />
              </div>
            </div>
            <div></div>
            {openExpandedProject.includes(index) && (
              <Loader loading={projectsData.loading}>
                <div className="analytics-container-content-sidebar-link--child">
                  {updatedProjectDates[key]?.map((i, contentIndex) => {
                    return (
                      <div
                        key={i.id}
                        className={
                          projectId === i?.projectId
                            ? "analytics-container-content-sidebar-link active"
                            : "analytics-container-content-sidebar-link"
                        }
                        role="presentation"
                        onClick={() => setProjectId(i?.projectId)}
                      >
                        <div className="folder-icon" />
                        <div className="analytics-container-content-sidebar-link-title">
                          <div className="analytics-container-content-sidebar-link-title-main">
                            {i?.projectName}
                          </div>
                          <div className="analytics-container-content-sidebar-link-share-option">
                            {i?.sharedByUser ? (
                              <div className="shared-with" key="shared-with">
                                <div className="shared-with-content">
                                  shared by:
                                </div>

                                <div className="log-menu-content">
                                  <div className="log-menu">
                                    <div className="log-menu-profile">
                                      <div
                                        aria-hidden
                                        className="user-name-initials"
                                      >
                                        <span
                                          className="user"
                                          title={i?.sharedByUser}
                                        >
                                          {i?.sharedByUser.charAt(0)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : i?.sharedusers?.length > 0 ? (
                              <div className="shared-with" key="shared-with">
                                <div className="shared-with-content">
                                  shared with:
                                </div>
                                {i.sharedusers.map((user) => (
                                  <div className="log-menu-content">
                                    <div key={user} className="log-menu">
                                      <div className="log-menu-profile">
                                        <div
                                          aria-hidden
                                          className="user-name-initials"
                                        >
                                          <span className="user" title={user}>
                                            {user.charAt(0)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="shared-with-content">private</div>
                            )}
                          </div>
                        </div>

                        <div>
                          <div
                            className="three-dots-icon"
                            role="presentation"
                            onClick={() => {
                              handleClick(i, index + 1, contentIndex + 1);
                              setInputText(i?.projectName);
                            }}
                          >
                            {/* <div className="add-project" />
                                <div className="project-name">Create New Project</div> */}
                          </div>
                          <Popover
                            overlayClassName="proj-options-popover"
                            placement="bottom"
                            open={
                              openProjectOptions ===
                              (contentIndex + 1) * 10000 + (index + 1)
                            }
                            trigger="click"
                            content={renderProjectOptions(i)}
                          ></Popover>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Loader>
            )}
          </>
        );
      })}
      <div
        className=""
        onClick={() => {
          setProjectId(sharedDocumentId);
          renderProjects(-1);
          dispatch(
            getBookmarkAction({
              body: {
                project_id: projectId,
                asset_class: convertToLowerCaseWithUnderscores(bookmarkFilters),
              },
            })
          );
        }}
      >
        <div className="analytics-container-content-sidebar-link">
          <div className="analytics-container-content-sidebar-link-title">
            Shared Documents
          </div>
        </div>
      </div>
      {openShareModal && (
        <Modal
          className="share-with-modal"
          open={openShareModal}
          closable
          footer={false}
          onCancel={() => setOpenShareModal(false)}
        >
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div
                className="email-tags-container"
                style={{
                  maxHeight: "100px",
                  overflowY: "auto",
                  marginBottom: "10px",
                }}
              >
                <div className="email-tags">
                  {emailTags.map((email, index) => (
                    <div key={index} className="email-tag">
                      {email}
                      <span
                        className="remove-tag"
                        onClick={() => handleTagRemove(email)}
                      />
                    </div>
                  ))}
                  <Input
                    //  mode="tags"
                    value={currentEmail}
                    onChange={handleEmailInputChange}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Email ID"
                    //   style={{ flex: '0 0 auto', marginLeft: '5px' }}
                  />
                  {emailError && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
              </div>
              <div className="share-with-message-input">
                <Input placeholder="Message (Optional)" />
              </div>
              <div className="invite-btn">
                <Button
                  disabled={
                    !isEnterPressed || emailError || emailTags.length === 0
                  }
                  type="primary"
                  onClick={() => handleInviteClick()}
                >
                  Invite
                </Button>
                {/* <ToastContainer /> */}
              </div>
            </div>
            {dummyData?.sharedusers?.length ? (
              <div className="shared-with-title">Already shared with</div>
            ) : null}
            {dummyData?.sharedusers?.map((user) => (
              <div className="shared-with-content" key={user}>
                <div className="shared-with-persons-data">
                  <div className="shared-with-persons-list">
                    <div key={user} className="log-menu">
                      <div className="log-menu-profile">
                        <div aria-hidden className="user-name-initials">
                          <span className="user" title={user}>
                            {user.charAt(0)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="person-1-details person-details">
                      <div className="person-1-name person-name">{user}</div>
                    </div>
                    {/* <div className="delete-icon" role="presentation" onClick={showDeleteModal()} /> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
      {/* {openDeleteModal && (
        <Modal className="share-with-modal" open={openDeleteModal} closable footer={false} onCancel={showDeleteModal}>
          <div className="delete-modal-content">
            <div className="delete-title">Share with</div>
            {dummyData?.sharedusers?.map((user) => (
              <><div className="delete-message">Are you sure you want to remove {user}</div><div className="delete-btn">
                <Button className="cancel-btn" type="primary" role="presentation" onClick={showDeleteModal}>No</Button>
                <Button className="add-btn" type="primary" role="presentation">Yes</Button>
              </div></>
            ))}

          </div>
        // </Modal>
      )} */}
    </div>
  );
}

export default ProjectDropdown;
