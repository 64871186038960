import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { modifyDataForDropdown, trackOutSideEvents } from '../../../utils';
import SvgLoader from '../SvgLoader';
import Panel from '../Panel';
import { getTypesValuesAction, getTypesValuesRefreshAction } from '../../container/PowerSearchBox/logic';
import { selectFilterAction } from '../../container/FilterModal/logics';
import { updateFilterAction } from '../../container/PowerSearch/logic';
import Loader from '../Loader';

function FilterDropdown({
  apiUrl, selectedValue, currentSelected, id, isDefault, filterType, assetClasse, resetSection, element, update,
}) {
  const divEl = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('filters');

  const [isOpen, setIsOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState('');
  const [currentOptionVal, setCurrentOptionVal] = useState('');
  const [genderOPtions, setGenderOptions] = useState([]);
  const optionValues = useSelector((state) => state.optionValues);
  const currentFilters = useSelector((state) => state.filters);
  const options = modifyDataForDropdown(optionValues.data, 'key', 'value');

  useEffect(() => {
    trackOutSideEvents(divEl, () => setIsOpen(false));
  }, []);

  useEffect(() => {
    if (options.length && currentSelected === selectedValue) {
      setIsOpen(true);
    }
  }, [JSON.stringify(options)]);

  useEffect(() => {
    if (currentFilters.filters[id]?.currentFilter?.length) {
      setCurrentOption(decodeURIComponent(currentFilters.filters[id].currentFilter[0]));
      if (currentFilters.filters[id].name === 'gender') {
        setGenderOptions([...currentFilters.filters[id].currentKeys]);
      }
      setIsOpen(false);
    }
  }, [JSON.stringify(currentFilters)]);

  useEffect(() => {
    if (resetSection === assetClasse) {
      setCurrentOption('');
      setGenderOptions([...[]]);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [], currentKeys: [] },
        id,
      }));
    }
  }, [resetSection, assetClasse]);

  useEffect(() => {
    const f = JSON.parse(filters);
    if (f) {
      f.forEach((item) => {
        const SECondition = item.name === selectedValue && !isDefault && item.assetClass === assetClasse;
        const others = item.name === selectedValue && !isDefault;
        const Condition = filterType === 'SE' ? SECondition : others;
        if (Condition) {
          setCurrentOption(item.currentFilter[0]);
          dispatch(updateFilterAction({
            filterOption: { currentFilter: [item.currentFilter[0]], currentKeys: [item.currentKeys[0]] },
            id,
          }));
          if (item.name === 'gender') {
            setGenderOptions([...item.currentKeys[0]]);
            dispatch(updateFilterAction({
              filterOption: { currentFilter: [item.currentFilter[0]], currentKeys: [...[...item.currentKeys]] },
              id,
            }));
          }
        }
      });
    }
  }, [update]);

  useEffect(() => {
    if (currentSelected === selectedValue) {
      if (selectedValue !== 'gender') {
        dispatch(updateFilterAction({
          filterOption: { currentFilter: [encodeURIComponent(currentOptionVal)], currentKeys: [encodeURIComponent(currentOption)] },
          id,
        }));
      } else {
        dispatch(updateFilterAction({
          filterOption: { currentFilter: [encodeURIComponent(currentOption)], currentKeys: [...[genderOPtions]] },
          id,
        }));
      }
    }
  }, [currentOption]);

  useEffect(() => {
    let request = {};
    if (filterType === 'SE' && currentSelected === selectedValue) {
      dispatch(getTypesValuesRefreshAction());
      request = {
        apiUrl,
        params: queryString.stringify({
          field_name: selectedValue,
          asset_class: element.spa ? assetClasse : 'ot_search_assetclasses',
          size: 50,
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
      dispatch(getTypesValuesAction(request));
    } else if (filterType === 'CT' && currentSelected === selectedValue) {
      dispatch(getTypesValuesRefreshAction());
      request = {
        apiUrl,
        params: queryString.stringify({
          field_name: selectedValue,
          terminal_name: 'clinical_trials',
        }),
        headers: {
          Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
        },
      };
      dispatch(getTypesValuesAction(request));
    }
  }, [currentSelected]);

  const handleOptionClick = (option) => {
    setCurrentOptionVal(option.value);
    setCurrentOption(option.key);
    if (selectedValue === 'gender') {
      setGenderOptions([...option.value]);
    }
    setIsOpen(false);
  };

  const renderedOptions = options.map((option) => (
    <div
      className="options filter-option"
      aria-hidden
      onClick={() => handleOptionClick(option)}
      key={option.key}
    >
      {option.key}
    </div>
  ));

  const handleClick = () => {
    dispatch(selectFilterAction(selectedValue));
    if (options.length) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div
      ref={divEl}
      className="dropdown"
    >
      <Panel
        onClick={handleClick}
        className="panel-dropdown filter-display"
      >
        {currentOption || 'Select...'}
        <SvgLoader width={24} height={24} svgName="down-arrow" />
      </Panel>
      {isOpen ? (
        <Panel className="panel filter-display-panel">
          <Loader loading={optionValues.loading} error={optionValues.error} noData={optionValues.data?.length === 0} height={50}>
            <div>
              {renderedOptions}
            </div>
          </Loader>
        </Panel>
      ) : null}
    </div>
  );
}

FilterDropdown.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  currentSelected: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  filterType: PropTypes.string.isRequired,
  resetSection: PropTypes.string.isRequired,
  assetClasse: PropTypes.string.isRequired,
  element: PropTypes.PropTypes.shape({
    spa: PropTypes.bool,
  }),
  update: PropTypes.bool.isRequired,
};

FilterDropdown.defaultProps = {
  element: ({
    spa: false,
  }),
};

export default FilterDropdown;
