import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import useD3 from 'hook-use-d3';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';
import SvgLoader from '../../../common/components/SvgLoader';
import Mixpanel from '../../../utils/mixpanel';
import {
  getCollapisibleGraphAction,
  getCollapisibleGraphRefresh,
  getCollapsibleGraphStateAction,
} from './logic';
import {
  marketedLadder,
  clinicalLadder,
  noDateFoundMessageClinicalLadder,
  noDateFoundMessageLadder,
} from './data';
import { modifyDataForGetResultsQuery, wordLimit } from '../../../utils';

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function CollapsibleGraph({ currentExpanded, selected, setExpanded }) {
  const dispatch = useDispatch();
  const baseWidth = window.innerWidth - 150;
  const [searchParams] = useSearchParams();
  const q = searchParams.get('query');
  const query = modifyDataForGetResultsQuery(
    JSON.parse(searchParams.get('query')),
  );
  const tooltipRef = React.useRef(null);
  const secondTooltipRef = React.useRef(null);

  const [chosenType, setChosenType] = React.useState([]);
  const [chosenNodes, setChosenNodes] = React.useState([]);
  const [dataPoint, setDataPoint] = React.useState([]);
  const [currentIndexNode, setCurrentIndexNode] = React.useState(0);
  const [callApi, setCallApi] = React.useState(true);
  const [isToolTipVisible, setIsToolTipVisible] = React.useState(false);
  const [toolTipData, setToolTipData] = React.useState({});
  const [tooltipId, setTooltipId] = React.useState('');
  const [currentHoveredNodeIndex, setCurrentHoveredNodeIndex] = React.useState(-1);
  const [onlyLastElementExpand, setOnlyLastElementExpand] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loaderPosition, setLoaderPosition] = React.useState([0, 0]);
  const [noDataFoundPosition, setNoDataFoundPosition] = React.useState([0, 0]);
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [lastNodeName, setLastNodeName] = React.useState('');
  const [showTextNameOnHover, setShowTextNameOnHover] = React.useState(false);
  const [textNameHovered, setTextNameHovered] = React.useState('');
  const [textHoveredId, setTextHoveredId] = React.useState('');

  const collapisibleGraphData = useSelector(
    (state) => state.collapisibleGraphData,
  );

  const dimensions = {
    width: baseWidth + 2000,
    height: isExpanded ? 1650 : 250,
    margin: {
      left: 10,
      top: 10,
      bottom: 10,
      right: 10,
    },
  };

  const startingCirlceCenter = isExpanded ? [150, 820] : [150, 120];
  const marketCircleCenter = isExpanded ? [300, 760] : [300, 60];
  const clinicalCircleCenter = isExpanded ? [300, 880] : [300, 180];
  let currentDrugName = '';
  if (Object.keys(query.body).length > 0) {
    currentDrugName = capitalizeFirstLetter(
      JSON.parse(searchParams.get('query'))[0]?.currentFilter[0],
    );
  }

  const maxFontSize = 25;
  const spreadOfText = 18;

  useEffect(() => {
    setChosenNodes([...[]]);
    setDataPoint([...[]]);
    setCurrentIndexNode(0);
    setOnlyLastElementExpand(false);
  }, [chosenType]);

  useEffect(() => {
    if (isExpanded) {
      const element = document.getElementById('callapsible-graph-expanded');
      element.scrollTo(0, 500);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (chosenType.length > 0 && callApi) {
      dispatch(getCollapisibleGraphRefresh());
      const request = {
        headers: queryString.stringify({
          size: isExpanded ? 10 : 5,
          type: chosenType[0].toLowerCase(),
          field: chosenType.includes('Marketed')
            ? marketedLadder[currentIndexNode]
            : clinicalLadder[currentIndexNode],
        }),
        body: {
          filters: {
            ...query.body,
          },
        },
      };

      if (chosenType.includes('Marketed')) {
        request.body.filters = {
          ...request.body.filters,
          ...{
            development_status: [
              {
                value: chosenType[0],
                operator: 'OR',
              },
            ],
          },
        };
      }

      if (chosenNodes.length) {
        const additionalQuery = {};

        chosenNodes.forEach((item) => {
          additionalQuery[item.name] = [
            {
              value: item.key,
              operator: 'OR',
            },
          ];
        });

        request.body.filters = { ...request.body.filters, ...additionalQuery };
      }
      dispatch(getCollapisibleGraphAction(request));
    }
  }, [chosenType, chosenNodes]);

  useEffect(() => {
    if (collapisibleGraphData?.flag) {
      if (collapisibleGraphData?.data?.length > 0) {
        const { data, field, displayField } = collapisibleGraphData;
        const modifyData = [
          {
            name: displayField,
            type: 'Head',
          },
        ];

        data.forEach((item) => {
          if (item.key && item.value) {
            modifyData.push({
              name: item.key,
              key: item.value,
              type: 'subject',
            });
          }
        });

        if (modifyData.length !== 1) {
          setDataPoint([
            ...dataPoint,
            {
              name: field,
              subjects: modifyData,
            },
          ]);
          setNoDataFound(false);
          setOnlyLastElementExpand(false);
        } else {
          setNoDataFound(true);
          setOnlyLastElementExpand(true);
        }
      } else if (collapisibleGraphData?.data?.length === 0) {
        setNoDataFound(true);
        setOnlyLastElementExpand(true);
      }
      setIsLoading(false);
    }
  }, [JSON.stringify(collapisibleGraphData)]);

  const handleCordernatesUpdate = (point, deviationInX, deviationInY) => [
    point[0] + deviationInX,
    deviationInY > 0
      ? point[1] + deviationInY
      : point[1] - Math.abs(deviationInY),
  ];

  const drawCurve = (holder, endpoints, color = 'black') => {
    const lineGenerator = d3.line().curve(d3.curveBasis);
    const pointA = { x: endpoints[0][0], y: endpoints[0][1] };
    const pointB = { x: endpoints[1][0], y: endpoints[1][1] };

    let points = [
      [pointA.x, pointA.y],
      [(pointA.x + pointB.x) / 2, (pointA.y + pointB.y) / 2],
      [pointB.x, pointB.y],
    ];

    if (pointB.y !== pointA.y) {
      points = [
        [pointA.x, pointA.y],
        [pointB.x - 30, pointB.y + 3],
        [pointB.x, pointB.y],
      ];
    }

    const pathData = lineGenerator(points);

    holder
      .append('path')
      .attr('d', pathData)
      .attr('fill', 'none')
      .attr('stroke', color);
  };

  const setDefaultState = () => {
    setChosenNodes([]);
    setChosenType([]);
    setCurrentHoveredNodeIndex(-1);
    setDataPoint([]);
    setCallApi(true);
    setNoDataFound(false);
    setLastNodeName('');
    setShowTextNameOnHover(false);
    setIsLoading(false);
    dispatch(getCollapisibleGraphRefresh());
  };

  useEffect(() => {
    setDefaultState();
  }, [isExpanded]);

  const updateNodes = (nodes) => {
    const temp = [];
    const lengthOfNodes = nodes.length;

    let secondLastNodeCoordinates = [0, 0];

    if (lengthOfNodes >= 2) {
      secondLastNodeCoordinates = nodes[lengthOfNodes - 2].coordinates;
    }

    nodes.forEach((subject, i) => {
      if (lengthOfNodes >= 2 && i === lengthOfNodes - 2) {
        const tempNode = subject;
        tempNode.coordinates = handleCordernatesUpdate(
          subject.coordinates,
          0,
          120 - subject.coordinates[1],
        );
        temp.push(tempNode);
      } else if (lengthOfNodes >= 2 && i === lengthOfNodes - 1) {
        const tempNode = subject;
        tempNode.coordinates = handleCordernatesUpdate(
          subject.coordinates,
          0,
          120 - secondLastNodeCoordinates[1],
        );
        temp.push(tempNode);
      } else {
        temp.push(subject);
      }
    });

    const countLetters = (str) => {
      const letters = str.replace(/\./g, '');
      return letters.length;
    };

    const textLength = 20;

    if (lengthOfNodes >= 2) {
      temp.forEach((subject, i) => {
        const tempNode = subject;
        tempNode.coordinates = [340 + 160 * (i + 1), tempNode.coordinates[1]];
        if (i === lengthOfNodes - 1) {
          const lenghtOfLabel = countLetters(
            wordLimit(subject.label.toLowerCase(), textLength, true),
          );
          const errorCorrectionX2 = lenghtOfLabel <= 10 ? lenghtOfLabel * 12 : lenghtOfLabel * 9;
          tempNode.coordinates = [
            340 + 160 * (i + 1) + errorCorrectionX2,
            tempNode.coordinates[1],
          ];
        }
        temp[i] = tempNode;
      });
    }
    setNoDataFoundPosition([
      temp[lengthOfNodes - 1].coordinates[0],
      temp[lengthOfNodes - 1].coordinates[1],
    ]);
    setChosenNodes([...temp]);
  };

  const curve = d3.line().curve(d3.curveNatural);

  const adjustPath = (holder, points, color = 'black') => {
    holder
      .select('path')
      .transition()
      .attr('d', curve(points))
      .attr('stroke', color)
      .attr('stroke-width', 1)
      .attr('fill', 'none');
  };

  const drawCurveLine = (holder, points, color = 'black') => {
    holder
      .append('path')
      .attr('d', curve(points))
      .attr('stroke', color)
      .attr('stroke-width', 1)
      .attr('fill', 'none');
  };

  const drawSubjects = (
    holder,
    startingPoint,
    childrens,
    level,
    hasSubjectsForNextlevel = false,
    heighLightedlabel = '',
  ) => {
    const lineLength = 100;
    const verticalLength = startingPoint[1];
    const textLength = 20;

    const handleColor = (item) => {
      let color = 'black';
      if (hasSubjectsForNextlevel) {
        color = '#1f1f1f6a';
        if (heighLightedlabel === item.name) {
          color = '#5252FA';
        }
      }
      return color;
    };

    const secondCondition = () => {
      if (chosenType.includes('Marketed') && marketedLadder[level + 1]) {
        return true;
      }
      if (chosenType.includes('Clinical') && clinicalLadder[level + 1]) {
        return true;
      }
      return false;
    };

    const handleClassName = (item) => {
      let className = secondCondition()
        ? 'normal-black pointer'
        : 'normal-black dissabled';
      if (hasSubjectsForNextlevel) {
        className = secondCondition()
          ? 'normal-grey pointer'
          : 'normal-grey dissabled';
        if (heighLightedlabel === item.name) {
          className = secondCondition()
            ? 'normal-blue pointer'
            : 'normal-blue dissabled';
        }
      }
      return className;
    };

    const midChild = childrens.subjects.length / 2;

    drawCurve(
      holder,
      [startingPoint, [startingPoint[0] + lineLength, verticalLength]],
      hasSubjectsForNextlevel ? '#5252FA' : 'black',
    );

    childrens.subjects.forEach((item, index) => {
      const drawLabels = holder.append('g');

      const variationY = (y) => {
        if (midChild === index) {
          return y + 4;
        }

        if (midChild > index) {
          const offset = midChild - index;
          const adjustment = offset * spreadOfText;
          return y - adjustment + 4;
        }
        const offset = index - midChild;
        const adjustment = offset * spreadOfText;
        return y + adjustment + 4;
      };

      const coordinates = {
        x1: startingPoint[0] + lineLength,
        y1: verticalLength,
        x2: startingPoint[0] + lineLength + 70 - 8,
        y2: variationY(verticalLength) - 3,
      };

      const replaceSpacesWithDashes = (str) => str.replace(/ /g, '-');

      drawLabels
        .append('text')
        .attr('x', coordinates.x2 + 8)
        .attr('y', variationY(verticalLength))
        .attr(
          'id',
          replaceSpacesWithDashes(`${item.name}-text-${childrens.name}`),
        )
        .attr(
          'class',
          item.type === 'Head' ? 'header-text-col' : handleClassName(item),
        )
        .text(wordLimit(item.name, textLength, true))
        .on('click', () => {
          if (item.type === 'subject' && secondCondition()) {
            setLastNodeName(item.name);
            setCurrentIndexNode(level + 1);

            setCallApi(true);
            setIsLoading(true);

            if (level === currentIndexNode - 1) {
              setOnlyLastElementExpand(true);
            }

            const countLetters = (str) => {
              const letters = str.replace(/\./g, '');
              return letters.length;
            };

            const temp = chosenNodes.filter((subject) => subject.level < level);

            const lenghtOfLabel = countLetters(
              wordLimit(item.name.toLowerCase(), textLength, true),
            );
            const errorCorrectionX2 = lenghtOfLabel <= 10 ? lenghtOfLabel * 12 : lenghtOfLabel * 9;

            if (!isExpanded) {
              updateNodes([
                ...temp,
                {
                  name: childrens.name,
                  label: item.name,
                  key: item.key,
                  level,
                  coordinates: [
                    coordinates.x2 + errorCorrectionX2,
                    coordinates.y2,
                  ],
                },
              ]);
            } else {
              setChosenNodes([
                ...temp,
                {
                  name: childrens.name,
                  label: item.name,
                  key: item.key,
                  level,
                  coordinates: [
                    coordinates.x2 + errorCorrectionX2,
                    coordinates.y2,
                  ],
                },
              ]);
              setNoDataFoundPosition([
                coordinates.x2 + errorCorrectionX2,
                coordinates.y2,
              ]);
            }
            setLoaderPosition([
              coordinates.x2 + errorCorrectionX2,
              coordinates.y2,
            ]);

            const indexOfChildrenName = dataPoint.findIndex(
              (data) => data.name === childrens.name,
            );
            const tempData = dataPoint.slice(0, indexOfChildrenName + 1);
            setDataPoint([...tempData]);
          }
        })
        .on('mouseenter', () => {
          if (item.type === 'subject') {
            setTextNameHovered(item.name);
            setTextHoveredId(
              replaceSpacesWithDashes(`${item.name}-text-${childrens.name}`),
            );
            setShowTextNameOnHover(true);
          }
        })
        .on('mouseleave', () => {
          setShowTextNameOnHover(false);
          setTextHoveredId('');
          setTextNameHovered('');
        });

      if (item.type === 'subject') {
        holder
          .append('circle')
          .attr('cx', coordinates.x2)
          .attr('cy', coordinates.y2)
          .attr('r', 3)
          .attr('class', handleClassName(item));

        drawCurve(
          holder,
          [
            [coordinates.x1, coordinates.y1],
            [coordinates.x2, coordinates.y2],
          ],
          handleColor(item),
        );
      }
    });
  };

  const updateNodesOnClosedNodeClick = (nodes) => {
    const temp = [];

    nodes.forEach((subject, i) => {
      const tempNode = subject;
      tempNode.coordinates = [340 + 160 * (i + 1), tempNode.coordinates[1]];
      temp[i] = tempNode;
    });
    setChosenNodes([...temp]);
  };

  const drawCloseNodes = (holder, startingPoint, childrens, level) => {
    const lineLength = 150;
    const subHolder = holder.append('g');

    drawCurveLine(
      subHolder,
      [startingPoint, [startingPoint[0] + lineLength, startingPoint[1]]],
      '#5252FA',
    );

    const currentNode = chosenNodes.filter(
      (subject) => subject.level === level,
    );

    subHolder
      .append('circle')
      .attr('cx', startingPoint[0] + lineLength)
      .attr('cy', startingPoint[1])
      .attr('r', 10)
      .attr('class', 'pointer')
      .attr('fill', '#5252FA')
      .attr('id', childrens.name)
      .on('mouseenter', () => {
        setIsToolTipVisible(true);
        setToolTipData({ ...childrens });
        setTooltipId(childrens.name);
        setCurrentHoveredNodeIndex(level);
      })
      .on('mouseleave', () => {
        setIsToolTipVisible(false);
        setToolTipData({ ...{} });
        setTooltipId('');
        setCurrentHoveredNodeIndex(-1);
      })
      .on('click', () => {
        setCurrentIndexNode(level + 1);

        const temp = chosenNodes.filter((subject) => subject.level < level);
        setCallApi(false);
        setOnlyLastElementExpand(true);
        updateNodesOnClosedNodeClick([...temp]);

        const indexOfChildrenName = dataPoint.findIndex(
          (data) => data.name === childrens.name,
        );
        const tempData = dataPoint.slice(0, indexOfChildrenName + 1);
        setDataPoint([...tempData]);
        setNoDataFound(false);
        setIsLoading(false);
        setLastNodeName('');
      });

    subHolder
      .append('text')
      .attr(
        'x',
        startingPoint[0]
          - wordLimit(currentNode[0].label, 20, true).length * 0.5
          + lineLength
          - childrens.name.length * 3,
      )
      .attr('y', startingPoint[1] + 25)
      .attr('font-size', '12px')
      .attr('fill', 'black')
      .text(wordLimit(currentNode[0].label, 20, true));

    subHolder
      .append('text')
      .attr(
        'x',
        startingPoint[0]
          - childrens.subjects[0].name.length * 0.5
          + lineLength
          - childrens.name.length * 3,
      )
      .attr('y', startingPoint[1] + 40)
      .attr('font-size', '10px')
      .attr('fill', 'black')
      .text(`(${childrens.subjects[0].name})`);
  };

  const handleNodes = (holder, startingPoint) => {
    const lengthOfDataPoints = dataPoint.length;

    for (let i = 0; i < dataPoint.length; i += 1) {
      if (lengthOfDataPoints === 1) {
        drawSubjects(holder, startingPoint, dataPoint[i], i, false);
      } else if (i === 0 && lengthOfDataPoints <= 2 && !onlyLastElementExpand) {
        drawSubjects(
          holder,
          startingPoint,
          dataPoint[i],
          i,
          true,
          chosenNodes[0].label,
        );
      } else if (i === lengthOfDataPoints - 1) {
        if (noDataFound) {
          drawSubjects(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
            true,
            lastNodeName,
          );
        } else {
          drawSubjects(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
            false,
          );
        }
      } else if (i === 0) {
        drawCloseNodes(holder, startingPoint, dataPoint[i], i);
      } else if (lengthOfDataPoints > 2 && i === lengthOfDataPoints - 2) {
        if (!onlyLastElementExpand) {
          drawSubjects(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
            true,
            chosenNodes[i].label,
          );
        } else {
          drawCloseNodes(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
          );
        }
      } else {
        drawCloseNodes(holder, chosenNodes[i - 1].coordinates, dataPoint[i], i);
      }
    }
  };

  const handleExpandedNodes = (holder, startingPoint) => {
    const lengthOfDataPoints = dataPoint.length;

    for (let i = 0; i < dataPoint.length; i += 1) {
      if (lengthOfDataPoints === 1) {
        drawSubjects(holder, startingPoint, dataPoint[i], i, false);
      } else if (i === 0) {
        drawSubjects(
          holder,
          startingPoint,
          dataPoint[i],
          i,
          true,
          chosenNodes[0].label,
        );
      } else if (i === lengthOfDataPoints - 1) {
        if (noDataFound) {
          drawSubjects(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
            true,
            lastNodeName,
          );
        } else {
          drawSubjects(
            holder,
            chosenNodes[i - 1].coordinates,
            dataPoint[i],
            i,
            false,
          );
        }
      } else {
        drawSubjects(
          holder,
          chosenNodes[i - 1].coordinates,
          dataPoint[i],
          i,
          true,
          chosenNodes[i].label,
        );
      }
    }
  };

  const draw = (svg) => {
    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const marketedColor = chosenType.includes('Marketed')
      ? '#5252FA'
      : chosenType.includes('Clinical')
        ? '#CCCCCC'
        : 'black';

    const clinicalColor = chosenType.includes('Clinical')
      ? '#5252FA'
      : chosenType.includes('Marketed')
        ? '#CCCCCC'
        : 'black';

    const mainCtr = svg
      .append('g')
      .attr(
        'transform',
        `translate(${dimensions.margin.left}, ${dimensions.margin.top})`,
      );

    if (d3.select('.marketed-ctr').empty()) {
      const ctr = mainCtr.append('g').attr('class', 'marketed-ctr');
      drawCurveLine(
        ctr,
        [startingCirlceCenter, marketCircleCenter],
        marketedColor,
      );

      ctr
        .append('circle')
        .attr('cx', marketCircleCenter[0])
        .attr('cy', marketCircleCenter[1])
        .attr('r', 40)
        .attr('class', 'pointer')
        .attr('fill', marketedColor)
        .on('click', () => {
          setChosenType([...[], 'Marketed']);
          Mixpanel.track('marketedClickEvent', {
            action: 'Collapsible Graph Marketed node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        });

      ctr
        .append('text')
        .attr('x', marketCircleCenter[0] - 35)
        .attr('y', marketCircleCenter[1] + 5)
        .text('Marketed')
        .attr('fill', 'white')
        .attr('class', 'drug-name-coll pointer')
        .on('click', () => {
          if (chosenType.includes('Marketed')) {
            setChosenType([...[]]);
          } else {
            setChosenType([...[], 'Marketed']);
            Mixpanel.track('marketedClickEvent', {
              action: 'Collapsible Graph Marketed node click',
              widget_name: 'Collapsible Graph',
              terminal_name: 'Intervention',
              page: 'Analytics Page',
              query: JSON.parse(q),
              intervention_name: currentDrugName,
            });
            setIsLoading(true);
            setLoaderPosition([
              ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
            ]);
            setNoDataFoundPosition([
              ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
            ]);
          }
        });

      const clinicalCtr = mainCtr.append('g').attr('class', 'clinical-ctr');

      drawCurveLine(
        clinicalCtr,
        [startingCirlceCenter, clinicalCircleCenter],
        clinicalColor,
      );

      clinicalCtr
        .append('circle')
        .attr('cx', clinicalCircleCenter[0])
        .attr('cy', clinicalCircleCenter[1])
        .attr('r', 40)
        .attr('class', 'pointer')
        .attr('fill', clinicalColor)
        .on('click', () => {
          setChosenType([...[], 'Clinical']);
          Mixpanel.track('clinicalClickEvent', {
            action: 'Collapsible Graph Clinical node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        });

      clinicalCtr
        .append('text')
        .attr('x', clinicalCircleCenter[0] - 25)
        .attr('y', clinicalCircleCenter[1] + 5)
        .text('Clinical')
        .attr('fill', 'white')
        .attr('class', 'drug-name-coll pointer')
        .on('click', () => {
          if (chosenType.includes('Clinical')) {
            setChosenType([...[]]);
          } else {
            setChosenType([...[], 'Clinical']);
            Mixpanel.track('clinicalClickEvent', {
              action: 'Collapsible Graph Clinical node click',
              widget_name: 'Collapsible Graph',
              terminal_name: 'Intervention',
              page: 'Analytics Page',
              query: JSON.parse(q),
              intervention_name: currentDrugName,
            });
            setIsLoading(true);
            setLoaderPosition([
              ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
            ]);
            setNoDataFoundPosition([
              ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
            ]);
          }
        });
    }

    if (chosenType.includes('Marketed')) {
      const marketedCtr = d3.select('.marketed-ctr');
      adjustPath(
        marketedCtr,
        [
          startingCirlceCenter,
          [marketCircleCenter[0], marketCircleCenter[1] + 60],
        ],
        marketedColor,
      );

      marketedCtr
        .select('circle')
        .on('click', () => {
          setChosenType([...[]]);
        })
        .transition()
        .attr('r', 40)
        .attr('cy', marketCircleCenter[1] + 60)
        .attr('fill', marketedColor);

      marketedCtr
        .select('text')
        .on('click', () => {
          setChosenType([...[]]);
        })
        .transition()
        .attr('font-size', '14px')
        .attr('x', marketCircleCenter[0] - 35)
        .attr('y', marketCircleCenter[1] + 65)
        .attr('fill', 'white');

      const clinicalCtr = d3.select('.clinical-ctr');
      adjustPath(
        clinicalCtr,
        [
          startingCirlceCenter,
          [clinicalCircleCenter[0] - 50, clinicalCircleCenter[1] + 20],
          [clinicalCircleCenter[0], clinicalCircleCenter[1] + 20],
        ],
        clinicalColor,
      );

      clinicalCtr
        .select('circle')
        .on('click', () => {
          setChosenType([...[], 'Clinical']);
          Mixpanel.track('clinicalClickEvent', {
            action: 'Collapsible Graph Clinical node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        })
        .transition()
        .attr('r', 25)
        .attr('cy', clinicalCircleCenter[1] + 20)
        .attr('fill', clinicalColor);

      clinicalCtr
        .select('text')
        .on('click', () => {
          setChosenType([...[], 'Clinical']);
          Mixpanel.track('clinicalClickEvent', {
            action: 'Collapsible Graph Clinical node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        })
        .transition()
        .attr('x', clinicalCircleCenter[0] - 15)
        .attr('font-size', '8px')
        .attr('y', clinicalCircleCenter[1] + 25)
        .attr('fill', 'black');
    }

    if (chosenType.includes('Clinical')) {
      const clinicalCtr = d3.select('.clinical-ctr');
      adjustPath(
        clinicalCtr,
        [
          startingCirlceCenter,
          [clinicalCircleCenter[0], clinicalCircleCenter[1] - 60],
        ],
        clinicalColor,
      );

      clinicalCtr
        .select('circle')
        .on('click', () => {
          setChosenType([...[]]);
        })
        .transition()
        .attr('r', 40)
        .attr('cy', clinicalCircleCenter[1] - 60)
        .attr('fill', clinicalColor);

      clinicalCtr
        .select('text')
        .on('click', () => {
          setChosenType([...[]]);
        })
        .transition()
        .attr('font-size', '14px')
        .attr('x', clinicalCircleCenter[0] - 25)
        .attr('y', clinicalCircleCenter[1] - 55)
        .attr('fill', 'white');

      const marketedCtr = d3.select('.marketed-ctr');
      adjustPath(
        marketedCtr,
        [
          startingCirlceCenter,
          [marketCircleCenter[0] - 50, marketCircleCenter[1] - 20],
          [marketCircleCenter[0], marketCircleCenter[1] - 20],
        ],
        marketedColor,
      );

      marketedCtr
        .select('circle')
        .on('click', () => {
          setChosenType([...[], 'Marketed']);
          Mixpanel.track('marketedClickEvent', {
            action: 'Collapsible Graph Marketed node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        })
        .transition()
        .attr('r', 25)
        .attr('cy', marketCircleCenter[1] - 20)
        .attr('fill', marketedColor);

      marketedCtr
        .select('text')
        .on('click', () => {
          setChosenType([...[], 'Marketed']);
          Mixpanel.track('marketedClickEvent', {
            action: 'Collapsible Graph Marketed node click',
            widget_name: 'Collapsible Graph',
            terminal_name: 'Intervention',
            page: 'Analytics Page',
            query: JSON.parse(q),
            intervention_name: currentDrugName,
          });
          setIsLoading(true);
          setLoaderPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
          setNoDataFoundPosition([
            ...[marketCircleCenter[0] + 40, marketCircleCenter[1] + 60],
          ]);
        })
        .transition()
        .attr('x', marketCircleCenter[0] - 18)
        .attr('font-size', '8px')
        .attr('y', marketCircleCenter[1] - 15)
        .attr('fill', 'black');
    }

    mainCtr
      .append('circle')
      .attr('cx', startingCirlceCenter[0])
      .attr('cy', startingCirlceCenter[1])
      .attr('r', 90)
      .attr('fill', 'black');

    mainCtr
      .append('text')
      .attr('x', startingCirlceCenter[0] / 2)
      .attr('y', startingCirlceCenter[1] + 10)
      .text(currentDrugName)
      .attr('fill', 'white')
      .attr('class', 'drug-name-coll')
      .style('font-size', () => {
        const len = currentDrugName.substring(0, 90 / 3).length;
        let size = 90 / 3;
        size *= 10 / len;
        size -= 2;
        if (Math.round(size) > maxFontSize) {
          return `${maxFontSize}px`;
        }
        return `${Math.round(size)}px`;
      });

    if (dataPoint.length > 0 && chosenType.includes('Marketed')) {
      if (isExpanded) {
        d3.select('.clinical-trees').remove();
        d3.select('.marketed-trees').remove();
        const marketedTress = mainCtr
          .append('g')
          .attr('class', 'marketed-trees');
        handleExpandedNodes(marketedTress, [
          marketCircleCenter[0] + 40,
          marketCircleCenter[1] + 60,
        ]);
      } else if (!isLoading) {
        d3.select('.clinical-trees').remove();
        d3.select('.marketed-trees').remove();
        const marketedTress = mainCtr
          .append('g')
          .attr('class', 'marketed-trees');
        handleNodes(marketedTress, [
          marketCircleCenter[0] + 40,
          marketCircleCenter[1] + 60,
        ]);
      }
    }

    if (dataPoint.length > 0 && chosenType.includes('Clinical')) {
      if (isExpanded) {
        d3.select('.marketed-trees').remove();
        d3.select('.clinical-trees').remove();
        const clinicalTress = mainCtr
          .append('g')
          .attr('class', 'clinical-trees');
        handleExpandedNodes(clinicalTress, [
          clinicalCircleCenter[0] + 40,
          clinicalCircleCenter[1] - 60,
        ]);
      } else if (!isLoading) {
        d3.select('.marketed-trees').remove();
        d3.select('.clinical-trees').remove();
        const clinicalTress = mainCtr
          .append('g')
          .attr('class', 'clinical-trees');
        handleNodes(clinicalTress, [
          clinicalCircleCenter[0] + 40,
          clinicalCircleCenter[1] - 60,
        ]);
      }
    }

    if (dataPoint.length === 0) {
      d3.select('.clinical-trees').remove();
      d3.select('.marketed-trees').remove();
    }

    if (!isLoading && noDataFound) {
      d3.select('.no-data-found').remove();
      mainCtr.append('g').attr('class', 'no-data-found');
      const subCtr = d3.select('.no-data-found');
      drawCurve(
        subCtr,
        [
          noDataFoundPosition,
          [noDataFoundPosition[0] + 100, noDataFoundPosition[1]],
        ],
        'black',
      );

      const textNoData = () => {
        if (chosenType.includes('Marketed')) {
          return noDateFoundMessageLadder[currentIndexNode];
        }
        return noDateFoundMessageClinicalLadder[currentIndexNode];
      };

      subCtr
        .append('text')
        .attr('x', noDataFoundPosition[0] + 105)
        .attr('y', noDataFoundPosition[1] + 4)
        .attr('class', 'normal-black')
        .attr('fill', 'black')
        .text(textNoData);
    } else {
      d3.select('.no-data-found').remove();
    }
  };

  const SVGRef = useD3(
    (svg) => {
      draw(svg);
    },
    [
      JSON.stringify(chosenType),
      JSON.stringify(dataPoint),
      isLoading,
      isExpanded,
      noDataFound,
      loaderPosition,
    ],
  );

  const handleShrinkClick = () => {
    dispatch(getCollapsibleGraphStateAction(false));
    setIsExpanded(false);
    setExpanded('');
    setDefaultState();
    Mixpanel.track('collapseWidgetEvent', {
      action: 'collapse Collapsible Graph',
      widget_name: 'Collapsible Graph',
      terminal_name: 'Intervention',
      page: 'Analytics Page',
      query: JSON.parse(q),
    });
  };

  const handleExpandClick = () => {
    dispatch(getCollapsibleGraphStateAction(true));
    setIsExpanded(true);
    setExpanded(selected.label);
    setDefaultState();
    Mixpanel.track('expandWidgetEvent', {
      action: 'expand Collapsible Graph widget',
      widget_name: 'Collapsible Graph',
      terminal_name: 'Intervention',
      page: 'Analytics Page',
      query: JSON.parse(q),
      intervention_name: currentDrugName,
    });
  };

  const handleTextElement = (name) => {
    if (chosenNodes[currentHoveredNodeIndex]?.label === name) {
      return (
        <>
          <div className="dot-policy-blue" />
          <div className="list-adjust">
            <span className="text-blue">{name}</span>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="dot-policy" />
        <div className="list-adjust">
          <span>{name}</span>
        </div>
      </>
    );
  };

  const renderTooltipOnHoverText = () => {
    if (showTextNameOnHover) {
      return (
        <Tooltip
          anchorSelect={`#${textHoveredId}`}
          isOpen={showTextNameOnHover}
          ref={secondTooltipRef}
          noArrow
          place="top"
        >
          <div className="collapsible-tooltip-hover-text">{textNameHovered}</div>
        </Tooltip>
      );
    }
    return null;
  };

  const renderTooltip = () => {
    if (isToolTipVisible) {
      return (
        <Tooltip
          anchorSelect={`#${tooltipId}`}
          place="top"
          isOpen={isToolTipVisible}
          ref={tooltipRef}
          className="tooltip-colllapsible-graph"
        >
          <div className="tooltip-content">
            <div className="header-text-col">
              {toolTipData.subjects[0].name}
            </div>
            <div className="tooltip-body">
              {toolTipData.subjects.map((item, index) => (index !== 0 ? (
                <div className="list-items-policy" key={item}>
                  {handleTextElement(item.name)}
                </div>
              ) : null))}
            </div>
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const renderExpandedView = () => (
    <div>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">{selected.label}</div>
        </div>
        <div className="icons">
          <SvgLoader
            onClick={() => handleShrinkClick()}
            width={22}
            height={22}
            svgName="contract"
            hoverIconName="contract-hover"
          />
          {/* <SvgLoader width={24} height={24} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <div id="callapsible-graph-expanded">
        <svg ref={SVGRef} width={dimensions.width} height={dimensions.height}>
          {isLoading && (
            <foreignObject
              x={loaderPosition[0]}
              y={loaderPosition[1]}
              width="70"
              height="20"
            >
              <div className="col-3">
                <div className="snippet" data-title="dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing" />
                  </div>
                </div>
              </div>
            </foreignObject>
          )}
        </svg>
      </div>
      {renderTooltip()}
      {renderTooltipOnHoverText()}
    </div>
  );

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  if (isExpanded) {
    return (
      <div className="expanded-widget">
        <div className="Widget expanded-endpoints">{renderExpandedView()}</div>
      </div>
    );
  }

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">{selected.label}</div>
          {/* <SvgLoader className="patent-info-icon" width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
        </div>
        <div className="icons">
          <SvgLoader
            className="patent-expand-icon"
            onClick={() => handleExpandClick()}
            width={22}
            height={22}
            svgName="expand"
            hoverIconName="expand-hover"
          />
          {/* <SvgLoader width={23} height={23} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <div id="callapsible-graph">
        <svg ref={SVGRef} width={dimensions.width} height={dimensions.height}>
          {isLoading && (
            <foreignObject
              x={loaderPosition[0]}
              y={loaderPosition[1]}
              width="70"
              height="20"
            >
              <div className="col-3">
                <div className="snippet" data-title="dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing" />
                  </div>
                </div>
              </div>
            </foreignObject>
          )}
        </svg>
      </div>
      {renderTooltip()}
      {renderTooltipOnHoverText()}
    </div>
  );
}

CollapsibleGraph.propTypes = {
  currentExpanded: PropTypes.string.isRequired,
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default CollapsibleGraph;
