import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestOtpAction, requestOtpRefreshAction } from '../LoginModal/logics';
import { getCookie, setCookie } from '../../../utils';
import { sendNotification } from '../../../common/components/Notification/logic';
import { addLicenseAction, closeLoginModalAction, verifyOTPAction } from './logic';
import Loader from '../../../common/components/Loader';
import { removeSession } from '../../../common/container/Status/logic';
import SvgLoader from '../../../common/components/SvgLoader';
import Mixpanel from '../../../utils/mixpanel';

function LoginModalV2() {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const location = useLocation();
  const navigate = useNavigate();

  const inputEmail = getCookie('userEmail') || '';
  const [validOTP, setValidOTP] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);

  const open = useSelector((state) => state.isLoginModalOpen.open);
  const requestOtpStatus = useSelector((state) => state.requestOtpStatus);
  const LicenseStatus = useSelector((state) => state.LicenseStatus);
  const verifyOTPSTatus = useSelector((state) => state.verifyOTPSTatus);

  useEffect(() => {
    if (open && getCookie('userEmail') && !getCookie('accessToken')) {
      dispatch(requestOtpRefreshAction());
      dispatch(requestOtpAction({
        headers: queryString.stringify({
          email: inputEmail.toLowerCase(),
        }),
      }));
      setOTPSent(true);
    }
  }, [open]);

  useEffect(() => {
    if (otp.includes('')) {
      setValidOTP(false);
    } else {
      setValidOTP(true);
    }
  }, [otp]);

  const addHoursToTimestamp = (timestamp, hours) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + hours);
    const newTimestamp = date.getTime();
    return newTimestamp;
  };

  useEffect(() => {
    if (LicenseStatus.flag && getCookie('freshUser')) {
      navigate(`/callback?access_token=${verifyOTPSTatus.data.response.access_token}`);
      Mixpanel.track('user_logged_in_event', {
        action: 'user logged in otp verified successfully',
      });
    }
  }, [JSON.stringify(LicenseStatus)]);

  useEffect(() => {
    if (verifyOTPSTatus.flag) {
      if (getCookie('freshUser')) {
        if (getCookie('freshUser') === 'individual') {
          setCookie(true, 'showTrialStatusModal');
        } else {
          setCookie(true, 'showTwoWeekStatusModal');
        }
        dispatch(addLicenseAction({
          body: {
            email: inputEmail,
            license_type: getCookie('freshUser') === 'org' ? 'licensed' : 'trial',
            trial_start_date: getCookie('freshUser') === 'individual' ? Date.now() : 0,
            trial_end_date: getCookie('freshUser') === 'individual' ? addHoursToTimestamp(Date.now(), 4) : 0,
            license_start_date: getCookie('freshUser') === 'org' ? Date.now() : 0,
            license_end_date: getCookie('freshUser') === 'org' ? addHoursToTimestamp(Date.now(), 360) : 0,
            is_active: true,
            details: 'trial or org',
          },
        }));
      } else {
        navigate(`/callback?access_token=${verifyOTPSTatus.data.response.access_token}`);
      }
    }

    if (verifyOTPSTatus.error) {
      if (verifyOTPSTatus.data.message === 'Error in OTP verification') {
        dispatch(sendNotification({
          type: 'OTP_VERIFICATION_FAILED_1',
          notificationType: 'error',
          message: verifyOTPSTatus.data.error.response.error_description || 'Oops something went wrong, please try again',
          showNotification: true,
        }));
      } else {
        dispatch(sendNotification({
          type: 'OTP_VERIFICATION_FAILED_2',
          notificationType: 'error',
          message: verifyOTPSTatus.data.error.response.error_description || 'Oops something went wrong, please try again',
          showNotification: true,
        }));
        setTimeout(() => {
          dispatch(closeLoginModalAction());
          dispatch(removeSession());
        }, 500);
      }
    }
  }, [JSON.stringify(verifyOTPSTatus)]);

  useEffect(() => {
    if (requestOtpStatus.flag && requestOtpStatus.data.message === 'OTP sent successfully') {
      dispatch(sendNotification({
        type: 'OPT_SENT_SUCCESS',
        notificationType: 'success',
        message: 'OTP sent successfully',
        showNotification: true,
      }));
      Mixpanel.track('otp_sent_successfully_notification_event', {
        action: 'otp sent successfully notification',
      });
    }
  }, [JSON.stringify(requestOtpStatus)]);

  const handleLoginClick = () => {
    if (getCookie('freshUser')) {
      if (getCookie('freshUser') === 'individual') {
        setCookie(true, 'showTrialStatusModal');
      } else {
        setCookie(true, 'showTwoWeekStatusModal');
      }
      dispatch(addLicenseAction({
        body: {
          email: inputEmail,
          license_type: getCookie('freshUser') === 'org' ? 'licensed' : 'trial',
          trial_start_date: getCookie('freshUser') === 'individual' ? Date.now() : 0,
          trial_end_date: getCookie('freshUser') === 'individual' ? addHoursToTimestamp(Date.now(), 0.05) : 0,
          license_start_date: getCookie('freshUser') === 'org' ? Date.now() : 0,
          license_end_date: getCookie('freshUser') === 'org' ? addHoursToTimestamp(Date.now(), 360) : 0,
          is_active: true,
          details: 'trial or org',
        },
      }));
    }
    dispatch(requestOtpRefreshAction());
    dispatch(requestOtpAction({
      headers: queryString.stringify({
        email: inputEmail.toLowerCase(),
      }),
    }));
    setOTPSent(true);
  };

  const verifyOTP = () => {
    if (validOTP) {
      setCookie(inputEmail, 'userEmail');
      setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
      dispatch(verifyOTPAction({
        headers: queryString.stringify({
          email: inputEmail,
          otp: otp.join(''),
        }),
      }));
      Mixpanel.track('verify_otp_button_event', {
        action: 'verify otp button clicked',
      });
    }
  };

  const handleInputChange = (index, value) => {
    if (value.length === 6) {
      setOtp([...value.split('')]);
    } else {
      const newOTP = [...otp];
      newOTP[index] = value.slice(0, 1);
      setOtp(newOTP);
    }
    if (index < inputRefs.current.length - 1 && value !== '') {
      setTimeout(() => {
        inputRefs.current[index + 1].focus();
      }, 100);
    }

    if (index <= inputRefs.current.length - 1 && index > 0 && value === '') {
      setTimeout(() => {
        inputRefs.current[index - 1].focus();
      }, 100);
    }
  };

  const renderOTPinput = () => (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <input
          key={`${digit}-${Math.random()}`}
          type="text"
          value={digit}
          className="otp-number-text"
          onChange={(e) => handleInputChange(index, e.target.value)}
          ref={(ref) => {
            inputRefs.current[index] = ref;
          }}
        />
      ))}
    </div>
  );

  const renderOTP = () => (
    <div>
      { renderOTPinput() }
      <div className="otp-resend-text">
        Didn’t you receive the OTP?
        <span aria-hidden className="otp-resend-text-blue pointer" onClick={() => handleLoginClick()}>
          &nbsp;
          Resend OTP
        </span>
      </div>
    </div>
  );

  return (
    <Modal className="login-modal-v2-ctr" footer={false} onCancel={() => dispatch(removeSession())} open={open} closeIcon={false} width={400} height={380}>
      <div className="ctr-login-modal">
        <Loader loading={!otpSent && !getCookie('userEmail')} height={360}>
          <div className="login-header-text">Login to continue</div>
          <SvgLoader width={24} height={24} className="login-cross-icon" svgName="cross-login" onClick={() => dispatch(removeSession())} pointer />
          {
          otpSent
            ? (
              <div className="login-sub-header-text-green flex-col">
                <span>
                  {'We\'ve sent OTP to your email'}
                </span>
                <span>{getCookie('userEmail') || inputEmail}</span>
              </div>
            )
            : <div className="login-sub-header-text">Enter your registered email</div>
        }
          <div>
            {otpSent ? renderOTP() : null}
          </div>
          {
          otpSent ? <div aria-hidden onClick={verifyOTP} className={`${validOTP ? 'login-button pointer' : 'login-button-dissabled'}`}>Verify OTP</div>
            : <div aria-hidden onClick={() => handleLoginClick()} className={`${!validOTP ? 'login-button-dissabled' : 'login-button pointer'}`}>Get OTP</div>
        }

        </Loader>
      </div>
    </Modal>
  );
}

export default LoginModalV2;
