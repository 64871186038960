import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Input, Button,
} from 'antd';
import { useDispatch } from 'react-redux';
import optionsTags from './data';
import useAppSelector from '../../../utils/redux-hook';
import { sendNotification } from '../../../common/components/Notification/logic';
import { requestDemoAction } from './logic';
import Mixpanel from '../../../utils/mixpanel';

const { TextArea } = Input;
function ModalRequestDemo({
  setOpen, open,
}) {
  const dispatch = useDispatch();
  const [currentRequirement, setCurrentRequirement] = useState([]);
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validDesignation, setValidDesignation] = useState(true);
  const [validCompany, setValidCompany] = useState(true);
  const [validAdditionalInformation, setValidAdditionalInformation] = useState(true);
  const [requestDemoButtonDissabled, setRequestDemoButtonDissabled] = useState(true);

  const statusRequestDemo = useAppSelector((state) => state.statusRequestDemo);

  const [userInfo, setUserDetails] = useState({
    userName: '',
    companyName: '',
    emailId: '',
    designation: '',
    requirement: '',
    addiTionalInformation: '',
  });

  useEffect(() => {
    if (statusRequestDemo.flag && statusRequestDemo.data === 'Demo Requested Successfully') {
      setOpen(false);
      dispatch(sendNotification({
        type: 'REQUEST_SUCCESS',
        notificationType: 'success',
        message: statusRequestDemo.data,
        showNotification: true,
      }));
      Mixpanel.track('user_support_event', {
        action: 'Request demo form submission successful',
        page: 'Home Page',
        user_data: {
          user_name: userInfo.userName,
          email: userInfo.emailId,
          organization_name: userInfo.companyName,
          designation: userInfo.designation,
          requirements: userInfo.requirement,
          additional_information: userInfo.addiTionalInformation,
        },
      });
    }

    if (statusRequestDemo.error) {
      setOpen(false);
      Mixpanel.track('user_support_event', {
        action: 'Request demo form submission unsuccessful',
        page: 'Home Page',
        user_data: {
          user_name: userInfo.userName,
          email: userInfo.emailId,
          organization_name: userInfo.companyName,
          designation: userInfo.designation,
          requirements: userInfo.requirement,
          additional_information: userInfo.addiTionalInformation,
        },
      });
    }
  }, [statusRequestDemo]);

  const checkBtnDissabled = !validName || !validEmail || !validDesignation || currentRequirement.length === 0 || !validAdditionalInformation;

  useEffect(() => {
    const emptyCheck = userInfo.userName === '' || userInfo.companyName === '' || userInfo.designation === '' || userInfo.emailId === '' || (userInfo.addiTionalInformation).trim() === '';
    setRequestDemoButtonDissabled(checkBtnDissabled || emptyCheck);
  }, [validName, validEmail, validAdditionalInformation, validDesignation, JSON.stringify(currentRequirement), JSON.stringify(userInfo)]);

  const handleCancel = () => {
    const temp = {
      userName: '',
      companyName: '',
      emailId: '',
      designation: '',
      requirement: '',
    };
    setUserDetails({ ...temp });
    setCurrentRequirement([]);
    setValidEmail(true);
    setValidName(true);
    setValidDesignation(true);
    setOpen(false);
  };

  const handleChange = (e, type) => {
    const temp = userInfo;
    switch (type) {
      case 'name':
        temp.userName = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'company':
        temp.companyName = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'email':
        temp.emailId = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'designation':
        temp.designation = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'additional_info':
        temp.addiTionalInformation = e.target.value;
        setUserDetails({ ...temp });
        break;
      default:
        break;
    }
  };

  const callApi = () => {
    dispatch(requestDemoAction({
      params: {
        user_name: userInfo.userName,
        email: userInfo.emailId,
        organization_name: userInfo.companyName,
        designation: userInfo.designation,
        requirements: userInfo.requirement,
        additional_information: userInfo.addiTionalInformation,
      },
    }));
    Mixpanel.track('requestDemoClickEvent', {
      action: 'Request demo button click to submit form',
      page: 'Home Page',
      user_data: {
        user_name: userInfo.userName,
        email: userInfo.emailId,
        organization_name: userInfo.companyName,
        designation: userInfo.designation,
        requirements: userInfo.requirement,
        additional_information: userInfo.addiTionalInformation,
      },
    });
    handleCancel();
  };

  const updateAll = () => {
    const temp = userInfo;
    currentRequirement.forEach((requirement) => {
      temp.requirement += `${requirement},`;
    });
    setUserDetails({ ...temp });
    setTimeout(() => callApi(), 50);
  };

  const handleClick = (value) => {
    if (currentRequirement.indexOf(value) > -1) {
      const temp = currentRequirement.filter((item) => item !== value);
      setCurrentRequirement([...temp]);
    } else {
      setCurrentRequirement([...currentRequirement, value]);
    }
  };

  const renderRequirements = () => optionsTags.map((requirement) => {
    if (currentRequirement.indexOf(requirement.value) > -1) {
      return (
        <div key={requirement.label} aria-hidden onClick={() => handleClick(requirement.value)} className="tag active">{requirement.value}</div>
      );
    }
    return <div className="tag" key={requirement.label} aria-hidden onClick={() => handleClick(requirement.value)}>{requirement.value}</div>;
  });

  const handleCompanyNameValidation = () => {
    setValidCompany(userInfo.companyName.length > 0);
  };

  const handleAdditionalInformationValidation = () => {
    setValidAdditionalInformation(userInfo.addiTionalInformation.length > 0);
  };

  const handleNameValidation = () => {
    const check = /^[A-Za-z\s]+$/;
    setValidName(check.test(userInfo.userName) && userInfo.userName !== '');
  };

  const handleDesignationValidation = () => {
    const check = /^[A-Za-z\s]+$/;
    setValidDesignation(check.test(userInfo.designation) && userInfo.designation !== '');
  };

  const handleEmailIdValidation = () => {
    const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(check.test(userInfo.emailId));
  };

  return (
    <Modal
      open={open}
      footer={false}
      onCancel={() => handleCancel()}
      closable
      className="request-demo-modal"
    >
      <div className="request-demo-modal-title">Request Demo</div>
      <div className="request-demo-modal-input">
        <Input placeholder="Full name" onBlur={handleNameValidation} value={userInfo?.userName} onChange={(e) => handleChange(e, 'name')} label="Full name*" />
        <span className="not-valid">{`${validName ? '' : 'Please enter valid full name'}`}</span>
      </div>
      <div className="request-demo-modal-input">
        <Input placeholder="Enter your company name " value={userInfo?.companyName} onChange={(e) => handleChange(e, 'company')} onBlur={handleCompanyNameValidation} label="Enter your company name*" />
        <span className="not-valid">{`${validCompany ? '' : 'Please enter company name'}`}</span>
      </div>
      <div className="request-demo-modal-input">
        <Input placeholder="Enter your email id" onBlur={handleEmailIdValidation} value={userInfo?.emailId} onChange={(e) => handleChange(e, 'email')} label="Enter your email id*" />
        <span className="not-valid">{`${validEmail ? '' : 'Please enter valid email'}`}</span>
      </div>
      <div className="request-demo-modal-input">
        <Input placeholder="Enter your designation" onBlur={handleDesignationValidation} value={userInfo?.designation} onChange={(e) => handleChange(e, 'designation')} label="Enter your designation*" />
        <div className="not-valid">{`${validDesignation ? '' : 'Please enter designation'}`}</div>
      </div>
      <div className="request-demo-modal-req">
        <div className="request-demo-modal-req-title">About your requirement*</div>
        <div className="requirements-container">{renderRequirements()}</div>
      </div>
      <div className="request-demo-modal-input-textarea">
        <TextArea
          value={userInfo.addiTionalInformation}
          onChange={(e) => handleChange(e, 'additional_info')}
          placeholder="Additional Information"
          className="request-demo-textarea"
          rows={4}
          onBlur={handleAdditionalInformationValidation}
          maxLength="1024"
          style={{
            resize: 'none',
          }}
        />
        <div className="not-valid">{`${validAdditionalInformation ? '' : 'Please enter additional information'}`}</div>
      </div>
      <div className="request-demo-modal-req-btn">
        <Button disabled={requestDemoButtonDissabled} onClick={() => updateAll()}>
          Request a Demo
        </Button>
      </div>
    </Modal>
  );
}

ModalRequestDemo.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ModalRequestDemo;
