/* eslint-disable  */
import {
  React, useState, useEffect,
} from 'react';
import {
  Popover, Input, Button,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { getCookie } from '../../../utils';
import Loader from '../../components/Loader';
import SvgLoader from '../../components/SvgLoader';
import { getProjectsAction, createProjectAction } from './logic';
import ProjectDropdown from '../ProjectDropdown';
import BookmarkList from './BookmarkList';
import BookmarkFilter from '../BookmarkFilter';
import Notification from '../../components/Notification';
import { sendNotification } from '../../components/Notification/logic';

function WorkSpace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectsData = useSelector(
    (state) => state.projectsData,
  );
  const createProject = useSelector(
    (state) => state.createProject,
  );

  const [selectedTopic, setSelectedTopic] = useState('bookmark');
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [openExpandedProject, setOpenExpandedProject] = useState([3]);
  const [inputText, setInputText] = useState('');
  const [projectId, setProjectId] = useState('');
  const [sharedDocumentId, setSharedDocumentId] = useState('');
  const [inputError, setInputError] = useState('');
  const [updatedProjectDates, setUpdatedProjectDates] = useState({
    'This week': [],
    'Last few week': [],
    'Last few month': [],
    Default: [],
    'Shared Documents': [],
    Archived: [],
  });
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [bookmarkFilters, setBookmarkFilters] = useState([]);

  const filterData = [
    'Publications', 'Clinical Trials', 'Congresses', 'Theses', 'Patents', 'News and Press Releases', 'Guidelines', 'Grants', 'Regulatory',
  ];

  useEffect(() => {
    if (projectsData.flag) {
      const projectDates = {
        'This week': [],
        'Last few week': [],
        'Last few month': [],
        Default: [],

        Archived: [],
        'Shared Documents': [],
      };

      projectsData?.data?.map((data) => {
        if (projectDates[data.category]) {
          projectDates[data.category].push(data);
        }
        if (data.isArchive === true) {
          projectDates.Archived.push(data);
          projectDates[data.category] = projectDates[data.category].filter(
            // eslint-disable-next-line no-underscore-dangle
            (item) => item._id !== data._id,
          );
        }
      });
      setUpdatedProjectDates(() => projectDates);
      setProjectId(projectDates.Default[0]?.projectId);
      setSharedDocumentId(projectDates['Shared Documents'][0]?.projectId);
    }
  }, [projectsData]);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/');
      navigate(0);
    }
  }, []);

  const apiCall = () => {
    dispatch(getProjectsAction(
      {
        params: {
          doc_id: '',
        },
      },
    ));
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleChange = (value) => {
    setInputText(value);
    if (value.length > 3) {
      setInputError('');
    } else {
      setInputError('Please enter more than 3 characters');
    }
  };

  const handleClick = () => {
    dispatch(createProjectAction(
      {
        body: {
          project_name: inputText,
        },
      },
    ));
    setOpenCreateProject(!openCreateProject);
    setInputText('');
  };

  const handleCancel = () => {
    setOpenCreateProject(false);
    setInputText('');
  };

  useEffect(() => {
    if (createProject.flag) {
      apiCall();
      dispatch(sendNotification({
        type: 'PROJECT_CREATED_SUCCESS',
        notificationType: 'success',
        message: 'Project created successfully',
        showNotification: true,
      }));
    } else if (createProject.error) {
      if (createProject.data?.statusCode === 409) {
        dispatch(sendNotification({
          type: 'CREATE_PROJECT_FAILURE',
          notificationType: 'failure',
          message: createProject.data?.message,
          showNotification: true,
        }));
      }
    }
  }, [JSON.stringify(createProject)]);
  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New Project</div>
        <div className="close-icon" role="presentation" onClick={() => handleCancel()} />
      </div>
      <div className="project-content">
        <Input value={inputText} onChange={(e) => handleChange(e.target.value)} />
        {inputError && <div style={{ color: 'red' }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button type="primary" className="cancel-btn" onClick={() => handleCancel()}>Cancel</Button>
        <Button type="primary" role="presentation" onClick={handleClick} disabled={inputText.length <= 3}>Create</Button>

      </div>
    </div>
  );

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const renderProjects = (index) => {
    setOpenExpandedProject((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };

  const renderFilter = () => (
    <BookmarkFilter projectId={projectId} setOpenFilterPopover={setOpenFilterPopover} setBookmarkFilters={setBookmarkFilters} bookmarkFilters={bookmarkFilters} filterData={filterData} />
  );

  const renderList = (type) => {
    switch (type) {
      case 'bookmark':
        return (
          <div className="analytics-container-content-value">
            <div className="analytics-container-content-value-data search-and-explore-results-container-list">
              <BookmarkList projectId={projectId} apiCall={apiCall} bookmarkFilters={bookmarkFilters} filterData={filterData} />
            </div>
          </div>
        );

      default:
        return (<div className="analytics-container-content-value search-and-explore-results-container-list" />);
    }
  };

  return (
    <div className="workspace-main-page">
      <Header className="results-header-light" type="light" />
      <div className="ct-results-page">
        <div className="analytics-container">
          <div className="project-container">
            <div className="project-sections project-sections-head">
              <Popover overlayClassName="create-proj-popover" placement="bottomLeft" trigger="click" content={renderCreateProject()} open={openCreateProject} fresh>
                <div
                  className="create-project"
                  role="presentation"
                  onClick={() => {
                    setInputText('');
                    setOpenCreateProject(!openCreateProject);
                  }}
                >
                  <div className="add-project" />
                  <div className="project-name">Create New Project</div>
                </div>
              </Popover>
              <div className="workspace-tabs">
                <div className="subtopics">
                  <div className="project-sections-head-links-start">
                    {/* <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'mindmap' ? 'selected' : ''}
                        onClick={() => handleTopicClick('mindmap')}
                      >
                        <div className="mindmap-icon" />
                        <span>Mindmap</span>
                      </button>
                    </div> */}
                    <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'bookmark' ? 'selected' : ''}
                        onClick={() => handleTopicClick('bookmark')}
                      >
                        <div className="bookmark-icon" />
                        <span>Bookmarks</span>
                      </button>
                    </div>
                    {/* <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'saved_searches' ? 'selected' : ''}
                        onClick={() => handleTopicClick('saved_searches')}
                      >
                        <div className="search-icon" />
                        <span>Saved Searches</span>
                      </button>
                    </div> */}
                    {/* <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'saved_widgets' ? 'selected' : ''}
                        onClick={() => handleTopicClick('saved_widgets')}
                      >
                        <div className="saved-widgets-icon" />

                        <span>Saved Widgets</span>
                      </button>
                    </div> */}
                    {/* <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'chat_history' ? 'selected' : ''}
                        onClick={() => handleTopicClick('chat_history')}
                      >
                        <div className="search-history-icon" />

                        <span>Chat History</span>
                      </button>
                    </div> */}
                  </div>
                  {/* <div className="project-sections-head-links-end">
                    {selectedTopic === 'bookmark' ? (
                      <div className="filter-bar-container-workspace">
                        <div className="filter-center-container">
                          <div aria-hidden className="sort-by">
                            <div className="export-btn" />
                            <span className="filter-text-name">Export</span>
                          </div>
                        </div>

                        <div className="filter-center-container">
                          <div aria-hidden className="sort-by">

                            <SvgLoader className="option" width="20px" height="20px" svgName="sort-filter" />

                            <span className="filter-text-name">Sort</span>
                          </div>
                        </div>

                        <Popover
                          overlayClassName="create-proj-popover create-proj-popover-filter"
                          placement="bottom"
                          content={() => renderFilter()}
                          open={openFilterPopover}
                          onOpenChange={(open) => setOpenFilterPopover(open)}
                          trigger="click"

                        >
                          <div aria-hidden className="ct-filters">
                            <SvgLoader className="option" width={20} height={20} svgName="filter-icon" />

                            <span className="filter-text-name">Filter</span>

                          </div>
                        </Popover>

                      </div>
                    ) : (null)}
                    {selectedTopic === 'saved_searches' ? (
                      <div className="filter-bar-container-workspace">
                        <div className="filter-center-container">
                          <div aria-hidden className="sort-by">
                            <div className="export-btn" />
                            <span className="filter-text-name">Export</span>

                          </div>
                        </div>

                        <div className="filter-center-container">
                          <div aria-hidden className="sort-by">
                            <div className="calender-icon" />
                            <span className="filter-text-name">Date</span>
                            <div className="vector" />

                          </div>
                        </div>

                        <div aria-hidden className="ct-filters">
                          <SvgLoader className="option" width={20} height={20} svgName="filter-icon" />

                          <span className="filter-text-name">Filter</span>
                        </div>
                      </div>
                    ) : (null)}
                  </div> */}
                </div>
              </div>

            </div>
            <div className="analytics-container-content">
              <div className="analytics-container-content-sidebar">
                <ProjectDropdown updatedProjectDates={updatedProjectDates} sharedDocumentId={sharedDocumentId} projectsData={projectsData} openExpandedProject={openExpandedProject} renderProjects={renderProjects} setProjectId={setProjectId} projectId={projectId} bookmarkFilters={bookmarkFilters} filterData={filterData} />
              </div>
              <Loader loading={projectsData?.loading || !projectsData.flag}>
                {renderList(selectedTopic)}
              </Loader>

            </div>
            <Notification />
          </div>
        </div>
      </div>

    </div>
  );
}

export default WorkSpace;
