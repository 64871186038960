import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InterventionSearchBox from '../InterventionSearchBox';
import Mixpanel from '../../../utils/mixpanel';

function InterventionSearch({ terminalName }) {
  const [goClick, setGoClick] = useState(false);
  const [goBtnDissable, setGoBtnDissable] = useState(true);

  const handleClick = () => {
    if (goBtnDissable === false) {
      setGoClick(true);
    }
    Mixpanel.track('goButtonEnable', {
      action: 'Go button enable and navigate to intervention analytics page',
      view: 'Intervention page',
      goButtonStatus: !goBtnDissable,
    });
  };

  return (
    <div className="ct-home-page-main">
      <div className="ct-home-page">

        <div className="greetings">
          {`Hello, Welcome to ${terminalName} Terminal`}

        </div>
        <div className="search-main intervention-searchbox">
          <div className="header" />
          <InterventionSearchBox goBtnDissable={goBtnDissable} setGoBtnDissable={setGoBtnDissable} goClick={goClick} />
          <div
            role="presentation"
            className={`go-button ${goBtnDissable ? 'disabled' : ''}`}
            onClick={() => handleClick()}
          >
            Go
          </div>

        </div>
      </div>
    </div>
  );
}

InterventionSearch.propTypes = {
  terminalName: PropTypes.string.isRequired,
};

export default InterventionSearch;
