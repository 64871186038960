import { React, useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';
import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import { modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import { hashGeneratorAction } from '../../../common/container/SavedSearches/logic';
import InterventionAnalyticsContainer from '../../container/InterventionAnalyticsContainer';
import { getInterventionSearchResultsAction, getInterventionSearchResultsRefreshAction } from '../../container/InterventionList/logic';

function InterventionAnalyticsPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const getInterventionSearchResults = useSelector(
    (state) => state.getInterventionSearchResults,
  );
  const [totalCount, setTotalCount] = useState(0);
  const query = searchParams.get('query');
  const sort = searchParams.get('sort');
  const workspaceQuery = searchParams.get('workspaceQuery');
  const workspaceFilters = searchParams.get('workspaceFilters');
  const wq = JSON.parse(workspaceQuery);
  const wf = JSON.parse(workspaceFilters);

  const autoSuggestUrl = '/intervention/v0/search/getFieldValues';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);
  const qUrl = location.pathname + location.search;
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters, true));
  const out = merge(requestData, filtersData);
  const projId = getCookie('projectId');
  const terminalName = 'Intervention';
  let view = '';

  let workspaceQ = {};
  if (wq || wf) {
    workspaceQ = merge(wq || {}, wf || {}, filtersData || {});
  }
  if (!location.search.includes('semantic_search')) {
    view = 'Power Search';
  } else {
    view = 'Semantic Search';
  }

  const params = {
    headers: {
      from: 1,
      size: 10,
      project_id: projId,
    },
    body: { filters: out.body },
  };
  if (JSON.stringify(workspaceQ) !== '{}') {
    params.body.filters = { ...workspaceQ.body };
  }

  const hashApiCall = () => {
    dispatch(hashGeneratorAction(
      {
        body: {
          query: out.body,
          project_id: projId,
        },
      },
    ));
  };

  useEffect(() => {
    if (getCookie('projectId')) {
      hashApiCall();
    }
  }, [getCookie('projectId')]);

  useEffect(() => {
    if (getInterventionSearchResults?.flag) {
      setTotalCount(getInterventionSearchResults?.data?.total);
    }
  }, [JSON.stringify(getInterventionSearchResults)]);
  useEffect(() => {
    if (query?.length || workspaceQuery?.length) {
      const request = {
        apiUrl: '/intervention/v0/search/getSearchResults',
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          from: 1,
          size: 10,
          project_id: projId,
        }),
        body: params.body,
      };
      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(request));
    }

    return () => {
      dispatch(getInterventionSearchResultsRefreshAction({}));
    };
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" reqBody={requestData} filtersData={filtersData} terminalName={terminalName} view={view} totalCount={totalCount} url={qUrl} q={q} f={f} hashApiCall={hashApiCall} />
      <div className="ct-results-page intervention-result-page">
        <div className="card-view-nav">
          <div className="explore-results-text">
            {getInterventionSearchResults?.data?.total ? `${getInterventionSearchResults?.data?.total} Results found` : null}
          </div>
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="analytics-container intervention-analytics-container">
          <InterventionAnalyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default InterventionAnalyticsPage;
