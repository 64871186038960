const optionsTags = [
  {
    value: 'Preclinical Landscape',
    label: 'pl',
  },
  {
    value: 'Drug',
    label: 'drug',
  },
  {
    value: 'Disease',
    label: 'disease',
  },
  {
    value: 'Therapy Area',
    label: 'therapy',
  },
  {
    value: 'Protein',
    label: 'protein',
  },
  {
    value: 'Clinical Trials',
    label: 'ct',
  },
  {
    value: 'Competitive Landscape',
    label: 'cl',
  },
];

export default optionsTags;
