import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';

export const openLoginModalAction = createAction(OPEN_LOGIN_MODAL);
export const closeLoginModalAction = createAction(CLOSE_LOGIN_MODAL);

const onOpenLoginModal = (state) => ({
  ...state,
  open: true,
});

const onCloseLoginModal = (state) => ({
  ...state,
  open: false,
});

export const isLoginModalOpenReducer = createReducer(
  {
    open: false,
  },
  (builder) => {
    builder.addCase(openLoginModalAction, (state) => onOpenLoginModal(state))
      .addCase(closeLoginModalAction, (state) => onCloseLoginModal(state))
      .addDefaultCase((state) => state);
  },
);

const ADD_LICENCE = 'ADD_LICENCE';
const ADD_LICENCE_SUCCESS = 'ADD_LICENCE_SUCCESS';
const ADD_LICENCE_FAILURE = 'ADD_LICENCE_FAILURE';
const ADD_LICENCE_REFRESH = 'ADD_LICENCE_REFRESH';

export const addLicenseAction = createAction(ADD_LICENCE);

const addLicenseSuccess = createAction(ADD_LICENCE_SUCCESS);

const addLicenseFaliure = createAction(ADD_LICENCE_FAILURE);

export const addLicenseRefreshAction = createAction(ADD_LICENCE_REFRESH);

export const addLicenseEpic = (actions$) => actions$.pipe(
  ofType(ADD_LICENCE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/addLicense?`, 'POST', action.payload.body)).pipe(
    map((res) => addLicenseSuccess(res)),
    catchError((err) => of(addLicenseFaliure(err))),
  )),
);

const onaddLicenseAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onaddLicenseSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onaddLicenseFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onaddLicenseRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const addLicenseReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addLicenseAction, (state) => onaddLicenseAction(state))
      .addCase(addLicenseSuccess, (state, action) => onaddLicenseSuccess(state, action.payload))
      .addCase(addLicenseFaliure, (state) => onaddLicenseFailure(state))
      .addCase(addLicenseRefreshAction, () => onaddLicenseRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const VERIFY_OTP = 'VERIFY_OTP';
const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
const VERIFY_OTP_REFRESH = 'VERIFY_OTP_REFRESH';

export const verifyOTPAction = createAction(VERIFY_OTP);

const verifyOTPSuccess = createAction(VERIFY_OTP_SUCCESS);

const verifyOTPFaliure = createAction(VERIFY_OTP_FAILURE);

export const verifyOTPRefreshAction = createAction(VERIFY_OTP_REFRESH);

export const verifyOTPEpic = (actions$) => actions$.pipe(
  ofType(VERIFY_OTP),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/verifyOTP?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => verifyOTPSuccess(res)),
    catchError((err) => of(verifyOTPFaliure({ ...err, showNotification: false }))),
  )),
);

const onverifyOTPAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onverifyOTPSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onverifyOTPFailure = (state, payload) => ({
  ...state,
  data: { ...payload.response },
  flag: false,
  loading: false,
  showNotification: false,
  error: true,
});

const onverifyOTPRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const verifyOTPReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(verifyOTPAction, (state) => onverifyOTPAction(state))
      .addCase(verifyOTPSuccess, (state, action) => onverifyOTPSuccess(state, action.payload))
      .addCase(verifyOTPFaliure, (state, action) => onverifyOTPFailure(state, action.payload))
      .addCase(verifyOTPRefreshAction, () => onverifyOTPRefreshAction())
      .addDefaultCase((state) => state);
  },
);
