import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';

function Dropdown({
  options, value, onChange, setShow, className, defaultMessage,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const divEl = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }

      if (!divEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleClick = () => {
    if (options.length) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (option) => {
    setIsOpen(false);
    if (setShow) {
      setShow('power');
    }
    onChange(option);
  };

  const renderedOptions = options.map((option) => (
    <div
      className={`options ${className[2]}`}
      aria-hidden
      onClick={() => handleOptionClick(option)}
      key={option.value}
    >
      {option.label}
    </div>
  ));

  return (
    <div
      ref={divEl}
      className="dropdown"
    >
      <Panel
        onClick={handleClick}
        className={`panel-dropdown ${[className[0]]}`}
      >
        {value?.label || defaultMessage || 'Select...'}
        <SvgLoader width={24} height={24} svgName="down-arrow" />
      </Panel>
      {isOpen && <Panel className={`panel ${className[1]}`}>{renderedOptions}</Panel>}
    </div>
  );
}

Dropdown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  setShow: PropTypes.func,
  className: PropTypes.instanceOf(Array).isRequired,
  defaultMessage: PropTypes.string,
};

Dropdown.defaultProps = {
  setShow: () => null,
  defaultMessage: '',
};

export default Dropdown;
