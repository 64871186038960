import { createAction, createReducer } from '@reduxjs/toolkit';

const WIDGETS = 'WIDGETS';

const INITIAL_WIDGETS_DATA = {
  widgets: [
    {
      label: 'Status Snapshot',
      value: 'status',
      type: 'pie',
      size: 'small',
    },
    {
      label: 'Sponsor Insights',
      value: 'industry_sponser',
      type: 'meter',
      size: 'small',
    },
    {
      label: 'Enrollment Stats',
      value: 'enrolement_meter',
      type: 'enrolement',
      size: 'small',
    },
    {
      label: 'Intervention Insights',
      value: 'intervention',
      type: 'top3',
      size: 'small',
    },
    {
      label: 'Geographical View',
      value: 'geoGraph',
      type: 'geoGraph',
      size: 'medium',
    },
    {
      label: 'Trial Phase Breakdown',
      value: 'phase',
      type: 'phase',
      size: 'medium',
    },
    // {
    //   label: 'comming-soon',
    //   value: 'coming-sson',
    //   type: 'comming-soon',
    //   size: 'medium',
    // },
    {
      label: 'Endpoint Analytics',
      value: 'endpoints',
      type: 'endpoints',
      size: 'large',
    },
  ],
};

export const createWigetsAction = createAction(WIDGETS);

const onCreateWigetsAction = (state, payload) => {
  state.widgets = [...payload];
  return state;
};

export const widgetsReducer = createReducer(
  INITIAL_WIDGETS_DATA,
  (builder) => {
    builder.addCase(createWigetsAction, (state, action) => onCreateWigetsAction(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
