import React from 'react';
import PropTypes from 'prop-types';

function ComingSoon({ currentExpanded, selected }) {
  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className="Widget">
      <div className="coming-soon">
        <div>Coming Soon</div>
      </div>
    </div>
  );
}

ComingSoon.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default ComingSoon;
