import React, { useState } from 'react';
import classNames from 'classnames';

function SvgLoader({
  width, height, svgName, className = '', pointer = false, hoverIconName = '', ...rest
}) {
  const clasess = classNames(`${svgName}-icon`, className, `${pointer ? 'pointer' : ''}`);
  const [hover, setHover] = useState(false);

  return (
    <svg
      onBlur={() => setHover(false)}
      onFocus={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      width={width}
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      height={height}
      className={clasess}
    >
      <use xlinkHref={`/spritemap.svg#sprite-${hover && hoverIconName !== '' ? hoverIconName : svgName}`} />
    </svg>
  );
}

export default SvgLoader;
