import React from 'react';
import PropTypes from 'prop-types';

function StudyDetailsSection({ data, studyDocData }) {
  return (
    <div className="detail-container study-details">
      <div className="study-details-container">
        <div className="header">STUDY DETAILS</div>
        <div className="study-details-box">
          <div className="box-container">
            <div className="box-list">
              <div className="box-list-title">Type</div>
              <div className="box-list-value">{data?.study_type || '-'}</div>
            </div>
            {studyDocData.length ? (
              <div className="box-list">
                <div className="box-list-title">Study drug</div>
                <div className="box-list-value">
                  {data?.drug_roa?.length ? (
                    data.drug_roa.map((drug) => (
                      <div key={drug.id}>{drug.drug}</div>
                    ))
                  ) : (
                    'Data unavailable'
                  )}
                </div>
              </div>
            ) : (null)}
            {studyDocData.length ? (
              <div className="box-list">
                <div className="box-list-title">Is adaptive</div>
                <div className="box-list-value">{data?.is_adaptive || 'Data unavailable'}</div>
              </div>
            ) : (null)}

            {studyDocData.length ? (
              <div className="box-list">
                <div className="box-list-title">Number of sites</div>
                <div className="box-list-value">
                  {data?.total_sites ? (data?.total_sites) : 'Data unavailable'}
                </div>
              </div>
            ) : (null)}

          </div>

          <div className="box-container">

            <div className="box-list">
              <div className="box-list-title">Design</div>
              <div className="box-list-value">
                {data?.study_design && JSON.stringify(data?.study_design) !== '{}'
                  ? Object.keys(data?.study_design).map((itm) => (
                    <div className="design-itm" key={itm}>{`${itm}: ${data?.study_design[itm]}`}</div>
                  ))
                  : 'Data unavailable'}
              </div>
            </div>
            {studyDocData?.length ? (
              <div className="box-list">
                <div className="box-list-title">Comparator</div>
                <div className="box-list-value">
                  {data?.comparator?.length ? (
                    data.comparator.map((drug) => (
                      <div key={drug.id}>{drug}</div>
                    ))
                  ) : (
                    'Data unavailable'
                  )}
                </div>
              </div>
            ) : (null)}
            {studyDocData?.length ? (
              <div className="box-list">
                <div className="box-list-title">Type of control</div>
                <div className="box-list-value">
                  {data?.type_of_control?.length ? (
                    data.type_of_control.map((drug) => (
                      <div key={drug.id}>{drug}</div>
                    ))
                  ) : (
                    'Data unavailable'
                  )}
                </div>
                {' '}

              </div>
            ) : (null)}

            {studyDocData?.length ? (
              <div className="box-list">
                <div className="box-list-title">Line of therapy</div>
                <div className="box-list-value">{data?.line_of_therapy || 'Data unavailable'}</div>
              </div>
            ) : (null)}

          </div>

        </div>
      </div>

      {data?.study_detail_table?.length
        ? (
          <div className="study-details-container">
            <div className="header">ARMS AND ACTIVE INTERVENTION</div>
            <div className="study-details-table">
              <div className="table-header">
                <div className="th name">Arm</div>
                <div className="th name">Intervention/treatment</div>
              </div>
              {data?.study_detail_table?.map((itm) => (
                <div className="table-body">
                  <div className="td data">
                    <div className="subdata val">
                      {`${itm?.arm?.type ? `${itm?.arm?.type} :` : ''} ${itm?.arm?.label}`}
                    </div>
                    <div className="subdata data">
                      {itm?.arm?.description}
                    </div>
                  </div>
                  <div className="td data">
                    {itm['intervention/treatment'].map((td) => (
                      <>
                        <div className="subdata val">
                          {`${td?.type ? `${td?.type} :` : ''} ${td?.name}`}
                        </div>
                        <div className="subdata data">
                          {td?.detail}
                        </div>
                        <div className="subdata data">
                          {`${td?.other_name?.length ? `Other Name: ${td?.other_name?.map((name, i) => `${name}, ${i < (td.other_name.length - 1) ? ',' : ''}`)}` : ''}`}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
        : null}
      {data?.primary_outcome_measures?.length
        ? (
          <div className="study-details-container primary-outcome">
            <div className="header">PRIMARY OUTCOME MEASURE</div>
            <div className="study-details-table">
              <div className="table-header">
                <div className="th name">Time Frame</div>
                <div className="th name">Measure</div>
                <div className="th name">Description</div>
              </div>
              {data?.primary_outcome_measures?.map((primaryOutcome) => (
                <div className="table data">
                  <div className="td data">
                    {primaryOutcome?.duration}
                  </div>
                  <div className="td data">
                    {primaryOutcome?.metric}
                  </div>
                  <div className="td data">
                    {primaryOutcome?.detail}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
        : null}
      {data?.secondary_outcome_measures?.length
        ? (
          <div className="study-details-container secondary-outcome">
            <div className="header">SECONDARY OUTCOME MEASURE</div>
            <div className="study-details-table">
              <div className="table-header">
                <div className="th name">Time Frame</div>
                <div className="th name">Measure</div>
                <div className="th name">Description</div>
              </div>
              {data?.secondary_outcome_measures?.map((secondaryOutcome) => (
                <div className="table data">
                  <div className="td data">
                    {secondaryOutcome?.duration}
                  </div>
                  <div className="td data">
                    {secondaryOutcome?.metric}
                  </div>
                  <div className="td data">
                    {secondaryOutcome?.detail}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
        : null}
    </div>
  );
}

StudyDetailsSection.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default StudyDetailsSection;
