import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { getSponsorDeepDiveDataAction } from './logic';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';
import sponserToggleFields, { keyFieldMap } from './data';

function SponsorDeepDive({
  setShowDeepdive, params, sponsorKey, setIsGraphClicked,
}) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  const [activeSponsorType, setActiveSponsorType] = useState(sponsorKey);

  const getSponsorDeepDiveData = useSelector(
    (state) => state.getSponsorDeepDiveData,
  );

  let sponsorQuery = [];

  useEffect(() => {
    let request = {};
    if (location.pathname === '/clinicalTrials/analyticsResults') {
      request = {
        ...params,
        apiUrl: '/clinical-trials/v0/search/getSponsorList',
        headers: queryString.stringify({
          terminal_name: 'clinical_trials',
          sponsor_type: activeSponsorType,
        }),
      };
      dispatch(getSponsorDeepDiveDataAction(request));
      Mixpanel.track('sponserTypeChangeEvent', {
        action: 'sponser Type change in sponser insights deep dive view',
        search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
        query: JSON.parse(semanticQuery) || JSON.parse(query),
        widget_name: 'Sponsor Insights',
        page: 'Analytics Page',
        terminal_name: 'Clinical Trials',
        sponsorType: activeSponsorType,
      });
    }
  }, [activeSponsorType]);

  const handleRowClick = (rowId, sponsorType) => {
    sponsorQuery = [
      {
        name: 'institution_id',
        label: 'institution_id',
        field: 'institution_id',
        value: 'institution_id',
        type: 'institution_id',
        currentFilter: [rowId],
        currentKeys: [rowId],
      },
    ];

    Mixpanel.track('widget_deepdive_event', {
      action: 'Sponsor row click in sponser insights deep dive view',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery) || JSON.parse(query),
      widget_name: 'Sponsor Insights',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
      sponsorType,
      rowId,
    });

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    navigate(`/clinicalTrials/searchResults?query=${encodeURIComponent(query)}${`&sponsorQuery=${JSON.stringify(sponsorQuery)}`}`);
    navigate(0);
  };

  const renderType = (value) => {
    const tempVal = value.split(' ');
    let tempName = '';
    tempVal.forEach((val) => {
      tempName = `${tempName} ${val[0].toUpperCase() + val.slice(1).toLowerCase()}`;
    });
    return tempName;
  };

  const renderSponsorTable = () => getSponsorDeepDiveData.data[0]?.sponsers?.map((sponsor) => (
    <div
      key={sponsor.sponsor_name}
      className="sponsor-deep-dive-table-data-container"
      role="presentation"
      onClick={() => handleRowClick(sponsor.affiliation_id, getSponsorDeepDiveData.data[0].type)}
    >
      <div className="table-data td name">{sponsor.sponsor_name}</div>
      <div className="table-data td trials">
        {getSponsorDeepDiveData.data[0].type !== 'all' ? keyFieldMap[getSponsorDeepDiveData.data[0].type] : renderType(sponsor.type)}
      </div>
      <div className="table-data td trials">{sponsor.trial_count}</div>
    </div>
  ));

  const renderToggleFields = () => sponserToggleFields.map((field) => (
    <div
      role="presentation"
      key={field.key}
      onClick={() => setActiveSponsorType(field.key)}
      className={`tab-item ${activeSponsorType === field.key ? 'active' : ''}`}
    >
      {field.label}
    </div>
  ));

  return (

    <div className="sponsor-deep-dive-page">
      <div className="expanded-widget">
        <div
          className="back-btn-container"
          role="presentation"
          onClick={() => {
            setShowDeepdive(false);
            setIsGraphClicked(false);
          }}
        >
          <span className="back-arrow" />
          <span>Back</span>
        </div>
        <div className="sponsor-deep-dive-container sponsor-deep-dive-container-table">
          <div className="sponsor-deep-dive-header">
            <div className="title">Sponsor Type</div>
            <div className="tabs-container">
              {renderToggleFields()}
            </div>
          </div>
          <Loader
            error={getSponsorDeepDiveData.error}
            loading={getSponsorDeepDiveData.loading}
            noData={getSponsorDeepDiveData.data.length === 0}
            height="400px"
            className="white-loader"
            message="No records found"
          >
            <div className="sponsor-deep-dive-table-container">
              <div className="sponsor-deep-dive-table-header">
                <div className="table-header th name">Sponsor Name</div>
                <div className="table-header th type">Type</div>
                <div className="table-header th trials">#Trials</div>
              </div>
              {renderSponsorTable()}
            </div>
          </Loader>
        </div>
      </div>
    </div>
  );
}

SponsorDeepDive.propTypes = {
  sponsorKey: PropTypes.number.isRequired,
  setShowDeepdive: PropTypes.func.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
  setIsGraphClicked: PropTypes.func.isRequired,
};

export default SponsorDeepDive;
