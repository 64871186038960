import { React, useState, useEffect } from 'react';
import { Popover, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { getCookie } from '../../../utils';
import Loader from '../../components/Loader';
import SvgLoader from '../../components/SvgLoader';
import { getProjectsAction, createProjectAction } from './logic';
import ProjectDropdown from '../ProjectDropdown';
import BookmarkList from './BookmarkList';
import BookmarkFilter from '../BookmarkFilter';
import SavedSearchFilter from '../SavedSearchFilter';
import BookmarkSortOptions from '../BookmarkSortOptions';
import BookmarkExportOptions from '../BookmarkExportOptions';
import { sendNotification } from '../../components/Notification/logic';
import SavedSearchesList from './SavedSearchesList';

function WorkSpace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectsData = useSelector((state) => state.projectsData);
  const createProject = useSelector((state) => state.createProject);
  const bookmarkData = useSelector((state) => state.bookmarkData);
  const savedSearchesData = useSelector((state) => state.savedSearchesData);

  const [selectedTopic, setSelectedTopic] = useState('bookmark');
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [defaultProjectId, setDefaultProjectId] = useState();
  const [openExpandedProject, setOpenExpandedProject] = useState([defaultProjectId]);
  const [inputText, setInputText] = useState('');
  const [projectId, setProjectId] = useState('');
  const [sharedDocumentId, setSharedDocumentId] = useState('');
  const [inputError, setInputError] = useState('');
  const [updatedProjectDates, setUpdatedProjectDates] = useState({
    'This week': [],
    'Last few week': [],
    'Last few month': [],
    Archived: [],
  });
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [bookmarkFilters, setBookmarkFilters] = useState([]);
  const [bookmarkFilterUsers, setBookmarkFilterUsers] = useState([]);
  const [sortByOptions, setSortByOptions] = useState({});
  const [openSortPopover, setOpenSortPopover] = useState(false);
  const [openExportPopover, setOpenExportPopover] = useState(false);

  const filterData = [
    'Publications',
    'Clinical Trials',
    'Congresses',
    'Theses',
    'Patents',
    'News and Press Releases',
    'Guidelines',
    'Grants',
    'Regulatory',
  ];
  useEffect(() => {
    if (projectsData.flag) {
      const projectDates = {
        'This week': [],
        'Last few week': [],
        'Last few month': [],

        Archived: [],
      };

      // eslint-disable-next-line array-callback-return
      projectsData?.data?.map((data) => {
        if (projectDates[data.category]) {
          projectDates[data.category].push(data);
          if (data.projectName === getCookie('projectName')) {
            setProjectId(data.projectId);
          }
          if (data.projectName === 'Shared Documents') {
            setSharedDocumentId(data.projectId);
          }
        }
        if (data.isArchive === true) {
          projectDates.Archived.push(data);
          projectDates[data.category] = projectDates[data.category].filter(
            // eslint-disable-next-line no-underscore-dangle
            (item) => item._id !== data._id,
          );
        }
      });
      setUpdatedProjectDates(() => projectDates);
    }
  }, [projectsData]);

  useEffect(() => {
    if (projectsData.flag) {
      // eslint-disable-next-line array-callback-return
      projectsData?.data?.map((item) => {
        if (item.projectName === getCookie('projectName')) {
          if (item.category === 'This week') {
            setDefaultProjectId(0);
          } else if (item.category === 'Last few week') {
            setDefaultProjectId(1);
          } else if (item.category === 'Last few month') {
            setDefaultProjectId(2);
          } else {
            setDefaultProjectId(3);
          }
        }
      });
    }
  }, [JSON.stringify(projectsData)]);

  useEffect(() => {
    setOpenExpandedProject([defaultProjectId]);
  }, [defaultProjectId]);

  useEffect(() => {
    if (!getCookie('accessToken')) {
      navigate('/');
      navigate(0);
    }
  }, []);

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: '',
        },
      }),
    );
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleChange = (value) => {
    setInputText(value);
    if (value.length > 3) {
      setInputError('');
    } else {
      setInputError('Please enter more than 3 characters');
    }
  };

  const handleClick = () => {
    dispatch(
      createProjectAction({
        body: {
          project_name: inputText,
        },
      }),
    );
    setOpenCreateProject(!openCreateProject);
    setInputText('');
  };

  const handleCancel = () => {
    setOpenCreateProject(false);
    setInputText('');
  };

  useEffect(() => {
    if (createProject.flag) {
      dispatch(
        sendNotification({
          type: 'PROJECT_CREATED_SUCCESS',
          notificationType: 'success',
          message: 'Project created successfully',
          showNotification: true,
        }),
      );
      apiCall();
    } else if (createProject.error) {
      if (createProject.data?.statusCode === 409) {
        dispatch(
          sendNotification({
            type: 'CREATE_PROJECT_ERROR',
            notificationType: 'error',
            message: createProject.data?.message,
            showNotification: true,
          }),
        );
      }
    }
  }, [JSON.stringify(createProject)]);
  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New Project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => handleCancel()}
        />
      </div>
      <div className="project-content">
        <Input
          value={inputText}
          onChange={(e) => handleChange(e.target.value)}
        />
        {inputError && <div style={{ color: 'red' }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={handleClick}
          disabled={inputText.length <= 3}
        >
          Create
        </Button>
      </div>
    </div>
  );

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };

  const renderProjects = (index) => {
    setOpenExpandedProject((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };

  const renderFilter = () => (
    <BookmarkFilter
      sortByOptions={sortByOptions}
      projectId={projectId}
      setOpenFilterPopover={setOpenFilterPopover}
      setBookmarkFilters={setBookmarkFilters}
      setBookmarkFilterUsers={setBookmarkFilterUsers}
      bookmarkFilters={bookmarkFilters}
      bookmarkFilterUsers={bookmarkFilterUsers}
      filterData={filterData}
      projectsData={projectsData}
    />
  );

  const renderSavedSearchFilter = () => (
    <SavedSearchFilter
      projectId={projectId}
      setOpenFilterPopover={setOpenFilterPopover}
      setBookmarkFilterUsers={setBookmarkFilterUsers}
      bookmarkFilterUsers={bookmarkFilterUsers}
      projectsData={projectsData}
    />
  );
  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const renderSortOptions = () => (
    <BookmarkSortOptions
      filterData={filterData}
      bookmarkFilters={bookmarkFilters}
      bookmarkFilterUsers={bookmarkFilterUsers}
      projectId={projectId}
      setOpenSortPopover={setOpenSortPopover}
      setSortByOptions={setSortByOptions}
      sortByOptions={sortByOptions}
    />
  );

  const renderExportOptions = () => (
    <BookmarkExportOptions
      bookmarkFilters={bookmarkFilters}
      bookmarkFilterUsers={bookmarkFilterUsers}
      projectId={projectId}
      setOpenExportPopover={setOpenExportPopover}
      sortByOptions={sortByOptions}
    />
  );

  const renderList = (type) => {
    switch (type) {
      case 'bookmark':
        return (
          <div className="analytics-container-content-value">
            <div className="analytics-container-content-value-data search-and-explore-results-container-list">
              <BookmarkList
                projectId={projectId}
                setBookmarkFilters={setBookmarkFilters}
                setSortByOptions={setSortByOptions}
                setBookmarkFilterUsers={setBookmarkFilterUsers}
              />
            </div>
          </div>
        );

      default:
        return (
          <div className="analytics-container-content-value-saved-search">

            <div className="analytics-container-content-value-data search-and-explore-results-container-list">
              <SavedSearchesList
                projectId={projectId}
              // setBookmarkFilters={setBookmarkFilters}
              // setSortByOptions={setSortByOptions}
                setBookmarkFilterUsers={setBookmarkFilterUsers}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="workspace-main-page">
      <Header className="results-header-light" type="light" />
      <div className="ct-results-page">
        <div className="analytics-container">
          <div className="project-container">
            <div className="project-sections project-sections-head">
              <Popover
                overlayClassName="create-proj-popover"
                placement="bottomLeft"
                trigger="click"
                content={renderCreateProject()}
                open={openCreateProject}
                onOpenChange={(open) => setOpenCreateProject(open)}
              >
                <div
                  className="create-project"
                  role="presentation"
                  onClick={() => {
                    setInputText('');
                    setOpenCreateProject(!openCreateProject);
                  }}
                >
                  <div className="add-project" />
                  <div className="project-name">Create New Project</div>
                </div>
              </Popover>
              <div className="workspace-tabs">
                <div className="subtopics">
                  <div className="project-sections-head-links-start">
                    {/* <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={selectedTopic === 'mindmap' ? 'selected' : ''}
                        onClick={() => handleTopicClick('mindmap')}
                      >
                        <div className="mindmap-icon" />
                        <span>Mindmap</span>
                      </button>
                    </div> */}
                    <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={
                          selectedTopic === 'bookmark' ? 'selected' : ''
                        }
                        onClick={() => handleTopicClick('bookmark')}
                      >
                        <div className="bookmark-icon" />
                        <span>Bookmarks</span>
                      </button>
                    </div>
                    <div className="project-sections-head-links">
                      <button
                        type="button"
                        className={
                          selectedTopic === 'saved_searches' ? 'selected' : ''
                        }
                        onClick={() => handleTopicClick('saved_searches')}
                      >
                        <div className="search-icon" />
                        <span>Saved Searches</span>
                      </button>
                    </div>

                  </div>
                  <div className="project-sections-head-links-end">
                    {selectedTopic === 'bookmark' && bookmarkData?.data?.length !== 0 ? (
                      <div className="filter-bar-container-workspace">
                        <Popover
                          overlayClassName="create-proj-popover-export"
                          trigger="click"
                          placement="bottom"
                          open={openExportPopover}
                          onOpenChange={(open) => setOpenExportPopover(open)}
                          content={() => renderExportOptions()}
                        >
                          <div className="filter-center-container">
                            <div aria-hidden className="sort-by">
                              <div className="export-btn" />
                              <span className="filter-text-name">Export</span>
                            </div>
                          </div>
                        </Popover>

                        <Popover
                          overlayClassName="create-proj-popover create-proj-popover-sort"
                          trigger="click"
                          placement="bottom"
                          onOpenChange={(open) => setOpenSortPopover(open)}
                          open={openSortPopover}
                          content={() => renderSortOptions()}
                        >
                          <div className="filter-center-container">
                            <div aria-hidden className="sort-by">
                              {!isEmpty(sortByOptions) ? (
                                <SvgLoader
                                  className="option"
                                  width="20px"
                                  height="20px"
                                  svgName="sort-checked-icon"
                                />
                              ) : (
                                <SvgLoader
                                  className="option"
                                  width="20px"
                                  height="20px"
                                  svgName="sort-filter"
                                />
                              )}

                              <span className="filter-text-name">Sort</span>
                            </div>
                          </div>
                        </Popover>

                        <Popover
                          overlayClassName="create-proj-popover create-proj-popover-filter"
                          placement="bottom"
                          content={() => renderFilter()}
                          open={openFilterPopover}
                          onOpenChange={(open) => setOpenFilterPopover(open)}
                          trigger="click"
                        >
                          <div aria-hidden className="ct-filters">
                            {bookmarkFilters.length > 0
                            || bookmarkFilterUsers.length > 0 ? (
                              <SvgLoader
                                className="option"
                                width={20}
                                height={20}
                                svgName="filter-checked-icon"
                              />
                              ) : (
                                <SvgLoader
                                  className="option"
                                  width={20}
                                  height={20}
                                  svgName="filter-icon"
                                />
                              )}

                            <span className="filter-text-name">Filter</span>
                          </div>
                        </Popover>
                      </div>
                    ) : null}
                    {selectedTopic === 'saved_searches' && savedSearchesData?.data?.length !== 0 ? (
                      <div className="filter-bar-container-workspace">

                        <Popover
                          overlayClassName="create-proj-popover create-proj-popover-filter"
                          placement="bottom"
                          content={() => renderSavedSearchFilter()}
                          open={openFilterPopover}
                          onOpenChange={(open) => setOpenFilterPopover(open)}
                          trigger="click"
                        >
                          <div aria-hidden className="ct-filters">
                            {bookmarkFilters.length > 0
                            || bookmarkFilterUsers.length > 0 ? (
                              <SvgLoader
                                className="option"
                                width={20}
                                height={20}
                                svgName="filter-checked-icon"
                              />
                              ) : (
                                <SvgLoader
                                  className="option"
                                  width={20}
                                  height={20}
                                  svgName="filter-icon"
                                />
                              )}

                            <span className="filter-text-name">Filter</span>
                          </div>
                        </Popover>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="analytics-container-content">
              <div className="analytics-container-content-sidebar">
                <ProjectDropdown
                  updatedProjectDates={updatedProjectDates}
                  sharedDocumentId={sharedDocumentId}
                  projectsData={projectsData}
                  openExpandedProject={openExpandedProject}
                  renderProjects={renderProjects}
                  setProjectId={setProjectId}
                  projectId={projectId}
                  bookmarkFilters={bookmarkFilters}
                  filterData={filterData}
                  setBookmarkFilters={setBookmarkFilters}
                  setSortByOptions={setSortByOptions}
                  setBookmarkFilterUsers={setBookmarkFilterUsers}
                />
              </div>
              <Loader loading={projectsData?.loading || !projectsData.flag}>
                {renderList(selectedTopic)}
              </Loader>
            </div>
            {/* <Notification /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkSpace;
