/* eslint-disable no-debugger */
/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { trackOutSideEvents, wordLimit } from '../../../utils';
import SvgLoader from '../SvgLoader';
import { refreshFilterAction } from '../../container/PowerSearch/logic';
import Mixpanel from '../../../utils/mixpanel';
import { Popover } from 'antd';
import SavedSearches from '../../container/SavedSearches';

function ResultViewTags({ reqBody }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [miniView, setMiniView] = useState(true);
  const ctrTags = useAnimation();
  const [tags, setTags] = useState([]);
  const [modifyIconHover, setModifyIconHover] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);

  const tagsRef = useRef(null);
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  useEffect(() => {
    const temp = [];

    if (semanticQuery) {
      JSON.parse(semanticQuery)?.forEach((item) => {
        item.currentFilter?.forEach((element) => {
          if (item.type === 'date-range' && element !== '') {
            const date = new Date(element * 1000);
            const month = (String(date.getMonth() + 1)).length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const tempDate1 = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate();

            const tempDate = `${date.getFullYear()}-${month}-${tempDate1}`;
            temp.push(tempDate);
          } else if (element !== '') {
            temp.push(element);
          }
        });
      });

      setTags([...temp]);
    }

    if (query) {
      JSON.parse(query)?.forEach((item) => {
        if (item.name === 'gender') {
          if (item.currentGender) {
            temp.push(item.currentGender[0]);
          }
        } else {
          item.currentFilter?.forEach((element) => {
            if (item.type === 'date-range' && element !== '') {
              const date = new Date(element * 1000);
              const month = (String(date.getMonth() + 1)).length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
              const tempDate1 = String(date.getDate()).length === 1 ? `0${date.getDate()}` : date.getDate();
              const tempDate = `${date.getFullYear()}-${month}-${tempDate1}`;
              temp.push(tempDate);
            } else if (element !== '' && item.type !== 'date-range') {
              temp.push(element);
            }
          });
        }
      });

      setTags([...temp]);
    }
  }, []);

  useEffect(() => {
    trackOutSideEvents(tagsRef, () => setMiniView(true));
  }, []);

  const handleModifyClick = () => {
    dispatch(refreshFilterAction());
    if (location.pathname === '/explore/results' || location.pathname === '/explore/analytics') {
      if (semanticQuery?.length) {
        Mixpanel.track('search_modify_event', {
          action: 'Modify click',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
          modify_query: JSON.parse(semanticQuery),
          search_type: 'Semantic Search',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
        });

        navigate(`/explore?semanticquery=${encodeURIComponent(semanticQuery)}`);
      } else {
        Mixpanel.track('search_modify_event', {
          action: 'Modify click',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
          modify_query: JSON.parse(query),
          search_type: 'Power Search',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
        });

        navigate(`/explore?query=${encodeURIComponent(query)}`);
      }
    } else if (location.pathname === '/clinicalTrials/searchResults' || location.pathname === '/clinicalTrials/analyticsResults') {
      if (semanticQuery?.length) {
        Mixpanel.track('search_modify_event', {
          action: 'Modify click',
          page: `${location.pathname === '/clinicalTrials/searchResults' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Clinical Trials',
          modify_query: JSON.parse(semanticQuery),
          search_type: 'Semantic Search',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
        });

        navigate(`/clinicalTrials?semanticquery=${encodeURIComponent(semanticQuery)}`);
      } else {
        Mixpanel.track('search_modify_event', {
          action: 'Modify click',
          page: `${location.pathname === '/clinicalTrials/searchResults' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Clinical Trials',
          modify_query: JSON.parse(query),
          search_type: 'Power Search',
        });

        Mixpanel.track('page_exit_event', {
          action: 'page leave event',
          page: `${location.pathname === '/explore/results' ? 'List View Page' : 'Analytics Page'}`,
          terminal_name: 'Search and Explore',
        });

        navigate(`/clinicalTrials?query=${encodeURIComponent(query)}`);
      }
    } else if (location.pathname === '/intervention/results' || location.pathname === '/intervention/analytics') {
      Mixpanel.track('search_modify_event', {
        action: 'Modify click',
        page: `${location.pathname === '/intervention/results' ? 'List View Page' : 'Analytics Page'}`,
        terminal_name: 'Intervention',
        modify_query: JSON.parse(query),
      });

      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: `${location.pathname === '/intervention/results' ? 'List View Page' : 'Analytics Page'}`,
        terminal_name: 'Intervention',
      });

      navigate(`/intervention?query=${query}`);
    }
  };

  const renderTags = () => tags.map((item) => (
    <div className="tags" key={item}>
      <div className="tag-label" title={item}>{wordLimit(item, 30)}</div>
    </div>
  ));

  const renderSixTags = (index) => (
    <div className="tags" title={tags[index]}>
      <div className="tag-label" title={tags[index]}>{wordLimit(tags[index], 30)}</div>
    </div>
  );

  const renderMiniView = () => (
    <>
      {renderSixTags(0)}
      {renderSixTags(1)}
      {renderSixTags(2)}
      {renderSixTags(3)}
      {renderSixTags(4)}
      {renderSixTags(5)}
      {tags.length - 6 ? <div className="tags" style={{ width: 50 }}>{`+${tags.length - 6}`}</div> : null}
    </>
  );

  return (
    <div className="ct-header-tags" aria-hidden ref={tagsRef} onClick={() => setMiniView(false)}>
      {(tags.length) ? (
        <motion.div initial={{ x: 0 }} animate={ctrTags} className={`tags-data ${miniView ? 'mini-view-tags' : 'full-view-tags'}`}>
          {(!miniView || tags.length < 6) && renderTags()}
          {(miniView && tags.length > 6)
            ? renderMiniView() : null}
        </motion.div>
      ) : null}
      <div aria-hidden className="edit" onMouseEnter={() => setModifyIconHover(true)} onClick={() => handleModifyClick()} onBlur={() => setModifyIconHover(false)} onMouseOut={() => setModifyIconHover(false)}>
        <SvgLoader width={32} height={32} hoverIconName="edit-icon" svgName={`${modifyIconHover ? 'edit-icon' : 'edit-icon-hover'}`} />
        <span className="edit-icon" />
        <span
          aria-hidden
          className="edit-text"
          onMouseEnter={() => setModifyIconHover(true)}
          onBlur={() => setModifyIconHover(false)}
          onMouseOut={() => setModifyIconHover(false)}
        >
          Modify
        </span>
      </div>
      {/* <Popover overlayClassName='bookmark-popover' placement="bottom" open={showSavedSearches} content={<SavedSearches setShowSavedSearches={setShowSavedSearches} reqBody={reqBody} onClose={() => setShowSavedSearches(!showSavedSearches)} />} >
        <div className="saved-searches-icon" onClick={() => {
          setShowSavedSearches(true);
        }} role='presentation' />

      </Popover> */}

    </div >
  );
}

export default ResultViewTags;
