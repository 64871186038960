import React, { useState } from 'react';
import ModalRequestDemo from '../../container/ModalRequestDemo';
import Selection from '../../container/Selection';
import Header from '../../../common/components/Header';
// eslint-disable-next-line no-unused-vars

function HomePage() {
  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);

  return (
    <div className="main-page">
      {/* <Loader loading={!projectsData.flag && projectsData.loading}> */}
      <Header className="header-home" />
      <Selection setIsOpenRequestDemo={(flag) => setIsOpenRequestDemo(flag)} />
      <ModalRequestDemo
        setOpen={(flag) => setIsOpenRequestDemo(flag)}
        open={isOpenRequestDemo}
      />
      {/* </Loader> */}
    </div>
  );
}

export default HomePage;
