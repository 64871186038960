import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const SUBMIT_CONTACT_US_FORM = 'SUBMIT_CONTACT_US_FORM';
const SUBMIT_CONTACT_US_FORM_SUCCESS = 'SUBMIT_CONTACT_US_FORM_SUCCESS';
const SUBMIT_CONTACT_US_FORM_FAILURE = 'SUBMIT_CONTACT_US_FORM_FAILURE';
const SUBMIT_CONTACT_US_FORM_REFRESH = 'SUBMIT_CONTACT_US_FORM_REFRESH';

export const postSubmitFormAction = createAction(SUBMIT_CONTACT_US_FORM);

const postSubmitFormSuccess = createAction(SUBMIT_CONTACT_US_FORM_SUCCESS);

const postSubmitFormFaliure = createAction(SUBMIT_CONTACT_US_FORM_FAILURE);

export const postSubmitFormRefreshAction = createAction(SUBMIT_CONTACT_US_FORM_REFRESH);

export const postSubmitFormEpic = (actions$) => actions$.pipe(
  ofType(SUBMIT_CONTACT_US_FORM),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/auth/contactUs?`, 'POST', action.payload.body)).pipe(
    map((res) => postSubmitFormSuccess(res)),
    catchError((err) => of(postSubmitFormFaliure(err))),
  )),
);

const onpostSubmitFormAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onpostSubmitFormSuccess = (state, { response }) => ({
  ...state,
  data: response.data,
  loading: false,
  flag: true,
  error: false,
});

const onpostSubmitFormFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onpostSubmitFormRefresh = () => ({
  data: '',
  loading: false,
  error: false,
  flag: false,
});

export const postSubmitFormReducer = createReducer(
  {
    data: '',
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(postSubmitFormAction, (state) => onpostSubmitFormAction(state))
      .addCase(postSubmitFormSuccess, (state, action) => onpostSubmitFormSuccess(state, action.payload))
      .addCase(postSubmitFormFaliure, (state) => onpostSubmitFormFailure(state))
      .addCase(postSubmitFormRefreshAction, () => onpostSubmitFormRefresh())
      .addDefaultCase((state) => state);
  },
);
