import { createAction, createReducer } from '@reduxjs/toolkit';

const FILTERS = 'FILTERS';
const FILTERS_UPDATE = 'FILTERS_UPDATE';
const FILTERS_RESET = 'FILTERS_RESET';

export const filtersAction = createAction(FILTERS);

const filtersUpdate = createAction(FILTERS_UPDATE);

export const filtersReset = createAction(FILTERS_RESET);

const onfiltersAction = (state) => ({
  ...state,
});

const onfiltersUpdate = (state, payload) => ({
  ...state,
  data: [...payload],
});

const onfiltersReset = () => ({
  data: [],
});

export const terminalFiltersReducer = createReducer(
  {
    data: [],
  },
  (builder) => {
    builder.addCase(filtersAction, (state) => onfiltersAction(state))
      .addCase(filtersUpdate, (state, action) => onfiltersUpdate(state, action.payload))
      .addCase(filtersReset, () => onfiltersReset())
      .addDefaultCase((state) => state);
  },
);

const FILTER_SELECTED = 'FILTER_SELECTED';

export const selectFilterAction = createAction(FILTER_SELECTED);

const onSelectFilterAction = (state, payload) => ({
  data: payload,
});

export const currentFilterSelectedRedcuer = createReducer(
  {
    data: '',
  },
  (builder) => {
    builder.addCase(selectFilterAction, (state, action) => onSelectFilterAction(state, action.payload))
      .addDefaultCase((state) => state);
  },
);
