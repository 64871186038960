const currentLadder = [
  'indication',
  'reg_body',
  'labeller',
  'brand_name',
  'dosage_form',
  'target',
  'moa',
  'dose',
  'registry',
  'sponsor',
  'phase',
  'trial_id',
];

export const marketedLadder = [
  'indication',
  'reg_body',
  'labeller',
  'brand_name',
  'dosage_form',
  'target',
  'moa',
  'dose',
];

export const noDateFoundMessageLadder = [
  'No Indication Found',
  'No Regulatory Body Found',
  'No Labeller Found',
  'No Brand Name Found',
  'No Dosage Form Found',
  'No Target Found',
  'No Mechanism of Action Found',
  'No Dose Found',
];

export const clinicalLadder = [
  'indication',
  'registry',
  'sponsor',
  'phase',
  'trial_id',
];

export const noDateFoundMessageClinicalLadder = [
  'No Indication Found',
  'No Registry Found',
  'No Sponsor Found',
  'No Phase Found',
  'No Trial ID Found',
];

export const dataPoint2 = [
  { name: 'labeller', type: 'Head' },
  { name: 'Pfizer', type: 'subject' },
  { name: 'Ranbaxy', type: 'subject' },
  { name: 'Apotex', type: 'subject' },
  { name: 'Valent', type: 'subject' },
];

export default currentLadder;
