import { createAction, createReducer } from '@reduxjs/toolkit';
import { getCookie, removeCookies } from '../../../utils';
import webAuth from '../../../utils/webAuth';
import Mixpanel from '../../../utils/mixpanel';

const SET_SESSION = 'SET_SESSION';
const REMOVE_SESSION = 'REMOVE_SESSION';

const INTIAL_STATE = {
  session: getCookie('accessToken') || '',
};

export const setSession = createAction(SET_SESSION);

export const removeSession = createAction(REMOVE_SESSION);

export const sessionReducer = createReducer(INTIAL_STATE, (builder) => {
  builder
    .addCase(setSession, (state, action) => {
      state.session = action.payload;
    })
    .addCase(removeSession, (state) => {
      Mixpanel.track('logoutEvent', {
        action: 'Logout button click header',
        userDetail: `${getCookie('userName')}@${getCookie('org')}`,
      });
      const params = {
        returnTo: process.env.appUrl,
        clientID: process.env.auth0.clientId,
      };
      webAuth.logout(params);
      removeCookies();
      state.session = '';
    })
    .addDefaultCase((state) => state);
});
