import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SEARCH_RESULSTS = 'GET_SEARCH_RESULSTS';
const GET_SEARCH_RESULSTS_SUCCESS = 'GET_SEARCH_RESULSTS_SUCCESS';
const GET_SEARCH_RESULSTS_FAILURE = 'GET_SEARCH_RESULSTS_FAILURE';
const GET_MORE_SEARCH_RESULSTS = 'GET_MORE_SEARCH_RESULSTS';
const GET_MORE_SEARCH_RESULSTS_SUCCESS = 'GET_MORE_SEARCH_RESULSTS_SUCCESS';
const GET_SEARCH_RESULSTS_REFRESH = 'GET_SEARCH_RESULSTS_REFRESH';

export const getSearchResultsAction = createAction(GET_SEARCH_RESULSTS);

export const getMoreSearchResultsAction = createAction(GET_MORE_SEARCH_RESULSTS);

const getSearchResultsSuccess = createAction(GET_SEARCH_RESULSTS_SUCCESS);

const getMoreSearchResultsSuccess = createAction(GET_MORE_SEARCH_RESULSTS_SUCCESS);

const getSearchResultsFaliure = createAction(GET_SEARCH_RESULSTS_FAILURE);

export const getSearchResultsRefreshAction = createAction(GET_SEARCH_RESULSTS_REFRESH);

export const getSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getSearchResultsSuccess(res)),
    catchError((err) => of(getSearchResultsFaliure(err))),
  )),
);

export const getMoreSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreSearchResultsSuccess(res)),
    catchError((err) => of(getSearchResultsFaliure(err))),
  )),
);

const onGetSearchResultsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetMoreSearchResultsAction = (state) => ({
  ...state.data,
  loading: false,
  error: false,
});

const onGetSearchResultsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetMoreSearchResultsSuccess = (state, { response }) => (
  {
    data: {
      total: response?.total,
      data: [
        ...state.data,
        ...response.data,
      ],
    },
    loading: false,
    flag: true,
  });

const onGetSearchResultsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onSearchResultRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getSearchResultsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSearchResultsAction, (state) => onGetSearchResultsAction(state))
      .addCase(getSearchResultsSuccess, (state, action) => onGetSearchResultsSuccess(state, action.payload))
      .addCase(getSearchResultsFaliure, (state) => onGetSearchResultsFailure(state))
      .addCase(getMoreSearchResultsAction, (state) => onGetMoreSearchResultsAction(state))
      .addCase(getMoreSearchResultsSuccess, (state, action) => onGetMoreSearchResultsSuccess(state, action.payload))
      .addCase(getSearchResultsRefreshAction, () => onSearchResultRefreshAction())
      .addDefaultCase((state) => state);
  },
);
