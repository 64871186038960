import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { updateFilterAction } from '../../container/PowerSearch/logic';

function FilterNumberRange({
  id, selectedValue, isDefault, assetClasse, resetSection, filterType,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('filters');

  const currentFilters = useSelector((state) => state.filters);

  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  useEffect(() => {
    const f = JSON.parse(filters);
    if (f) {
      f.forEach((item) => {
        const SECondition = item.name === selectedValue && !isDefault && item.assetClass === assetClasse;
        const others = item.name === selectedValue && !isDefault;
        const Condition = filterType === 'SE' ? SECondition : others;
        if (Condition) {
          setMin(item.currentFilter[0]);
          setMax(item.currentFilter[1]);
          dispatch(updateFilterAction({
            filterOption: { currentFilter: [item.currentFilter[0] || '', item.currentFilter[1] || ''], currentKeys: [item.currentFilter[0] || '', item.currentFilter[1] || ''] },
            id,
          }));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (resetSection === assetClasse) {
      setMin(null);
      setMax(null);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [], currentKeys: [] },
        id,
      }));
    }
  }, [resetSection, assetClasse]);

  useEffect(() => {
    if (currentFilters.filters[id]?.currentFilter?.length) {
      const values = currentFilters.filters[id].currentFilter;
      if (values[0]) {
        setMin(values[0]);
      }
      if (values[1]) {
        setMax(values[1]);
      }
    }
  }, [JSON.stringify(currentFilters)]);

  useEffect(() => {
    dispatch(updateFilterAction({
      filterOption: { currentFilter: [min || '', max || ''], currentKeys: [min || '', max || ''] },
      id,
    }));
  }, [min, max]);

  const handleNumUpdate = (e, type) => {
    if (type === 'min') {
      setMin(e.target.value);
    } else {
      setMax(e.target.value);
    }
  };

  return (
    <div className="calender-container filter-calender-container">
      <input
        value={min || ''}
        type="number"
        placeholder="Min"
        onChange={(e) => handleNumUpdate(e, 'min')}
        size="small"
        className="date-range-input"
      />
      <input
        value={max || ''}
        type="number"
        placeholder="Max"
        size="small"
        onChange={(e) => handleNumUpdate(e, 'max')}
        className="date-range-input"
      />
    </div>
  );
}

FilterNumberRange.propTypes = {
  id: PropTypes.number.isRequired,
  selectedValue: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  resetSection: PropTypes.string.isRequired,
  assetClasse: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FilterNumberRange;
