import { combineEpics } from 'redux-observable';
import rootCommonEpic from './common/container/epics';
import rootAppEpic from './app/container/epic';
import rootClinicalTrialsEpic from './clinicalTrials/container/epic';
import rootSearchAndExploreEpic from './searchAndExplore/container/epics';
import rootInterventionEpic from './intervention/container/epic';

const rootEpic = combineEpics(
  rootCommonEpic,
  rootAppEpic,
  rootClinicalTrialsEpic,
  rootSearchAndExploreEpic,
  rootInterventionEpic,
);

export default rootEpic;
