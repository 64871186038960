import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_GEOGRAPHICAL_DATA = 'GET_GEOGRAPHICAL_DATA';
const GET_GEOGRAPHICAL_DATA_SUCCESS = 'GET_GEOGRAPHICAL_DATA_SUCCESS';
const GET_GEOGRAPHICAL_DATA_FAILURE = 'GET_GEOGRAPHICAL_DATA_FAILURE';
const GET_GEOGRAPHICAL_DATA_REFRESH = 'GET_GEOGRAPHICAL_DATA_REFRESH';

export const getGeographicalDataAction = createAction(GET_GEOGRAPHICAL_DATA);

const getGeographicalDataSuccess = createAction(GET_GEOGRAPHICAL_DATA_SUCCESS);

const getGeographicalDataFaliure = createAction(GET_GEOGRAPHICAL_DATA_FAILURE);

export const getGeographicalDataRefreshAction = createAction(GET_GEOGRAPHICAL_DATA_REFRESH);

export const getGeographicalDataEpic = (actions$) => actions$.pipe(
  ofType(GET_GEOGRAPHICAL_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/clinical-trials/v0/search/getCountryInsights?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getGeographicalDataSuccess(res)),
    catchError((err) => of(getGeographicalDataFaliure(err))),
  )),
);

const ongetGeographicalDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetGeographicalDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
  error: false,
  minYear: response.minYear,
  maxYear: response.maxYear,
});

const ongetGeographicalDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetGeographicalDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getGeographicalDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getGeographicalDataAction, (state) => ongetGeographicalDataAction(state))
      .addCase(getGeographicalDataSuccess, (state, action) => ongetGeographicalDataSuccess(state, action.payload))
      .addCase(getGeographicalDataFaliure, (state) => ongetGeographicalDataFailure(state))
      .addCase(getGeographicalDataRefreshAction, () => ongetGeographicalDataRefresh())
      .addDefaultCase((state) => state);
  },
);

const GET_GEOGRAPHICAL_STATE_DATA = 'GET_GEOGRAPHICAL_STATE_DATA';
const GET_GEOGRAPHICAL_STATE_DATA_SUCCESS = 'GET_GEOGRAPHICAL_STATE_DATA_SUCCESS';
const GET_GEOGRAPHICAL_STATE_DATA_FAILURE = 'GET_GEOGRAPHICAL_STATE_DATA_FAILURE';
const GET_GEOGRAPHICAL_STATE_DATA_REFRESH = 'GET_GEOGRAPHICAL_STATE_DATA_REFRESH';

export const getGeographicalStateDataAction = createAction(GET_GEOGRAPHICAL_STATE_DATA);

const getGeographicalStateDataSuccess = createAction(GET_GEOGRAPHICAL_STATE_DATA_SUCCESS);

const getGeographicalStateDataFaliure = createAction(GET_GEOGRAPHICAL_STATE_DATA_FAILURE);

export const getGeographicalStateDataRefreshAction = createAction(GET_GEOGRAPHICAL_STATE_DATA_REFRESH);

export const getGeographicalStateDataEpic = (actions$) => actions$.pipe(
  ofType(GET_GEOGRAPHICAL_STATE_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/clinical-trials/v0/search/getStateInsights?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getGeographicalStateDataSuccess(res)),
    catchError((err) => of(getGeographicalStateDataFaliure(err))),
  )),
);

const ongetGeographicalStateDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetGeographicalStateDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
  error: false,
});

const ongetGeographicalStateDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetGeographicalStateDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getGeographicalStateDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getGeographicalStateDataAction, (state) => ongetGeographicalStateDataAction(state))
      .addCase(getGeographicalStateDataSuccess, (state, action) => ongetGeographicalStateDataSuccess(state, action.payload))
      .addCase(getGeographicalStateDataFaliure, (state) => ongetGeographicalStateDataFailure(state))
      .addCase(getGeographicalStateDataRefreshAction, () => ongetGeographicalStateDataRefresh())
      .addDefaultCase((state) => state);
  },
);

const DATE_RANGE_UPDATE = 'DATE_RANGE_UPDATE';

export const dateRangeUpdateAction = createAction(DATE_RANGE_UPDATE);

const ondateRangeUpdateAction = (state, payload) => ({
  ...state,
  data: [...payload],
  flag: true,
});

export const dateRangeReducer = createReducer(
  {
    data: [null, null],
    flag: false,
  },
  (builder) => {
    builder.addCase(dateRangeUpdateAction, (state, action) => ondateRangeUpdateAction(state, action.payload))
      .addDefaultCase((state) => state);
  },
);

const SHOW_BACK_BUTTON = 'SHOW_BACK_BUTTON';

const INTIAL_STATE = {
  isExpanded: false,
};

export const showBackButtonAction = createAction(SHOW_BACK_BUTTON);

export const showBackButtonReducer = createReducer(INTIAL_STATE, (builder) => {
  builder
    .addCase(showBackButtonAction, (state, action) => {
      state.isExpanded = action.payload;
    })
    .addDefaultCase((state) => state);
});
