import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeSession } from './logic';
import { debounceCall, getCookie } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import { openLoginModalAction } from '../../../app/container/LoginModalV2/logic';

function Status() {
  const { type, status } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const allowedPaths = ['/', '/home', '/homeV2', '/persona', '/contact', '/callback', '/privacyPolicy', '/userPolicy', '/clinicalTrials', '/explore', '/intervention'];

  const allowedPathEmail = ['/', '/contact', '/userPolicy', '/privacyPolicy'];

  const activityFlow = () => {
    if (type === undefined) {
      return null;
    }
    if (type === 'LOGOUT_SUCCESS') {
      Mixpanel.reset();
      dispatch(removeSession());
      navigate('/', { replace: true });
    } else if (type === 'SET_SESSION') {
      navigate(process.env.redirectRoute, { replace: true });
    } else if (type.includes('_FAILURE')) {
      if (status === 401 && type !== 'LOGIN_FAILURE') {
        debounceCall(() => dispatch(openLoginModalAction()), 200);
      }
    }
    return false;
  };

  useEffect(() => {
    if (window.location.pathname === '/' && getCookie('userEmail') && getCookie('personaBuild') !== 'false') {
      navigate('/home', { replace: true });
      navigate(0);
    }

    if (!allowedPathEmail.includes(location.pathname) && !getCookie('userEmail')) {
      navigate('/');
      navigate(0);
    }

    if (!allowedPaths.includes(location.pathname) && !getCookie('accessToken')) {
      debounceCall(() => dispatch(openLoginModalAction()), 200);
    }
  });

  useEffect(() => {
    if (type !== 'cancel') {
      activityFlow();
    }
  }, [type]);
  return null;
}

export default Status;
