import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Popover, Modal, Button, Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../../components/SvgLoader';
import Comment from '../../Comment';
import SavedSearchWorkspace from '../../../SavedSearchWorkspace';
import {
  shareSaveSearchAction,
  shareSaveSearchRefreshAction,
} from '../../../SavedSearches/logic';
import { sendNotification } from '../../../../components/Notification/logic';

function SavedSearchesCard({ data, projectId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shareSaveSearch = useSelector((state) => state.shareSaveSearch);

  // eslint-disable-next-line no-unused-vars
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [message, setMessage] = useState('');

  function formatString(input) {
    const replacedString = input.replace(/_/g, ' ');
    const capitalizedString = replacedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return capitalizedString;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // eslint-disable-next-line no-shadow
  const handleSearchClick = (data) => {
    navigate(data?.queryUrl?.url);
  };

  const showShareModal = () => {
    setOpenShareModal(!openShareModal);
  };

  const handleInviteClick = () => {
    dispatch(
      shareSaveSearchAction({
        body: {
          email_ids: emailTags,
          terminal_name: data?.terminalName,
          view: data?.view,
          query: data?.query,
          filters: data?.filters,
          count: data?.count,
          comment: message || data?.comment,
          query_url: data?.queryUrl,
          hash: data?.hash,
        },
      }),
    );
    setOpenShareModal(!openShareModal);
  };

  useEffect(() => {
    if (shareSaveSearch?.flag && Object.keys(shareSaveSearch?.data).length) {
      if (shareSaveSearch.data.message.includes('not')) {
        dispatch(
          sendNotification({
            type: 'SAVE_SEARCH_SHARED_FAILURE',
            notificationType: 'error',
            message: shareSaveSearch.data.message,
            showNotification: true,
          }),
        );
      } else {
        dispatch(
          sendNotification({
            type: 'SAVE_SEARCH_SHARED_SUCCESS',
            notificationType: 'success',
            message: shareSaveSearch.data.message,
            showNotification: true,
          }),
        );
      }
      dispatch(shareSaveSearchRefreshAction());
    }
  }, [JSON.stringify(shareSaveSearch)]);
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && currentEmail) {
      if (!emailTags.includes(currentEmail)) {
        setEmailTags([...emailTags, currentEmail]);
        setCurrentEmail('');
        setIsEnterPressed(true);
      }
      e.preventDefault();
    }
  };

  const handleEmailInputChange = (e) => {
    setCurrentEmail(e.target.value);
    if (emailRegex.test(e.target.value) || e.target.value === '') {
      setEmailError('');
    } else {
      setEmailError('Invalid email format');
    }
  };

  const handleTagRemove = (emailToRemove) => {
    setEmailTags(emailTags.filter((email) => email !== emailToRemove));
  };

  // const [activeBookmarkId, setActiveBookmarkId] = useState(null);

  // const renderClass = (predictionScore) => {
  //     if (predictionScore > 65) {
  //         return 'pos-high';
  //     }
  //     if (predictionScore > 35 && predictionScore <= 65) {
  //         return 'pos-med';
  //     }
  //     return 'pos-low';
  // };

  // const handleListClick = () => {
  //     setSelectedCard(data);
  // };

  // const renderTooltip = (predictionScore) => (
  //     <div className="prediction-tooltip-container">
  //         <div className="prediction-tooltip-data">
  //             <div className={predictionScore >= 65 ? 'high' : (predictionScore >= 35 && predictionScore < 65 ? 'medium' : 'low')} />
  //             <div className="prediction-tooltip-sub-container">
  //                 <div className="prediction-score">{`${predictionScore} %`}</div>
  //                 <div className="predition-title">Probability of success</div>
  //             </div>
  //         </div>

  //     </div>
  // );

  // const getClassName = (str) => {
  //     const modifiedStr = str.replace(/ /g, '-').toLowerCase();
  //     return modifiedStr;
  // };

  // const renderTooltipValue = (dataset) => dataset?.map((item) => `${item?.name || item?.kol_name || item?.author_name || item} `);
  // const renderField = (value, dataset, len = 0) => {
  //     return (<span className="filter">
  //         <span className="filter-value" title={renderTooltipValue(dataset)}>
  //             {' '}
  //             {value?.name ?? value}
  //         </span>
  //         <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
  //     </span>);
  // };

  return (
    <div
      role="presentation"
      className="search-and-explore-results-container-list-content-main explore "
      // onClick={() => handleListClick()}
    >
      <div className="list-content-left">
        <div className="list-content-header">
          <div className="list-content-section">
            <div className="list-content-terminal-name">
              <span className="list-content-terminal-key">Terminal: </span>
              <span className="list-content-terminal-value">
                {formatString(data?.terminalName)}
              </span>
            </div>
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />

            <div className="list-content-view">
              <div className="view-value">{data?.view}</div>
            </div>
            <SvgLoader
              className="divider"
              width={6}
              height={6}
              svgName="seperator-icon"
            />

            <div className="list-content-total-count">
              <span className="list-content-total-count-key">Result: </span>
              <span className="list-content-total-count-value">
                {data?.count}
              </span>
            </div>
          </div>
          <div className="list-icon-section">
            {/* <div className={activeBookmarkId === data?.trial_id ? 'bookmark-icon-active-list' : 'bookmark-icon'} role="presentation" /> */}

            {/* <div className="share-icon" role="presentation" onClick={showShareModal} /> */}
          </div>
        </div>
        {data?.view !== 'Semantic Search' ? (
          <div className="list-content-title">
            {data?.queryUrl?.q?.map((itm) => itm?.currentFilter?.map((item) => (
              <div className="tags">
                <div className="list-content-title-key" key={item}>
                  {item}
                </div>
              </div>
            )))}
          </div>
        ) : (
          <div className="list-content-title">
            <div className="tags">
              <div className="list-content-title-key">{data?.query?.body?.body}</div>
            </div>
          </div>
        )}

        <div className="list-content-data">
          <div className="list-content-data-key">Filter: </div>
          <div className="list-content-data-value">
            {data?.queryUrl?.f?.map((itm) => itm?.currentFilter?.map((item) => (
              <div className="list-content-title-key" key={item}>
                {item}
                <span>,</span>
              </div>
            )))}
          </div>
        </div>
      </div>
      <div className="list-content-right">
        <div className="list-icon-section">
          <div className="comment-section">
            <Comment
              from="saved-searches"
              data={data}
              projectId={projectId}
              setOpenDrawer={setOpenDrawer}
            />
          </div>
          <div
            className="search-icon-saved-searches"
            role="presentation"
            onClick={() => handleSearchClick(data)}
          />
          {/* <SvgLoader svgName="search-icon-save-search" height={32} width={32}/> */}
          <Popover
            overlayClassName="bookmark-popover"
            trigger="click"
            placement="bottom"
            open={showSavedSearches}
            onOpenChange={(open) => setShowSavedSearches(open)}
            content={(
              <SavedSearchWorkspace
                projIds={projectId}
                data={data}
                setShowSavedSearches={setShowSavedSearches}
                reqBody={data?.query}
                onClose={() => setShowSavedSearches(!showSavedSearches)}
                filtersData={data?.filters}
                terminalName={data?.terminalName}
                view={data?.view}
                totalCount={data?.count}
                semanticQueryRequest={data?.query}
                queryUrl={data?.queryUrl}
              />
            )}
          >
            <div
              className="saved-search-active"
              onClick={() => {
                setShowSavedSearches(true);
              }}
              role="presentation"
            />
          </Popover>

          <div
            className="share-icon"
            role="presentation"
            onClick={() => showShareModal()}
          />

          {openShareModal && (
            <Modal
              className="share-with-modal"
              open={openShareModal}
              closable
              footer={false}
              onCancel={() => setOpenShareModal(false)}
            >
              <div className="share-modal-content">
                <div className="share-modal-content-input">
                  <div className="share-with-title">Share with</div>
                  <div
                    className="email-tags-container"
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto',
                      marginBottom: '10px',
                    }}
                  >
                    <div className="email-tags">
                      {emailTags.map((email, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="email-tag">
                          {email}
                          <span
                            role="presentation"
                            className="remove-tag"
                            onClick={() => handleTagRemove(email)}
                          />
                        </div>
                      ))}
                      <Input
                        value={currentEmail}
                        onChange={handleEmailInputChange}
                        onKeyDown={(e) => handleKeyDown(e)}
                        placeholder="Email ID"
                      />
                      {emailError && (
                        <div style={{ color: 'red' }}>{emailError}</div>
                      )}
                    </div>
                  </div>
                  <div className="share-with-message-input">
                    <Input
                      placeholder="Message (Optional)"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div className="invite-btn">
                    <Button
                      disabled={
                        !isEnterPressed || emailError || emailTags.length === 0
                      }
                      type="primary"
                      onClick={() => handleInviteClick()}
                    >
                      Invite
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedSearchesCard;
