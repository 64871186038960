import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_CT_SECTION_LIST = 'GET_CT_SECTION_LIST';
const GET_CT_SECTION_LIST_SUCCESS = 'GET_CT_SECTION_LIST_SUCCESS';
const GET_CT_SECTION_LIST_FAILURE = 'GET_CT_SECTION_LIST_FAILURE';

export const getCtSectionListAction = createAction(GET_CT_SECTION_LIST);

const getCtSectionListSuccess = createAction(GET_CT_SECTION_LIST_SUCCESS);

const getCtSectionListFaliure = createAction(GET_CT_SECTION_LIST_FAILURE);

const onGetCtSectionListAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetCtSectionListSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetCtSectionListFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getCtSectionListEpic = (actions$) => actions$.pipe(
  ofType(GET_CT_SECTION_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params.url}?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getCtSectionListSuccess(res)),
    catchError((err) => of(getCtSectionListFaliure(err))),
  )),
);

export const getCtSectionListReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getCtSectionListAction, (state) => onGetCtSectionListAction(state))
      .addCase(getCtSectionListSuccess, (state, action) => onGetCtSectionListSuccess(state, action.payload))
      .addCase(getCtSectionListFaliure, (state) => onGetCtSectionListFailure(state))
      .addDefaultCase((state) => state);
  },
);

const GET_CT_RELATED_ARTICLES = 'GET_CT_RELATED_ARTICLES';
const GET_CT_RELATED_ARTICLES_SUCCESS = 'GET_CT_RELATED_ARTICLES_SUCCESS';
const GET_CT_RELATED_ARTICLES_FAILURE = 'GET_CT_RELATED_ARTICLES_FAILURE';

export const getCtRelatedArticlesAction = createAction(GET_CT_RELATED_ARTICLES);

const getCtRelatedArticlesSuccess = createAction(GET_CT_RELATED_ARTICLES_SUCCESS);

const getCtRelatedArticlesFaliure = createAction(GET_CT_RELATED_ARTICLES_FAILURE);

const onGetCtRelatedArticlesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetCtRelatedArticlesSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetCtRelatedArticlesFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getCtRelatedArticlesEpic = (actions$) => actions$.pipe(
  ofType(GET_CT_RELATED_ARTICLES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/search/clinical_trials/related_articles?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getCtRelatedArticlesSuccess(res)),
    catchError((err) => of(getCtRelatedArticlesFaliure(err))),
  )),
);

export const getCtRelatedArticlesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getCtRelatedArticlesAction, (state) => onGetCtRelatedArticlesAction(state))
      .addCase(getCtRelatedArticlesSuccess, (state, action) => onGetCtRelatedArticlesSuccess(state, action.payload))
      .addCase(getCtRelatedArticlesFaliure, (state) => onGetCtRelatedArticlesFailure(state))
      .addDefaultCase((state) => state);
  },
);

const GET_DOCS_SE = 'GET_DOCS_SE';
const GET_DOCS_SE_SUCCESS = 'GET_DOCS_SE_SUCCESS';
const GET_DOCS_SE_FAILURE = 'GET_DOCS_SE_FAILURE';
const GET_DOCS_SE_REFRESH = 'GET_DOCS_SE_REFRESH';

export const getDocsSEAction = createAction(GET_DOCS_SE);

const getDocsSESuccess = createAction(GET_DOCS_SE_SUCCESS);

const getDocsSEFaliure = createAction(GET_DOCS_SE_FAILURE);

export const getDocsSERefreshAction = createAction(GET_DOCS_SE_REFRESH);

export const getDocsSEEpic = (actions$) => actions$.pipe(
  ofType(GET_DOCS_SE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, action.payload.method, action.payload.body)).pipe(
    map((res) => getDocsSESuccess(res)),
    catchError((err) => of(getDocsSEFaliure(err))),
  )),
);

const ongetDocsSEAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetDocsSESuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetDocsSEFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetDocsSERefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getDocsSEReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getDocsSEAction, (state) => ongetDocsSEAction(state))
      .addCase(getDocsSESuccess, (state, action) => ongetDocsSESuccess(state, action.payload))
      .addCase(getDocsSEFaliure, (state) => ongetDocsSEFailure(state))
      .addCase(getDocsSERefreshAction, () => ongetDocsSERefreshAction())
      .addDefaultCase((state) => state);
  },
);
