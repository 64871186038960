import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../../../utils';
import { removeSession } from '../../../common/container/Status/logic';

function RegectedModal() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = useState(getCookie('rejectedApprovedl') === 'true');

  const handleClose = () => {
    setOpen(false);
    setCookie(false, 'rejectedApprovedl');
    setTimeout(() => dispatch(removeSession()), 0);
  };

  if (getCookie('rejectedApprovedl') !== 'true' || location.pathname === '/callback') {
    return null;
  }

  return (
    <Modal className="trial-status-modal" onCancel={() => handleClose()} footer={false} open={open} closeIcon={false} width={604}>
      <div className="trial-status-modal-ctr">
        <div className="rejected-image" />
        <div className="ts-header">Your request has been rejected</div>
        <div className="ts-sub-header">
          Please connect with our
          {' '}
          <spam>support team</spam>
        </div>
        <div aria-hidden onClick={() => handleClose()} className="okay-btn">Okay</div>
      </div>
    </Modal>
  );
}

export default RegectedModal;
