/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams, useLocation } from 'react-router-dom';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { getTypesValuesAction, updateCurrentDropdownAction, getTypesValuesRefreshAction } from '../../container/PowerSearchBox/logic';
import {
  debounceCall, getTerminalName, modifyDataForDropdown, wordLimit,
} from '../../../utils';
import Loader from '../Loader';
import { updateFilterAction, goBtnZIndexUpdateAction } from '../../container/PowerSearch/logic';
import Mixpanel from '../../../utils/mixpanel';

function AutoSuggestMultiSelect({
  className, selectedValue, id, setShow, apiUrl, removedValues,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const multiSelectRef = useRef(null);
  const [input, setInput] = useState('');
  const [openDropdown, setOpendDropDown] = useState(false);
  const [tags, setTags] = useState([]);
  const [keys, setKeys] = useState([]);
  const [synonymKeys, setSynonymKeys] = useState([]);
  const [checkFocus, setCheckFocus] = useState(false);
  const [miniView, setMiniView] = useState(false);
  const currentDropdown = useSelector((state) => state.currentDropdown);
  const optionValues = useSelector((state) => state.optionValues);
  const options = modifyDataForDropdown(optionValues.data, 'key', 'value');
  const query = searchParams.get('query');
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (query && !removedValues?.includes(selectedValue)) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item.name === selectedValue) {
          setTags([...item.currentFilter]);
          setKeys([...item.currentKeys]);
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(goBtnZIndexUpdateAction(openDropdown ? -1 : 0));
  }, [openDropdown]);

  useEffect(() => {
    if (options.length && id === currentDropdown.id && optionValues.fieldName === selectedValue) {
      setOpendDropDown(true);
    }
  }, [JSON.stringify(options), currentDropdown]);

  useEffect(() => {
    dispatch(updateFilterAction({
      filterOption: { currentFilter: tags, currentKeys: keys, currentSynonymKey: synonymKeys[0]?.includes('&') ? encodeURIComponent(synonymKeys[0]) : synonymKeys[0] },

      id,
    }));
    setOpendDropDown(false);
  }, [tags]);

  useEffect(() => {
    const handler = (event) => {
      if (!multiSelectRef.current) {
        return;
      }

      if (!multiSelectRef.current.contains(event.target)) {
        setMiniView(true);
        setOpendDropDown(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (input.length > 3) {
      setShowLoader(false);
      let request = {};
      if (location.pathname === '/searchAndExplore') {
        request = {
          apiUrl,
          params: queryString.stringify({
            field_query: input,
            field_name: selectedValue,
            asset_class: 'ot_search_assetclasses',
          }),
          headers: {
            Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
          },
        };
      } else {
        request = {
          apiUrl,
          params: queryString.stringify({
            field_query: input,
            field_name: selectedValue,
            terminal_name: 'clinical_trials',
          }),
          headers: {
            Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
          },
        };
      }
      debounceCall(() => dispatch(getTypesValuesAction(request)), 300);
    }
  }, [input]);

  const handleChange = (event) => {
    event.preventDefault();
    setInput(event.target.value);
    setOpendDropDown(true);
    setShowLoader(true);
    dispatch(getTypesValuesRefreshAction({}));
    dispatch(updateCurrentDropdownAction(id));
    debounceCall(() => updateCurrentDropdownAction(id), 300);
    if (!event.target.value?.length) {
      setShowLoader(false);
      setOpendDropDown(false);
    }
  };

  const handleOptionClick = (valueTag, val, valSynonymKey) => {
    if (tags.indexOf(valueTag) !== -1) {
      const temp = tags.filter((item) => item !== valueTag);
      const tempKeys = keys.filter((item) => item !== val);
      const tempSynonymKeys = synonymKeys.filter((item) => item !== valSynonymKey);
      setTags([...temp]);
      setKeys([...tempKeys]);
      setSynonymKeys([...tempSynonymKeys]);
    } else {
      setTags([...tags, valueTag]);
      setKeys([...keys, val]);
      setSynonymKeys([...synonymKeys, valSynonymKey]);
    }
    setInput('');
    setCheckFocus(true);
    setOpendDropDown(false);
  };

  const handleDivClick = () => {
    Mixpanel.track('search_box_tap_event', {
      action: 'user taped on search box to fill data for power search',
      field_name: selectedValue,
      terminal_name: getTerminalName(location),
      page: 'Search Page',
    });
    setMiniView(false);
    dispatch(updateCurrentDropdownAction(id));
  };

  const handleCrossOptionClick = (i) => {
    handleOptionClick(tags[i], keys[i]);
  };

  const renderTags = () => tags.map((item, i) => (
    <div className={`tags ${miniView ? '' : 'adjust-tag-size'}`} key={item}>
      <div className="tag-label" title={item}>{wordLimit(item, 20)}</div>
      <SvgLoader className="cross-icon" onClick={() => handleCrossOptionClick(i)} width={28} height={28} svgName="cross" />
    </div>
  ));

  const renderDropdowns = () => (options.map((item) => (
    selectedValue === 'country' || selectedValue === 'site' ? (
      <div aria-hidden key={item.value} onClick={() => handleOptionClick(item.label, item.value)} className={`options ${className[2]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
        <div className="main-options">
          <div className="main-options-title">
            {item.label}
            {tags.indexOf(item.label) > -1 && <SvgLoader width={24} height={24} svgName="tick" />}
          </div>
          {/* <div className="sub-options">
              {`Pref Name: ${item.label}`}
            </div> */}
        </div>
      </div>
    ) : (
      <div aria-hidden key={item.value} onClick={() => handleOptionClick(item.synonyms, item.value, item.key)} className={`options ${className[2]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
        <div className="main-options">
          <div className="main-options-title">
            {item.synonyms}
            {tags.indexOf(item.label) > -1 && <SvgLoader width={24} height={24} svgName="tick" />}
          </div>
          <div className="sub-options">
            {`Pref Name: ${item.label}`}
          </div>
        </div>
      </div>
    )
  )));
  const renderMiniView = () => (
    <>
      <div className="tags" title={tags[0]}>
        {wordLimit(tags[0], 20)}
        <SvgLoader className="cross-icon" onClick={() => handleCrossOptionClick(0)} width={28} height={28} svgName="cross" />
      </div>
      {tags.length - 1 && <div className="tags" style={{ width: 50 }}>{`+${tags.length - 1}`}</div>}
    </>
  );

  return (
    <div aria-hidden ref={multiSelectRef} className="multi-select" onClick={() => handleDivClick()}>
      <div className="multi-select-wrap">
        {tags.length
          ? (
            <div className={`multi-select-div ${className[0]}`}>
              <div className="tags-data">
                {!miniView && renderTags()}
                {(miniView)
                  ? renderMiniView() : null}
              </div>
            </div>
          ) : null}
        {(!miniView || !tags.length) && <input autoFocus={checkFocus} onFocus={() => setShow('power')} className="multi-input" onChange={handleChange} value={input} />}
      </div>
      <div className="input-div">
        {openDropdown
            && (
              <Panel className={`panel ${className[1]}`}>
                {renderDropdowns()}
                <Loader
                  error={optionValues.error}
                  loading={(!optionValues.flag && optionValues.loading) || showLoader}
                  noData={optionValues.data?.length === 0}
                  height={50}
                  className="white-loader"
                />
              </Panel>
            )}
      </div>
    </div>
  );
}

AutoSuggestMultiSelect.propTypes = {
  className: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setShow: PropTypes.func.isRequired,
  apiUrl: PropTypes.string.isRequired,
  removedValues: PropTypes.instanceOf(Array).isRequired,
};

export default AutoSuggestMultiSelect;
