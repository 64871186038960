import React, { useEffect, useState } from 'react';
import { Modal, Space, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import DropdwnMultiSelect from '../../components/DropdownMultiSelect';
import { appRoles, availableApps } from '../../../utils';
import {
  addRolesAction, addRolesRefreshAction, removeRolesAction, removeRolesRefreshAction,
} from './logic';
import { sendNotification } from '../../../common/components/Notification/logic';

function AssignRemoveRoles({ userList, open, setOpen }) {
  const dispatch = useDispatch();

  const [options, setOpions] = useState([]);
  const [selectedOption, setSelectedOptions] = useState([]);
  const [allowedApps, setAllowedAppRoles] = useState([]);
  const [notAllowedApps, setNotAllowedApps] = useState([]);

  const addRoleStatus = useSelector((state) => state.addRoleStatus);
  const removeRolesStatus = useSelector((state) => state.removeRolesStatus);

  const defaultState = () => {
    setOpen(false);
    setSelectedOptions([...[]]);
  };

  useEffect(() => {
    if (removeRolesStatus.flag) {
      if (removeRolesStatus.data.message === 'Roles removed successfully') {
        dispatch(sendNotification({
          type: 'ROLE_ADDED_SUCCESSFULLY',
          notificationType: 'success',
          message: 'Roles removed successfully',
          showNotification: true,
        }));
        defaultState();
      }
    }
  }, [JSON.stringify(removeRolesStatus)]);

  useEffect(() => {
    if (addRoleStatus.flag) {
      if (addRoleStatus.data.message === 'Roles assigned successfully') {
        dispatch(sendNotification({
          type: 'ROLE_ADDED_SUCCESSFULLY',
          notificationType: 'success',
          message: 'Roles assigned successfully',
          showNotification: true,
        }));
        defaultState();
      }
    }
  }, [JSON.stringify(addRoleStatus)]);

  useEffect(() => {
    if (userList.flag) {
      const tempList = userList.data.map((user) => ({
        userId: user.user_id || '',
        value: user.email,
        label: user.email,

      }));
      setOpions([...tempList]);
    }
  }, [JSON.stringify(userList)]);

  const handleChange = (value) => {
    setSelectedOptions([...value]);
    setAllowedAppRoles([...availableApps]);
  };

  const handleRolesClick = () => {
    const request = {
      body: {
        email: selectedOption[0].label,
        roles: allowedApps.map((app) => appRoles[app]),
      },
    };

    const requestRemove = {
      body: {
        email: selectedOption[0].label,
        roles: notAllowedApps.map((app) => appRoles[app]),
      },
    };

    if (selectedOption[0].userId !== '') {
      request.body.user_id = selectedOption[0].userId;
      requestRemove.body.user_id = selectedOption[0].userId;
    }

    if (allowedApps.length) {
      dispatch(addRolesRefreshAction());
      dispatch(addRolesAction(request));
    }

    if (notAllowedApps.length) {
      dispatch(removeRolesRefreshAction());
      dispatch(removeRolesAction(requestRemove));
    }
  };

  const handleSwitchClick = (value, flag) => {
    if (flag) {
      if (!allowedApps.includes(value)) {
        setAllowedAppRoles([...allowedApps, value]);
      }

      if (notAllowedApps.includes(value)) {
        const temp = notAllowedApps.filter((app) => app !== value);
        setNotAllowedApps([...temp]);
      }
    } else {
      if (allowedApps.includes(value)) {
        const temp = allowedApps.filter((app) => app !== value);
        setAllowedAppRoles([...temp]);
      }

      if (!notAllowedApps.includes(value)) {
        setNotAllowedApps([...notAllowedApps, value]);
      }
    }
  };

  const renderApps = () => availableApps.map((app) => (
    <div className="roles-sub-ctr">
      <div className="role-name">{app}</div>
      <Space direction="vertical">
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={allowedApps.includes(app)}
          onChange={(value) => handleSwitchClick(app, value)}
        />
      </Space>
    </div>
  ));

  return (
    <Modal className="login-modal-v2-ctr" footer={false} onCancel={() => setOpen(false)} open={open} closeIcon>
      <div className="admin-assign-role-ctr">
        <div className="main-text-assign-roles">Assign Remove Roles</div>
        <div className="adim-role-ctr">
          {selectedOption.length === 0 ? <DropdwnMultiSelect placeholder="Enter Email" className={['assing-role-dropdown', 'assign-role-panel', 'assign-role-option']} options={options} handleOnSelection={handleChange} /> : null}
        </div>
        <div>
          {selectedOption.length ? (
            <div>
              <div className="assignee-text">{selectedOption[0].label}</div>
              <div>
                {renderApps()}
              </div>
            </div>
          ) : null}
        </div>
        {selectedOption.length
          ? <div className="button-ctr-roles"><button className="submit-btn-roles" onClick={() => handleRolesClick()} type="submit">Submit</button></div> : null}
      </div>
    </Modal>
  );
}

export default AssignRemoveRoles;
