import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgLoader from '../../../common/components/SvgLoader';
import PieChart from '../../../clinicalTrials/container/PieChart';
import exploreWidgets from './data';
import TrendingTopics from '../TrendingTopics';
import Wordcloud from '../WordCloud';
import LiteratureSummaryCard from '../LiteratureSummaryCard';
import NewsLetter from '../../../clinicalTrials/container/NewsLetter';
// import GrantBarGraph from '../GrantBarGraph';
import ComingSoon from '../../../common/components/CommingSoon';
import RecommendedQuestions from '../RecommendedQuestions';

function ExploreAnatyticsContainer({ params }) {
  const [currentExpanded, setCurrentExpanded] = useState('');

  const renderGraphs = (item) => {
    switch (item?.type) {
      case 'pie':
        return <PieChart currentExpanded={currentExpanded} params={params} selected={item} />;
      // case 'grant':
      //   return <GrantBarGraph currentExpanded={currentExpanded} params={params} selected={item} />;
      case 'recommended_next_searches':
        return <RecommendedQuestions currentExpanded={currentExpanded} selected={item} params={params} />;
      case 'topics':
        return <TrendingTopics setExpanded={setCurrentExpanded} currentExpanded={currentExpanded} params={params} selected={item} />;
      case 'wordcloud':
        return <Wordcloud setExpanded={setCurrentExpanded} currentExpanded={currentExpanded} params={params} selected={item} />;
      case 'summary':
        return <LiteratureSummaryCard currentExpanded={currentExpanded} params={params} selected={item} />;
      case 'newsletter':
        return <NewsLetter currentExpanded={currentExpanded} params={params} selected={item} setExpanded={setCurrentExpanded} />;
      case 'comming-soon':
        return <ComingSoon currentExpanded={currentExpanded} selected={item} />;
      default:
        return (
          currentExpanded === ''
            ? (
              <div className="Widget">
                <div className="widget-header">
                  <div className="widget-sub-header">
                    {item?.label ? <SvgLoader width={17} height={34} svgName="widget-header-icon" /> : null}
                    <div className="header-text">{item?.label}</div>
                  </div>
                </div>
              </div>
            ) : null
        );
    }
  };

  return (
    <div className="widget-container">
      {exploreWidgets.map((itm) => {
        if (itm.type === 'newsletter' && process.env.type !== 'development') {
          return null;
        }
        return (
          <div className={`graph-card ${itm.type} ${currentExpanded === '' ? itm.size : ''}`} key={itm.type}>
            {itm?.label
              ? renderGraphs(itm)
              : currentExpanded === '' ? (
                <div className="Widget comming-soon">
                  <div className="coming-soon">
                    {/* <div>Coming Soon</div> */}
                  </div>
                </div>
              ) : null}
          </div>
        );
      })}
    </div>
  );
}

ExploreAnatyticsContainer.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default ExploreAnatyticsContainer;
