import requestDemoReducer from './ModalRequestDemo/logic';
import getTerminalsReducer from './Selection/logic';
import checkUserReducer, { registerUserReducer } from './TryOntosightModal/logics';
import addUserConcentReducer from './Declarations/logic';
import getQuestionsReducer, { addPreferencesReducer } from './Persona/logic';
import { isLoginModalOpenReducer, verifyOTPReducer } from './LoginModalV2/logic';
import getLicenceReducer from './Main/logic';
import { updateUserStatusReducer } from './Admin/logic';
import requestOtpReducer from './LoginModal/logics';
import addRolesReducer, { removeRolesReducer } from './AssignRemoveRoles/logic';

const rootAppReducer = ({
  statusRequestDemo: requestDemoReducer,
  terminals: getTerminalsReducer,
  checkUser: checkUserReducer,
  registerUserStatus: registerUserReducer,
  userConcentStatus: addUserConcentReducer,
  questions: getQuestionsReducer,
  preferencesStatus: addPreferencesReducer,
  isLoginModalOpen: isLoginModalOpenReducer,
  getLicence: getLicenceReducer,
  userStatusUpdate: updateUserStatusReducer,
  requestOtpStatus: requestOtpReducer,
  verifyOTPSTatus: verifyOTPReducer,
  addRoleStatus: addRolesReducer,
  removeRolesStatus: removeRolesReducer,
});

export default rootAppReducer;
