import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_INTERVENTION_METER_DATA = 'GET_INTERVENTION_METER_DATA';
const GET_INTERVENTION_METER_DATA_SUCCESS = 'GET_INTERVENTION_METER_DATA_SUCCESS';
const GET_INTERVENTION_METER_DATA_FAILURE = 'GET_INTERVENTION_METER_DATA_FAILURE';
const GET_INTERVENTION_METER_DATA_REFRESH = 'GET_INTERVENTION_METER_DATA_REFRESH';

export const getInterventionMeterAction = createAction(GET_INTERVENTION_METER_DATA);

const getInterventionMeterSuccess = createAction(GET_INTERVENTION_METER_DATA_SUCCESS);

const getInterventionMeterFaliure = createAction(GET_INTERVENTION_METER_DATA_FAILURE);

export const getInterventionMeterRefreshAction = createAction(GET_INTERVENTION_METER_DATA_REFRESH);

export const getInterventionMeterEpic = (actions$) => actions$.pipe(
  ofType(GET_INTERVENTION_METER_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/clinical-trials/v0/search/getInterventionStatistics?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getInterventionMeterSuccess(res)),
    catchError((err) => of(getInterventionMeterFaliure(err))),
  )),
);

const ongetInterventionMeterAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetInterventionMeterSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetInterventionMeterFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetInterventionMeterRefresh = (state) => ({
  ...state,
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getInterventionMeterReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getInterventionMeterAction, (state) => ongetInterventionMeterAction(state))
      .addCase(getInterventionMeterSuccess, (state, action) => ongetInterventionMeterSuccess(state, action.payload))
      .addCase(getInterventionMeterFaliure, (state) => ongetInterventionMeterFailure(state))
      .addCase(getInterventionMeterRefreshAction, (state) => ongetInterventionMeterRefresh(state))
      .addDefaultCase((state) => state);
  },
);

export default getInterventionMeterReducer;
