import React, { useState } from 'react';
import LandingPageHeader from '../../container/LandingPageHeader';
import IntroSection from '../../components/IntroSection';
import KnowledgeArea from '../../components/KnowledgeArea';
import TryNowOntosight from '../../components/TryNowOntosight';
import ContactDetails from '../../components/ContactDetails';

function LandingPageV2() {
  const [opendTryOntosightModal, setTryOntosightModal] = useState(false);

  return (
    <div className="landing-page-ctr">
      <div className="make-uniform">
        <LandingPageHeader opendTryOntosightModal={opendTryOntosightModal} setTryOntosightModal={setTryOntosightModal} />
        <IntroSection setTryOpen={setTryOntosightModal} />
      </div>
      <KnowledgeArea />
      <TryNowOntosight setTryOpen={setTryOntosightModal} />
      <ContactDetails />
    </div>
  );
}

export default LandingPageV2;
