/*eslint-disable */
import { React, useState, useEffect } from "react";
import { Popover, Input, Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createProjectAction,
  createProjectRefreshAction,
  getProjectsAction,
} from "../WorkSpace/logic";
import {
  createBookmarkAction,
  createBookmarkRefreshAction,
} from "../Bookmark/logic";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import { sendNotification } from "../../components/Notification/logic";
import { getCookie } from "../../../utils";

function Bookmark({
  docId,
  setActiveBookmarkId,
  onClose,
  type,
  bookmarkId,
  setBookmarkid,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const createProject = useSelector((state) => state.createProject);
  const projectsData = useSelector((state) => state.projectsData);
  const createBookmark = useSelector((state) => state.createBookmark);
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [inputText, setInputText] = useState("");
  const [openProjectOptions, setOpenProjectOptions] = useState(false);
  const [openRenameProject, setOpenRenameProject] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [checkedBookmarks, setCheckedBookmarks] = useState([]);
  const [inputError, setInputError] = useState("");

  const projId=getCookie("projectId")

  const handleChange = (value) => {
    setInputText(value);
    if (value.length > 3) {
      setInputError("");
    } else {
      setInputError("Please enter more than 3 characters");
    }
  };
  const handleClick = () => {
    dispatch(
      createProjectAction({
        body: {
          project_name: inputText,
        },
      })
    );
    setOpenCreateProject(!openCreateProject);
  };

  useEffect(() => {
    if (createProject.flag) {
      // toast(`${inputText} Project created successfully`, {
      //     position: 'top-center',
      //     autoClose: 2000,
      //     pauseOnHover: false,
      //     draggable: true,
      //     newestOnTop: true,
      //     type: 'success',
      //     hideProgressBar: false,
      //     closeButton: true,
      //     theme: "dark",
      // });
      dispatch(
        sendNotification({
          type: "PROJECT_CREATED_SUCCESS",
          notificationType: "success",
          message: "Project created successfully",
          showNotification: true,
        })
      );
      dispatch(createProjectRefreshAction());
      apiCall();
    }
  }, [JSON.stringify(createProject)]);

  const apiCall = () => {
    dispatch(
      getProjectsAction({
        params: {
          doc_id: docId,
        },
      })
    );
  };

  useEffect(() => {
    apiCall();
    dispatch(createBookmarkRefreshAction());
  }, []);

  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(
        sendNotification({
          type: "BOOKMARK_CREATED_SUCCESS",
          notificationType: "success",
          message: "Bookmark created successfully",
          showNotification: true,
        })
      );
      dispatch(createBookmarkRefreshAction({}));
    }
  }, [createBookmark.flag]);


  const renderRenameProject = (data) => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> Rename Folder</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => setOpenRenameProject(!openRenameProject)}
        />
      </div>
      <div className="project-content">
        <Input onChange={(e) => handleChange(e.target.value)} />
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => setOpenRenameProject(!openRenameProject)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={() => handleRenameClick(inputText, data)}
        >
          Rename
        </Button>
      </div>
    </div>
  );

  const renderProjectOptions = (data) => (
    <div className="project-options-section">
      <Popover
        overlayClassName="create-proj-popover"
        placement="bottomLeft"
        open={openRenameProject}
        content={renderRenameProject(data)}
      >
        <div
          className="project-options"
          role="presentation"
          onClick={() => setOpenRenameProject(!openRenameProject)}
        >
          <div className="rename-btn" />
          <div className="rename">Rename</div>
        </div>
      </Popover>
      {/* <div className="project-options">
        <div className="rename-btn" />
        <div className="rename">Rename</div>
      </div> */}
      <div className="project-options">
        <div className="archive-btn" />
        <div className="archive">Archive</div>
      </div>
    </div>
  );
  const renderCreateProject = () => (
    <div className="create-project-sec">
      <div className="project-header">
        <div className="project-title"> New project</div>
        <div
          className="close-icon"
          role="presentation"
          onClick={() => setOpenCreateProject(!openCreateProject)}
        />
      </div>
      <div className="project-content">
        <Input onChange={(e) => handleChange(e.target.value)} />
        {inputError && <div style={{ color: "red" }}>{inputError}</div>}
      </div>
      <div className="btn-section">
        <Button
          type="primary"
          className="cancel-btn"
          onClick={() => setOpenCreateProject(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          role="presentation"
          onClick={handleClick}
          disabled={inputText.length <= 3}
        >
          Create
        </Button>
      </div>
    </div>
  );

  const handleCheckboxChange = (projectId) => {
    setCheckedBookmarks((prevSelected) => {
      if (prevSelected.includes(projectId)) {
        return prevSelected.filter((id) => id !== projectId);
      } else {
        return [...prevSelected, projectId];
      }
    });
  };

  const handleOk = (selectedProjects) => {
    if(selectedProjects.includes(projId)){
        setBookmarkid([...bookmarkId, docId]);

    }
    dispatch(
      createBookmarkAction({
        body: {
          project_id: selectedProjects,
          doc_id: docId,
          asset_class: type,
        },
      })
    );
    onClose();
  };
  const handleCancel = () => {
    // setOpenCreateProject(false);
    // setInputText('');
    onClose();
  };

  const handleOptionClick = (idx) => {
    setOpenProjectOptions(!openProjectOptions);
  };
  return (
    <div className="bookmark">
      <div className="bookmark-container">
        <div className="bookmark-title">Add to my bookmark</div>
        <Popover
          overlayClassName="create-proj-popover"
          placement="bottomRight"
          open={openCreateProject}
          content={renderCreateProject()}
        >
          <div
            className="create-project"
            role="presentation"
            onClick={() => setOpenCreateProject(!openCreateProject)}
          >
            <div className="add-project" />
            <div className="project-name">Create New Project</div>
          </div>
        </Popover>
      </div>
      {/* <Loader loading={projectsData.loading}>
                <div className="analytics-container-content-sidebar-link--child">
                    {projectsData?.data?.map((i, contentIndex) => (
                        <div key={i.id} className="analytics-container-content-sidebar-link">
                            <div className="folder-icon" />
                            <div className='project-content'>
                                <div className="analytics-container-content-sidebar-link-title">
                                    <div className="analytics-container-content-sidebar-link-title-main">{i?.projectName}</div>
                                </div>
                                <Checkbox onChange={() => handleCheckboxChange(i?.projectId)} checked={checkedBookmarks.includes(i?.projectId)} />
                              
                            </div>



                        </div>
                    ))}
                </div>
                <div className="analytics-container-content-sidebar-link-footer">
                <Button type="primary" className="cancel-btn" onClick={() => handleCancel()}>Cancel</Button>

                    <Button type="primary" className="ok-btn" onClick={() => handleOk(checkedBookmarks)} disabled={checkedBookmarks.length === 0}>Ok</Button>
                </div>
            </Loader> */}
      <Loader loading={projectsData.loading}>
        <div className="analytics-container-content-sidebar-link--child">
          {projectsData?.data
            ?.filter((project) => project.projectName !== "Shared Documents")
            .map((i, contentIndex) => (
              <div
                key={i.id}
                className="analytics-container-content-sidebar-link"
                onClick={() => handleCheckboxChange(i?.projectId)}
              >
                <div className="folder-icon" />
                <div className="project-content">
                  <div className="analytics-container-content-sidebar-link-title">
                    <div className="analytics-container-content-sidebar-link-title-main">
                      {i?.projectName}
                    </div>
                  </div>
                  <Checkbox
                    // onChange={() => handleCheckboxChange(i?.projectId)}
                    checked={checkedBookmarks.includes(i?.projectId)}
                  />
                  {/* <Popover overlayClassName="proj-options-popover" placement="bottom" open={openProjectOptions} content={renderProjectOptions(i)}>
            <div className="three-dots-icon" role="presentation" onClick={() => handleOptionClick(i)}>
            </div>
          </Popover> */}
                </div>
              </div>
            ))}
        </div>
        <div className="analytics-container-content-sidebar-link-footer">
          <Button
            type="primary"
            className="cancel-btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="ok-btn"
            onClick={() => handleOk(checkedBookmarks)}
            disabled={checkedBookmarks.length === 0}
          >
            Ok
          </Button>
        </div>
      </Loader>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Bookmark;
