import React from 'react';
import Header from '../../../common/components/Header';
import InterventionSearch from '../../container/InterventionSearch';

function InterventionLandingPage() {
  return (
    <div className="main-page">
      <Header className="header-home" />
      <div className="home-bg-img">
        <div className="blue-bg-eclips-main" />
        <div className="blue-bg-eclips-left" />
        <div className="blue-bg-eclips-right" />
        <div className="blue-bg-eclips-left-bottom" />
        <div className="blue-bg-eclips-right-bottom" />
      </div>
      <InterventionSearch terminalName="Intervention" />
    </div>
  );
}

export default InterventionLandingPage;
