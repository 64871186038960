const knowledgeAreas = [
  {
    title: 'Pharmacological Research',
    context: 'Harness AI-driven insights from publications, clinical trials, patents, grants, and more diverse data sources.',
    key: 'research',
    svgName: 'research-logo',
  },
  {
    title: 'Clinical Trials',
    context: 'Discover clinical landscapes with insights from global trials on endpoints, success rates, and top interventions.',
    key: 'clinical',
    svgName: 'ct-logo',
  },
  {
    title: 'Medical Affairs',
    context: 'Understand every detail of medical conditions of your interest using carefully vetted scientific literature',
    key: 'affairs',
    svgName: 'affairs-logo',
  },
  {
    title: 'Established Products',
    context: 'Comprehensive view of interventions across therapeutic areas, indications, trials, competition, and patents.',
    key: 'products',
    svgName: 'products-logo',
  },
];

export default knowledgeAreas;
