import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_FIELD_VALUES = 'GET_FIELD_VALUES';
const GET_FIELD_VALUES_SUCCESS = 'GET_FIELD_VALUES_SUCCESS';
const GET_FIELD_VALUES_FAILURE = 'GET_FIELD_VALUES_FAILURE';

export const getFieldValuesAction = createAction(GET_FIELD_VALUES);

const getFieldValuesSuccess = createAction(GET_FIELD_VALUES_SUCCESS);

const getFieldValuesFaliure = createAction(GET_FIELD_VALUES_FAILURE);

export const getfieldValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_FIELD_VALUES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/intervention/v0/search/getFieldValues?${action.payload.params}`, 'get', {}, false, action.payload.headers)).pipe(
    map((res) => getFieldValuesSuccess(res)),
    catchError((err) => of(getFieldValuesFaliure(err))),
  )),
);

const ongetFieldValuesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetFieldValuesSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetFieldValuesFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const getFieldValuesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getFieldValuesAction, (state) => ongetFieldValuesAction(state))
      .addCase(getFieldValuesSuccess, (state, action) => ongetFieldValuesSuccess(state, action.payload))
      .addCase(getFieldValuesFaliure, (state) => ongetFieldValuesFaliure(state))
      .addDefaultCase((state) => state);
  },
);

export default getFieldValuesReducer;
