import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get Partner details
const GET_TERMINALS = 'GET_TERMINALS';
const GET_TERMINALS_SUCCESS = 'GET_TERMINALS_SUCCESS';
const GET_TERMINALS_FAILURE = 'GET_TERMINALS_FAILURE';

export const getTerminalsAction = createAction(GET_TERMINALS);

const getTerminalsSuccess = createAction(GET_TERMINALS_SUCCESS);

const getTerminalFaliure = createAction(GET_TERMINALS_FAILURE);

export const getTerminalsEpic = (actions$) => actions$.pipe(
  ofType(GET_TERMINALS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/terminals/getTerminal?${action.payload.headers}`, 'get')).pipe(
    map((res) => getTerminalsSuccess(res)),
    catchError((err) => of(getTerminalFaliure(err))),
  )),
);

const getTerminalsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getTerminalsAction, (state) => {
      state.loading = true;
    })
      .addCase(getTerminalsSuccess, (state, action) => {
        state.data = [...action.payload.response.finalTerminals];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getTerminalFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default getTerminalsReducer;
