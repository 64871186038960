import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
const ARCHIVE_PROJECT_SUCCESS = 'ARCHIVE_PROJECT_SUCCESS';
const ARCHIVE_PROJECT_FAILURE = 'ARCHIVE_PROJECT_FAILURE';
const ARCHIVE_PROJECTS_REFRESH = 'ARCHIVE_PROJECTS_REFRESH';

export const archiveProjectAction = createAction(ARCHIVE_PROJECT);

const archiveProjectSuccess = createAction(ARCHIVE_PROJECT_SUCCESS);

const archiveProjectFaliure = createAction(ARCHIVE_PROJECT_FAILURE);

export const archiveProjectRefreshAction = createAction(ARCHIVE_PROJECTS_REFRESH);

export const archiveProjectEpic = (actions$) => actions$.pipe(
  ofType(ARCHIVE_PROJECT),
  // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/archiveProject', 'PATCH', action.payload.params)).pipe(
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/archiveProject`, 'PATCH', action.payload.params)).pipe(

    map((res) => archiveProjectSuccess(res)),
    catchError((err) => of(archiveProjectFaliure(err))),
  )),
);

const onArchiveProjectAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onArchiveProjectSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const onArchiveProjectFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onArchiveProjectRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const archiveProjectReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(archiveProjectAction, (state) => onArchiveProjectAction(state))
      .addCase(archiveProjectSuccess, (state, action) => onArchiveProjectSuccess(state, action.payload))
      .addCase(archiveProjectFaliure, (state, action) => onArchiveProjectFaliure(state, action.payload))
      .addCase(archiveProjectRefreshAction, () => onArchiveProjectRefresh())
      .addDefaultCase((state) => state);
  },
);

export { archiveProjectReducer };
