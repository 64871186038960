import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_GRANT_DATA = 'GET_GRANT_DATA';
const GET_GRANT_DATA_SUCCESS = 'GET_GRANT_DATA_SUCCESS';
const GET_GRANT_DATA_FAILURE = 'GET_GRANT_DATA_FAILURE';

export const getGrantDataAction = createAction(GET_GRANT_DATA);

const getGrantDataSuccess = createAction(GET_GRANT_DATA_SUCCESS);

const getGrantDataFaliure = createAction(GET_GRANT_DATA_FAILURE);

export const getGrantDataEpic = (actions$) => actions$.pipe(
  ofType(GET_GRANT_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/meter?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getGrantDataSuccess(res)),
    catchError((err) => of(getGrantDataFaliure(err))),
  )),
);

const ongetGrantDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetGrantDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetGrantDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getGrantDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getGrantDataAction, (state) => ongetGrantDataAction(state))
      .addCase(getGrantDataSuccess, (state, action) => ongetGrantDataSuccess(state, action.payload))
      .addCase(getGrantDataFaliure, (state) => ongetGrantDataFailure(state))
      .addDefaultCase((state) => state);
  },
);
