/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import Panel from '../Panel';
import SvgLoader from '../SvgLoader';
import { getTerminalName, modifyDataForDropdown } from '../../../utils';
import { getTypesValuesAction, updateCurrentDropdownAction } from '../../container/PowerSearchBox/logic';
import { goBtnZIndexUpdateAction, updateFilterAction } from '../../container/PowerSearch/logic';
import Mixpanel from '../../../utils/mixpanel';

function PsDropdown({
  setShow, className, selectedValue, id, apiUrl,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const currentDropdown = useSelector((state) => state.currentDropdown);
  const [options, setOptions] = useState([]);
  const query = searchParams.get('query');
  const divEl = useRef();

  useEffect(() => {
    dispatch(goBtnZIndexUpdateAction(isOpen ? -1 : 0));
  }, [isOpen]);

  const optionValues = useSelector((state) => state.optionValues);

  useEffect(() => {
    if (query) {
      const q = JSON.parse(query);
      q.forEach((item) => {
        if (item.name === 'gender') {
          setSelected(item.currentGender[0]);
        } else if (item.name === selectedValue) {
          setSelected(...item.currentKeys[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (optionValues.flag && optionValues.fieldName === selectedValue) {
      const tempOptions = modifyDataForDropdown(optionValues.data, 'key', 'value');
      setOptions([...tempOptions]);
    }
  }, [JSON.stringify(optionValues)]);

  useEffect(() => {
    if (options.length && id === currentDropdown.id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [JSON.stringify(options), currentDropdown]);

  useEffect(() => {
    Mixpanel.track('getFieldsValueEvent', {
      action: 'get Fields Value',
      view: 'CT power search',
      fieldName: selectedValue,
      fieldType: 'dropdown',
    });
    const request = {
      apiUrl,
      params: queryString.stringify({
        field_name: selectedValue,
        terminal_name: 'clinical_trials',
      }),
      headers: {
        Authorization: '2e1f8a9b7c4d6e0f8d1c2a3e4b5f6a7c8d9e0f1a2b3c4d5e6f7g8h9i0j1k2l3m4n5',
      },
    };
    dispatch(getTypesValuesAction(request));
  }, []);

  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }

      if (!divEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const handleClick = () => {
    if (setShow) {
      setShow('power');
    }
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedValue === 'gender') {
      setSelected(option.key);
      setIsOpen(false);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [option.value], currentKeys: [option.value], currentGender: [option.key] },
        id,
      }));
    } else {
      setSelected(option.value);
      setIsOpen(false);
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [option.value] },
        id,
      }));
    }
  };

  const handleMounseEnter = () => {
    Mixpanel.track('search_box_tap_event', {
      action: 'user taped on search box to fill data for power search',
      field_name: selectedValue,
      terminal_name: getTerminalName(location),
      page: 'Search Page',
    });
    dispatch(updateCurrentDropdownAction(id));
  };

  const renderedOptions = options.map((option) => (
    <div
      className={`options ${className[2]}`}
      aria-hidden
      onClick={() => handleOptionClick(option)}
      key={option.value}
    >
      {option.label}
    </div>
  ));

  return (
    <div
      aria-hidden
      ref={divEl}
      className="dropdown"
      onClick={() => handleMounseEnter()}
    >
      <Panel
        onClick={handleClick}
        className={`panel-dropdown ${[className[0]]}`}
      >
        {options.length
          ? (
            <>
              {selected || 'Select...'}
              <SvgLoader width={24} height={24} svgName="down-arrow" />
            </>
          )
          : null}

      </Panel>
      {isOpen && <Panel className={`panel ${className[1]}`}>{renderedOptions}</Panel>}
    </div>
  );
}

PsDropdown.propTypes = {
  value: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  setShow: PropTypes.func,
  className: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  apiUrl: PropTypes.string.isRequired,
};

PsDropdown.defaultProps = {
  setShow: () => null,
};

export default PsDropdown;
