import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Link, animateScroll, scroller } from 'react-scroll';
import sideOptions from './data';
import SvgLoader from '../../../common/components/SvgLoader';
import Mixpanel from '../../../utils/mixpanel';

const options = {
  duration: 500,
  smooth: true,
};
function DeepDiveSideBar({
  selectedOption, setSelectedOption, isSideBarClicked, redirectTo,
}) {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const handleMouseEnter = () => {
    isSideBarClicked.current = true;
  };

  useEffect(() => {
    setSelectedOption(redirectTo);
    animateScroll.scrollTo(500, options);
    scroller.scrollTo(redirectTo, {
      duration: 0,
      smooth: true,
      containerId: 'containerElement',
    });
  }, [redirectTo]);

  return (
    <div className="deepdive-sidebar" onMouseEnter={() => handleMouseEnter()}>
      <div>
        {sideOptions.map((item) => (
          <Link
            spy
            smooth
            offset={-70}
            duration={500}
            to={item.value}
            containerId="containerElement"
            className="sidebar-link"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedOption(item.value);
              Mixpanel.track('sideBarOptionsClickEvent', {
                action: 'side bar option click for Deep Dive of specific field',
                view: `Results page with ${semanticQuery ? 'semantic search query' : 'power search query'}`,
                clickedField: item.value,
                query: JSON.parse(semanticQuery) || JSON.parse(query),
              });
            }}
            ignoreCancelEvents
            key={item.value}
            isDynamic
          >
            <div
              className={`sidebar-option ${selectedOption === item.value ? 'active' : ''}`}
              role="presentation"
              id={item.value}
            >
              <SvgLoader className="sidebar-icons" width={17.65} height={19} svgName={item.icon} />
              <span className="sidebar-name">{item.display_name}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

DeepDiveSideBar.propTypes = {
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  isSideBarClicked: PropTypes.instanceOf(Object).isRequired,
};

export default DeepDiveSideBar;
