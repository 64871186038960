/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import SvgLoader from '../SvgLoader';
import Mixpanel from '../../../utils/mixpanel';
import { getCookie, setCookie, trackOutSideEvents } from '../../../utils';
import ProfileModal from '../ProfileModal';
import ResultViewTags from '../ResultViewTags';
import { persistor } from '../../../store';
import { removeSession } from '../../container/Status/logic';
import { getProjectsAction, createProjectAction } from '../../container/WorkSpace/logic';
import HeaderProjectDropdown from '../../container/HeaderProjectDropdown';
import Loader from '../Loader';

function Header({
  className = '', isResultsPage = false, defaultView = false, type = 'none', reqBody, accesspage = false,
}) {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const profileRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const projectsData = useSelector((state) => state.projectsData);
  const createProject = useSelector((state) => state.createProject);
  const [openProjectDropdown, setOpenProjectDropdown] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');

  useEffect(() => {
    trackOutSideEvents(profileRef, () => setShowProfileModal(false));
    
    setSelectedProjectName(getCookie('projectName') ?? '');
    setSelectedProjectId(getCookie('projectId') ?? '');
  }, []);

  useEffect(
    () => {
      
      if (getCookie('accessToken')) {
        if(!getCookie('projectId') || !getCookie('sharedDocuments')) {
        
        if (projectsData.flag ) {
          let check = false, checkDefault = false;
          projectsData?.data.forEach((project) => {
            if (project.projectName === 'Default' && !getCookie('projectId')) {
              if(selectedProjectId === '') {
                setSelectedProjectId(project.projectId);
                setSelectedProjectName(project.projectName);
              }
              setCookie(project.projectId, 'projectId');
              setCookie(project.projectName, 'projectName');
            }
            if (project.projectName === 'Shared Documents') {
              check = true;
            }
            if (project.projectName === 'Default') {
              checkDefault = true;
            }
          });
          if (!check && !getCookie('sharedDocuments')) {
            dispatch(createProjectAction(
              {
                body: {
                  project_name: 'Shared Documents',
                },
              },
            ));
          }
          if(check && !getCookie('sharedDocuments')) {
            setCookie(true, 'sharedDocuments');
          }
          if (!checkDefault && !getCookie('projectId')) {
            dispatch(createProjectAction(
              {
                body: {
                  project_name: 'Default',
                },
              },
            ));
          }
        }
        // if (projectsData.flag) {
        //   let check = false;
        //   projectsData?.data.forEach((project) => {
        //     if (project.projectName === 'Shared Documents') {
        //       check = true;
        //     }
        //   });

        //   if (!check && !getCookie('sharedDocuments')) {
        //     dispatch(createProjectAction(
        //       {
        //         body: {
        //           project_name: 'Shared Documents',
        //         },
        //       },
        //     ));
        //   }
        // }
      }
    }
    },
    [JSON.stringify(projectsData)],

  );

  useEffect(() => {
    if (getCookie('accessToken') && !getCookie('projectId')) {
      dispatch(getProjectsAction(
        {
          params: {
            doc_id: '',
          },
        },
      ));
    }
  }, [JSON.stringify(createProject)]);

  useEffect(() => {
    if (createProject.flag && !getCookie('sharedDocuments')) {
      setCookie(true, 'sharedDocuments');
    }
  }, [JSON.stringify(createProject)]);

  const onProjectChange = (projectId, projectName) => {
    setSelectedProjectId(projectId);
    setCookie(projectId, 'projectId');
    setSelectedProjectName(projectName);
    setCookie(projectName, 'projectName');
  };

  const classes = classNames(className);
  const handleLogoclick = () => {
    Mixpanel.track('terminalLogoClickEvent', {
      action: 'Terminal Logo Clicked, go to landing page triggered',
      from: window.location.pathname,
    });
    persistor.purge();
  };

  const logo = {
    width: isResultsPage || type === 'light' ? 120 : 216,
    height: isResultsPage || type === 'light' ? 40 : 70,
  };

  const getInitials = () => {
    if (getCookie('fullName')) {
      const fullName = getCookie('fullName');
      let initials = '';
      initials += fullName[0].toUpperCase();
      return initials;
    }
    return false;
  };
  const renderWorkSpace = () => {
    navigate('/workspace');
    navigate(0);
  };
  const handleHomeClick = () => {
    navigate('/home');
    navigate(0);
  };

  return (
    <div className={classes}>
      <div className="header-start">
        {(isResultsPage || ['/clinicalTrials', '/explore', '/intervention', '/admin', '/userPolicy', '/privacyPolicy', '/workspace'].includes(location.pathname))
          ? (
            <Link to="/">
              <SvgLoader onClick={handleLogoclick} width={logo.width} height={logo.height} svgName={`${type === 'light' ? 'ontosight-logo-dark' : 'ontosight-logo-terminal'}`} className={`${isResultsPage || type === 'light' ? 'results-logo' : ''}`} />
            </Link>
          )
          : (
            <Link to="/">
              <SvgLoader width={logo.width} height={logo.height} svgName="ontosight-new-svg" />
            </Link>
          )}
        {isResultsPage && !defaultView ? (
          <div className="header-container">
            <div className={`${type === 'light' ? 'header-terminal-name-light' : 'header-terminal-name"'}`} title={location.pathname.includes('explore') ? 'Search & Explore Terminal' : 'Clinical Trials Terminal'}>
              {location.pathname.includes('explore') ? 'Search & Explore' : location.pathname.includes('intervention') ? 'Intervention' : 'Clinical Trials'}
            </div>
            <div className="header-line-divider" style={{ display: type === 'light' ? 'none' : 'block' }} />
          </div>
        )
          : null}
        {
          isResultsPage && !defaultView ? <ResultViewTags reqBody={reqBody} /> : null
        }
      </div>
      <div className="header-end">
        {!(location.pathname.includes('/home') || location.pathname.includes('/admin')) ? (<Button className="home-btn" onClick={() => handleHomeClick()}>Home</Button>) : (null)}

        {getCookie('accessToken') && !(location.pathname.includes('/workspace') || location.pathname.includes('/admin') || location.pathname.includes('/privacyPolicy') || location.pathname.includes('/userPolicy')|| location.pathname.includes('/contact')) ? (<Button className="workspace-btn" onClick={() => renderWorkSpace()}>Projects</Button>) : (null)}
        {getCookie('accessToken') && !(location.pathname.includes('/workspace') || location.pathname.includes('/admin') || location.pathname.includes('/privacyPolicy') || location.pathname.includes('/userPolicy')|| location.pathname.includes('/contact')) ? (
          <div className="project-dropdown">
            {getCookie('accessToken') && <div className='active-project' >Active Project</div>}
            <div className="selected-project" onClick={() => { setOpenProjectDropdown(!openProjectDropdown); }} role="presentation">{selectedProjectName}</div>
            { openProjectDropdown && <HeaderProjectDropdown projectId={selectedProjectId} projectName={selectedProjectName} onProjectChange={onProjectChange} setOpenProjectDropdown={setOpenProjectDropdown} />}

          </div>
        ) : (null)}

        {
          getCookie('accessToken') && !defaultView
            ? (
              <div className="log-menu">
                <div className="log-menu-profile">
                  <div aria-hidden onClick={() => setShowProfileModal(true)} className="user-name-initials"><span className="user">{getInitials()}</span></div>
                </div>
                {showProfileModal
                  && (
                    <div className="log-menu-links" ref={profileRef}>
                      <ProfileModal />
                    </div>
                  )}
              </div>
            )
            : null
        }

        {accesspage && <div aria-hidden onClick={() => dispatch(removeSession())} className="access-logout"><div className="login-btn">Logout</div></div>}
      </div>
    </div>
  );
}

export default Header;
