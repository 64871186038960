import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_PREVIEW_SUMMARY_DATA = 'GET_PREVIEW_SUMMARY_DATA';
const GET_PREVIEW_SUMMARY_DATA_SUCCESS = 'GET_PREVIEW_SUMMARY_DATA_SUCCESS';
const GET_PREVIEW_SUMMARY_DATA_FAILURE = 'GET_PREVIEW_SUMMARY_DATA_FAILURE';
const GET_PREVIEW_SUMMARY_DATA_REFRESH = 'GET_PREVIEW_SUMMARY_DATA_REFRESH';

export const getSummaryDataAction = createAction(GET_PREVIEW_SUMMARY_DATA);

const getSummaryDataSuccess = createAction(GET_PREVIEW_SUMMARY_DATA_SUCCESS);

const getSummaryDataFaliure = createAction(GET_PREVIEW_SUMMARY_DATA_FAILURE);

export const getSummaryDataRefreshAction = createAction(GET_PREVIEW_SUMMARY_DATA_REFRESH);

export const getSummaryDataEpic = (actions$) => actions$.pipe(
  ofType(GET_PREVIEW_SUMMARY_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'GET')).pipe(
    map((res) => getSummaryDataSuccess(res)),
    catchError((err) => of(getSummaryDataFaliure(err))),
  )),
);

const ongetSummaryDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSummaryDataSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  flag: true,
});

const ongetSummaryDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetSummaryDataRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

const getSummaryDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSummaryDataAction, (state) => ongetSummaryDataAction(state))
      .addCase(getSummaryDataSuccess, (state, action) => ongetSummaryDataSuccess(state, action.payload))
      .addCase(getSummaryDataFaliure, (state) => ongetSummaryDataFailure(state))
      .addCase(getSummaryDataRefreshAction, () => ongetSummaryDataRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default getSummaryDataReducer;
