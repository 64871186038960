import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_EXPLORE_SEARCH_RESULSTS = 'GET_EXPLORE_SEARCH_RESULSTS';
const GET_EXPLORE_SEARCH_RESULSTS_SUCCESS = 'GET_EXPLORE_SEARCH_RESULSTS_SUCCESS';
const GET_EXPLORE_SEARCH_RESULSTS_FAILURE = 'GET_EXPLORE_SEARCH_RESULSTS_FAILURE';
const GET_MORE_EXPLORE_SEARCH_RESULSTS = 'GET_MORE_EXPLORE_SEARCH_RESULSTS';
const GET_MORE_EXPLORE_SEARCH_RESULSTS_SUCCESS = 'GET_MORE_EXPLORE_SEARCH_RESULSTS_SUCCESS';
const GET_EXPLORE_SEARCH_RESULSTS_REFRESH = 'GET_EXPLORE_SEARCH_RESULSTS_REFRESH';

export const getExploreSearchResultsAction = createAction(GET_EXPLORE_SEARCH_RESULSTS);

export const getMoreExploreSearchResultsAction = createAction(GET_MORE_EXPLORE_SEARCH_RESULSTS);

const getExploreSearchResultsSuccess = createAction(GET_EXPLORE_SEARCH_RESULSTS_SUCCESS);

const getMoreExploreSearchResultsSuccess = createAction(GET_MORE_EXPLORE_SEARCH_RESULSTS_SUCCESS);

const getExploreSearchResultsFaliure = createAction(GET_EXPLORE_SEARCH_RESULSTS_FAILURE);

export const getExploreSearchResultsRefreshAction = createAction(GET_EXPLORE_SEARCH_RESULSTS_REFRESH);

export const getExploreSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPLORE_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getExploreSearchResultsSuccess(res)),
    catchError((err) => of(getExploreSearchResultsFaliure(err))),
  )),
);

export const getMoreExploreSearchResultsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_EXPLORE_SEARCH_RESULSTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreExploreSearchResultsSuccess(res)),
    catchError((err) => of(getExploreSearchResultsFaliure(err))),
  )),
);

const onGetExploreSearchResultsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetMoreExploreSearchResultsAction = (state) => ({
  ...state.data,
  loading: false,
  error: false,
});

const onGetSearchResultsSuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
    error: false,
  });

const onGetMoreSearchResultsSuccess = (state, { response }) => (
  {
    data: {
      total: response?.total,
      data: [
        ...state.data,
        ...response.data,
      ],
    },
    loading: false,
    flag: true,
    error: false,
  });

const onGetSearchResultsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onExploreSearchResultRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getExploreSearchResultsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExploreSearchResultsAction, (state) => onGetExploreSearchResultsAction(state))
      .addCase(getExploreSearchResultsSuccess, (state, action) => onGetSearchResultsSuccess(state, action.payload))
      .addCase(getExploreSearchResultsFaliure, (state) => onGetSearchResultsFailure(state))
      .addCase(getMoreExploreSearchResultsAction, (state) => onGetMoreExploreSearchResultsAction(state))
      .addCase(getMoreExploreSearchResultsSuccess, (state, action) => onGetMoreSearchResultsSuccess(state, action.payload))
      .addCase(getExploreSearchResultsRefreshAction, () => onExploreSearchResultRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const GET_EXPLORE_SEARCH_SUMMARY = 'GET_EXPLORE_SEARCH_SUMMARY';
const GET_EXPLORE_SEARCH_SUMMARY_SUCCESS = 'GET_EXPLORE_SEARCH_SUMMARY_SUCCESS';
const GET_EXPLORE_SEARCH_SUMMARY_FAILURE = 'GET_EXPLORE_SEARCH_SUMMARY_FAILURE';

export const getExploreSearchSummaryAction = createAction(GET_EXPLORE_SEARCH_SUMMARY);

const getExploreSearchSummarySuccess = createAction(GET_EXPLORE_SEARCH_SUMMARY_SUCCESS);

const getExploreSearchSummaryFaliure = createAction(GET_EXPLORE_SEARCH_SUMMARY_FAILURE);

const onGetExploreSearchSummaryAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onGetSearchSummarySuccess = (state, { response }) => (
  {
    ...state,
    data: { ...response },
    loading: false,
    flag: true,
  });

const onGetSearchSummaryFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getExploreSearchSummaryEpic = (actions$) => actions$.pipe(
  ofType(GET_EXPLORE_SEARCH_SUMMARY),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.params.url}?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getExploreSearchSummarySuccess(res)),
    catchError((err) => of(getExploreSearchSummaryFaliure(err))),
  )),
);

export const getExploreSearchSummaryReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getExploreSearchSummaryAction, (state) => onGetExploreSearchSummaryAction(state))
      .addCase(getExploreSearchSummarySuccess, (state, action) => onGetSearchSummarySuccess(state, action.payload))
      .addCase(getExploreSearchSummaryFaliure, (state) => onGetSearchSummaryFailure(state))
      .addDefaultCase((state) => state);
  },
);
