import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_QUESTIONS = 'GET_QUESTIONS';
const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';
const GET_QUESTIONS_REFRESH = 'GET_QUESTIONS_REFRESH';

export const getQuestionsAction = createAction(GET_QUESTIONS);

const getQuestionsSuccess = createAction(GET_QUESTIONS_SUCCESS);

const getQuestionsFaliure = createAction(GET_QUESTIONS_FAILURE);

export const getQuestionsRefreshAction = createAction(GET_QUESTIONS_REFRESH);

export const getQuestionsEpic = (actions$) => actions$.pipe(
  ofType(GET_QUESTIONS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/getQuestions?`, 'GET')).pipe(
    map((res) => getQuestionsSuccess(res)),
    catchError((err) => of(getQuestionsFaliure(err))),
  )),
);

const ongetQuestionsAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetQuestionsSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetQuestionsFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetQuestionsRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

const getQuestionsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getQuestionsAction, (state) => ongetQuestionsAction(state))
      .addCase(getQuestionsSuccess, (state, action) => ongetQuestionsSuccess(state, action.payload))
      .addCase(getQuestionsFaliure, (state) => ongetQuestionsFailure(state))
      .addCase(getQuestionsRefreshAction, () => ongetQuestionsRefreshAction())
      .addDefaultCase((state) => state);
  },
);

const ADD_PREFERENCE = 'ADD_PREFERENCE';
const ADD_PREFERENCE_SUCCESS = 'ADD_PREFERENCE_SUCCESS';
const ADD_PREFERENCE_FAILURE = 'ADD_PREFERENCE_FAILURE';
const ADD_PREFERENCE_REFRESH = 'ADD_PREFERENCE_REFRESH';

export const addPreferencesAction = createAction(ADD_PREFERENCE);

const addPreferencesSuccess = createAction(ADD_PREFERENCE_SUCCESS);

const addPreferencesFaliure = createAction(ADD_PREFERENCE_FAILURE);

export const addPreferencesRefreshAction = createAction(ADD_PREFERENCE_REFRESH);

export const addPreferencesEpic = (actions$) => actions$.pipe(
  ofType(ADD_PREFERENCE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/addPreferences?`, 'POST', action.payload.body)).pipe(
    map((res) => addPreferencesSuccess(res)),
    catchError((err) => of(addPreferencesFaliure(err))),
  )),
);

const onaddPreferencesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onaddPreferencesSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onaddPreferencesFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const onaddPreferencesRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const addPreferencesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(addPreferencesAction, (state) => onaddPreferencesAction(state))
      .addCase(addPreferencesSuccess, (state, action) => onaddPreferencesSuccess(state, action.payload))
      .addCase(addPreferencesFaliure, (state) => onaddPreferencesFailure(state))
      .addCase(addPreferencesRefreshAction, () => onaddPreferencesRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default getQuestionsReducer;
