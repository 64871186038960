/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Tooltip, Modal, Collapse } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../common/components/SvgLoader';
import StudyDetailsSection from '../../components/StudyDetailsSection';
import AboutSection from '../../components/AboutSection';
import EligibilitySection from '../../components/EligibilitySection';
import Mixpanel from '../../../utils/mixpanel';
import PreviewSectionCard from '../../../searchAndExplore/components/PreviewSectionCard';
import { sendNotification } from '../../../common/components/Notification/logic';

function DeepDiveContainer({

  setSelectedOption, isSideBarClicked, showDataFor, pdfUrl,
}) {
  const dispatch = useDispatch();
  const exploreDeepDiveDetails = useSelector(
    (state) => state.getExploreDeepDiveDetails,
  );
  const getExploreDeepDiveMentionsData = useSelector(
    (state) => state.getExploreDeepDiveMentionsData,
  );
  const getExploreDeepDiveHistoryData = useSelector(
    (state) => state.getExploreDeepDiveHistoryData,
  );
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const [resultsModal, setresultsModal] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('Protocols');
  const [modalData, setModalData] = useState([]);
  const [isValidPdf, setIsValidPdf] = useState(false);
  const { Panel } = Collapse;

  async function fetchData(url) {
    try {
      const response = await fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (response.ok) {
        setIsValidPdf(true);
      } else {
        setIsValidPdf(false);
      }
    } catch (error) {
      dispatch(sendNotification({
        type: 'PDF_LOAD_FAILED',
        notificationType: 'error',
        message: error?.message || 'Oops something went wrong, please try again',
        showNotification: true,
      }));
    }
  }

  useEffect(() => {
    if (pdfUrl !== '') {
      fetchData(pdfUrl);
    }
  }, [pdfUrl]);

  const handleScroll = () => {
    const targets = document.querySelectorAll('.details-section');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && isSideBarClicked.current === false) {
          setSelectedOption(entry.target.id);
        }
      });
    });

    if (isSideBarClicked.current === false) {
      targets.forEach((target) => {
        observer.observe(target);
      });
    }
    if (isSideBarClicked.current === true) {
      observer.disconnect();
    }
  };

  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
  };
  const handleMouseEnter = (e) => {
    e.stopPropagation();
    isSideBarClicked.current = false;
  };

  const handleBlur = (e) => {
    e.stopPropagation();
    isSideBarClicked.current = true;
  };

  const renderChannelSection = (channelData) => (
    <div className="detail-container channel">
      <div className="header">CHANNEL</div>
      <div className="channel-section-table">
        <div className="table table-header">
          <div className="table th">Location</div>
          <div className="table th">Contacts</div>
          <div className="table th">Sponsors</div>
          <div className="table th">Principal Investigator</div>
          <div className="table th">Collaborators</div>
        </div>
        <div className="table data">
          <div className="table td">
            {channelData?.locations
              ? Object.keys(channelData?.locations).map((itm) => (
                <>
                  <div className="subdata-val">{itm}</div>
                  <div className="table subdata">
                    {channelData?.locations[itm][0]?.second_row}
                  </div>
                </>
              ))
              : null}
          </div>
          <div className="table td">
            {channelData?.contacts?.map((contact) => (
              <div>{contact.name || 'Data unavailable'}</div>
            ))}
          </div>
          <div className="table td">
            {channelData?.sponsors || 'Data unavailable'}
          </div>
          <div className="table td">Data unavailable</div>
          <div className="table td">
            {channelData?.collaborators?.length
              ? channelData?.collaborators
              : 'Data unavailable'}
          </div>
        </div>
      </div>
    </div>
  );

  const dataUnavailableBox = (header) => (
    <div className="detail-container">
      {header ? <div className="header">{header}</div> : null}
      <div className="data-unailable-section">
        <div className="data-unavailable-text">Data unavailable</div>
      </div>
    </div>
  );

  const renderHistoryTable = (historyChanges) => (
    <div className="detail-container history">
      <div className="header">HISTORY OF CHANGES</div>
      <div className="history-section-table">
        <div className="table table-header">
          <div className="table th">Version</div>
          <div className="table th">Submitted Date</div>
          <div className="table th">Changes</div>
        </div>
        {historyChanges?.map((history) => (
          <div className="table data">
            <div className="table td">{history?.version}</div>
            <div className="table td">{history?.changed_date}</div>
            <div className="table td">{history?.changes}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderMentionsData = (mentionsData) => (
    <div className="detail-container mentions">
      <div className="header">{`MENTIONS IN OTHER SOURCES (${mentionsData.length})`}</div>
      <div className="search-and-explore-results-container">
        <div className="search-and-explore-results-container-list ct-deep-dive">
          <div className="explore-list-results">
            {mentionsData?.map((data) => (
              <div className="search-and-explore-results-container-list-content-main deep-dive">
                <div className="list-content-header">
                  <Tooltip
                    placement="bottomLeft"
                    color="#5252fa"
                    overlayClassName="explore-list-doc-type"
                    title={data?.publication_id ? 'Publications' : 'Congresses'}
                  >
                    <SvgLoader
                      width={17}
                      height={17}
                      svgName={`${data?.publication_id ? 'publications' : 'congresses'}-icon`}
                    />
                  </Tooltip>
                  <div className="list-content-divider">
                    <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                  </div>
                  <div className="list-content-asset-name">
                    {data?.journal_title || data?.congress_name}
                  </div>
                  <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                  <div className="list-content-published-date">
                    <div className="published-date-title">Published Date:</div>
                    <div className="published-date-value">{data?.date}</div>
                  </div>
                  <a
                    className="redirect-to"
                    target="_blank"
                    href={data?.data_source_link}
                    rel="noreferrer noopener"
                  >
                    <SvgLoader
                      className="divider"
                      width={14}
                      height={14}
                      svgName="redirect-to-url-icon"
                    />
                  </a>
                </div>
                <div className="list-content-title" title={data?.title}>
                  {data?.title}
                </div>
                <div
                  className="list-content-summary"
                  title={data?.authors?.map(
                    (author, idx) => `${author?.author_name}${idx < data.authors.length - 1 ? ', ' : ''
                    }`,
                  )}
                >
                  {data?.authors?.map(
                    (author, idx) => `${author?.author_name}${idx < data.authors.length - 1 ? ', ' : ''
                    }`,
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderStudyDocsSection = (studyDocSection) => (
    studyDocSection?.url.length && isValidPdf ? (
      <div className="detail-container">
        <div className="header">STUDY DOCUMENTS</div>
        <div className="ct-protocols-bar selected">
          <div className="ct-protocol-icon" />
          <button
            type="button"
            className={selectedTopic === 'Protocols' ? 'selected-tab' : ''}
            onClick={() => handleTopicClick('Protocols')}
          >
            Protocols
          </button>
          <div className="line" />
        </div>
        <div className="">
          <div className="">
            <embed
              src={pdfUrl}
              type="application/pdf"
              style={{ width: '100%', height: 500 }}
            />
          </div>
        </div>
      </div>
    ) : (
      dataUnavailableBox('STUDY DOCUMENTS')
    )
  );

  return (
    <>
      <div className="deepdive-container-main">
        <div
          className="deepdive-container-main-content"
          id="containerElement"
          role="presentation"
          onMouseEnter={(e) => handleMouseEnter(e)}
          onBlur={(e) => handleBlur(e)}
          onWheel={() => handleScroll()}
        >
          <Element name="about" className="details-section" id="about">
            {exploreDeepDiveDetails?.data?.data?.about ? <AboutSection deepdivedata={exploreDeepDiveDetails?.data?.data?.about} /> : null}
          </Element>
          <Element name="study_details" className="details-section" id="study_details">
            {exploreDeepDiveDetails?.data?.data?.study_details ? <StudyDetailsSection data={exploreDeepDiveDetails?.data?.data?.study_details} studyDocData={exploreDeepDiveDetails?.data?.data?.study_documents} /> : null}
          </Element>
          <Element
            name="eligibility"
            className="details-section"
            id="eligibility"
          >
            <EligibilitySection
              data={exploreDeepDiveDetails?.data?.data?.eligibility}
            />
          </Element>
          <Element name="channel" className="details-section" id="channel">
            {exploreDeepDiveDetails?.data?.data?.channel
              ? renderChannelSection(
                exploreDeepDiveDetails?.data?.data?.channel,
              )
              : dataUnavailableBox('CHANNEL')}
          </Element>
          <Element name="results" className="details-section" id="results">
            <div className="detail-container results">
              <div className="header">RESULTS</div>
              <Collapse
                expandIconPosition="end"
                defaultActiveKey={['0', '1']}
              >
                <Panel
                  header={(
                    <div className="header">
                      {`PRIMARY OUTCOME MEASURES ${exploreDeepDiveDetails?.data?.data?.results?.results
                        ?.Primary?.length
                        ? `(${exploreDeepDiveDetails?.data?.data?.results?.results?.Primary?.length})`
                        : ''
                      }`}
                    </div>
                  )}
                  key="0"
                >
                  <div className="outcome-measures-sec">
                    {exploreDeepDiveDetails?.data?.data?.results?.results
                      .Primary?.length
                      ? exploreDeepDiveDetails?.data?.data?.results?.results.Primary?.map(
                        (itm) => (
                          <div
                            className="measure-list"
                            role="presentation"
                            onClick={() => {
                              setresultsModal(true);
                              setModalData(itm);
                              Mixpanel.track('resultsDeepDivePrimaryClickEvent', {
                                action: 'Primary Results Deep Dive',
                                view: `Results page with ${semanticQuery ? 'semantic search query' : 'power search query'}`,
                                clickedValue: itm,
                                query: JSON.parse(semanticQuery) || JSON.parse(query),
                              });
                            }}
                          >
                            <div className="measure-list-header">
                              <div className="measure-list-itm">
                                {itm?.type}
                              </div>
                              <div className="measure-list-itm">
                                <SvgLoader
                                  className="divider"
                                  width={10}
                                  height={20}
                                  svgName="vertical-line"
                                />
                              </div>
                              <div className="measure-list-itm">{`Time Frame: ${itm?.time_frame}`}</div>
                            </div>
                            <div className="measure-name">{itm?.title}</div>
                          </div>
                        ),
                      )
                      : dataUnavailableBox()}
                  </div>
                </Panel>
                <Panel
                  header={(
                    <div className="header">
                      {`SECONDARY OUTCOME MEASURES ${exploreDeepDiveDetails?.data?.data?.results?.results
                        ?.Secondary?.length
                        ? `(${exploreDeepDiveDetails?.data?.data?.results?.results?.Secondary?.length})`
                        : ''
                      }`}
                    </div>
                  )}
                  key="1"
                >
                  <div className="outcome-measures-sec">
                    {exploreDeepDiveDetails?.data?.data?.results?.results
                      ?.Secondary?.length
                      ? exploreDeepDiveDetails?.data?.data?.results?.results?.Secondary?.map(
                        (itm) => (
                          <div
                            className="measure-list"
                            role="presentation"
                            onClick={() => {
                              setresultsModal(true);
                              setModalData(itm);
                              Mixpanel.track('resultsDeepDiveSecondaryClickEvent', {
                                action: 'Secondary Results Deep Dive',
                                view: `Results page with ${semanticQuery ? 'semantic search query' : 'power search query'}`,
                                clickedValue: itm,
                                query: JSON.parse(semanticQuery) || JSON.parse(query),
                              });
                            }}
                          >
                            <div className="measure-type">{itm?.type}</div>
                            <div className="measure-name">{itm?.title}</div>
                            <div className="measure-time">{`Time Frame${itm?.time_frame}`}</div>
                          </div>
                        ),
                      )
                      : dataUnavailableBox()}
                  </div>
                </Panel>
              </Collapse>
            </div>
          </Element>
          <Element
            name="study_documents"
            className="details-section"
            id="study_documents"
          >
            {renderStudyDocsSection(exploreDeepDiveDetails?.data?.data?.study_documents[0])}
          </Element>
          <Element name="history" className="details-section" id="history">
            {getExploreDeepDiveHistoryData?.data?.data?.length
              ? renderHistoryTable(getExploreDeepDiveHistoryData?.data?.data)
              : dataUnavailableBox('HISTORY')}
          </Element>
          <Element name="mentions" className="details-section" id="mentions">
            {getExploreDeepDiveMentionsData?.data?.data?.length
              ? renderMentionsData(getExploreDeepDiveMentionsData?.data?.data)
              : dataUnavailableBox('MENTIONS IN OTHER SOURCES')}
          </Element>
          <Element name="associated_documents" className="details-section" id="associated_documents">
            <div className="detail-container mentions">
              <div className="header">ASSOCIATED DOCUMENTS</div>
              <PreviewSectionCard
                trialData={showDataFor}
                isdeepdive
              />
            </div>
          </Element>
        </div>
      </div>
      <Modal
        className="results-modal"
        open={resultsModal}
        width={900}
        title={(
          <div
            className="modal-back-btn"
            role="presentation"
            onClick={() => setresultsModal(false)}
          >
            <span className="back-btn">
              <SvgLoader
                className="divider"
                width={18}
                height={12}
                svgName="back-arrow-modal"
              />
            </span>
            <span className="back-btn-text">Back</span>
          </div>
        )}
        closable
        onCancel={() => setresultsModal(false)}
        footer={false}
      >
        <div className="modal-container-results">
          <div className="results-container-list">
            <div className="reasults-list-header">
              <div className="reasults-list-itm">{modalData?.type}</div>
              <div className="reasults-list-itm">
                <SvgLoader
                  className="divider"
                  width={10}
                  height={20}
                  svgName="vertical-line"
                />
              </div>
              <div className="reasults-list-itm">{`Time Frame: ${modalData?.time_frame}`}</div>
            </div>
            <div className="reasults-list-title">{modalData?.title}</div>
          </div>
          <div className="results-container-list">
            <div className="header">MEASURE DESCRIPTION</div>
            <div className="results-list-desc">
              {modalData?.measure_description}
            </div>
          </div>
          <div className="results-container-list">
            <div className="header">OUTCOME MEASURES</div>
            <div className="results-list-table">
              {modalData?.table_data?.title?.map((tableItem) => (
                <div className="table-data-list">
                  <div className="table-header td">{tableItem}</div>
                  <div className="table-value td">
                    {typeof modalData?.table_data?.data?.OG000[tableItem]
                      === 'object'
                      ? `${modalData?.table_data?.data?.OG000[tableItem]?.range
                        ?.length
                        ? `Range: ${modalData?.table_data?.data?.OG000[tableItem]?.range}`
                        : ''
                      } ${modalData?.table_data?.data?.OG000[tableItem]?.value
                        ?.length
                        ? `Value: ${modalData?.table_data?.data?.OG000[tableItem]?.value}`
                        : ''
                      }`
                      : modalData?.table_data?.data?.OG000[tableItem]}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

DeepDiveContainer.propTypes = {
  setSelectedOption: PropTypes.func.isRequired,
  isSideBarClicked: PropTypes.instanceOf(Object).isRequired,
};

export default DeepDiveContainer;
