/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import webAuth from '../../../utils/webAuth';
import { sendNotification } from '../Notification/logic';
import { getCookie, setCookie } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import { setSession } from '../../container/Status/logic';

export default function Callack() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  let timer = null;

  const accessToken = searchParams.get('access_token');

  const getToken = async (token) => {
    try {
      const response = await axios.get(`${process.env.apiUrl}/common/v0/auth/callback`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      const fullName = `${response.data.data.profile.first_name} ${response.data.data.profile.last_name}`;
      const userName = `${response.data.data.profile.user_id}`;
      const bearer = `Bearer ${response.data.data.accessToken}`;
      setCookie(JSON.stringify(response.data.data.permissions), 'permissions');
      setCookie(response.data.data.refreshToken, 'refreshToken');
      setCookie(userName, 'userName');
      setCookie(fullName, 'fullName');
      setCookie(response.data.data.profile.organization || 'individual', 'org');
      dispatch(setSession(bearer));
      Mixpanel.identify(`${userName}@${response.data.data.profile.organization || 'individual'}`);
      if (getCookie('personaBuild') === 'false') {
        Mixpanel.track('persona_event', {
          action: 'user persona building process has began',
          data: getCookie('userEmail'),
        });
        navigate('/persona', { replace: true });
        navigate(0);
      } else {
        navigate(getCookie('loggedUrl'), { replace: true });
        navigate(0);
      }
      return 0;
    } catch (err) {
      Mixpanel.track('loginFailureEvent', {
        action: 'Login failure',
        error: `${err?.message} || ${err}`,
      });
      setCookie(false, 'pageLoading');
      navigate('/', { replace: true });
      navigate(0);
      return 0;
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getToken(accessToken);
        const bearer = `Bearer ${accessToken}`;
        setCookie(bearer, 'accessToken');
        setCookie(false, 'pageLoading');
      }, 100);
    }
  }, [accessToken]);

  return (
    <Loader height="100vh" loading />
  );
}
