import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCookie } from '../../../utils';

function RedirectPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const app = searchParams.get('app');

    if (app && getCookie('accessToken')) {
      window.location.href = process.env.terminalURL[app];
    }
  });

  return (
    <div className="white-page" />
  );
}

export default RedirectPage;
