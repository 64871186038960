import auth0 from 'auth0-js';

const webAuth = new auth0.WebAuth({
  domain: process.env.auth0.domain,
  clientID: process.env.auth0.clientId,
  audience: process.env.auth0.audience,
  redirectUri: `${process.env.appUrl}callback`,
  responseType: 'token',
});

export default webAuth;
