import React from 'react';
import PropTypes from 'prop-types';
import PieChart from '../../container/PieChart';
import SpeedometerGraph from '../../container/SpeedometerGraph';
import InterventionMeter from '../../container/InterventionMeter';
import PhaseGraph from '../../container/PhaseGraph';
import EnrollmentMeter from '../../container/EnrolementMeter';
import EndpointsGraph from '../../container/EndopointsGraph';
import ComingSoon from '../../../common/components/CommingSoon';
import Geographical from '../../container/Geographical';

function GraphTypeHandler({
  params, selected, currentExpanded, setExpanded, setIsGraphClicked,
}) {
  const handleGraphs = () => {
    switch (selected.type) {
      case 'pie':
        return <PieChart currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'meter':
        return <SpeedometerGraph currentExpanded={currentExpanded} params={params} selected={selected} setIsGraphClicked={setIsGraphClicked} />;
      case 'top3':
        return <InterventionMeter setExpanded={setExpanded} currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'phase':
        return <PhaseGraph setExpanded={setExpanded} currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'enrolement':
        return <EnrollmentMeter currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'endpoints':
        return <EndpointsGraph setExpanded={setExpanded} currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'geoGraph':
        return <Geographical setExpanded={setExpanded} currentExpanded={currentExpanded} params={params} selected={selected} />;
      case 'comming-soon':
        return <ComingSoon currentExpanded={currentExpanded} selected={selected} />;
      default:
        return null;
    }
  };

  return (
    <div className={`graph-card ${selected.type} ${currentExpanded === '' ? selected.size : ''}`}>
      {handleGraphs()}
    </div>
  );
}

GraphTypeHandler.propTypes = {
  selected: PropTypes.PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.PropTypes.shape({}).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  setIsGraphClicked: PropTypes.func.isRequired,
};

export default GraphTypeHandler;
