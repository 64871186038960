import { React, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { merge } from 'webpack-merge';
import Header from '../../../common/components/Header';

import AnalyticsNavOptions from '../../../common/container/AnalyticsNavOptions';
import { modifyDataForGetResultsQuery, getCookie } from '../../../utils';
import InterventionAnalyticsContainer from '../../container/InterventionAnalyticsContainer';
import { getInterventionSearchResultsAction, getInterventionSearchResultsRefreshAction } from '../../container/InterventionList/logic';

function InterventionAnalyticsPage() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getInterventionSearchResults = useSelector(
    (state) => state.getInterventionSearchResults,
  );
  const query = searchParams.get('query');
  const sort = searchParams.get('sort');
  const autoSuggestUrl = '/intervention/v0/search/getFieldValues';
  const q = JSON.parse(query);
  const requestData = modifyDataForGetResultsQuery(q, true);
  const filters = searchParams.get('filters');
  const filtersData = modifyDataForGetResultsQuery(JSON.parse(filters, true));
  const out = merge(requestData, filtersData);
  const projId = getCookie('projectId');

  const params = {
    headers: {
      from: 1,
      size: 10,
      project_id: projId,
    },
    body: { filters: out.body },
  };

  useEffect(() => {
    if (query?.length) {
      const request = {
        apiUrl: '/intervention/v0/search/getSearchResults',
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          from: 1,
          size: 10,
          project_id: projId,
        }),
        body: params.body,
      };
      if (sort && sort !== 'null') {
        params.body = { ...params.body, sort: [JSON.parse(sort)] };
      }
      dispatch(getInterventionSearchResultsAction(request));
    }

    return () => {
      dispatch(getInterventionSearchResultsRefreshAction({}));
    };
  }, []);

  return (
    <div className="main-page">
      <Header className="results-header-light" isResultsPage type="light" />
      <div className="ct-results-page intervention-result-page">
        <div className="card-view-nav">
          <div className="explore-results-text">
            {getInterventionSearchResults?.data?.total ? `${getInterventionSearchResults?.data?.total} Results found` : null}
          </div>
          <AnalyticsNavOptions autoSuggestUrl={autoSuggestUrl} />
        </div>
        <div className="analytics-container intervention-analytics-container">
          <InterventionAnalyticsContainer params={params} />
        </div>
      </div>
    </div>
  );
}

export default InterventionAnalyticsPage;
