import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { updateFilterAction } from '../../container/PowerSearch/logic';

function FilterDatePicker({
  id, selectedValue, isDefault, resetSection, assetClasse,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get('filters');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const f = JSON.parse(filters);
    if (f) {
      f.forEach((item) => {
        if (item.name === selectedValue && !isDefault) {
          const date = new Date(item.currentFilter[0] * 1000);
          const date1 = new Date(item.currentFilter[1] * 1000);
          dispatch(updateFilterAction({
            filterOption: { currentFilter: [item.currentFilter[0], item.currentFilter[1]], currentKeys: [item.currentFilter[0], item.currentFilter[1]] },
            id,
          }));
          if (item.currentFilter[0]) {
            setStartDate(dayjs(`${date.toISOString().slice(0).replace(/-/g, '-')}`));
          }
          if (item.currentFilter[1]) {
            setEndDate(dayjs(`${date1.toISOString().slice(0).replace(/-/g, '-')}`));
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (resetSection === assetClasse) {
      setStartDate('');
      setEndDate('');
      dispatch(updateFilterAction({
        filterOption: { currentFilter: [], currentKeys: [] },
        id,
      }));
    }
  }, [resetSection, assetClasse]);

  useEffect(() => {
    if (startDate !== '' || endDate !== '') {
      const start = startDate ? moment(startDate.$d).startOf('day').unix() : '';
      const end = endDate ? moment(endDate.$d).endOf('day').unix() : '';

      dispatch(updateFilterAction({
        filterOption: { currentFilter: [start, end], currentKeys: [start, end] },
        id,
      }));
    }
  }, [startDate, endDate]);

  const handleDateSelect = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  return (
    <div className="calender-container filter-calender-container">
      <DatePicker
        value={startDate}
        placeholder="Start date"
        onChange={(date) => handleDateSelect(date, 'start')}
        className="date-range-input"
      />
      <DatePicker
        value={endDate}
        placeholder="End date"
        onChange={(date) => handleDateSelect(date, 'end')}
        className="date-range-input"
      />
    </div>
  );
}

FilterDatePicker.propTypes = {
  id: PropTypes.number.isRequired,
  selectedValue: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  resetSection: PropTypes.string.isRequired,
  assetClasse: PropTypes.string.isRequired,
};

export default FilterDatePicker;
