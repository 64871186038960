import React, { useState } from 'react';
import LoginModal from '../LoginModal';
import TryOntosightModal from '../TryOntosightModal';
import Declarations from '../Declarations';
import Mixpanel from '../../../utils/mixpanel';

function LandingPageHeader({ opendTryOntosightModal, setTryOntosightModal }) {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openDeclarations, setOpenDeclarations] = useState(false);

  const LoginBtnClick = () => {
    setOpenLoginModal(true);
    Mixpanel.track('login_btn_click_event', {
      action: 'login button click to open login modal',
      page: 'Landing Page',
    });
  };

  const TryOntosightModalClick = () => {
    setTryOntosightModal(true);
    Mixpanel.track('try_ontosight_for_free_btn_click_event', {
      action: 'Try Ontosight button click to open try ontosigt modal',
      page: 'Landing Page',
      cta: 'cta 1 from top',
    });
  };

  return (
    <div className="landing-page-header-ctr">
      <div className="ontosight-logo-landing" />
      <div className="header-btns">
        <div aria-hidden className="header-login pointer" onClick={() => LoginBtnClick()}><span className="login-text">Login</span></div>
        <div aria-hidden className="header-try pointer" onClick={() => TryOntosightModalClick()}>
          <span className="try-text">Try Ontosight</span>
          <sup className="try-text">®</sup>
          &nbsp;
          <span className="try-text"> For Free</span>
        </div>
      </div>
      {openLoginModal && <LoginModal open={openLoginModal} setOpen={setOpenLoginModal} setTryOntosightModal={setTryOntosightModal} />}
      {opendTryOntosightModal && <TryOntosightModal open={opendTryOntosightModal} setOpen={setTryOntosightModal} setOpenDeclarations={setOpenDeclarations} />}
      <Declarations open={openDeclarations} setOpen={setOpenDeclarations} />
    </div>
  );
}

export default LandingPageHeader;
