/* eslint-disable */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Popover, Tooltip, Button } from 'antd';
import SvgLoader from '../../../../components/SvgLoader';
import Bookmark from '../../../Bookmark';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBookmarkAction, getBookmarkAction } from '../../../Bookmark/logic';
import { sendNotification } from '../../../../components/Notification/logic';
import { ToastContainer, toast } from 'react-toastify';
// import { Tooltip, Popover } from 'antd';
// import SvgLoader from '../../../common/components/SvgLoader';
// import { ucFirst } from '../../../utils';
// import Bookmark from '../../../common/container/Bookmark';

function BookmarkCardTwo({
  data, setSelectedCard, projectId, apiCall, setBookmarkFilters, bookmarkFilters
}) {

  const [activeBookmarkId, setActiveBookmarkId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [isBookmarkDeleted, setIsBookmarkDeleted] = useState(false);
  const dispatch = useDispatch();
  const deleteBookmark = useSelector((state) => state.deleteBookmark);
  const bookmarkData = useSelector((state) => state.bookmarkData);
  const [isBookmarked, setIsBookmarked] = useState(true);

  const renderClass = (predictionScore) => {
    if (predictionScore > 65) {
      return 'pos-high';
    }
    if (predictionScore > 35 && predictionScore <= 65) {
      return 'pos-med';
    }
    return 'pos-low';
  };

  function convertToLowerCaseWithUnderscores(filterData) {
    return filterData?.map(filter => filter.toLowerCase().replace(/ /g, '_')) ?? [];
}



  const handleListClick = () => {
    setSelectedCard(data);
  };

  const showDeleteBookmarkModal = (docId) => {
    setOpenDeleteModal(true)
    setActiveBookmarkId(docId);
  }

  // useEffect(() => {
  //   if (deleteBookmark?.flag) {
  //     setIsBookmarkDeleted(true);
  //   }
  // }, [deleteBookmark]);

  useEffect(() => {
    if (isBookmarked == false) {
      dispatch(sendNotification({
        type: ' BOOKMARK_DELETE_SUCCESS',
        notificationType: 'success',
        message: 'Bookmark deleted successfully',
        showNotification: true,
    }))
    }
  }, [isBookmarked]);


  const handleBookmarkDelete = (docId) => {
      let projectIds = [projectId]
      dispatch(deleteBookmarkAction(
        {
          body: {

            project_id: projectIds,
            doc_id: docId

          },
        },
      ));
      setIsBookmarked(false);
    // }

    setOpenDeleteModal(false)
  }

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div className={predictionScore >= 65 ? 'high' : (predictionScore >= 35 && predictionScore < 65 ? 'medium' : 'low')} />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>

    </div>
  );

  const getClassName = (str) => {
    const modifiedStr = str.replace(/ /g, '-').toLowerCase();
    return modifiedStr;
  };

  const renderTooltipValue = (dataset) => dataset?.map((item) => `${item?.name || item?.kol_name || item?.author_name || item} `);
  const renderField = (value, dataset, len = 0) => {
    return (<span className="filter">
      <span className="filter-value" title={renderTooltipValue(dataset)}>
        {' '}
        {typeof value === "string" ? value : value?.name}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
    </span>);
  };

  const renderSubDetailsDocument = ({
    route_of_administration,
    dosage_form,
    committee,
    sub_type,
    active_ingredient,
    sponsors,
    indications,
    interventions,
    start_date,
    authors,
  }) => (
    <div className={`sub-details-container`}>
      {authors?.length ? (
        <div className={`sub-details ${(sponsors?.length || indications?.length) ? 'extra-fileds-present' : ''}`}>
          <SvgLoader width={16} height={16} svgName="authors-icon" />
          <span className="filter-value">
            {' '}
            {renderField(authors[0]?.kol_name || authors[0]?.author_name || authors[0], authors, authors.length)}
          </span>
        </div>
      ) : null}
      {sponsors?.length ? (
        <div className={`sub-details ${indications?.length ? 'extra-fileds-present' : ''}`}>
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          <span className="filter-value">
            {' '}
            {sponsors}
          </span>
        </div>
      ) : null}
      {indications?.length ? (
        <div className={`sub-details ${indications?.length ? 'extra-fileds-present' : ''}`}>
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indications[0], indications, indications.length)}
        </div>
      ) : null}
      {interventions?.length ? (
        <div className={`sub-details ${interventions?.length ? 'extra-fileds-present' : ''}`}>
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(interventions[0], interventions, interventions.length)}
        </div>
      ) : null}
      {interventions?.length && data?.type === 'regulatory_data' ? (
        <div className={`sub-details ${start_date?.length ? 'extra-fileds-present' : ''}`}>
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {typeof interventions === 'string'
            ? (
              <span className="filter-value">
                {' '}
                {active_ingredient}
              </span>
            ) : renderField(interventions[0]?.name, interventions, interventions.length)}
        </div>
      ) : (
        null
      )}
      {sub_type === 'advisory_committee' ? (
        <div className={`sub-details ${start_date?.length ? 'extra-fileds-present' : ''}`}>

          <span className="filter-value" title={committee}>
            {' '}
            {committee}
          </span>
        </div>
      ) : (null)}
      {sub_type === 'drug_specific_guidances' ? (
        <div className={`sub-details ${start_date?.length ? 'extra-fileds-present' : ''}`}>

          <span className="filter-value">
            {' '}
            {dosage_form}
          </span>
        </div>
      ) : (null)}
      {sub_type === 'drug_specific_guidances' ? (
        <div className={`sub-details ${start_date?.length ? 'extra-fileds-present' : ''}`}>

          <span className="filter-value">
            {' '}
            {route_of_administration}
          </span>
        </div>
      ) : (null)}
      {data?.type === 'hta_recommendations' ? (
        <div className={`sub-details ${start_date?.length ? 'extra-fileds-present' : ''}`}>
          {active_ingredient?.length ? <SvgLoader width={16} height={16} svgName="indication-icon" />
            : null}
          <span className="filter-value">
            {' '}
            {renderField(active_ingredient[0]?.name, active_ingredient, active_ingredient.length)}
          </span>
        </div>
      ) : (null)}
      {start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {' '}
            {start_date}
          </span>
        </div>
      ) : null}
    </div>
  );

  
  if(!isBookmarked)
    return null;

  return (
    <div
      role="presentation"
      className={`search-and-explore-results-container-list-content-main explore `}
      onClick={() => handleListClick()}
    >
      <div className="list-content-header">
        <div className='list-content-section'>
          { }
          <div className="list-content-asset-class-icon">
            <Tooltip placement="bottomLeft" color="#646464" overlayClassName="explore-list-doc-type" title={data?.display_type}>
              <SvgLoader
                width={16}
                height={16}
                svgName={`${data?.type}-icon`}
              />
            </Tooltip>
          </div>
          <div className="list-content-asset-name">
            <span>{data?.data_source || data?.regulatory_body}</span>
            <span className="redirect-icon-wrapper">
              <a
                target="_blank"
                href={data?.data_source_link}
                rel="noreferrer noopener"
                label
              >
                <SvgLoader width={16} height={16} svgName="redirect-icon" />
              </a>
            </span>
          </div>
          <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
          <div className="list-content-published-date">
            <div className="published-date-value">{data?.date || data?.published_date}</div>
          </div>
          {data?.guideline_type?.length ? (
            <>
              <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
              <div className="list-content-published-date">
                <div className="published-date-value">
                  {renderField(guideline_type[0], indications, indications.length)}
                </div>
              </div>
            </>
          ) : (null)}
          {data?.type === 'patents' ? (
            <>
              <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.expiry_date}</div>
              </div>
            </>
          ) : (null)}
          {data?.type === 'patents' || data?.type === 'grants' ? (
            <>
              <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.status}</div>
              </div>
            </>
          ) : (null)}

          {data?.type === 'publications' && data?.full_text !== '' ? (
            <>
              <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
              <div className="list-content-full-text">
                <div className="full-text-value" />
              </div>
            </>
          ) : (
            null

          )}

          {' '}
          {data?.decision ? (

            <>
              <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
              <div className="list-content-published-date">
                <div className="published-date-value">{data?.decision}</div>
              </div>
            </>

          ) : null}

          {data?.prediction_score ? (
            <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
          ) : null}
          {data?.phase_short
            ? (
              <>

                <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                <div className="list-content-published-date">{data?.phase_short}</div>
              </>
            ) : null}
          {data?.study_status
            ? (
              <>

                <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                <div className="status">
                  <div className={getClassName(data.study_status)}>
                    {data.study_status === 'Completed' ? (
                      <div className="completed-icon" />
                    ) : null}
                    {data.study_status}
                  </div>
                </div>
              </>
            ) : null}
          {data.prediction_score ? (
            <div className="pos-val-container">
              <Tooltip
                placement="left"
                overlayClassName="prediction-tooltip position-zero"
                title={() => renderTooltip(data.prediction_score)}
                arrow={false}
              >
                <div
                  role="presentation"
                  className={renderClass(data.prediction_score)}
                  id={`score${data.trial_id}`}
                >
                  <SvgLoader width={11} height={8} svgName={renderClass(data.prediction_score)} />
                  PoS
                </div>
              </Tooltip>
            </div>
          ) : null}
          {(data?.study_results?.length)
            ? (
              <>
                <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                <div className="list-content-published-date">
                  <div className="published-date-value">{data?.full_text?.length ? 'Free full text available' : 'Results available'}</div>
                </div>
              </>
            ) : null}
          {(data?.sub_type?.length)
            ? (
              <>
                <SvgLoader className="divider" width={6} height={6} svgName="seperator-icon" />
                <div className="list-content-published-date">
                  <div className="published-date-value">{ucFirst(data?.sub_type)}</div>
                </div>
              </>
            ) : null}

        </div>
        <div className='list-icon-section'>
          <Popover overlayClassName="bookmark-popover" placement="bottomRight" open={activeBookmarkId === data?.trial_id} content={<Bookmark docId={location.pathname.includes('explore') ? data?.doc_id : ''} activeBookmarkId={activeBookmarkId} setActiveBookmarkId={setActiveBookmarkId} trialId={location.pathname.includes('explore') ? data?.trial_id : ''} onClose={() => setActiveBookmarkId(null)} />}>
            <div className='bookmark-icon-active-list' role="presentation" onClick={() => showDeleteBookmarkModal(data?.doc_id)} />
            {/* <ToastContainer /> */}
            { }
          </Popover>

          {/* <div className="share-icon" role="presentation" onClick={showShareModal} /> */}
        </div>
      </div>
      <div className="list-content-title" title={data.title || data?.title_publication || data?.layman_title}>{data?.title || data?.title_publication || data?.layman_title}</div>
      <div className="list-content-data">
        {renderSubDetailsDocument(data)}

      </div>
      {openDeleteModal && (
        <Modal className="share-with-modal" open={openDeleteModal} closable footer={false} onCancel={() => setOpenDeleteModal(false)}>
          <div className="delete-modal-content">
            <><div className="delete-message">Are you sure you want to delete this bookmark ?</div><div className="delete-btn">
              <Button className="cancel-btn" type="primary" role="presentation" onClick={() => setOpenDeleteModal(false)}>No</Button>
              <Button className="add-btn" type="primary" role="presentation" onClick={() => handleBookmarkDelete(data?.doc_id)}>Yes</Button>
            </div></>
          </div>
        </Modal>
      )}
    </div>

  );
}

BookmarkCardTwo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default BookmarkCardTwo;
