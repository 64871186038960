import React, { useEffect } from 'react';
import {
  faAddressBook,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../components/Header';
import ContactUsForm from '../../container/ContactUsForm';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const rednerContactDetailsIndia = () => (
    <div className="contact-details">
      <div className="contact-details-header">India</div>
      <div className="contact-details-sec sec-top">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faAddressBook}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>
          7th Floor, Midas Tower,Beside STPI Building, Rajiv Gandhi Infotech
          Park, Phase-1, Hinjewadi, Pune - 411057
        </div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faPhone}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>+91-20-66527300</div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faEnvelope}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <a href="mailto:support@innoplexus.com">support@innoplexus.com</a>
      </div>
      {/* <div className="button-action">
        <button className="send-message-button">Customer Support</button>
      </div> */}
    </div>
  );

  const rednerContactDetailsGermany = () => (
    <div className="contact-details">
      <div className="contact-details-header">Germany</div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faAddressBook}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>Frankfurter Strasse 27, 65760 Eschborn</div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faPhone}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>+49 6196-9677-311</div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faEnvelope}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <a href="mailto:support@innoplexus.com">support@innoplexus.com</a>
      </div>
    </div>
  );

  const rednerContactDetailsUSA = () => (
    <div className="contact-details">
      <div className="contact-details-header">USA</div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faAddressBook}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>33 Wood Avenue South, Suite 600, Iselin, New Jersey 08830</div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faPhone}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <div>+1 (732) 452-2633</div>
      </div>
      <div className="contact-details-sec">
        <FontAwesomeIcon
          className="icon-contact-details"
          icon={faEnvelope}
          style={{
            color: '#fff',
            backgroundColor: '#5252FA',
            borderRadius: '50%',
            padding: '14px',
            width: '18px',
            height: '18px',
            marginRight: '20px',
          }}
        />
        <a href="mailto:support@innoplexus.com">support@innoplexus.com</a>
      </div>
    </div>
  );

  const renderContactDetails = () => (
    <div className="contact-details-data-str">
      <div className="contact-details-part-1">
        {rednerContactDetailsGermany()}
        {rednerContactDetailsIndia()}
      </div>
      <div>{rednerContactDetailsUSA()}</div>
    </div>
  );
  return (
    <div className="main-page">
      <Header className="header-home" />
      <div className="contact-us-page">
        <div className="bg-top-contact">
          Contact Us
        </div>
        <div className="contact-us-main">
          <div className="contact-us-2">
            <div className="contact-us-2-header">
              Get in touch with us to know how we’re shaping the future of decision making
            </div>
            <ContactUsForm />
          </div>
          <div className="contact-details-data">
            {renderContactDetails()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
