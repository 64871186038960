/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Panel from '../../../common/components/Panel';
import { trackOutSideEvents } from '../../../utils';
import SvgLoader from '../../../common/components/SvgLoader';

function DropdwnMultiSelect({
  className, options, handleOnSelection, placeholder,
}) {
  const multiSelectRef = useRef(null);

  const [input, setInput] = useState('');
  const [openDropdown, setOpendDropDown] = useState(false);
  const [tags, setTags] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState([]);

  useEffect(() => {
    trackOutSideEvents(multiSelectRef, () => setOpendDropDown(false));
  }, []);

  useEffect(() => {
    if (input === '') {
      setUpdatedOptions([...options]);
    } else {
      const temp = [...options.filter((item) => item.label.includes(input))];
      setUpdatedOptions([...temp]);
    }
  }, [input]);

  const handleChange = (event) => {
    event.preventDefault();
    setOpendDropDown(true);
    setInput(event.target.value);
  };

  const handleOptionClick = (value) => {
    setTags([...[value]]);
    handleOnSelection([...[value]]);
    setInput('');
  };

  const renderDropdowns = () => updatedOptions.map((item) => (
    <div aria-hidden key={item.value} onClick={() => handleOptionClick(item)} className={`options ${className[2]} ${tags.indexOf(item.label) > -1 ? 'check' : ''}`}>
      {item.label}
      {tags.indexOf(item.label) > -1 ? <SvgLoader width={24} height={24} svgName="tick" /> : null}
    </div>
  ));

  return (
    <div aria-hidden ref={multiSelectRef} className="multi-select-filter">
      <div className="multi-select-assing-wrap">
        <input className="multi-input" placeholder={placeholder} onChange={handleChange} value={input} />
      </div>
      <div className="input-div">
        {(openDropdown && input?.length)
          ? (
            <Panel className={`panel ${className[1]}`}>
              {renderDropdowns()}
            </Panel>
          ) : null}
      </div>
    </div>
  );
}

DropdwnMultiSelect.propTypes = {
  className: PropTypes.instanceOf(Array).isRequired,
  element: PropTypes.PropTypes.shape({
    spa: PropTypes.bool,
  }),
};

DropdwnMultiSelect.defaultProps = {
  element: ({
    spa: false,
  }),
};

export default DropdwnMultiSelect;
