const sortByOptions = [
  { label: 'Relevance', value: {} },
  { label: 'Last Updated', value: { field: 'created_at_epoch', order: 'desc' } },
  { label: 'First Updated', value: { field: 'created_at_epoch', order: 'asc' } },
  { label: 'Status', value: { field: 'trial_status_normalised', order: 'asc' } },
  { label: 'Phase', value: { field: 'phase_normalised_buckets', order: 'asc' } },
  { label: 'Registry', value: { field: 'source', order: 'asc' } },
];

export const viewByOptions = [
  { label: 'Registry', value: 'registry' },
  { label: 'Phase', value: 'phase' },
  { label: 'Status', value: 'status' },
  { label: 'Type', value: 'type' },
];

export default sortByOptions;
