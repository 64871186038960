import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { appPermission, terminalsPath } from './data';
import { checkPermission } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import SvgLoader from '../../../common/components/SvgLoader';

function Card({
  terminal, description, type, status, setOpen,
}) {
  const terminalContent = {
    'ot-ct-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Clinical Trials Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Clinical Trials Terminal',
        },
      },
      label: 'ct-card',
      image: 'clinical-without-header.png',
    },
    'ot-search-exp-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Search & Explore Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Search & Explore Terminal',
        },
      },
      label: 'search-card',
      image: 'search-without-header.png',
    },
    'ot-therapy-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Therapy Area and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Therapy Area',
        },
      },
      label: 'therapy-card',
      image: 'app-img.png',
    },
    'ot-protein-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Protein Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Protein Terminal',
        },
      },
      label: 'protein-card',
      image: 'app-img.png',
    },
    'ot-cl-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Competitive Landscape Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Competitive Landscape Terminal',
        },
      },
      label: 'cl-card',
      image: 'app-img.png',
    },
    'ot-pl-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Preclinical Landscape Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Preclinical Landscape Terminal',
        },
      },
      label: 'pl-card',
      image: 'app-img.png',
    },
    'ot-intervention-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Intervention Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Intervention Terminal',
        },
      },
      label: 'intervention-card',
      image: 'app-img.png',
    },
    'ot-disease-access': {
      content: {
        comingSoon: {
          header: 'Coming Soon!',
          subHeader: 'Our team is currently working on Disease Terminal and we will update you as soon as it is completed.',
        },
        requestAccess: {
          header: 'You do not have access to Disease Terminal',
        },
      },
      label: 'disease-card',
      image: 'app-img.png',
    },
  };

  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const checkWords = (words) => {
    if (words.length > 100) {
      let count = 0;
      const wordList = words.split(' ');
      let result = '';

      wordList.forEach((word) => {
        if (count + word.length > 100) {
          return;
        }
        count += word.length + 1;
        result += `${word} `;
      });
      return (
        <div>
          {`${result.trim()}...`}
        </div>
      );
    }
    return words;
  };

  const check = (appName) => checkPermission(appName);

  if ((type === 'locked' && status === 'Coming Soon') || (type === 'locked' && check(terminal))) {
    return null;
  }

  if ((type === 'unlocked' && status === 'Coming Soon') || (type === 'unlocked' && !check(terminal))) {
    return null;
  }

  if (type === 'comming soon' && status !== 'Coming Soon') {
    return null;
  }

  const handlClick = () => {
    Mixpanel.track('terminal_name_event', {
      action: 'terminal card on home page click',
      page: 'Home Page',
      terminal: appPermission[terminal],
      type,
    });

    if (type === 'unlocked') {
      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: 'Home Page',
      });
      navigate(terminalsPath[terminal]);
      navigate(0);
    }
  };

  const contactSupportTeamclick = () => {
    Mixpanel.track('user_support_event', {
      action: 'contact support Team button click, to view request demo form',
      page: 'Home Page',
      terminal: appPermission[terminal],
    });
    setOpen();
  };

  const renderComingSoon = () => (
    <div className="cs-container">
      <div className="cs-header">{terminalContent[terminal]?.content.comingSoon.header}</div>
      <div className="cs-sub-header">{terminalContent[terminal]?.content.comingSoon.subHeader}</div>
    </div>
  );

  const renderRequestAcces = () => (
    <div className="ra-container">
      <div className="ra-texts">
        <div className="ra-header">{terminalContent[terminal]?.content.requestAccess.header}</div>
      </div>
      <button onClick={() => contactSupportTeamclick()} className="ra-btn request" type="button">
        Contact Support Team
        <SvgLoader className="ra-arrow" width={40} height={40} svgName="right-arrow" />
      </button>
    </div>
  );

  const renderCard = () => (
    <div aria-hidden onClick={handlClick}>
      <div className={`app-image ${terminalContent[terminal].label}`}>
        <div className="card-icon">
          <SvgLoader width={40} height={40} svgName={(type === 'locked' ? 'locked-icon' : '') || (type === 'comming soon' ? 'in-progress-icon' : '')} />
        </div>
      </div>
      <div className="app-name">
        {appPermission[terminal]}
      </div>
      <div className="app-detail">
        {checkWords(description)}
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        whileHover={{
          scale: 1.1,
          opacity: 1,
          transition: { duration: 1 },
        }}
        aria-hidden
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onBlur={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        className="terminal-card"
      >
        {hovered && type === 'locked' ? renderRequestAcces() : type === 'locked' && renderCard()}
        {hovered && type === 'comming soon' ? renderComingSoon() : type === 'comming soon' && renderCard()}
        {type === 'unlocked' && renderCard()}
      </motion.div>
    </AnimatePresence>
  );
}

Card.propTypes = {
  terminal: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Card;
