import React, { useState } from 'react';
import Dropdown from '../../../common/components/Dropdown';

function AdminDropdown({ options, handleOrgTypeUpdate, prefilled }) {
  const [selected, setSelected] = useState(prefilled || options[1]);

  const handleSelect = (value) => {
    handleOrgTypeUpdate(value.key);
    setSelected(value);
  };

  return (
    <div>
      <Dropdown
        options={options}
        value={selected}
        onChange={handleSelect}
        className={['admin-dropdown', 'admin-dropdown-panel', 'admin-dropdown-option']}
        defaultMessage="Select Organization Type"
      />
    </div>
  );
}

export default AdminDropdown;
