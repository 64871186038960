const sideOptions = [
  {
    display_name: 'About',
    value: 'about',
    icon: 'about-sidebar-icon',
  },
  // {
  //   display_name: 'Timeline',
  //   value: 'timeline',
  //   icon: 'study-details-sidebar-icon',
  // },
  {
    display_name: 'Study Details',
    value: 'study_details',
    icon: 'study-details-sidebar-icon',
  },
  {
    display_name: 'Eligibility',
    value: 'eligibility',
    icon: 'eligibility-sidebar-icon',
  },
  {
    display_name: 'Channel',
    value: 'channel',
    icon: 'channel-sidebar-icon',
  },
  {
    display_name: 'Results',
    value: 'results',
    icon: 'results-sidebar-icon',
  },
  {
    display_name: 'Study Documents',
    value: 'study_documents',
    icon: 'study-documents-sidebar-icon',
  },
  {
    display_name: 'History of Changes',
    value: 'history',
    icon: 'history-sidebar-icon',
  },
  {
    display_name: 'Mentions in Other Sources',
    value: 'mentions',
    icon: 'mentions-sources-sidebar-icon',
  },
  {
    display_name: 'Associated Documents',
    value: 'associated_documents',
    icon: 'associted-docs-sidebar-icon',
  },
];

export default sideOptions;
