import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../../../utils';
import { removeSession } from '../../../common/container/Status/logic';

function TrailPeriodExpiredModal() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = useState(getCookie('trialPeriodExpired') === 'true' && getCookie('rejectedApprovedl') !== 'true');

  const handleClose = () => {
    setOpen(false);
    setCookie(false, 'trialPeriodExpired');
    setTimeout(() => dispatch(removeSession()), 0);
  };

  if (!getCookie('accessToken') || getCookie('trialPeriodExpired') !== 'true' || location.pathname === '/callback') {
    return null;
  }

  return (
    <Modal className="trial-status-modal" onCancel={() => handleClose()} footer={false} open={open} closeIcon={false} width={604}>
      <div className="trial-status-modal-ctr">
        <div className="expired-image" />
        <div className="ts-header">Oh No! Your trial period has expired</div>
        <div className="ts-sub-header">Trial period of 240 minutes has completed. For unlimited free access please register from a organisational email ID or wait for the team to approved your account. </div>
        <div aria-hidden onClick={() => handleClose()} className="okay-btn">Okay</div>
      </div>
    </Modal>
  );
}

export default TrailPeriodExpiredModal;
