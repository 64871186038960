import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get Partner details
const CHECK_USER = 'CHECK_USER';
const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';
const CHECK_USER_REFRESH = 'CHECK_USER_REFRESH';

export const checkUserAction = createAction(CHECK_USER);

const checkUserSuccess = createAction(CHECK_USER_SUCCESS);

const checkUserFailure = createAction(CHECK_USER_FAILURE);

export const checkUserRefreshAction = createAction(CHECK_USER_REFRESH);

export const checkUserEpic = (actions$) => actions$.pipe(
  ofType(CHECK_USER),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/checkUser?${action.payload.headers}`, 'GET', action.payload.params)).pipe(
    map((res) => checkUserSuccess(res)),
    catchError((err) => of(checkUserFailure(err))),
  )),
);

const checkUserReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(checkUserAction, (state) => {
      state.loading = true;
    })
      .addCase(checkUserSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkUserFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkUserRefreshAction, (state) => {
        state.data = {};
        state.loading = false;
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const REGISTER_USER_DATA = 'REGISTER_USER_DATA';
const REGISTER_USER_DATA_SUCCESS = 'REGISTER_USER_DATA_SUCCESS';
const REGISTER_USER_DATA_FAILURE = 'REGISTER_USER_DATA_FAILURE';
const REGISTER_USER_DATA_REFRESH = 'REGISTER_USER_DATA_REFRESH';

export const registerUserAction = createAction(REGISTER_USER_DATA);

const registerUserSuccess = createAction(REGISTER_USER_DATA_SUCCESS);

const registerUserFaliure = createAction(REGISTER_USER_DATA_FAILURE);

export const registerUserRefreshAction = createAction(REGISTER_USER_DATA_REFRESH);

export const registerUserEpic = (actions$) => actions$.pipe(
  ofType(REGISTER_USER_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/register?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => registerUserSuccess(res)),
    catchError((err) => of(registerUserFaliure(err))),
  )),
);

const onregisterUserAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onregisterUserSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const onregisterUserFailure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  data: { ...response.error },
  error: true,
});

const onregisterUserRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const registerUserReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(registerUserAction, (state) => onregisterUserAction(state))
      .addCase(registerUserSuccess, (state, action) => onregisterUserSuccess(state, action.payload))
      .addCase(registerUserFaliure, (state, action) => onregisterUserFailure(state, action.payload))
      .addCase(registerUserRefreshAction, () => onregisterUserRefreshAction())
      .addDefaultCase((state) => state);
  },
);

export default checkUserReducer;
