import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeSession } from '../../container/Status/logic';
import SvgLoader from '../SvgLoader';

function Logout({ className }) {
  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(removeSession());
    localStorage.removeItem('chatMessages');
  };

  const classes = classNames('navbar-group-right-item', className);

  return (
    <div aria-hidden onClick={onLogoutClick} className={classes}>
      <SvgLoader width={20} height={20} svgName="sign-out-icon" />
      {' '}
      Sign out
    </div>
  );
}

Logout.propTypes = {
  webAuth: PropTypes.PropTypes.shape({
    logout: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

Logout.defaultProps = {
  className: '',
};

export default Logout;
