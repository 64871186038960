import React from 'react';
import { useNavigate } from 'react-router-dom';
import Mixpanel from '../../../utils/mixpanel';
import { debounceCall } from '../../../utils';

function Footer({ type }) {
  const navigate = useNavigate();

  const handleContactClick = () => {
    Mixpanel.track('bottom_bar_event', {
      misc: 'contact us',
      action: 'contact button click, to view contact us page',
      page: window.location.pathname,
    });
    navigate('/contact');
    navigate(0);
  };

  const handlePrivacyPolicyclick = () => {
    Mixpanel.track('bottom_bar_event', {
      misc: 'privacy policy',
      action: 'privacy policy button click, to view privacy policy page',
      page_name: window.location.pathname,
    });
    navigate('/privacyPolicy');
    navigate(0);
  };

  const handleUserPolicyClick = () => {
    Mixpanel.track('bottom_bar_event', {
      action: 'User policy button click, to view user policy page',
      page_name: window.location.pathname,
      misc: 'user policy',
    });
    navigate('/userPolicy');
    navigate(0);
  };

  const handleInnoplexusLogoclick = () => {
    debounceCall(() => {
      Mixpanel.track('bottom_bar_event', {
        action: 'Footer Innoplexus logo click, redirected to https://www.innoplexus.com/',
        page_name: window.location.pathname,
        misc: 'innoplexus.com',
      });
    }, 50);
  };

  return (
    <div className={`footer-main${type === 'light' ? '-light' : ''} disp-flex vcenter`} style={{ minHeight: 35 }}>
      <div className="container-fluid full-width">
        <div className="footer-links-content">
          <div className="logo-center">
            {type !== 'light' ? (
              <a aria-label="innoplexus-logo" label="innoplexus" target="_blank" rel="noopener noreferrer" onClick={() => handleInnoplexusLogoclick()} href="https://www.innoplexus.com/" className="footer-innoplexus-logo m-lr-20">
                <div className="footer-logo-new" />
              </a>
            ) : null}
            <div className={`footer-option-container${type === 'light' ? '-light' : ''}`}>
              <div className="footer-options">
                <div aria-hidden className={`option-text${type === 'light' ? '-light' : ''} pointer`} onClick={() => handleContactClick()}>Contact</div>
                <div aria-hidden onClick={() => handlePrivacyPolicyclick()} className={`option-text${type === 'light' ? '-light' : ''} pointer`}>Privacy Policy</div>
                <div aria-hidden onClick={() => handleUserPolicyClick()} className={`option-text${type === 'light' ? '-light' : ''} pointer`}>User Policy</div>
              </div>
              <div className={`option-text${type === 'light' ? '-light' : ''}`}>Copyright © 2024 | Partex NV</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
