import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES';
const GET_SAVED_SEARCHES_SUCCESS = 'GET_SAVED_SEARCHES_SUCCESS';
const GET_SAVED_SEARCHES_FAILURE = 'GET_SAVED_SEARCHES_FAILURE';
const GET_SAVED_SEARCHES_REFRESH = 'GET_SAVED_SEARCHES_REFRESH';

export const getSavedSearchesAction = createAction(GET_SAVED_SEARCHES);

const getSavedSearchesSuccess = createAction(GET_SAVED_SEARCHES_SUCCESS);

const getSavedSearchesFaliure = createAction(GET_SAVED_SEARCHES_FAILURE);
export const getSavedSearchesRefreshAction = createAction(
  GET_SAVED_SEARCHES_REFRESH,
);

export const getSavedSearchesEpic = (actions$) => actions$.pipe(
  ofType(GET_SAVED_SEARCHES),
  switchMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}/common/v0/workspace/saveSearch/getSaveSearch`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => getSavedSearchesSuccess(res)),
    catchError((err) => of(getSavedSearchesFaliure(err))),
  )),
);

const ongetSavedSearchesAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSavedSearchesSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetSavedSearchesFaliure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});
const onGetSavedSearchesRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const getSavedSearchesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getSavedSearchesAction, (state) => ongetSavedSearchesAction(state))
      .addCase(getSavedSearchesSuccess, (state, action) => ongetSavedSearchesSuccess(state, action.payload))
      .addCase(getSavedSearchesFaliure, (state) => ongetSavedSearchesFaliure(state))
      .addCase(getSavedSearchesRefreshAction, () => onGetSavedSearchesRefresh())
      .addDefaultCase((state) => state);
  },
);

const CREATE_SAVED_SEARCH = 'CREATE_SAVED_SEARCH';
const CREATE_SAVED_SEARCH_SUCCESS = 'CREATE_SAVED_SEARCH_SUCCESS';
const CREATE_SAVED_SEARCH_FAILURE = 'CREATE_SAVED_SEARCH_FAILURE';
const CREATE_SAVED_SEARCH_REFRESH = 'CREATE_SAVED_SEARCH_REFRESH';

export const createSavedSearchAction = createAction(CREATE_SAVED_SEARCH);

const createSavedSearchSuccess = createAction(CREATE_SAVED_SEARCH_SUCCESS);

const createSavedSearchFailure = createAction(CREATE_SAVED_SEARCH_FAILURE);

export const createSavedSearchRefresh = createAction(
  CREATE_SAVED_SEARCH_REFRESH,
);

export const createSavedSearchEpic = (actions$) => actions$.pipe(
  ofType(CREATE_SAVED_SEARCH),
  switchMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}/common/v0/workspace/saveSearch/createSaveSearch`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => createSavedSearchSuccess(res)),
    catchError((err) => of(createSavedSearchFailure(err))),
  )),
);

const onCreateSavedSearchAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onCreateSavedSearchSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  showNotification: true,
  flag: true,
});

const onCreateSavedSearchFailure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onCreateSavedSearchRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const createSavedSearchReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(createSavedSearchAction, (state) => onCreateSavedSearchAction(state))
      .addCase(createSavedSearchSuccess, (state, action) => onCreateSavedSearchSuccess(state, action.payload))
      .addCase(createSavedSearchFailure, (state, action) => onCreateSavedSearchFailure(state, action.payload))
      .addCase(createSavedSearchRefresh, () => onCreateSavedSearchRefresh())
      .addDefaultCase((state) => state);
  },
);

const SHARE_SAVE_SEARCH = 'SHARE_SAVE_SEARCH';
const SHARE_SAVE_SEARCH_SUCCESS = 'SHARE_SAVE_SEARCH_SUCCESS';
const SHARE_SAVE_SEARCH_FAILURE = 'SHARE_SAVE_SEARCH_FAILURE';
const SHARE_SAVE_SEARCHS_REFRESH = 'SHARE_SAVE_SEARCHS_REFRESH';

export const shareSaveSearchAction = createAction(SHARE_SAVE_SEARCH);

const shareSaveSearchSuccess = createAction(SHARE_SAVE_SEARCH_SUCCESS);

const shareSaveSearchFaliure = createAction(SHARE_SAVE_SEARCH_FAILURE);

export const shareSaveSearchRefreshAction = createAction(SHARE_SAVE_SEARCHS_REFRESH);

export const shareSaveSearchEpic = (actions$) => actions$.pipe(
  ofType(SHARE_SAVE_SEARCH),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/saveSearch/shareSearch`, 'POST', action.payload.body)).pipe(
    map((res) => shareSaveSearchSuccess(res)),
    catchError((err) => of(shareSaveSearchFaliure(err))),
  )),
);

const onShareSaveSearchAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onShareSaveSearchSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  showNotification: false,
  flag: true,
});

const onShareSaveSearchFaliure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onShareSaveSearchRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const shareSaveSearchReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(shareSaveSearchAction, (state) => onShareSaveSearchAction(state))
      .addCase(shareSaveSearchSuccess, (state, action) => onShareSaveSearchSuccess(state, action.payload))
      .addCase(shareSaveSearchFaliure, (state, action) => onShareSaveSearchFaliure(state, action.payload))
      .addCase(shareSaveSearchRefreshAction, () => onShareSaveSearchRefresh())
      .addDefaultCase((state) => state);
  },
);

const HASH_GENERATOR = 'HASH_GENERATOR';
const HASH_GENERATOR_SUCCESS = 'HASH_GENERATOR_SUCCESS';
const HASH_GENERATOR_FAILURE = 'HASH_GENERATOR_FAILURE';
const HASH_GENERATOR_REFRESH = 'HASH_GENERATOR_REFRESH';

export const hashGeneratorAction = createAction(HASH_GENERATOR);

const hashGeneratorSuccess = createAction(HASH_GENERATOR_SUCCESS);

const hashGeneratorFailure = createAction(HASH_GENERATOR_FAILURE);

export const hashGeneratorRefresh = createAction(
  HASH_GENERATOR_REFRESH,
);

export const hashGeneratorEpic = (actions$) => actions$.pipe(
  ofType(HASH_GENERATOR),
  switchMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}/common/v0/workspace/saveSearch/hash-generator`,
      'POST',
      action.payload.body,
    ),
  ).pipe(
    map((res) => hashGeneratorSuccess(res)),
    catchError((err) => of(hashGeneratorFailure(err))),
  )),
);

const onhashGeneratorAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const onhashGeneratorSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  showNotification: true,
  flag: true,
});

const onhashGeneratorFailure = (state, { response }) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
  data: { ...response },
});

const onhashGeneratorRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

const hashGeneratorReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(hashGeneratorAction, (state) => onhashGeneratorAction(state))
      .addCase(hashGeneratorSuccess, (state, action) => onhashGeneratorSuccess(state, action.payload))
      .addCase(hashGeneratorFailure, (state, action) => onhashGeneratorFailure(state, action.payload))
      .addCase(hashGeneratorRefresh, () => onhashGeneratorRefresh())
      .addDefaultCase((state) => state);
  },
);
export {
  createSavedSearchReducer, getSavedSearchesReducer, shareSaveSearchReducer, hashGeneratorReducer,
};
