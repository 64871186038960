const TrendLineOptions = [
  {
    name: 'Average Enrollment',
    value: ['Average Planned Enrolment', 'Average Actual Enrolment'],
  },
  {
    name: 'Cumulative Enrollment',
    value: ['Cumulative Actual Enrolment', 'Cumulative Planned Enrolment'],
  },
];

export default TrendLineOptions;
