import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get Partner details
const REQUEST_OTP = 'REQUEST_OTP';
const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';
const REQUEST_OTP_FAILURE = 'REQUEST_OTP_FAILURE';
const REQUEST_OTP_REFRESH = 'REQUEST_OTP_REFRESH';

export const requestOtpAction = createAction(REQUEST_OTP);

const requestOtpSuccess = createAction(REQUEST_OTP_SUCCESS);

const requestOtpFailure = createAction(REQUEST_OTP_FAILURE);

export const requestOtpRefreshAction = createAction(REQUEST_OTP_REFRESH);

export const requestOtpEpic = (actions$) => actions$.pipe(
  ofType(REQUEST_OTP),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/onboarding/getOTP?${action.payload.headers}`, 'POST', action.payload.params)).pipe(
    map((res) => requestOtpSuccess(res)),
    catchError((err) => of(requestOtpFailure(err))),
  )),
);

const requestOtpReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(requestOtpAction, (state) => {
      state.loading = true;
    })
      .addCase(requestOtpSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(requestOtpFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(requestOtpRefreshAction, (state) => {
        state.data = {};
        state.flag = false;
        state.error = false;
        state.loading = false;
      })
      .addDefaultCase((state) => state);
  },
);

export default requestOtpReducer;
