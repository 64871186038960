import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CookieSupport extends Component {
  constructor() {
    super();
    this.cookieEnabled = navigator.cookieEnabled;
    this.state = {
      cookieAllowed: !!localStorage.getItem('cookie_allowed') || false,
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    localStorage.setItem('cookie_allowed', 1);
    this.setState({ cookieAllowed: true });
  }

  render() {
    if (!this.cookieEnabled) {
      return <div className="loader">There seems to be an issue, please allow cookies and try again!!!</div>;
    }

    const { cookieAllowed } = this.state;
    const { children } = this.props;

    return (
      <>
        {
          (!cookieAllowed)
          && (
          <div className="cookie-notification">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                  <div className="cookie-notification-text">By continuing to use our site, you agree to our cookie policy</div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <button type="button" className="cookie-notification-button" onClick={this.onDismiss}>Dismiss</button>
                </div>
              </div>
            </div>
          </div>
          )
        }
        {children}
      </>
    );
  }
}

CookieSupport.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CookieSupport.defaultProps = {
  children: null,
};

export default CookieSupport;
