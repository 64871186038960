import React from 'react';

const processText = (requestedText) => {
  const textChunks = requestedText.split(' ');
  const textChunks2 = [];

  let start = 0;
  const checkStarts = /^\*\*/;
  const checkEnds = /\*\*:$/;
  const checkEndsDoubleAsterisk = /\*\*$/;
  const checkBold = /^\*\*.*\*:$/;
  const checkStartAndEndsWithDoubleAsterisk = /^\*\*.*\*\*$/;
  let temp = '';

  while (start < textChunks.length) {
    if (checkStarts.test(textChunks[start]) && !checkBold.test(textChunks[start])) {
      temp += `${textChunks[start]} `;
      start += 1;
      while (start < textChunks.length && !checkEnds.test(textChunks[start]) && !checkEndsDoubleAsterisk.test(textChunks[start])) {
        temp += `${textChunks[start]} `;
        start += 1;
      }
      temp += `${textChunks[start]} `;
      textChunks2.push(temp);
      start += 1;
      temp = '';
    } else {
      textChunks2.push(textChunks[start]);
      start += 1;
    }
  }

  const checkIndent = /^\d+(\.)$/;

  return textChunks2.map((value, i) => {
    if (checkStartAndEndsWithDoubleAsterisk.test(value.trim())) {
      return (
        <span>
          <strong>{`${value.slice(2, value.length - 3)}:`}</strong>
          {' '}
        </span>
      );
    }

    if (checkBold.test(value.trim())) {
      return (
        <span>
          <strong>{`${value.slice(2, value.length - 4)}:`}</strong>
          {' '}
        </span>
      );
    }

    if (checkIndent.test(value)) {
      return (
        <span>
          <br />
          {value}
        </span>
      );
    }
    if (i !== textChunks.length - 1) {
      return (
        <span>
          {value}
          {' '}
        </span>
      );
    }
    return <span>{value}</span>;
  });
};

function TextComponent({ text }) {
  if (!text) {
    return null;
  }

  return (
    <div>{processText(text)}</div>
  );
}

export default TextComponent;
