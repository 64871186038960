import React from 'react';
import Header from '../../../common/components/Header';
import SelectionV2 from '../../container/SelectionV2';

function HomePageV2() {
  return (
    <div className="main-page">
      <Header className="header-home" />
      <SelectionV2 />
      <div>Terminals</div>
    </div>
  );
}

export default HomePageV2;
