import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_CHATBOT_ANSWER = 'GET_CHATBOT_ANSWER';
const GET_CHATBOT_ANSWER_SUCCESS = 'GET_CHATBOT_ANSWER_SUCCESS';
const GET_CHATBOT_ANSWER_FAILURE = 'GET_CHATBOT_ANSWER_FAILURE';
const GET_CHATBOT_ANSWER_REFRESH = 'GET_CHATBOT_ANSWER_REFRESH';

export const getChatbotAnswerAction = createAction(GET_CHATBOT_ANSWER);

const getChatbotAnswerSuccess = createAction(GET_CHATBOT_ANSWER_SUCCESS);

const getChatbotAnswerFaliure = createAction(GET_CHATBOT_ANSWER_FAILURE);

export const getChatbotAnswerRefreshAction = createAction(GET_CHATBOT_ANSWER_REFRESH);

export const getChatbotAnswerEpic = (actions$) => actions$.pipe(
  ofType(GET_CHATBOT_ANSWER),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/chatbot?${action.payload.params.headers}`, 'GET')).pipe(
    map((res) => getChatbotAnswerSuccess(res)),
    catchError((err) => of(getChatbotAnswerFaliure(err))),
  )),
);

const ongetChatbotAnswerAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetChatbotAnswerSuccess = (state, { response }) => ({
  ...state,
  data: { ...response.data },
  loading: false,
  flag: true,
});

const ongetChatbotAnswerFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetChatbotAnswerRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const getChatbotAnswerReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getChatbotAnswerAction, (state) => ongetChatbotAnswerAction(state))
      .addCase(getChatbotAnswerSuccess, (state, action) => ongetChatbotAnswerSuccess(state, action.payload))
      .addCase(getChatbotAnswerFaliure, (state) => ongetChatbotAnswerFailure(state))
      .addCase(getChatbotAnswerRefreshAction, () => ongetChatbotAnswerRefreshAction())
      .addDefaultCase((state) => state);
  },
);
