import { combineEpics } from 'redux-observable';
import { getfieldValuesEpic } from './InterventionSearchBox/logic';
import { getInterventionSearchResultsEpic, getMoreInterventionSearchResultsEpic, getInterventionSummaryEpic } from './InterventionList/logic';
import { getPatentsDataEpic } from './PatentsList/logic';
import { getCollapisibleGraphEpic } from './CollapsibleGraph/logic';

const rootInterventionEpic = combineEpics(
  getfieldValuesEpic,
  getInterventionSearchResultsEpic,
  getMoreInterventionSearchResultsEpic,
  getInterventionSummaryEpic,
  getPatentsDataEpic,
  getCollapisibleGraphEpic,
);

export default rootInterventionEpic;
