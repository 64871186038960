import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import GraphTypeHandler from '../../components/GraphTypeHandler';

function AnalyticsContainer({ params }) {
  const widgets = useSelector((state) => state.widgets.widgets);
  const [currentExpanded, setCurrentExpanded] = useState('');
  const [isGraphClicked, setIsGraphClicked] = useState(false);

  const renderGraphs = () => widgets.map((item) => (
    <GraphTypeHandler
      key={item.value}
      params={params}
      selected={item}
      currentExpanded={currentExpanded}
      setExpanded={setCurrentExpanded}
      setIsGraphClicked={setIsGraphClicked}
    />
  ));

  return (
    <div className={isGraphClicked ? 'widget-container-large widget-container' : 'widget-container'}>
      {renderGraphs()}
    </div>
  );
}

AnalyticsContainer.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default AnalyticsContainer;
