import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_COLLAPISIBLE_GRAPH_DATA = 'GET_COLLAPISIBLE_GRAPH_DATA';
const GET_COLLAPISIBLE_GRAPH_DATA_SUCCESS = 'GET_COLLAPISIBLE_GRAPH_DATA_SUCCESS';
const GET_COLLAPISIBLE_GRAPH_DATA_FAILURE = 'GET_COLLAPISIBLE_GRAPH_DATA_FAILURE';
const GET_COLLAPISIBLE_GRAPH_DATA_REFRESH = 'GET_COLLAPISIBLE_GRAPH_DATA_REFRESH';

export const getCollapisibleGraphAction = createAction(GET_COLLAPISIBLE_GRAPH_DATA);

const getCollapisibleGraphSuccess = createAction(GET_COLLAPISIBLE_GRAPH_DATA_SUCCESS);

const getCollapisibleGraphFaliure = createAction(GET_COLLAPISIBLE_GRAPH_DATA_FAILURE);

export const getCollapisibleGraphRefresh = createAction(GET_COLLAPISIBLE_GRAPH_DATA_REFRESH);

export const getCollapisibleGraphEpic = (actions$) => actions$.pipe(
  ofType(GET_COLLAPISIBLE_GRAPH_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/intervention/v0/analytics/collapsible-graph?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getCollapisibleGraphSuccess(res)),
    catchError((err) => of(getCollapisibleGraphFaliure(err))),
  )),
);

const ongetCollapisibleGraphAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetCollapisibleGraphSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  field: response.field,
  displayField: response.displayField,
  loading: false,
  flag: true,
  error: false,
});

const ongetCollapisibleGraphFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetCollapisibleGraphRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getCollapisibleGraphReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getCollapisibleGraphAction, (state) => ongetCollapisibleGraphAction(state))
      .addCase(getCollapisibleGraphSuccess, (state, action) => ongetCollapisibleGraphSuccess(state, action.payload))
      .addCase(getCollapisibleGraphFaliure, (state) => ongetCollapisibleGraphFailure(state))
      .addCase(getCollapisibleGraphRefresh, () => ongetCollapisibleGraphRefresh())
      .addDefaultCase((state) => state);
  },
);

const COLLAPSIBLE_GRAPH_EXPANDED = 'COLLAPSIBLE_GRAPH_EXPANDED';

const INTIAL_STATE = {
  isExpanded: false,
};

export const getCollapsibleGraphStateAction = createAction(COLLAPSIBLE_GRAPH_EXPANDED);

export const getCollapsibleGraphStateReducer = createReducer(INTIAL_STATE, (builder) => {
  builder
    .addCase(getCollapsibleGraphStateAction, (state, action) => {
      state.isExpanded = action.payload;
    })
    .addDefaultCase((state) => state);
});
