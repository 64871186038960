import React from 'react';
import { useDispatch } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import NoDataFound from '../NoDataFound';

function Loader(props) {
  const dispatch = useDispatch();
  const {
    loading, width = '100%', height = 'calc(100vh - 110px)', style = {}, error, errorMessage = '', retryAction = undefined, className = '',
  } = props;
  if (loading) {
    return (
      <div className={`loader ${className}`} style={{ height, width, ...style }}>
        <div className="spinner">
          <div className="bounce5" />
          <div className="bounce6" />
          <div className="bounce7" />
        </div>
      </div>
    );
  }
  if (error) {
    const styleLoader = {
      height,
      width,
      ...style,
      textAlign: 'center',
    };
    return (
      <div style={styleLoader} className="loader">
        {errorMessage || 'Oops! There has been an issue. Re-try in some time.'}
        {
          retryAction ? <button type="button" onClick={() => dispatch(retryAction())}>Retry</button> : null
        }
      </div>
    );
  }

  const {
    message = '', noData = false, children = null,
  } = props;
  if (noData) {
    return <NoDataFound message={message} className={className ? `loader ${className}` : 'loader'} style={{ height, width, ...style }} />;
  }
  return (
    <ErrorBoundary height={height} width={width}>
      {children}
    </ErrorBoundary>
  );
}

export default Loader;
