import React from 'react';

function HeaderV2() {
  return (
    <div className="header-v2-ctr">
      <div className="ontosight-logo-header-v2" />
    </div>
  );
}

export default HeaderV2;
