import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import DeepDiveSideBar from '../../container/DeepDiveSideBar';
import DeepDiveContainer from '../../container/DeepDiveContainer';
import { getExploreDeepDiveDetailsAction, getExploreDeepDiveMentionsDetailsAction, getExploreDeepDiveHistoryDetailsAction } from '../../container/DeepDiveContainer/logic';
import Loader from '../../../common/components/Loader';
import { setBackButtonStatusAction } from '../../container/CTdeepDive/logic';

function CTDeepDivePage({
  setShowDeeopdive, showDataFor, setDrawerWidth, setPreviewCardData, redirectTo,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const deepdivepage = searchParams.get('deepdivepage');
  const location = useLocation();

  const [selectedOption, setSelectedOption] = useState('about');
  // eslint-disable-next-line no-unused-vars
  const [pdfUrl, setPdfUrl] = useState('');
  const isSideBarClicked = useRef(false);
  const exploreDeepDiveDetails = useSelector((state) => state.getExploreDeepDiveDetails);
  const currentDocId = useSelector((state) => state.currentDocId);

  useEffect(() => {
    const isDeepDiveTypeSE = location.pathname.includes('searchAndExplore');
    let url = '';
    let apiUrl = '';
    if (isDeepDiveTypeSE) {
      url = `/explore/v0/search/${showDataFor.type}/resource/${showDataFor.doc_id}`;
      apiUrl = '/explore/v0/search/clinical_trials/related';
    } else {
      url = `/clinical-trials/v0/search/clinical_trials/resource/${showDataFor.doc_id || currentDocId.docId}`;
      apiUrl = '/clinical-trials/v0/search/clinical_trials/related';
    }
    dispatch(getExploreDeepDiveDetailsAction({
      params: {
        url,
        headers: queryString.stringify({
          terminal_name: 'clinical_trials',
          view: 'deepDiveView',
        }),
      },
    }));
    dispatch(getExploreDeepDiveMentionsDetailsAction({
      params: {
        url: apiUrl,
        headers: queryString.stringify({
          from: 1,
          size: 50,
          trial_id: showDataFor?.trial_id,
          relation: 'mentioned_data',
        }),
      },
    }));
    dispatch(getExploreDeepDiveHistoryDetailsAction({
      params: {
        url: apiUrl,
        headers: queryString.stringify({
          from: 1,
          size: 50,
          trial_id: showDataFor?.trial_id,
          relation: 'history_change_data',
        }),
      },
    }));
  }, [currentDocId, showDataFor]);

  useEffect(() => {
    if (exploreDeepDiveDetails.flag) {
      setPdfUrl(exploreDeepDiveDetails?.data?.data?.study_documents[0]?.url);
    }
  }, [JSON.stringify(exploreDeepDiveDetails)]);

  const handleBackClick = () => {
    dispatch(setBackButtonStatusAction(false));
    setShowDeeopdive(false);
    setPreviewCardData({});
    setDrawerWidth(600);
  };

  return (
    <div className="clinical-trials-deep-dive-page">
      {!deepdivepage?.length
        ? (
          <div className="back-btn-container" role="presentation" onClick={handleBackClick}>
            <span className="back-arrow" />
            <span>Back</span>
          </div>
        )
        : null}
      <Loader loading={exploreDeepDiveDetails.loading} error={exploreDeepDiveDetails.error}>
        <div className="clinical-trials-deep-dive-container" style={{ display: 'flex' }}>
          <DeepDiveSideBar setSelectedOption={setSelectedOption} selectedOption={selectedOption} isSideBarClicked={isSideBarClicked} redirectTo={redirectTo} />
          <DeepDiveContainer selectedOption={selectedOption} setSelectedOption={setSelectedOption} isSideBarClicked={isSideBarClicked} showDataFor={showDataFor} pdfUrl={pdfUrl} />
        </div>
      </Loader>
    </div>
  );
}

CTDeepDivePage.propTypes = {
  setShowDeeopdive: PropTypes.func.isRequired,
  showDataFor: PropTypes.instanceOf(Object).isRequired,
  setPreviewCardData: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default CTDeepDivePage;
