import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'qs';
import SvgLoader from '../../../common/components/SvgLoader';
import { getQuestionsDataAction } from './logic';
import Loader from '../../../common/components/Loader';
import { wordLimit } from '../../../utils';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

function RecommendedQuestions({ currentExpanded, selected, params }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const questionsData = useSelector((state) => state.questionsData);
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');
  const filters = searchParams.get('filters');
  const f = JSON.parse(filters);

  const handleRowClick = (row) => {
    const tempQuery = [{
      field_name: 'semantic_search',
      currentFilter: [encodeURIComponent(row)],
      type: 'sematic',
    }];
    navigate(`/explore/analytics?semanticquery=${JSON.stringify(tempQuery)}`);
    navigate(0);
  };

  useEffect(() => {
    const tempParams = { ...params };
    const request = {
      body: {
        filters: {
          ...tempParams.body.filters,
        },
      },
      headers: QueryString.stringify({
        semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
      }),
    };
    const tempQ = JSON.parse(query);

    tempQ?.forEach((item) => {
      if (item.currentSynonymKey) {
        request.body.filters[item.name] = [{
          value: item.currentSynonymKey[0] !== null ? item.currentSynonymKey : item?.currentKeys[0],
          operator: 'OR',
          asset_class: 'ot_search_assetclasses',
        }];
      }
    });
    f?.forEach((item) => {
      if (item.currentSynonymKey) {
        request.body.filters[item.name] = [{
          value: item.currentSynonymKey[0] !== null ? item.currentSynonymKey[0] : item?.currentKeys[0],
          operator: 'OR',
          asset_class: 'ot_search_assetclasses',
        }];
      }
    });
    debounceCall(() => {
      dispatch(getQuestionsDataAction(request));
    }, 60);
  }, []);

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="questions-header-text">
            {selected?.label}
            <div className="partex-ai-icon" />
          </div>
        </div>
      </div>
      <div className="questions-data-container">
        <Loader
          style={{ left: 180 }}
          error={questionsData.error}
          loading={questionsData.loading}
          noData={!questionsData?.data?.length || questionsData?.data[0]?.count === 0}
          height="250px"
          className="white-loader"
          message="No records found"

        >
          {/* <div id="topics-bar" /> */}
        </Loader>
        <table>
          <tbody>
            {questionsData.data.slice(0, 5).map((row) => (
              <tr key={row} className="clickable-row">

                <td>
                  <div className="questions-bar" role="presentation" onClick={() => handleRowClick(row)}>
                    <div className="search-questions" role="presentation" />
                    <div className="questions-title">{wordLimit(row, 60)}</div>
                    <div className="search-arrow" />

                  </div>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

RecommendedQuestions.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    graphData: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
};

export default RecommendedQuestions;
