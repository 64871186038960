import React from 'react';
import PropTypes from 'prop-types';
import SvgLoader from '../../../common/components/SvgLoader';

function AboutSection({ deepdivedata }) {
  return (
    <div className="detail-container about">
      <div className="about-section-header">
        {deepdivedata?.study_status ? (
          <>
            <div className="header-item status">{deepdivedata?.study_status}</div>
            <SvgLoader className="divider" width={10} height={20} svgName="vertical-line" />
          </>
        ) : null}
        {deepdivedata?.phase ? <div className="header-item pahse">{deepdivedata?.phase}</div> : null}
      </div>
      <div className="about-section title">{deepdivedata?.title}</div>
      <div className="about-section trial-id">
        <span>TRIAL ID: </span>
        <span>{deepdivedata?.trial_id}</span>
      </div>
      <div className="about-section trial-snapshot">
        <div className="snapshot-title">TRIAL SNAPSHOT</div>
        <div className="snapshot-container">
          <div className="snapshot-right">
            <div className="snapshot-list">
              <div className="snapshot-list-title">Enrollment (Anticipated)</div>
              <div className="snapshot-list-value">{deepdivedata?.enrollment_anticipated}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Enrollment (Actual)</div>
              <div className="snapshot-list-value">{deepdivedata?.enrollment_actual}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Phase</div>
              <div className="snapshot-list-value">{deepdivedata?.phase || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Study Dates</div>
              <div className="snapshot-list-value">{deepdivedata?.start_date || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Study Intervention(s)</div>
              <div className="snapshot-list-value">{deepdivedata?.intervention?.length ? deepdivedata?.intervention?.map((itm, i) => `${itm?.name}${i < (deepdivedata.intervention.length - 1) ? ', ' : ''}`) : 'Data unavailable'}</div>
            </div>
          </div>
          <div className="snapshot-right">
            <div className="snapshot-list">
              <div className="snapshot-list-title">Registry name</div>
              <div className="snapshot-list-value registry">{deepdivedata?.registry || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Condition(s)</div>
              <div className="snapshot-list-value">{deepdivedata?.indications?.length ? deepdivedata?.indications?.map((item, i) => `${item}${i < (deepdivedata.indications.length - 1) ? ', ' : ''}`) : 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Sponsor</div>
              <div className="snapshot-list-value">{deepdivedata?.sponsors || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Current recruitment status</div>
              <div className="snapshot-list-value">{deepdivedata?.recruitment_status || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Trial completion date</div>
              <div className="snapshot-list-value">{deepdivedata?.completion_date || 'Data unavailable'}</div>
            </div>
            <div className="snapshot-list">
              <div className="snapshot-list-title">Primary completion date</div>
              <div className="snapshot-list-value">{deepdivedata?.primary_completion_date || 'Data unavailable'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section abstract">
        <div className="abstrat-title">ABSTRACT</div>
        <div className="abstrat-value">{deepdivedata?.abstract}</div>
      </div>
      {deepdivedata?.description
        ? (
          <div className="about-section description">
            <div className="description-title">DESCRIPTION</div>
            <div className="description-value">{deepdivedata?.description}</div>
          </div>
        ) : null}
    </div>
  );
}

AboutSection.propTypes = {
  deepdivedata: PropTypes.instanceOf(Object).isRequired,
};

export default AboutSection;
