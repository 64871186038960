import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SUMMARY_DATA = 'GET_SUMMARY_DATA';
const GET_SUMMARY_DATA_SUCCESS = 'GET_SUMMARY_DATA_SUCCESS';
const GET_SUMMARY_DATA_FAILURE = 'GET_SUMMARY_DATA_FAILURE';
const GET_SUMMARY_DATA_REFRESH = 'GET_SUMMARY_DATA_REFRESH';

export const getLiteratureSummaryDataAction = createAction(GET_SUMMARY_DATA);

const getLiteratureSummaryDataSuccess = createAction(GET_SUMMARY_DATA_SUCCESS);

const getLiteratureSummaryDataFaliure = createAction(GET_SUMMARY_DATA_FAILURE);

export const getLiteratureSummaryDataRefresh = createAction(GET_SUMMARY_DATA_REFRESH);

export const getLiteratureSummaryDataEpic = (actions$) => actions$.pipe(
  ofType(GET_SUMMARY_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/summary?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getLiteratureSummaryDataSuccess(res)),
    catchError((err) => of(getLiteratureSummaryDataFaliure(err))),
  )),
);

const ongetLiteratureSummaryDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const ongetLiteratureSummaryDataSuccess = (state, { response }) => ({
  ...state,
  data: response.data,
  loading: false,
  flag: true,
});

const ongetLiteratureSummaryDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetLiteratureSummaryDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getLiteratureSummaryDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getLiteratureSummaryDataAction, (state) => ongetLiteratureSummaryDataAction(state))
      .addCase(getLiteratureSummaryDataSuccess, (state, action) => ongetLiteratureSummaryDataSuccess(state, action.payload))
      .addCase(getLiteratureSummaryDataFaliure, (state) => ongetLiteratureSummaryDataFailure(state))
      .addCase(getLiteratureSummaryDataRefresh, () => ongetLiteratureSummaryDataRefresh())
      .addDefaultCase((state) => state);
  },
);
