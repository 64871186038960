/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tooltip, Input, Button, Modal, Popover,
} from 'antd';
import { useSearchParams } from 'react-router-dom';
import { dateFormat, wordLimit } from '../../../../../utils';
import Mixpanel from '../../../../../utils/mixpanel';
import SvgLoader from '../../../../components/SvgLoader';
// import List from '../List';
import Bookmark from '../../../Bookmark';

function BookmarkCardOne({ data, currentIndex = 0, type, cardDatad }) {
  // const [searchParams] = useSearchParams();
  // const query = searchParams.get('query');
  // const semanticQuery = searchParams.get('semanticquery');
  // const searchResult = useSelector((state) => state.searchResult);
  const [cardData, setCardData] = useState(
    {
      type: 'clinical_trials',
      display_type: 'Clinical Trials',
      doc_id: '8e51ef262cca6ce288383f22bd08c79d234e913bcda6c33f90b68a13',
      trial_id: 'NCT05857995',
      prediction_score: 0,
      study_start_date: 1723161600,
      first_submitted_date: 0,
      study_completion_date: '2027-06-30',
      source: 'ClinicalTrials.gov',
      data_source_link: 'https://clinicaltrials.gov/show/NCT05857995',
      study_type: 'INTERVENTIONAL',
      status: 'Planned Recruitment',
      phase: 'Other',
      phase_short: 'Other',
      enrollment_anticipated: 0,
      enrollment_actual: 0,
      intervention: [],
      indication: [
        'Lung Cancer',
        'Pulmonary Neoplasm',
        'Neoplasms, Lung',
        'Neoplasms, Pulmonary',
        'Neoplasm, Pulmonary',
        'Cancer, Lung',
        'Cancers, Lung',
        'Pulmonary Cancer',
        'Cancer, Pulmonary',
        'Cancers, Pulmonary',
        'Pulmonary Cancers',
        'Cancer of the Lung',
        'Cancer of Lung',
        'Pulmonary Neoplasms',
        'Lung Cancers',
      ],
      event_sponsor: [],
      laymen_title: 'Precision Lung Cancer Survivorship Care Intervention',
      scientific_title: 'Precision Lung Cancer Survivorship Care Intervention: A Randomized Controlled Trial Serving Rural Survivors and Communities',
      primary_observations: [
        {
          detail: 'The Functional Assessment of Cancer Therapy-Lung (FACT-L) is a 36-item self-report instrument that measures quality life. Scores range from 0 to 144 with higher scores indicating better quality of life.',
          metric: 'Change from Baseline (T0) in Mean Lung Cancer Quality of Life at 6 Months (T2)',
          is_safe: '',
          duration: 'Baseline (T0) and 6 Months (T2)',
          category: '',
          predicted_labels: '',
        },
      ],
      has_results: [],
      isBookmark: true,
    },
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [openBookmarkOpenModal, setOpenBookmarkOpenModal] = useState(false);
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);

  const getClassName = (str) => {
    const modifiedStr = (str ?? '').replace(/ /g, '-').toLowerCase();
    return modifiedStr;
  };

  //   useEffect(() => {
  //     if (searchResult?.flag) {
  //     //   setSearchResultData(searchResult?.data?.data);
  //       setSearchResultData([
  //         {
  //           type: 'clinical_trials',
  //           display_type: 'Clinical Trials',
  //           doc_id: '10c829077cceb5da07a62dbadd1790effb4555a47a479e0a76490117',
  //           trial_id: 'ISRCTN55277999',
  //           date: '23 Mar 2010',
  //           title: 'In vivo isolation of circulating tumor cells from peripheral blood of patients with lung cancer by using a structured antibody-coated nanodetector: An explorative mono-center non-randomized blinded trial',
  //           data_source: 'ISRCTN',
  //           data_source_link: 'http://www.isrctn.com/ISRCTN55277999?q=&filters=GT lastEdited:2000-03-01T00:00:00.000Z,LE lastEdited:2019-01-21T00:00:00.000Z&sort=&offset=7419&totalResults=17744&page=75&pageSize=100&searchType=advanced-search',
  //           indications: [
  //             'Lung cancer non small cell lung cancer - NSCLC, stage I-IV',
  //           ],
  //           interventions: [],
  //           prediction_score: 0,
  //           phase: 'Not applicable',
  //           phase_short: 'NA',
  //           study_status: 'Completed',
  //           sponsors: 'GILUPI GmbH Germany',
  //           study_results: [],
  //           isBookmark: true,
  //         },
  //       ]);
  //       if (currentIndex && !Object.keys(currentIndex)?.length) {
  //         setCardIndex(searchResult?.data?.data[0]);
  //       }
  //     }
  //   }, []);

  const trackEvent = (data) => {
    Mixpanel.track('trialsListViewEvent', {
      action: 'list view card click',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      query: JSON.parse(semanticQuery || query),
      page: 'List View Page',
      terminal_name: 'Clinical Trials',
      document_data: data,
    });
    // setCardIndex(data);
  };

  const renderTooltipValue = (dataset) => dataset?.map((item) => `${item} `);

  const handleTooltipformate = (dataset) => {
    const data = renderTooltipValue(dataset);
    let temp = '';
    if (data.length === 1) {
      return data;
    }

    data.forEach((item, i) => {
      if (i === data.length - 1) {
        temp += `${item}`;
      } else {
        temp += `${item}, `;
      }
    });

    return temp;
  };

  const renderClass = ({ prediction_score }) => {
    if (prediction_score > 65) {
      return 'pos-high';
    }
    if (prediction_score > 35 && prediction_score <= 65) {
      return 'pos-med';
    }
    return 'pos-low';
  };

  const renderField = (value, dataset, len = 0) => (
    <span className="filter">
      <span className="filter-value" title={handleTooltipformate(dataset)}>
        {' '}
        {wordLimit(value, 20)}
      </span>
      <span>{`${len > 1 ? ` +${len - 1}` : ''}`}</span>
    </span>
  );

  const renderSubDetailsDocument = ({
    event_sponsor,
    indication,
    intervention,
    study_start_date,
  }) => (
    <div className="sub-details-container">
      {event_sponsor?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="sponsor-icon" />
          {renderField(event_sponsor[0], event_sponsor, event_sponsor.length)}
        </div>
      ) : null}
      {indication?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="indication-icon" />
          {renderField(indication[0], indication, indication.length)}
        </div>
      ) : null}
      {intervention?.length ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="intervention-icon" />
          {renderField(intervention[0], intervention, intervention.length)}
        </div>
      ) : null}
      {study_start_date ? (
        <div className="sub-details">
          <SvgLoader width={16} height={16} svgName="calender-icon" />
          <span className="filter-value">
            {' '}
            {dateFormat(study_start_date * 1000)}
          </span>
        </div>
      ) : null}
    </div>
  );

  const renderTooltip = (predictionScore) => (
    <div className="prediction-tooltip-container">
      <div className="prediction-tooltip-data">
        <div className={predictionScore >= 65 ? 'high' : (predictionScore >= 35 && predictionScore < 65 ? 'medium' : 'low')} />
        <div className="prediction-tooltip-sub-container">
          <div className="prediction-score">{`${predictionScore} %`}</div>
          <div className="predition-title">Probability of success</div>
        </div>
      </div>

    </div>
  );

  const showShareModal = () => {
    setOpenShareModal(!openShareModal);
  };
  const showDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setOpenShareModal(!openShareModal);
  };

  const renderCard = () => {
    const item = data;
    return <div
      aria-hidden
      onClick={() => trackEvent(item)}
      className={`card-details ${currentIndex?.trial_id === item?.trial_id ? 'active' : ''
        }`}
      key={item.laymen_title}
    >
      <div className="status">
        <div className="status-content">
          <div className="normal">{item.source}</div>
          {item.trial_id || item.phase || item.status ? (
            <div className="dot" />
          ) : null}
          <div className="normal">{item.trial_id}</div>
          {item.phase || item.status ? <div className="dot" /> : null}
          <div className="normal">{item.phase}</div>
          {item.status ? <div className="dot" /> : null}
          <div className={getClassName(item.status)}>
            {item.status === 'Completed' ? (
              <div className="completed-icon" />
            ) : null}
            {item.status}
          </div>
          {item.prediction_score ? <div className="dot" /> : null}
          {item.prediction_score ? (
            <Tooltip
              placement="left"
              overlayClassName="prediction-tooltip position-zero"
              title={() => renderTooltip(item.prediction_score)}
              arrow={false}
            >
              <div
                role="presentation"
                className={renderClass(item)}
                id={`score${item.trial_id}`}
              >
                <SvgLoader width={11} height={8} svgName={renderClass(item)} />
                PoS
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div className='icon-section'>
          <Popover overlayClassName="bookmark-popover" placement="bottomRight" open={activeBookmarkId === item.trial_id} content={<Bookmark docId={item.doc_id} activeBookmarkId={activeBookmarkId} setActiveBookmarkId={setActiveBookmarkId} trialId={item.trial_id} onClose={() => setActiveBookmarkId(null)} />}>
            <div className="bookmark-icon" role="presentation" onClick={() => setActiveBookmarkId(activeBookmarkId === item.trial_id ? null : item.trial_id)} />

          </Popover>
          <div className="share-icon" role="presentation" onClick={showShareModal} />
        </div>

      </div>
      <div className="title">
        {item.scientific_title || item.laymen_title}
      </div>
      {renderSubDetailsDocument(item)}
    </div>
  }

  return (
    <>
      {renderCard()}
      {openShareModal && (

        <Modal className="share-with-modal" open={openShareModal} closable footer={false} onCancel={showShareModal}>
          <div className="share-modal-content">
            <div className="share-modal-content-input">
              <div className="share-with-title">Share with</div>
              <div className="share-with-email-input"><Input placeholder="Email ID" /></div>
              <div className="share-with-message-input"><Input placeholder="Message (Optional)" /></div>
              <div className="invite-btn">
                {' '}
                <Button type="primary" role="presentation">Invite</Button>
              </div>
            </div>
            <div className="shared-with-content">
              <div className="shared-with-title">Already shared with</div>
              <div className="shared-with-persons-data">
                <div className="shared-with-persons-list">
                  <div className="person-1 person-img" />
                  <div className="person-1-details person-details">
                    <div className="person-1-name person-name">John Anthony</div>
                    <div className="person-1-email person-email">john.anthony@hecase.com</div>
                  </div>
                  <div className="delete-icon" role="presentation" onClick={showDeleteModal} />
                </div>
                <div className="shared-with-persons-list">
                  <div className="person-2 person-img" />
                  <div className="person-2-details person-details">
                    <div className="person-2-name person-name">Alex Mack</div>
                    <div className="person-2-email person-email">alex.mack@hecase.com</div>
                  </div>
                  <div className="delete-icon" />
                </div>
              </div>
            </div>

          </div>
        </Modal>
      )}
      {openDeleteModal && (
        <Modal className="share-with-modal" open={openDeleteModal} closable footer={false} onCancel={showDeleteModal}>
          <div className="delete-modal-content">
            <div className="delete-title">Share with</div>
            <div className="delete-message">Are you sure you want to remove</div>
            <div className="delete-btn">
              <Button className="cancel-btn" type="primary" role="presentation" onClick={showDeleteModal}>No</Button>
              <Button className="add-btn" type="primary" role="presentation">Yes</Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

BookmarkCardOne.propTypes = {
  //   setCardIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.instanceOf(Object).isRequired,
};

export default BookmarkCardOne;
