import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import SvgLoader from '../../../common/components/SvgLoader';
import Loader from '../../../common/components/Loader';
import Mixpanel from '../../../utils/mixpanel';
import { getNewsLetterDataAction, getNewsLetterDataExpandedAction } from './logic';

function NewsLetter({
  params, selected, currentExpanded, setExpanded,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const semanticQuery = searchParams.get('semanticquery');
  const query = searchParams.get('query');

  const [isExpanded, setIsExpanded] = useState(false);
  const [reesponseData, setResponseData] = useState([]);
  const [isTimeout, setIsTimeout] = useState(false);
  const [reload, setReload] = useState(false);

  const getNewsLetterData = useSelector((state) => state.getNewsLetterData);
  const getNewsLetterDataExpanded = useSelector((state) => state.getNewsLetterDataExpanded);

  useEffect(() => {
    [getNewsLetterData, getNewsLetterDataExpanded].forEach((item) => {
      if (item.status !== 200 && item?.error) {
        if (item?.error && item?.status.message.includes('502')) {
          setIsTimeout(true);
        }
      }
    });
  }, [JSON.stringify(getNewsLetterData), JSON.stringify(getNewsLetterDataExpanded)]);

  useEffect(() => {
    if (!getNewsLetterData.flag || !getNewsLetterDataExpanded.flag || reload) {
      const request = {
        ...params,
        headers: queryString.stringify({
          semantic_search: semanticQuery?.length ? JSON.parse(semanticQuery)[0]?.currentFilter[0] : undefined,
        }),
      };
      dispatch(getNewsLetterDataAction(request));
      dispatch(getNewsLetterDataExpandedAction(request));
      setReload(false);
      setIsTimeout(false);
    }
  }, [isExpanded, reload]);

  useEffect(() => {
    if (getNewsLetterData?.flag) {
      setResponseData(isExpanded ? getNewsLetterDataExpanded?.data : getNewsLetterData?.data);
    }
  }, [JSON.stringify(getNewsLetterData), isExpanded]);

  const expandGraph = () => {
    Mixpanel.track('expand_widget_event', {
      action: 'expand newsletter widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Newsletter widget',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    setExpanded(selected.label);
    setIsExpanded(true);
  };

  const shrinkGraph = () => {
    Mixpanel.track('collapse_widget_event', {
      action: 'collapse newsletter widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Newsletter widget',
      terminal_name: 'Search and Explore',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
    setExpanded('');
    setIsExpanded(false);
  };

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  return (
    <div className={isExpanded ? 'expanded-widget' : ''}>
      <div className={`Widget ${isExpanded ? 'topics expanded-topics' : 'news-letter'}`}>
        <div className="widget-header">
          <div className="widget-sub-header">
            <SvgLoader width={17} height={34} svgName="widget-header-icon" />
            <div className="header-text">
              {selected.label}
              <sup className="partex-ai-icon">* Powered by Gen AI</sup>
              {' '}
            </div>
          </div>
          <div className="icons">
            {
              isExpanded
                ? <SvgLoader width={18.7} height={18.7} svgName="contract" hoverIconName="contract-hover" onClick={() => { shrinkGraph(); }} />
                : <SvgLoader width={18.7} height={18.7} svgName="expand" hoverIconName="expand-hover" onClick={() => { expandGraph(); }} />
            }
          </div>
        </div>
        { isTimeout
          ? (
            <div className="request-try-again">
              <span className="request-message">Response took longer than expected,</span>
              <div>
                <span className="request-message">please try again</span>
                <FontAwesomeIcon onClick={() => setReload(true)} icon={faRotateRight} className="pointer" />
              </div>
            </div>
          )
          : (
            <Loader
              loading={getNewsLetterData?.loading}
              error={getNewsLetterData?.error}
              noData={!getNewsLetterData?.data?.length}
              height="250px"
              className="white-loader"
              message="No records found"
            >
              <div className="news-letter-main">
                {reesponseData.map((itm) => (
                  <div className="news-list" key={itm}>
                    <div className="news-icon">
                      <SvgLoader width={12} height={12} svgName="news-letter-list-icon" />
                    </div>
                    <div title={itm} className="news-value">{itm}</div>
                  </div>
                ))}
              </div>
            </Loader>
          )}
      </div>
    </div>
  );
}

NewsLetter.propTypes = {
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
    body: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
};

export default NewsLetter;
