import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import queryString from 'query-string';
import apiCall from '../../../utils';

const GET_TYPES_VALUES = 'GET_TYPES_VALUES';
const GET_TYPES_VALUES_SUCCESS = 'GET_TYPES_VALUES_SUCCESS';
const GET_TYPES_VALUES_FAILURE = 'GET_TYPES_VALUES_FAILURE';
const GET_TYPES_VALUES_REFRESH = 'GET_TYPES_VALUES_REFRESH';

export const getTypesValuesAction = createAction(GET_TYPES_VALUES);

const getTypesValuesSuccess = createAction(GET_TYPES_VALUES_SUCCESS);

const getTypesValuesFaliure = createAction(GET_TYPES_VALUES_FAILURE);

export const getTypesValuesRefreshAction = createAction(GET_TYPES_VALUES_REFRESH);

export const getTypesValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_TYPES_VALUES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.params}`, 'get', {}, false, action.payload.headers)).pipe(
    map((res) => getTypesValuesSuccess(res)),
    catchError((err) => of(getTypesValuesFaliure(err))),
  )),
);

export const getTypesValuesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
    fieldName: '',
  },
  (builder) => {
    builder.addCase(getTypesValuesAction, (state, action) => {
      state.loading = true;
      state.fieldName = queryString.parse(action.payload.params).field_name;
      state.flag = false;
    })
      .addCase(getTypesValuesSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getTypesValuesFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addCase(getTypesValuesRefreshAction, (state) => {
        state.data = [];
        state.loading = false;
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const CURRENT_SHOW_DROPDOWN = 'CURRENT_SHOW_DROPDOWN';

export const updateCurrentDropdownAction = createAction(CURRENT_SHOW_DROPDOWN);

export const updateCurrentDropdownReducer = createReducer(
  {
    id: -1,
  },
  (builder) => {
    builder.addCase(updateCurrentDropdownAction, (state, action) => {
      state.id = action.payload;
    })
      .addDefaultCase((state) => state);
  },
);
