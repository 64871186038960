import getFieldValuesReducer from './InterventionSearchBox/logic';
import { getInterventionSummaryReducer, getInterventionSearchResultsReducer } from './InterventionList/logic';
import { interventionWidgetsReducer } from './InterventionAnalyticsContainer/logic';
import { getPatentsDataReducer } from './PatentsList/logic';
import { getCollapisibleGraphReducer, getCollapsibleGraphStateReducer } from './CollapsibleGraph/logic';

const rootInterventionReducer = ({
  fieldValuesResponse: getFieldValuesReducer,
  getInterventionSearchResults: getInterventionSearchResultsReducer,
  interventionSummary: getInterventionSummaryReducer,
  interventionWidgets: interventionWidgetsReducer,
  patentsData: getPatentsDataReducer,
  collapisibleGraphData: getCollapisibleGraphReducer,
  getCollapsibleGraphState: getCollapsibleGraphStateReducer,
});

export default rootInterventionReducer;
