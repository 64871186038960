/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import SEDeepDivePage from '../../pages/SEDeepDivePage';
import PreviewSectionCard from '../PreviewSectionCard';
import SvgLoader from '../../../common/components/SvgLoader';
import { wordLimit } from '../../../utils';

function ExploreSummaryCard({ activeList }) {
  const [showDeepdive, setShowDeepdive] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [isDuplicateCardExpanded, setIsDuplicateCardExpanded] = useState(false);
  const [showDeepdiveFor, setshowDeepdiveFor] = useState({});
  const [searchParams] = useSearchParams();
  const deepdivepage = searchParams.get('deepdivepage');

  useEffect(() => {
    if (deepdivepage) {
      setShowDeepdive(true);
      setshowDeepdiveFor(JSON.parse(deepdivepage));
    }
  }, [deepdivepage]);

  const toggleExpand = () => {
    setReadMore(!readMore);
  };

  const renderListDetails = (label, value) => (
    <div className="explore-details-card-list">
      <span className="explore-details-card-list title">{`${label}:`}</span>
      <span className="explore-details-card-list value">
        {value?.map((itm, idx) => `${itm.full_name}${idx < value.length - 1 ? ', ' : ''}`)}
      </span>

    </div>
  );

  return (
    <>
      <div className={`explore-summary-card ${isDuplicateCardExpanded ? 'expanded-duplicate-card' : ''}`}>
        <div className={`search-and-explore-details-card ${activeList?.type === 'clinical_trials' ? 'ct-preview-page' : ''}`}>
          <div className="explore-details-card-header">
            {activeList?.trial_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Trial ID:</div>
                <div className="source value">{activeList.trial_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.publication_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Publication ID:</div>
                <div className="source value" title={activeList.publication_id}>{activeList.publication_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.congress_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Congress ID:</div>
                <div className="source value" title={activeList.congress_id}>{activeList.congress_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.guideline_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Guideline ID:</div>
                <div className="source value" title={activeList.guideline_id}>{activeList.guideline_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.grant_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Grant ID:</div>
                <div className="source value" title={activeList.grant_id}>{activeList.grant_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.patent_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Claims</div>
                <div className="source title">Patent ID:</div>
                <div className="source value" title={activeList.patent_id}>{activeList.patent_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) : activeList?.press_id ? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                <div className="source title">Press ID:</div>
                <div className="source value" title={activeList.press_id}>{activeList.press_id}</div>
                <div className="partex-ai-icon" />
              </div>
            ) :  activeList?.type=='regulatory_data' || activeList?.type=='hta_recommendations'? (
              <div className="explore-details-header-container">
                <div className="summary-title">Summary</div>
                {/* <div className="source title">Press ID:</div> */}
                {/* <div className="source value" title={activeList.press_id}>{activeList.press_id}</div> */}
                <div className="partex-ai-icon" />
              </div>
            ):null}
          </div>
          {activeList?.type === 'patents' && activeList?.claims?.length? (
            <div className="explore-details-card-title">
              {readMore ? activeList?.claims[0] : wordLimit(activeList?.claims[0], 700)}
              <span onClick={toggleExpand} className='read-more-btn' role='presentation'>
                {readMore ? 'read less' : 'read more'}
              </span>
            </div>
          ) : (
            <div className="explore-details-card-title">
              {activeList?.summary}
            </div>
          )}

          {(['publications', 'congresses', 'guidelines'].includes(activeList?.type) && activeList?.authors?.length)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Authors:</span>
                <span className="explore-details-card-list value">{activeList?.authors?.map((author, idx) => (`${author?.author_name}${idx < (activeList.authors.length - 1) ? ',' : ''} `))}</span>
              </div>
            )
            : null}
          {(['publications', 'congresses', 'guidelines'].includes(activeList?.type) && activeList?.affiliation?.length)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Affiliations:</span>
                <span className="explore-details-card-list value">{activeList?.affiliation?.map((itm, idx) => `${itm}${idx < (activeList.affiliation.length - 1) ? ', ' : ''}`)}</span>
              </div>
            )
            : null}
            {(['publications', 'congresses', 'guidelines'].includes(activeList?.type) && activeList?.affiliations?.length)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Affiliations:</span>
                <span className="explore-details-card-list value">{activeList?.affiliations?.map((itm, idx) => `${itm}${idx < (activeList.affiliations.length - 1) ? ', ' : ''}`)}</span>
              </div>
            )
            : null}
          {(['hta_recommendations', 'regulatory_data'].includes(activeList?.type) && activeList?.regulatory_body)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Issuing body :</span>
                <span className="explore-details-card-list value">{activeList?.regulatory_body}</span>
              </div>
            )
            : null}
          {(['clinical_trials'].includes(activeList?.type) && activeList?.investigators?.length)
            ? renderListDetails('Investigator', activeList?.investigators)
            : null}
          {(['patents'].includes(activeList?.type) && activeList?.inventors)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Inventors :</span>
                <span className="explore-details-card-list value">{activeList?.inventors?.map((author, idx) => (idx < (activeList.inventors.length - 1) ? `${author}, ` : author))}</span>
              </div>
            )
            : null}
          {(['patents'].includes(activeList?.type) && activeList?.assignees)
            ? (
              <div className="explore-details-card-list">
                <span className="explore-details-card-list title">Assignees :</span>
                <span className="explore-details-card-list value">{activeList?.assignees?.map((assignee, idx) => (idx < (activeList.assignees.length - 1) ? `${assignee}, ` : assignee))}</span>
              </div>
            )
            : null}
          <div className="explore-details-card-list explore-details-card-list-journal-details">
            {['clinical_trials'].includes(activeList?.type)
              ? (
                <div className="journal-details-child">
                  <div className="explore-details-card-list-title">Study Type</div>
                  <div className="explore-details-card-list-value">{activeList?.study_type}</div>
                </div>
              )
              : null}
            {(['clinical_trials'].includes(activeList?.type) && activeList?.study_status)
              ? (
                <div className="journal-details-child">
                  <div className="explore-details-card-list-title">Study Status</div>
                  <div className="explore-details-card-list-value">{activeList?.study_status}</div>
                </div>
              )
              : null}
            {(['clinical_trials'].includes(activeList?.type) && activeList?.phase)
              ? (
                <div className="journal-details-child">
                  <div className="explore-details-card-list-title">Phase</div>
                  <div className="explore-details-card-list-value">{activeList?.phase}</div>
                </div>
              )
              : null}
            {(['clinical_trials'].includes(activeList?.type) && activeList?.sponsors)
              ? (
                <div className="journal-details-child">
                  <div className="explore-details-card-list-title">Sponsor(s)</div>
                  <div className="explore-details-card-list-value">{activeList?.sponsors}</div>
                </div>
              )
              : null}
            {(['clinical_trials'].includes(activeList?.type) && activeList?.endpoints)
              ? (
                <div className="journal-details-child">
                  <div className="explore-details-card-list-title">Endpoint(s)</div>
                  <div className="explore-details-card-list-value">{activeList?.endpoints}</div>
                </div>
              )
              : null}
            {activeList?.biological_concepts?.length
              ? (
                <div className="journal-details-child-bio-concepts">
                  <span className="explore-details-card-list-title">Key concepts :</span>
                  <span className="explore-details-card-list-value">{activeList?.biological_concepts.map((itm, idx) => `${itm}${idx < (activeList.biological_concepts.length - 1) ? ', ' : ''}`)}</span>
                </div>
              )
              : null}
          </div>
          {(['congresses'].includes(activeList?.type) && activeList?.description)
            ? (
              <div className="explore-details-card-list-content">
                <div className="explore-details-card-list-title">DESCRIPTION</div>
                <div className="explore-details-card-list-value">
                  {activeList?.description}
                </div>
              </div>
            )
            : null}
          {activeList?.data_source_link?( <div className="explore-details-card-list-content">
            <a
              className="visit-source-button"
              target="_blank"
              href={activeList?.data_source_link}
              rel="noreferrer noopener"
            >

              Visit Source
              <SvgLoader width={22} height={22} svgName="visit-source" />
            </a>
          </div>):(null)}
          <div className="dash-contanier">
            <div className="dash" />
          </div>
          {activeList?.type !== 'clinical_trials' && activeList?.type !== 'regulatory_data' && activeList?.type !== 'hta_recommendations' ? <div className="explore-details-card-header">
              <div className="explore-details-header-container adjust-asocciated-docs-width flex-col">
                <div className="summary-title">Associated Documents</div>
                <PreviewSectionCard
                  trialData={activeList}
                  setIsDuplicateCardExpanded={setIsDuplicateCardExpanded}
                  setShowDeepdive={setShowDeepdive}
                  showDataFor={setshowDeepdiveFor}
                  terminal='SE'
                />
              </div>
              <div>
              </div>
          </div> : null}
        </div>
        {activeList?.type === 'clinical_trials' ? (<div className="ct-preview-bottom-section">
          {activeList?.type === 'clinical_trials'
            ? (
              <PreviewSectionCard
                trialData={activeList}
                setIsDuplicateCardExpanded={setIsDuplicateCardExpanded}
                setShowDeepdive={setShowDeepdive}
                showDataFor={setshowDeepdiveFor}
              />
            ) : null}
          {activeList?.type === 'clinical_trials'
            ? (
              <div className="detailed-card-footer-btn">
                <Button
                  className="read-more-btn"
                  onClick={() => {
                    setshowDeepdiveFor(activeList);
                    setShowDeepdive(true);
                  }}
                >
                  Read more
                </Button>
              </div>
            ) : null}
        </div>) : (null)}

      </div>
      {showDeepdive ? <SEDeepDivePage setShowDeepdive={setShowDeepdive} showDataFor={showDeepdiveFor} setshowDeepdiveFor={setshowDeepdiveFor} /> : null}
    </>
  );
}

ExploreSummaryCard.propTypes = {
  activeList: PropTypes.instanceOf(Object).isRequired,
};

export default ExploreSummaryCard;
