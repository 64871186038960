/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import FilterDatePicker from '../FilterDatePicker';
import FilterMultiSelect from '../FilterMultiSelect';
import FilterDropdown from '../FilterDropdown/indes';
import FilterNumberRange from '../FilterNumberRange/indes';
import FilterMultiAutosuggest from '../FilterMultiAutosuggest';
import FilterAutosuggest from '../FilterAutosuggest';

function FilterInputType(props) {
  const { selected } = props;
  const renderFilters = (element) => {
    switch (element.type) {
      case 'autosuggest-multi':
        return <FilterMultiAutosuggest className={['autosuggest-multi', 'autosuggest-panel-filter', 'autosuggest-options']} {...props} selectedValue={element.key} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} subAssetClasse={element.subAssetClass ? element.subAssetClass : ''} />;
      case 'date-range':
        return <FilterDatePicker selectedValue={element.key} {...props} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} />;
      case 'dropdown':
        return <FilterDropdown selectedValue={element.key} {...props} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} />;
      case 'multiselect':
        return <FilterMultiSelect selectedValue={element.key} {...props} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} />;
      case 'number-range':
        return <FilterNumberRange selectedValue={element.key} {...props} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} />;
      case 'autosuggest':
        return <FilterAutosuggest className={['autosuggest-multi', 'autosuggest-panel-filter', 'autosuggest-options']} {...props} selectedValue={element.key} id={element.id} assetClasse={element.assetClass ? element.assetClass : ''} element={element} />;
      default:
        break;
    }
    return null;
  };

  return (
    <div className="filter-sub-container">
      <div className="filter-name">{selected.sectionName}</div>
      {selected.fields.map((item) => (
        <div className="sub-list" key={item.name}>
          {item.name ? <div className="filter-sub-name">{item.name}</div> : null}
          {renderFilters(item)}
        </div>
      ))}
    </div>
  );
}

FilterInputType.propTypes = {
  selected: PropTypes.PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
};

FilterInputType.defaultProps = {
  selected: ({
    type: '',
    name: '',
  }),
};

export default FilterInputType;
