const componayDetails = [
  {
    location: 'Frankfurt (Germany)',
    companyName: 'Innoplexus AG',
    addressFloor: 'Frankfurter Strasse 27,',
    street: '65760 Eschborn',
  },
  {
    location: 'Pune (India)',
    companyName: 'Innoplexus Consulting Services Pvt. Ltd.',
    addressFloor: '7th Floor, Midas Tower',
    street: 'Hinjewadi Phase 1 - Pune 57',
  },
  {
    location: 'Iselin (USA)',
    companyName: 'Innoplexus Holdings, Inc.',
    addressFloor: '33 Wood Avenue South',
    street: 'Suite 600, Iselin, NU 08830',
  },
  {
    location: 'Cham (Switzerland)',
    companyName: 'Amrit AG',
    addressFloor: 'Maschinengasse 12',
    street: '6330 Cham',
  },
];

export default componayDetails;
