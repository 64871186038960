import { getExploreSearchResultsReducer, getExploreSearchSummaryReducer } from './ExploreList/logic';
import { getTrendingTopicsDataReducer } from './TrendingTopics/logic';
import { getWordCloudDataReducer } from './WordCloud/logic';
import { getLiteratureSummaryDataReducer } from './LiteratureSummaryCard/logic';
import { getGrantDataReducer } from './GrantBarGraph/logic';
import { getCtSectionListReducer, getCtRelatedArticlesReducer, getDocsSEReducer } from '../components/PreviewSectionCard/logic';
import { getQuestionsDataReducer } from './RecommendedQuestions/logic';

const rootSearchAndExploreReducer = ({
  getExploreSearchResults: getExploreSearchResultsReducer,
  getExploreSearchSummary: getExploreSearchSummaryReducer,
  trendingTopics: getTrendingTopicsDataReducer,
  wordCloudData: getWordCloudDataReducer,
  getLiteratureSummaryData: getLiteratureSummaryDataReducer,
  getGrantData: getGrantDataReducer,
  getCtSectionList: getCtSectionListReducer,
  getCtRelatedArticles: getCtRelatedArticlesReducer,
  questionsData: getQuestionsDataReducer,
  seDocs: getDocsSEReducer,
});

export const searchAndExploreReducerWhiteList = [];

export default rootSearchAndExploreReducer;
