import { combineEpics } from 'redux-observable';
import { getGraphDataEpic } from './PieChart/logic';
import { getPhaseGraphDataEpic } from './PhaseGraph/logic';
import { getInterventionMeterEpic } from './InterventionMeter/logic';
import { getSponsorDataEpic } from './SpeedometerGraph/logic';
import { getEnrolomentDataEpic } from './EnrolementMeter/logic';
import { getEndpointsGraphDataEpic, getEndpointsStatusDataEpic } from './EndopointsGraph/logics';
import { getGeographicalDataEpic, getGeographicalStateDataEpic } from './Geographical/logic';
import { getExploreDeepDiveDetailsEpic, getExploreDeepDiveMentionsDetailsEpic, getExploreDeepDiveHistoryDetailsEpic } from './DeepDiveContainer/logic';
import { getSponsorDeepDiveDataEpic } from '../components/SponsorDeepDive/logic';
import { getNewsLetterDataEpic, getNewsLetterDataExpandedEpic } from './NewsLetter/logic';
import { getSummaryDataEpic } from './CardDetail/logic';

const rootClinicalTrialsEpic = combineEpics(
  getGraphDataEpic,
  getPhaseGraphDataEpic,
  getInterventionMeterEpic,
  getSponsorDataEpic,
  getEnrolomentDataEpic,
  getEndpointsGraphDataEpic,
  getEndpointsStatusDataEpic,
  getGeographicalDataEpic,
  getGeographicalStateDataEpic,
  getExploreDeepDiveDetailsEpic,
  getSponsorDeepDiveDataEpic,
  getNewsLetterDataEpic,
  getExploreDeepDiveMentionsDetailsEpic,
  getExploreDeepDiveHistoryDetailsEpic,
  getNewsLetterDataExpandedEpic,
  getSummaryDataEpic,
);

export default rootClinicalTrialsEpic;
