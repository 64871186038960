import React from 'react';
import componayDetails from './data';
import SvgLoader from '../../../common/components/SvgLoader';

function ContactDetails() {
  const handleIconClick = (type) => {
    if (type === 'youtube') {
      window.open('https://www.youtube.com/@Innoplexus', '_blank', 'noopener,noreferrer');
      return null;
    }

    window.open('https://www.linkedin.com/company/innoplexus', '_blank', 'noopener,noreferrer');
    return false;
  };

  const renderContacts = () => componayDetails.map((item) => (
    <div className="contact-details-ctr-content-in">
      <div className="cd-details-texts">
        <div className="cd-location-text">{item.location}</div>
        <div className="hor-br" />
        <div className="cd-company-text">{item.companyName}</div>
        <div className="cd-company-text">{item.addressFloor}</div>
        <div className="cd-company-text">{item.street}</div>
      </div>
      <div className="cd-dotter-line-sec">
        <div className="cd-dotter-line" />
      </div>
    </div>
  ));

  return (
    <div className="contact-details-ctr">
      <div className="contact-details-sub-ctr flex-row">
        <div className="contact-details-ctr-logo"><div className="cd-terminal-logo" /></div>
        <div className="contact-details-ctr-content">
          {renderContacts()}
          <div>
            <div className="write-to-us-text">Write to us</div>
            <div className="support-text">support@ontosight.ai</div>
            <div className="write-to-us-text">Follow us</div>
            <div className="social-media">
              <SvgLoader width={24} height={24} pointer svgName="linkden" onClick={() => handleIconClick('linkedin')} />
              <div className="youtube-icon pointer" role="presentation" onClick={() => handleIconClick('youtube')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
