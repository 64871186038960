import { createAction, createReducer, current } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SEARCH_POWER_TYPES = 'GET_SEARCH_POWER_TYPES';
const GET_SEARCH_POWER_TYPES_SUCCESS = 'GET_SEARCH_POWER_TYPES_SUCCESS';
const GET_SEARCH_POWER_TYPES_FAILURE = 'GET_SEARCH_POWER_TYPES_FAILURE';

export const getPowerSearchTypesAction = createAction(GET_SEARCH_POWER_TYPES);

const getPowerSearchTypesSuccess = createAction(GET_SEARCH_POWER_TYPES_SUCCESS);

const getPowerSearchTypesFaliure = createAction(GET_SEARCH_POWER_TYPES_FAILURE);

export const getPowerSearchTypesEpic = (actions$) => actions$.pipe(
  ofType(GET_SEARCH_POWER_TYPES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action.payload.apiUrl}?${action.payload.params}`, 'get', {}, false, action.payload.headers)).pipe(
    map((res) => getPowerSearchTypesSuccess(res)),
    catchError((err) => of(getPowerSearchTypesFaliure(err))),
  )),
);

export const getPowerSearchTypesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getPowerSearchTypesAction, (state) => {
      state.loading = true;
      state.flag = false;
    })
      .addCase(getPowerSearchTypesSuccess, (state, action) => {
        state.data = { ...action.payload.response.data.clinical_trials || action.payload.response.data.search_and_explore };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getPowerSearchTypesFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

const UPDATE_FILTER = 'UPDATE_FILTER';
const REMOVE_FILTER = 'REMOVE_FILTER';
const ADD_FILTER = 'ADD_FILTER';
const UPDATE_FILTER_URL = 'UPDATE_FILTER_URL';
const UPDATE_FILTER_INITIAL_STATE = 'UPDATE_FILTER_INITIAL_STATE';
const REFRESH_FILTER = 'REFRESH_FILTER';
const FILTER_EMPTY = 'FILTER_EMPTY';

const powerSearchFilter = [
  {
    field_name: 'indication', label: 'Indication', value: 'indication', type: 'autosuggest-multi', name: 'indication',
  },
];

const INTIAL_STATE = {
  filters: powerSearchFilter,
};

export const updateFilterAction = createAction(UPDATE_FILTER);

export const removeFilterAction = createAction(REMOVE_FILTER);

export const addFilterAction = createAction(ADD_FILTER);

export const updateFilterFromUrlAction = createAction(UPDATE_FILTER_URL);

export const updateFilterInitialStateAction = createAction(UPDATE_FILTER_INITIAL_STATE);

export const refreshFilterAction = createAction(REFRESH_FILTER);

export const filterEmptyAction = createAction(FILTER_EMPTY);

const updateFilter = (state, { filterOption, id }) => {
  const temp = [];
  state.filters.forEach((item, index) => {
    if (index === id) {
      temp.push({ ...item, ...filterOption });
    } else {
      temp.push(item);
    }
  });
  state.filters = [...temp];
  return state;
};

const removeFilter = (state, { id }) => {
  if (state.filters.length <= 1) {
    return state;
  }
  const temp = current(state).filters.filter((item, index) => index !== id);
  state.filters = [...temp];
  return state;
};

const addOption = (state, { newOption }) => {
  state.filters = [...current(state).filters, newOption];
  return state;
};

const updateFilterFromUrl = (state, payload) => {
  state.filters = [...payload];
  return state;
};

const onRefreshFilter = (state) => {
  state.filters = [...powerSearchFilter];
  return state;
};

const onFilterEmpty = (state) => {
  state.filters = [];
  return state;
};

const filtersReducer = createReducer(INTIAL_STATE, (builder) => {
  builder
    .addCase(updateFilterAction, (state, action) => updateFilter(state, action.payload))
    .addCase(removeFilterAction, (state, action) => removeFilter(state, action.payload))
    .addCase(addFilterAction, (state, action) => addOption(state, action.payload))
    .addCase(updateFilterFromUrlAction, (state, action) => updateFilterFromUrl(state, action.payload))
    .addCase(updateFilterInitialStateAction, (state, action) => updateFilterFromUrl(state, action.payload))
    .addCase(refreshFilterAction, (state) => onRefreshFilter(state))
    .addCase(filterEmptyAction, (state) => onFilterEmpty(state))
    .addDefaultCase((state) => state);
});

const GO_BTN_UPDATE_Z_INDEX = 'GO_BTN_UPDATE_Z_INDEX';

export const goBtnZIndexUpdateAction = createAction(GO_BTN_UPDATE_Z_INDEX);

export const goBtnZIndexUpdateReducer = createReducer({ zIndex: 0 }, (builder) => {
  builder
    .addCase(goBtnZIndexUpdateAction, (state, action) => {
      state.zIndex = action.payload;
    })
    .addDefaultCase((state) => state);
});

export default filtersReducer;
