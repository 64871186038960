import React, { useEffect } from 'react';
import Header from '../../components/Header';

const upList1 = [
  'has been collected by you in a diligent and professional manner and in accordance with the law;',
  'is de-identified such that the identity of source to whom Your Content relates is not identifiable or reasonably identifiable;',
  'comprises legitimate and original content such as, photos, diagrams and illustrations and that these have not been in any way copied from any secondary source; and',
  'will not infringe any third party intellectual property rights when used on, or in connection with, any Platform.',
];

const upList2 = [
  'To comply with all policies of Innoplexus or its Affiliates contained in, or referred to on, the Platform or as otherwise communicated to you by or on behalf of Innoplexus from time to time;',
  'Not to disrupt or interfere with the security of, or otherwise abuse, the Platform, or any services, system resources, software, accounts, servers or networks connected to or accessible through the Platform or affiliated or linked Web sites;',
  'Not to disrupt or interfere with any other user\'s enjoyment of the Platforms or affiliated or linked Web sites;',
  'Not to upload, post, or otherwise transmit through or on the Platform any viruses or other harmful, disruptive or destructive files;',
  'Not to use or attempt to use another\'s account, service or system without authorization from Innoplexus, or create or use a false identity on the Platform;',
  'Not to transmit through or on the Platform spam, chain letters, junk mail or any other type of unsolicited mass email to people or entities who have not agreed to be part of such mailings;',
  'Not to divulge your username and password to others either on or off the Platform;',
  'Not to attempt to obtain unauthorized access to any aspect of the Platform which is restricted from general access; and',
  'Not use the Platform to seek, provide or obtain specific medical advice, medical opinion, diagnosis or treatment as applied to any person’s condition or situation.',
];

const upList3 = [
  'Opt out of receiving online communications from us by clicking the ‘unsubscribe’ link at the bottom of the newsletter, advertisement or other communication (as applicable)',
  'Delete existing cookies from your web browser and/or disable cookies on your computer and/or other devices (as appropriate). Depending on the web browser you are using (e.g. Google Chrome, Safari, Internet Explorer, Mozilla Firefox), this can usually be done on your computer by accessing your ‘Browsing History’ through the ‘Settings’ or ‘Options’ menu (or equivalent) in your web browser, or if you are using a different device, then in the ‘Settings’ for that device. If you do not disable cookies in your web browser, and you subsequently visit our website, fresh cookies are likely to be placed and you may need to repeat the process of deleting existing cookies',
  'Alter your ad preference settings in each of Google and Facebook, and on the platforms of other providers, if you have access to do so',
];

const upList4 = [
  'As to the accuracy, currency, completeness, reliability or usefulness of, any information obtained through use of any Platform (including any advice, opinion, statement or other content or any products or services distributed or made available by third parties through any Platform);',
  'That confidentiality of information transmitted through any Platform will be maintained;',
  'That any Platform will be of merchantable quality, fit for a purpose or meet your requirements;',
  'That any access to, or use of, any Platform will:',
];

const up1 = 'By accessing the Site or any App (collectively any "Platform"), or by submitting the registration information on any Platform (at which point we ask you to agree to these Terms of Use) you create an agreement with us. Under this agreement, you agree to be bound by these terms of use as amended from time to time (Terms of Use). In return, we agree to allow you to access and use the Platforms until such access is terminated in accordance with these Terms of Use.';

const up2 = 'If you wish to add, curate, or edit content on a Platform, be able to contact other users, subscribe to services, have a profile page or be entitled to notification of material changes to these Terms of Use (collectively referred to as "Registration Features"), you must register on the site and create an account. Any such account may be terminated in accordance with these Terms of Use.';

// eslint-disable-next-line max-len
const up3 = 'The Platforms contain areas where you may post and share comments with other persons on a variety of subjects. You agree that you will not post or otherwise disseminate on or through any Platform unlawful, defamatory, harassing, libelous, tortious, abusive, offensive, threatening, or obscene communications or material of any kind, or materials which infringe or violate any third party\'s copyright, trademark, trade secret, privacy or other proprietary or property right, or that could constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law or regulation or, that are otherwise objectionable, including without limitation, content that evidences bigotry, racism, sexism, or hatred, or that promotes illegal activities, including without limitation unauthorized uses of medical devices, or physical harm against anyone. Innoplexus reserves the right but not the obligation to remove any materials it deems objectionable, in its sole discretion and without any reference to you. You agree to hold harmless Innoplexus products/platforms and its Associates from all claims from third parties based upon communications made or materials posted by you or upon your abuse of any Platform.';

const up4 = 'You expressly agree that you will not discuss or refer to any patient, any particular patient\'s medical condition or any other information which could identify a particular patient. You further expressly agree that you will not discuss, endorse or refer to "off label" uses of medical products, including any uses which have not been approved by the appropriate regulatory agency.';

const up5 = 'We receive and store certain types of information whenever you interact with us via our website. Our products/platforms automatically receive and record certain "traffic data" on our server logs including your IP address, cookie information, the page you requested, and your communications and interactions with other site registrants. Our products/platforms use this traffic data to help diagnose problems with its server and analyze trends. We automatically collect usage information, such as the numbers and frequency of visitors to our site. We may use this information to provide research, advice and information, including, without limitation, for benchmarking purposes. This data is only used in the aggregate. In addition, we may provide statistical information to our partners about how our customers, collectively, use our Site. We may share this type of statistical data so that our partners also understand how often people use areas of our Site so that they, too, may provide you with an optimal experience.';

const up6 = 'Your say and How to \'Opt out\'';

const up7 = 'THE PLATFORMS, INCLUDING THE IMAGE AND TEXT CONTENT, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. YOU ACKNOWLEDGE HAVING READ AND UNDERSTOOD THESE TERMS OF USE, INCLUDING THE "MEDICAL DISCLAIMER", AND ANY OTHER CONDITIONS BROUGHT TO YOUR ATTENTION IN THE COURSE OF YOUR ACCESS TO, OR USE OF, ANY PLATFORM.';

function UserPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderList = (data) => data.map((item) => (
    <div className="list-items-policy" key={item}>
      <div className="dot-policy" />
      <div className="list-adjust">
        {item}
      </div>
    </div>
  ));

  return (
    <div className="main-page">
      <Header className="results-header-light" type="light" />
      <div className="policy">
        <div className="policy-container">
          <div className="main-header">User Policy</div>
          <div className="sub-text">Terms of Use:</div>
          <div className="text-policy">
            {up1}
          </div>
          <div className="text-policy">
            Please take the time to read these Terms of Use carefully as they contain important information regarding your legal rights, remedies and obligations. These include various limitations and exclusions and an important Medical Disclaimer (below). If you do not agree with these Terms of Use, please do not register for, access, or use the said Platforms / Products.
          </div>
          <div className="text-policy">
            If you accept these Terms of Use (including the Medical Disclaimer below), you may browse content on any Platform, and may continue to do so for so long as you accept these Terms of Use. Continued browsing will be deemed to be continued acceptance of these Terms of Use.
          </div>
          <div className="text-policy">
            {up2}
          </div>
          <div className="sub-text">Overview</div>
          <div className="text-policy">
            By accessing, browsing, or using any Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use and to comply with all applicable laws and regulations.
          </div>
          <div className="text-policy">
            Innoplexus may, without notice to you, at any time revise these Terms of Use by updating this post. It is your responsibility to regularly check the Platforms to determine if there have been changes to these Terms of Use and to review such changes. However, where required by law, and provided you have registered with the site and therefore have an account settings page, we will send you an email notifying you of any material changes to these Terms of Use to the email address recorded on your Account Settings Page, and if you subscribe for email updates (at the end of this document) we will endeavor to notify you (to the email address you subscribe) of all changes. We may also make improvements or changes in the products, services, or programs described on any Platform at any time without notice.
          </div>
          <div className="text-policy">
            These Terms of Use apply exclusively to your access to and use of the Platforms and do not alter the terms or conditions of any other agreement you may have with Innoplexus or with its products.
          </div>
          <div className="sub-text">Medical Disclaimer</div>
          <div className="text-policy">
            Content on the Platforms is provided for educational purposes only, and is provided for use by researchers, academicians and scientists and for research. It should not be used for self-diagnosis or self-treatment. It is not intended as, nor should it be, a substitute for independent professional medical care. We recommend that you consult your own physician regarding any diagnosis, imaging interpretation or course of treatment.
          </div>
          <div className="text-policy">
            As content is submitted by individual users, our products cannot guarantee its accuracy and assume no legal liability or responsibility for the accuracy, currency or completeness of the information.
          </div>
          <div className="text-policy">
            If you are not qualified and are registering as a lay person, you further acknowledge that the content on the Platforms is provided for educational purposes only, and is provided for use by professionals in the field of research. You agree to use the information solely for your own private educational purposes and further agree not to rely on the information in any way.
          </div>
          <div className="sub-text">Ownership and Licenses</div>
          <div className="sub-text">User contributed image content</div>
          <div className="text-policy">
            All rights in any image content which you contribute to any Platform are, unless otherwise agreed by Innoplexus in writing at the time you contribute the same, licensed by you to Innoplexus by means of a worldwide, perpetual, sub-licensable, non-exclusive license.
          </div>
          <div className="text-policy">
            Innoplexus grants to you a non-transferable and non-exclusive license of the Image Content License to the extent necessary, and only to such an extent, for you to access and use the Platforms. Image Content (other than Image Content contributed by you) may not be modified, copied, distributed, reused, reproduced, republished, downloaded, displayed, posted, transmitted, or sold by you in any form or by any means, in whole or in part outside the bounds of the license, without prior written permission from the contributing user, or if the contributing user has delegated such rights to our product on their Account Settings Page then from us.
          </div>
          <div className="sub-text">Text content</div>
          <div className="text-policy">
            The text content contributed by You to the products/platforms can be readily edited by other users of Innoplexus products/platforms and it is necessary that all rights in any text content which you contribute to any Platform (including but not limited to articles, case descriptions, blog posts, tutorials, image descriptions and all communications you transmit to anyone through any Platform) be the property of Innoplexus, and immediately vest in Innoplexus upon creation. Unless otherwise agreed by Innoplexus in writing, all such content is licensed to you for non-commercial use.
          </div>
          <div className="text-policy">
            You may modify Text Content in good faith and in the legitimate use of a Platform, but Text Content may not otherwise be modified, copied, distributed, reused, reproduced, republished, downloaded, displayed, posted, transmitted, or sold by you in any form or by any means, in whole or in part, outside the scope of this license, without prior written permission from Innoplexus.
          </div>
          <div className="text-policy">
            Where you contribute any Text Content to any Platform, you assign to Innoplexus free of charge and effective on making such contribution, all right, title and interest in such content (including any copyright, but excluding any non-assignable personal rights).
          </div>
          <div className="sub-text">Warranty</div>
          <div className="text-policy">
            In respect of all content (including Image Content and Text Content) which you contribute to any Platform (Your Content), you warrant that you have the right to contribute such content to the Platforms and to allow such content to be used in accordance with these Terms of Use, and you further warrant that such content:
          </div>
          <div className="text-policy">{renderList(upList1)}</div>
          <div className="sub-text">Moral rights,etc.</div>
          <div className="text-policy">
            Despite any provision to the contrary at law, you consent to Innoplexus making, and allowing third parties to make, any use of your content which would otherwise infringe any non-assignable personal rights which may subsist in your content.
          </div>
          <div className="text-policy">Platform Intellectual Property Rights</div>
          <div className="text-policy">
            All rights in all software, designs, arrangements, graphics pertaining to the Platforms belong to Innoplexus.
          </div>
          <div className="text-policy">
            All trademarks, product names, and company names and/or logos displayed on the Platforms are trademarks of Innoplexus or its Affiliate and may not be reproduced, imitated, or used, in whole or in part, without the prior written permission of Innoplexus. All page headers, custom graphics, button icons, and logos are service marks, trademarks and/or trade dress of our products or its Associates and may not be reproduced, imitated, or used, in whole or in part, without the prior written permission of Innoplexus.
          </div>
          <div className="sub-text">User Conduct</div>
          <div className="sub-text">General</div>
          <div className="text-policy">
            In using any Platform, you agree:
          </div>
          <div className="text-policy">{renderList(upList2)}</div>
          <div className="text-policy">
            All trademarks, product names, and company names and/or logos displayed on the Platforms are trademarks of Innoplexus or its Affiliate and may not be reproduced, imitated, or used, in whole or in part, without the prior written permission of Innoplexus. All page headers, custom graphics, button icons, and logos are service marks, trademarks and/or trade dress of our products or its Associates and may not be reproduced, imitated, or used, in whole or in part, without the prior written permission of Innoplexus.
          </div>
          <div className="sub-text">Online Communities and Other Interactive Areas</div>
          <div className="text-policy">
            {up3}
          </div>
          <div className="text-policy">
            {up4}
          </div>
          <div className="sub-text">What Personal Information About Customers Does Our Products/Platforms Collect?</div>
          <div className="text-policy">
            Our primary goal in collecting information is to provide you with a friendly, customized, and efficient experience. We collect the following types of information from our customers:
          </div>
          <div className="sub-text">Information You Provide to Us:</div>
          <div className="text-policy">
            Our products/platforms collect identifying information when you visit the Site (including, without limitation, any associated web pages or landing pages), and when you enter promotions or contests. For example, we require customers who register for information on the site to give us the following personal information: their name, Company name, address, phone number, and e-mail address. When you initially express interest in obtaining additional information, we may also ask for additional types of personal information, such as title, department name, fax number, or additional company information, such as annual revenues or marketing spend. We may also request demographic information and unique identifiers in order to provide you with a more personalized experience or verify your passwords.
          </div>
          <div className="sub-text">Automatic Information:</div>
          <div className="text-policy">
            {up5}
          </div>
          <div className="sub-text">E-mail Communications:</div>
          <div className="text-policy">
            We may receive a confirmation when you open an e-mail from our products/platforms, if your computer supports this type of program, in order to help us make emails more interesting and helpful. We may also send out emails with information regarding our products/platforms or our partners including promotions and events. Customers can opt out of being contacted by us by sending an email to
            {' '}
            <span className="make-it-blue">support@innoplexus.com.</span>
          </div>
          <div className="sub-text">Information from Other Sources:</div>
          <div className="text-policy">
            For purposes, such as improving personalization of our site, we may receive information about you from other sources and add it to our account information.
          </div>
          <div className="sub-text">Will our products/platforms Share Any of the Information it receives?</div>
          <div className="text-policy">
            Information about our customers is an integral part of our business. We neither rent nor sell your personal information to anyone. We share your personal information only with entities that are controlled or controlled by or under common control of Innoplexus, and as described below:
          </div>
          <div className="text-policy">
            Agents: We employ other companies and people to perform tasks on our behalf and may need to share your information with them to provide products or services to you. Examples may include removing repetitive information from customer lists, analyzing data, providing marketing assistance, processing credit card payments, and providing customer service. We may also provide your personal information to agents who will use it to verify aggregate usage data that we provide to our partners. In all cases where we share your information with such agents, we explicitly require the agent to acknowledge and adhere to Innoplexus’ privacy and customer data handling policies.
          </div>
          <div className="text-policy">
            Promotional Offers: We may send offers to certain customers on behalf of other businesses with whom we have a partnership. However, when we do so, we do not give the other business your name and address.
          </div>
          <div className="text-policy">
            Business Transfers: In some cases, we may choose to buy or sell assets or businesses. In these types of transactions, customer information is typically one of the business assets that are transferred. Moreover, if Innoplexus, or substantially all of its assets, were acquired, customer information would be one of the assets that are transferred.
          </div>
          <div className="text-policy">
            Protection of Innoplexus products/platforms and Others: We may release personal information when we believe in good faith that release is necessary to comply with that law; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Innoplexus, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
          </div>
          <div className="text-policy">
            With Your Consent: Except as set forth above, you will be notified when your personal information may be shared with third-parties.
          </div>
          <div className="sub-text">The way we use information</div>
          <div className="sub-text">User Information</div>
          <div className="text-policy">
            We use your User Information for communicating with you in relation to the Platforms, including contacting you in relation to your contributions, managing newsletter distributions, directly marketing our products to you and notifying you of administrative matters, such as changes to this privacy policy or alterations to the Terms of Use.
          </div>
          <div className="text-policy">
            If you are a registered user, we also publish your User Information, excluding your email address, on your profile contained within the Platforms. Other users may contact you via a ‘contact user’ link in your profile. However, when this link is activated and a communication window opens, your email address remains hidden to the user.
          </div>
          <div className="sub-text">Information Transferred or Stored Overseas</div>
          <div className="text-policy">
            Many of those other providers are located overseas and Usage Data disclosed to them may be stored on servers located overseas. While we endeavor to ensure that those other providers, and any third-party server provider used by us, manage any personal information contained in the Usage Data, and any User Information, in substantially the same way as required by the Indian Privacy Principles, we will not be responsible if they fail to do so, nor will we be obliged to take steps to ensure that those other providers comply with the privacy principles of India. By continuing to use the Platforms, you consent to your information being provided to, and used by, other providers (including any third-party server provider) located overseas.
          </div>
          <div className="text-policy">
            For further information about how each of the other providers referred to above handles personal information (which may include your Usage Data and User Information), we recommend that you visit their respective websites and read their respective privacy policies.
          </div>
          <div className="sub-text">Our commitment to data security</div>
          <div className="text-policy">
            To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
          </div>
          <div className="sub-text">{up6}</div>
          <div className="text-policy">
            You can contact us to request access to the User Information, and any other personal information, that we may hold. We will respond to your request within a reasonable period and provide you with access, except in certain limited circumstances. You may request us to correct any personal information that is not accurate, up-to-date, complete, and relevant or is misleading, and we will change it where reasonable to do so.
          </div>
          <div className="text-policy">
            For further information about access and correction, or if you have any complaints or concerns about how we manage your User Information or Usage Data, please contact us at the details provided below.
          </div>
          <div className="text-policy">
            If you no longer wish us to use your User Information, Usage Data or IP Address Details for certain purposes, you can do any or all of the following:
          </div>
          <div className="text-policy">{renderList(upList3)}</div>
          <div className="text-policy">
            If you do opt out of receiving direct marketing materials from us, we will no longer use your information for those purposes, however we may use your information for the limited purpose of contacting you directly in relation to your use of any Platform, or changes to these Terms of Use.
          </div>
          <div className="text-policy">
            If you disable cookies in your web browser, delete existing cookies, or take other steps to ‘opt out’, the Platforms may not work properly and your experience with the Platforms will be less personalized and may be less enjoyable. Deleting existing cookies from your web browser may also cause it to lose recorded information for other web sites, such as previously used usernames and passwords.
          </div>
          <div className="text-policy">
            Your withdrawal of consent for us to use your information for certain purposes may mean that we can no longer provide the Platforms to you.
          </div>
          <div className="sub-text">Contact Us</div>
          <div className="text-policy">
            Should you have any questions or concerns about this privacy policy, please contact us (preferably by email) at the following addresses:
            <span className="make-it-blue">support@innoplexus.com</span>
          </div>
          <div className="sub-text">Third Party Sites</div>
          <div className="text-policy">
            The Site may permit you to link to other websites on the Internet, and other websites may contain links to the product’s/platform’s site. These other websites are not under Innoplexus or its product’s/platform’s control, and such links do not constitute an endorsement by Innoplexus of those other websites or the services offered through them. The privacy and security practices of websites linked to or from the product’s site are not covered by this Privacy Statement, and the product/platform is not responsible for the privacy or security practices or the content of such websites.
          </div>
          <div className="sub-text">
            Disclaimers and Limitations of Liability
          </div>
          <div className="sub-text">
            General Disclaimer
          </div>
          <div className="text-policy">
            {up7}
          </div>
          <div className="sub-text">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU EXPRESSLY AGREE THAT:
          </div>
          <div>
            <div className="text-policy">
              1. YOUR USE OF ANY PLATFORM IS AT YOUR SOLE RISK;
            </div>
            <div className="text-policy">
              2. EXCEPT AS OTHERWISE EXPRESSLY AGREED IN WRITING, ONTOSIGHT
              <span><sup>®</sup></span>
              {' '}
              AND ITS ASSOCIATES EXPRESSLY DISCLAIM ANY IMPLIED OR EXPRESS REPRESENTATIONS OR WARRANTIES OF ANY KIND RELATING TO THE USE OF THE PLATFORMS;
            </div>
            <div className="text-policy">
              3. WITHOUT LIMITING THE FOREGOING, ONTOSIGHT
              <span><sup>®</sup></span>
              {' '}
              AND ITS ASSOCIATES ASSUME NO RESPONSIBILITY FOR, AND MAKE NO WARRANTY OR REPRESENTATION:
              <div className="text-policy adjust-left-margin">
                {renderList(upList4)}
                <div className="adjust-left-margin">
                  <div>1. Not infringe any rights (including intellectual property rights) of any third party; or</div>
                  <div>2. Be free of defects, viruses, or anything else (such as worms or Trojan horses) that may interfere with or damage the operations of a computer system;</div>
                  <div>3. Be uninterrupted or error free or;</div>
                </div>
              </div>
            </div>
            <div className="text-policy">
              4. YOU MUST TAKE YOUR OWN PRECAUTIONS TO ENSURE THAT WHATEVER YOU SELECT FOR YOUR USE FROM ANY PLATFORM IS FREE OF DEFECTS, VIRUSES OR ANYTHING ELSE (SUCH AS WORMS OR TROJAN HORSES) THAT MAY INTERFERE WITH OR DAMAGE THE OPERATIONS OF A COMPUTER SYSTEM; and
            </div>
            <div className="text-policy">
              5. ONTOSIGHT
              <span><sup>®</sup></span>
              {' '}
              AND ITS ASSOCIATES ARE NOT LIABLE TO YOU OR ANYONE ELSE IF INTERFERENCE WITH OR DAMAGE TO YOUR COMPUTER SYSTEM OCCURS IN CONNECTION WITH YOUR USE OF ANY PLATFORM
            </div>
          </div>
          <div className="text-policy">
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM OR THROUGH YOUR USE OF ANY PLATFORM, OR OTHERWISE FROM ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            OR ITS ASSOCIATES, CREATES ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS OF USE. YOU ACKNOWLEDGE THAT ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            DOES NOT CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH ANY PLATFORM.
          </div>
          <div className="sub-text">Limitation of Liability</div>
          <div className="text-policy">
            AS EXPRESSLY PROVIDED IN THE FOLLOWING SENTENCE, THE LIABILITY OF ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            AND ITS ASSOCIATES TO YOU IN CONNECTION WITH THESE TERMS OF USE WHERE ANY LEGISLATION IMPLIES IN THE AGREEMENT ANY TERM, CONDITION OR WARRANTY, AND ALSO RENDERS VOID ANY PROVISION IN A CONTRACT WHICH PURPORTS TO EXCLUDE OR MODIFY THE APPLICATION OR EXERCISE OF, OR LIABILITY UNDER, SUCH TERM, CONDITION OR WARRANTY, SUCH TERM, CONDITION OR WARRANTY WILL BE DEEMED TO BE INCLUDED IN THESE TERMS OF USE. HOWEVER, THE AGGREGATE LIABILITY OF ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            AND ITS ASSOCIATES FOR ANY BREACH OF SUCH TERM, CONDITION OR WARRANTY WILL BE LIMITED, AT ONTOSIGHT&apos;S OPTION, TO ANY ONE OR MORE OF THE FOLLOWING:
          </div>
          <div className="text-policy">
            If the breach relates to services:
          </div>
          <div className="adjust-left-margin">
            <div className="text-policy">
              1. The supplying of the services again; or
            </div>
            <div className="text-policy">
              2. The payment of the cost of having the services supplied again.
            </div>
          </div>
          <div className="text-policy">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            AND ITS ASSOCIATES EXCLUDE ALL LIABILITY TO ANY PERSON FOR LOSS OR DAMAGE OF ANY KIND (HOWSOEVER CAUSED, INCLUDING BY NEGLIGENCE) ARISING FROM OR RELATING IN ANY WAY TO ANY ACCESS TO OR USE OF ANY PLATFORM (INCLUDING LIABILITY FOR ANY SPECIAL, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING, DAMAGES FOR LOSS OF PROFITS OR REVENUES, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA OR COSTS OF REPLACEMENT GOODS, OR OTHERWISE, EVEN IF SAME HAVE BEEN EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES).
          </div>
          <div className="sub-text">Indemnity</div>
          <div className="text-policy">
            YOU MUST INDEMNIFY AND KEEP INDEMNIFIED ONTOSIGHT
            <span><sup>®</sup></span>
            {' '}
            AND ITS ASSOCIATES AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS FROM AND AGAINST ANY CLAIMS, LOSSES, LIABILITIES, COSTS, EXPENSES (INCLUDING INVESTIGATIVE COSTS, COURT COSTS, LEGAL FEES, PENALTIES, FINES AND INTEREST) AND DAMAGES OF ANY KIND (INCLUDING THOSE WHICH ARE PROSPECTIVE OR CONTINGENT) WHATSOEVER AND HOWSOEVER, DIRECTLY OR INDIRECTLY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR YOUR USE OF ANY PLATFORM, INCLUDING LIABILITY ARISING IN CONNECTION WITH YOUR BREACH OF THESE TERMS.
          </div>
          <div className="sub-text">Termination</div>
          <div className="text-policy">
            Innoplexus may, as it sees fit, for any reason or no reason at all, without liability to you or any third party, terminate your account or your access to the Web Platform and if you fail to comply with these Terms of Use, product/platform may terminate your account or your access to any App Platform. If we exercise any such termination right we will send an email to the e-mail address recorded on your Account Settings Page and termination will be effective upon us sending that email (and will be considered effective even if the email is not delivered for any reason).
          </div>
          <div className="text-policy">
            You may terminate your account for any reason at any time by sending an email clearly requesting that your account be terminated to
            {' '}
            <span className="make-it-blue">support@innoplexus.com</span>
            {' '}
            and we will implement your request promptly.
          </div>
          <div className="text-policy">
            If your account is terminated, we may, as we see fit, delete any content relating to your use of any Platform on our servers or otherwise in our possession. Immediately upon termination, either by you or us, of your account you will no longer be permitted to use any Registration Features on that Platform. You may continue to browse content on the Web Platform but your continued browsing will be deemed to be continued acceptance of these Terms of Use.
          </div>
          <div className="sub-text">
            Governing Law; Venue and Jurisdiction
          </div>
          <div className="sub-text">
            Applicable Law
          </div>
          <div className="text-policy">
            These Terms of Use shall be governed by the laws of India.
          </div>
          <div className="sub-text">
            Competent Jurisdiction
          </div>
          <div className="text-policy">
            In the absence of mutual agreement, any dispute arising from the interpretation and/or performance of the present Terms of Use shall be submitted to Arbitration in accordance with the Arbitration and Conciliation Act, 1996, India to be held at Pune, Maharashtra, India in front of a Sole Arbitrator to be appointed mutually by both the parties. The language to be used shall be English.
          </div>
          <div className="sub-text">
            Entire Agreement
          </div>
          <div className="text-policy">
            These Terms of Use contain the entire understanding and agreement between you and Innoplexus regarding the Products/Platforms, and supersedes all prior and contemporaneous agreements and understandings between us regarding such subject matter.
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPolicy;
