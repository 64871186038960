import React from 'react';
import PropTypes from 'prop-types';
import PatentsList from '../../container/PatentsList';
import RecentTrials from '../../container/RecentTrials';
import CollapsibleGraph from '../../container/CollapsibleGraph';
import ComingSoon from '../../../common/components/CommingSoon';

function InterventionGraphHandler({
  params, selected, currentExpanded, setExpanded,
}) {
  const handleGraphs = () => {
    switch (selected.type) {
      case 'recent_trials':
        return (
          <RecentTrials currentExpanded={currentExpanded} selected={selected} />
        );
      case 'patents':
        return (
          <PatentsList
            params={params}
            setExpanded={setExpanded}
            currentExpanded={currentExpanded}
            selected={selected}
          />
        );

      case 'collapsible_graph':
        return (
          <CollapsibleGraph
            setExpanded={setExpanded}
            currentExpanded={currentExpanded}
            selected={selected}
          />
        );
      case 'comming-soon':
        return <ComingSoon currentExpanded={currentExpanded} selected={selected} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`graph-card ${selected.type} ${
        currentExpanded === '' ? selected.size : ''
      }`}
    >
      {handleGraphs()}
    </div>
  );
}

InterventionGraphHandler.propTypes = {
  selected: PropTypes.PropTypes.shape({
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  params: PropTypes.PropTypes.shape({}).isRequired,
  currentExpanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default InterventionGraphHandler;
