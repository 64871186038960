import React, { useEffect } from 'react';
import Header from '../../components/Header';

const data1 = ['Security: Our products are committed to maintain the confidentiality of users’ data and activity through encrypted servers in the VPC network and use of secure protocols. Any information given or received in confidence for one purpose will not be used for another purpose, or passed to a third party, without the data owner\'s prior consent.'];

const data2 = [
  'On receiving personal information about our Users, you no longer remain anonymous to Us. We may use this information to do internal research on our Users demographics, interests, and behavior to better understand, protect and serve our Users. This information is compiled and analyzed on an aggregated basis. We indicate fields that are mandatorily required to be filled and fields that are optional. The Users may decide whether to provide such information to Us.',
  'You may choose not to provide us with any personal information or information as required to complete your tasks. If we do not receive information required for your tasks, we may choose not to complete your task.',
  'On our Website, you can browse without telling Us who you are or revealing any personal information about Yourself. We may automatically track certain information about You based on Your behavior on our Website. This information may include the URL that You just came from (whether this URL is on our Platforms or not), which URL You next go to (whether this URL is on our Platforms or not), Your browser information, and Your IP address.',
  'On our Platforms, we use data collection devices such as "cookies" on certain pages to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist Us in providing our services. We offer certain features that are only available using a "cookie". You are always free to decline our cookies if Your browser permits, although in that case You may not be able to use certain features on the Platforms. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Website/App that are placed by third parties. We do not control the use of cookies by third parties.',
];

const data3 = [
  'We ask for some personal information when you create an account at any of the Innoplexus products, including your email address and a password, which is used to protect your account from unauthorized access.',
  'To provide you with access to the appropriate network, we will require you to provide the name and contact information described above, and may also request that you update or verify this information from time to time. By using the Site, you agree to provide accurate information, and to cooperate with any Innoplexus personnel.',
  'As a product user of Innoplexus, you can create a profile that includes personal information, such as your gender, age, occupation, in addition to other content, such as photos. This information may be accessed and viewed by other members belonging to your private communities and as determined by your privacy settings. The privacy settings of the account may be altered as deemed fit by you at any time.',
  'When you send messages through any of the Innoplexus products, we collect and maintain information associated with those messages, including email addresses and content.',
  'When you send, and receive messages to or from any of the Innoplexus product’s website, we collect and maintain information associated with those messages, such as the phone number/e-mail address, the wireless carrier/internet provider associated with the phone number/URL connection, the content of the message, and the date and time of the transaction, for logging purposes.',
];

const data4 = [
  'We use the information provided by you on the Website to provide our services.',
  'We have a right to use this information for its business purposes by itself or with its business associates.',
  'We will have a right to use the information to provide personalized advertisements and offers, or to include that information in compilations developed by us, or to develop derivative works based on that information.',
  'You agree that we may use personal information about you to improve its marketing and promotional efforts, to analyze site usage, improve the Site’s content and product offerings and for its business purposes.',
  'We may be required from time to time to disclose users’ personal information to Governmental or law enforcing agencies or our regulators.',
  'We may also disclose personal information to enforce our policies, respond to claims that a posting or other content violates others rights, or protects anyone’s rights, property or safety.',
  <span>
    Do other things for users as described when we collect the information.
    <br />
    Note: Please provide a description of the information to be collected by Innoplexus, along with its usage by Innoplexus / its products.
    {' '}
  </span>,
  'General information of the user.',
];

const data5 = [
  'Links to third-party advertisements, third-party websites or any third party electronic communication service may be provided on the website which are operated by third parties and are not controlled by, or affiliated to, or associated with Innoplexus, unless expressly specified on the Website.',
  'We are not responsible for any form of transmission, whatsoever, received by You from any third-party website. Accordingly, we do not make any representations concerning the privacy practices or policies of such third parties or terms of use of such third-party websites, nor do we control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other materials available on such third-party websites. The inclusion or exclusion does not imply any endorsement by us of the third-party websites, the website’s provider, or the information on the website. The information provided by You to such third-party websites shall be governed in accordance with the privacy policies of such third-party websites and it is recommended that You review the privacy policy of such third-party websites prior to using such websites.',
];

const data6 = [
  'You shall not submit, upload or post to our forums any content which',
  'Libels, defames, invades privacy of any person, or is obscene, pornographic, abusive, or threatening;',
  'Infringes any intellectual property or other right of any person or entity, including but not limited to, violating anyone’s copyrights or trademarks;',
  'Falsifies or deletes author attributions, legal notices or other proprietary designations;',
  'Violates any law;',
  'Advocates illegal activity;',
  'Contains viruses, corrupted files, or other materials that may cause damage to another computer. By posting any content on our forums, you automatically agree to indemnify our products from and against, any and all third-party claims, demands, liabilities, costs or expenses, including reasonable attorneys’ fees, resulting from your breach of these posting restrictions.',
  'By posting any content in the Website, you automatically grant to us a perpetual, royalty-free, irrevocable and unrestricted worldwide right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from and distribute the posted content or incorporate the posted content into any form, medium, or technology now known or later developed for any purpose, including advertising and promotion, and you automatically waive all moral rights with respect to the posted content.',
];

const data7 = [
  'You may opt-out of receiving notifications from us by changing your notification settings under the Settings tab after you log on to the Website. Unregistered users who receive emails from us may also opt-out of receiving emails in future.',
];

const data8 = [
  'Your account is password protected. We use industry standard measures to protect the personal information that is stored in our database. We limit the access to your personal information to those employees and contractors who need access to perform their job function, such as our customer service personnel. If you have any questions about the security on the Website, please contact us. Although we take appropriate measures to safeguard against unauthorized disclosures of information, we cannot assure you that your personal information will never be disclosed in a manner that is inconsistent with this Privacy Policy.',
  'You hereby acknowledge that our Website is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.',
  'Our Website will not be responsible for any loss of data / information resulting due to unauthorized access to Websites servers. You hereby agree to keep indemnified and harmless Innoplexus products from any such unauthorized access to your information.',
];

const text1 = 'The terms “We” / “Us” / “Our” individually and collectively refer to each entity being part of the Innoplexus and the terms "You” / “Your” / “Yourself” refer to the users. This Privacy Policy is a contract between you and Innoplexus entity whose Product Website you use or access or you otherwise deal with. This Privacy Policy shall be read together with the respective terms and conditions of the respective product entity and its respective Website or nature of business of the Website.';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderList = (data) => data.map((item) => (
    <div className="list-items-policy" key={item}>
      <div className="dot-policy" />
      <div className="list-adjust">
        {item}
      </div>
    </div>
  ));

  return (
    <div className="main-page">
      <Header className="results-header-light" type="light" />
      <div className="policy">
        <div className="policy-container">
          <div className="main-header">Privacy Policy</div>
          <div className="section-policy">
            <div className="text-policy">All the Products are owned, hosted and operated by Innoplexus AG (hereinafter referred to as “Innoplexus”), an entity incorporated under German laws.</div>
            <div className="text-policy">Since we highly value the trust placed by you in our products, we insist upon the highest standards for secure customer information privacy.</div>
            <div className="text-policy">{renderList(data1)}</div>
            <div className="text-policy">Please read the following statement to learn about our information gathering and dissemination practices.</div>
            <div className="text-policy">Note: Our privacy policy is subject to change at any time. To make sure you are aware of any changes, please review this policy periodically.</div>
            <div className="text-policy">
              This privacy policy is a legally binding document between you and Innoplexus (both terms defined below). The terms of this privacy policy will be effective upon your acceptance of the same (directly or indirectly in electronic form by use or the website or by other means) and will govern the relationship between you and Innoplexus for your use of the product website.
            </div>
            <div className="text-policy">
              By providing us your information or by making use of the facilities provided by the website, you hereby consent to the collection, storage, processing and transfer of any or all of your personal information and non-personal information by Innoplexus as specified under this privacy policy. You further agree that such collection, use, storage and transfer of your information shall not cause any loss or wrongful gain to you or any other person. You agree to keep indemnified and hold harmless Innoplexus from any loss caused to you or any other person by such collection, usage, storage and transfer of your information.
            </div>
            <div className="text-policy">
              {text1}
            </div>
            <div className="text-policy">
              This Privacy Policy may be amended / updated from time to time. We suggest that you regularly check this Privacy Policy to apprise yourself of any updates. Your continued use of the Website or provision of data or information thereafter will imply your unconditional acceptance of such updates to this Privacy Policy.
            </div>
            <div className="text-policy">
              Collection of Personally Identifiable Information
            </div>
            <div className="text-policy">
              We collect certain information about You to help us serve You better.
            </div>
            <div className="text-policy">{renderList(data2)}</div>
            <div className="text-policy">Mixpanel® Analytics</div>
            <div className="text-policy">
              Personal data is collected for using the services of Mixpanel® Analytics. The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
            </div>
            <div className="text-policy">
              Personal Data collected: Cookies and Usage data.
            </div>
          </div>
          <div className="main-header">Non-Personal Information</div>
          <div>
            <div className="text-policy">
              We may also collect information other than Personal Information from you through the website when you visit and / or use the website. Such information may be stored in server logs. This Non-Personal Information would not assist us to identify you personally.
            </div>
            <div>
              <div className="sub-header">1. Personal information/Other Information You May Provide</div>
              <div className="text-policy adjust-left-margin">{renderList(data3)}</div>
            </div>
            <div>
              <div className="sub-header">2. How We Use Your Personal Information/Other Information:</div>
              <div className="text-policy adjust-left-margin">{renderList(data4)}</div>
            </div>
            <div>
              <div className="sub-header">3. Collection of Information by Third Party Sites</div>
              <div className="text-policy adjust-left-margin">{renderList(data5)}</div>
            </div>
            <div>
              <div className="sub-header">4. Information Posted on The Website</div>
              <div className="text-policy adjust-left-margin">{renderList(data6)}</div>
            </div>
            <div>
              <div className="sub-header">5. E-mails and Opt-out</div>
              <div className="text-policy adjust-left-margin">{renderList(data7)}</div>
            </div>
            <div>
              <div className="sub-header">6. Security and Confidentiality</div>
              <div className="text-policy adjust-left-margin">{renderList(data8)}</div>
            </div>
            <div>
              <div className="sub-header">7. Information Safety</div>
              <div className="text-policy adjust-left-margin">
                All information is saved and stored on servers which are secured with passwords and pins to ensure no unauthorized person has access to it. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.
              </div>
            </div>
            <div>
              <div className="sub-header">8. Grievance Redressal</div>
              <div className="text-policy adjust-left-margin">
                If you find any discrepancies, or have any grievances in relation to the collection, storage, use, disclosure and transfer of Your Personal Information under this Privacy Policy or any Terms of Use, and other terms and conditions or policies of any Innoplexus product entity, please contact the following:
              </div>
            </div>
            <div className="text-policy">
              <span className="sub-header">Changes to Privacy Policy</span>
              {' '}
              <span>- Changes made to our privacy policy will be reflected on this page and may change without any prior notice.</span>
            </div>
          </div>
          <div className="text-policy">
            <span className="sub-header">Contact Us</span>
            {' '}
            <span>
              - If you have any questions about privacy, our practices or your dealings with Innoplexus products, you can contact us at: Email:
              <div className="make-it-blue">support@innoplexus.com</div>
            </span>
          </div>
          <div className="main-header">Cookie Policy</div>
          <div className="sub-text">Usage of Cookies</div>
          <div className="text-policy">
            Warning: This website uses cookies in order to generate statistics and make the services as efficient and simple as possible. Cookies are not used for purposes other than these and they are usually installed directly by Innoplexus product teams. By using this website, you agree to accept the use of cookies in accordance with the terms of use specified in this document. You can decide whether to accept cookies by using the settings of your browser.
          </div>
          <div className="main-header">Copyrights Policy</div>
          <div className="text-policy">
            The commercial use of the contents of the individual pages of this internet appearance requires our consent. All the rights to presentations and contributions on the individual pages of this internet appearance are held by the respective authors.
          </div>
          <div className="sub-text">Use of Images</div>
          <div className="text-policy">
            The use of any photographic works and photographic images (photographs, graphics and other pictorial representations) on this website takes place with the explicit consent of the respective authors and rights holders.
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
