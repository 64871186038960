import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Tooltip as TooltipAntd, Button } from 'antd';
import * as d3 from 'd3';
import SvgLoader from '../../../common/components/SvgLoader';
import { getInterventionMeterAction, getInterventionMeterRefreshAction } from './logic';
import Loader from '../../../common/components/Loader';
import { modifeyNumber } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';
import stackFormateKeys from './data';

const interventionOption = [{
  field_name: 'Phase',
  label: 'Intervention',
  display_name: 'Intervention',
  value: 'intervention',
  type: 'autosuggest-multi',
  name: 'intervention',
  currentFilter: [],
  currentKeys: [],
}];

const phaseOption = [{
  field_name: 'Phase',
  label: 'Phase',
  display_name: 'Phase',
  value: 'phase',
  type: 'multiselect',
  name: 'phase',
  currentFilter: [],
}];

function InterventionMeter({
  selected, params, currentExpanded, setExpanded,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const q = JSON.parse(query);
  const p = JSON.parse(semanticQuery);

  const [isExpanded, setIsExpanded] = useState(false);
  const [stackBarTooltipData, setStackBarTooltipData] = useState({});
  const [tooltipId, setTooltipId] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);

  const interventionData = useSelector((state) => state.interventionMeterData);
  const searchResult = useSelector((state) => state.searchResult);

  useEffect(() => {
    if ((currentExpanded === '' && searchResult.flag && !interventionData.flag) || currentExpanded === selected.label) {
      dispatch(getInterventionMeterRefreshAction());
      const request = {
        ...params,
        headers: queryString.stringify({
          ...params.headers,
          field_name: 'intervention',
          isExpanded,
        }),
      };
      dispatch(getInterventionMeterAction(request));
    }
  }, [currentExpanded, JSON.stringify(searchResult)], JSON.stringify(interventionData));

  const baseWidth = window.innerWidth - 150;

  const generateId = (str) => `${str.toLowerCase().replace(/[ /]/g, '-').trim()}`;

  const handleArrowClick = ({ interventionName, value, phaseName }) => {
    const newQuery = [];
    let flagIntervention = true;
    let flagPhase = true;
    if (q) {
      q.forEach((item) => {
        if (item.name === 'intervention') {
          item.currentFilter = [interventionName];
          item.currentKeys = [value];
          flagIntervention = false;
        }

        if (item.name === 'phase') {
          item.currentFilter = [phaseName];
          item.currentKeys = [phaseName];
          flagPhase = false;
        }
        newQuery.push(item);
      });
    }

    if (semanticQuery) {
      p.forEach((item) => {
        if (item.name === 'intervention') {
          item.currentFilter = [interventionName];
          item.currentKeys = [value];
          flagIntervention = false;
        }

        if (item.name === 'phase') {
          item.currentFilter = [phaseName];
          item.currentKeys = [phaseName];
          flagPhase = false;
        }
        newQuery.push(item);
      });
    }

    if (flagIntervention) {
      interventionOption[0].currentFilter = [interventionName];
      interventionOption[0].currentKeys = [value];
      newQuery.push(...interventionOption);
    }

    if (flagPhase) {
      phaseOption[0].currentFilter = [phaseName];
      phaseOption[0].currentKeys = [phaseName];
      newQuery.push(...phaseOption);
    }

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const draw = (dataset, totoalDrugs) => {
    const svgHeight = totoalDrugs * 50 || 0;
    const dimensions = {
      width: baseWidth,
      height: Math.max(300, svgHeight),
      margin: {
        top: 20,
        left: 380,
        right: 80,
        bottom: 100,
      },
    };

    dimensions.ctrWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.ctrHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom;

    const stack = d3.stack().keys(stackFormateKeys);
    const stackData = stack(dataset);

    const svg = d3.select('#intervention-stack-bar').append('svg')
      .attr('id', 'stack-bar-intervetion-ctr')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height);

    const colorScale = d3.scaleOrdinal()
      .domain(stackFormateKeys)
      .range(['#00ACC1', '#00897B', '#43A047', '#7CB342', '#C0CA33', '#FDD835', '#FFB300', '#FB8C00', '#F4511E', '#6D4C41', '#546E7A', '#757575', '#E53935', '#D81B60', '#8E24AA', '#5E35B1', '#3949AB', '#1E88E5', '#039BE5']);

    const x = d3.scaleLinear()
      .domain([0, d3.max(dataset.map((d) => d.trialCount))])
      .range([0, dimensions.ctrWidth]);

    const y = d3.scaleBand()
      .domain(dataset.map((item) => item.key))
      .range([0, dimensions.ctrHeight])
      .padding(0.5);

    const ctr = svg.append('g')
      .attr('transform', `translate(${dimensions.margin.left}, ${dimensions.margin.top})`);

    ctr.append('g')
      .call(d3.axisLeft(y).tickSize(0).tickPadding(10))
      .attr('class', 'axis-y');

    ctr.append('g')
      .attr('transform', `translate(0. ${dimensions.ctrHeight})`)
      .call(d3.axisBottom(x).tickFormat(''))
      .attr('class', 'axis-x');

    const stackBarCtr = ctr.append('g');

    const bar = stackBarCtr.selectAll('.bar')
      .data(stackData)
      .join('g')
      .attr('fill', (d) => colorScale(d.key));

    const getWidth = (d) => x(d[1]) - x(d[0]);

    const getId = (d) => {
      if (d[1]) {
        return generateId(`${d.data.key}-${d.phaseName}`);
      }
      return null;
    };

    bar.selectAll('rect')
      .data((d) => d.map((item) => ({ ...item, phaseName: d.key })))
      .join('rect')
      .attr('y', (d) => y(d.data.key))
      .attr('class', 'pointer')
      .attr('id', (d) => getId(d))
      .attr('x', (d) => x(d[0]))
      .attr('width', (d) => getWidth(d))
      .attr('height', y.bandwidth())
      .on('mouseenter', (event, datum) => {
        setShowTooltip(true);
        setTooltipId(generateId(`${datum.data.key}-${datum.phaseName}`));
        setStackBarTooltipData({
          value: datum.data.value,
          interventionName: datum.data.key,
          trialCount: datum[1] - datum[0],
          phaseName: datum.phaseName,
        });
      })
      .on('click', (event, datum) => {
        handleArrowClick({
          interventionName: datum.data.key,
          value: datum.data.value,
          phaseName: datum.phaseName,
        });
      });

    bar.selectAll('text')
      .data((d) => d)
      .join('text')
      .attr('y', (d) => y(d.data.key) + y.bandwidth() / 2 + 5)
      .attr('x', (d) => x(d[1]) + 20)
      .attr('fill', 'black')
      .text((d) => (d[1] === d.data.trialCount ? d.data.trialCount : ''));

    d3.selectAll('.axis-x path').attr('opacity', 0);
    d3.selectAll('.axis-x line').attr('opacity', 0);
    d3.selectAll('.axis-y path').attr('opacity', 0);
    d3.selectAll('.axis-y line').attr('opacity', 0);
  };

  useEffect(() => {
    if (interventionData.flag && isExpanded) {
      const formateData = [];

      d3.select('#stack-bar-intervetion-ctr').remove();

      interventionData.data[0].topDrugs.slice(0, 101).forEach((drug) => {
        let tempPhase = {};
        let trialCountPhase = 0;
        stackFormateKeys.forEach((phaseName) => {
          drug.phase.forEach((item) => {
            if (item.phase === phaseName && item.trialCount !== 0) {
              tempPhase[phaseName] = item.trialCount;
              trialCountPhase += item.trialCount;
              tempPhase = { ...tempPhase };
            }
          });
        });
        if (drug.count !== 0 && drug.phase.length !== 0) {
          formateData.push({
            ...drug,
            ...tempPhase,
            trialCount: trialCountPhase,
          });
        }
      });
      draw(formateData, formateData.length);
    }
  }, [JSON.stringify(interventionData)]);

  const handleCountClick = (value) => {
    const newQuery = [];
    let flag = true;
    if (q) {
      q.forEach((item) => {
        if (item.name === 'intervention') {
          item.currentFilter = [value.key];
          item.currentKeys = [value.value];
          flag = false;
        }
        newQuery.push(item);
      });
    }

    if (semanticQuery) {
      p.forEach((item) => {
        if (item.name === 'intervention') {
          item.currentFilter = [value.key];
          item.currentKeys = [value.value];
          flag = false;
        }
        newQuery.push(item);
      });
    }

    if (flag) {
      interventionOption[0].currentFilter = [value.key];
      interventionOption[0].currentKeys = [value.value];
      newQuery.push(...interventionOption);
    }

    Mixpanel.track('page_exit_event', {
      action: 'page leave event',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/searchResults?semanticquery=${semanticQuery}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/searchResults?query=${query}&deepdivequery=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const handleInterventionClick = (value) => {
    const newQuery = [];
    if (semanticQuery) {
      if (q) {
        q.forEach((item) => {
          if (item.name === 'intervention') {
            item.currentFilter = [...item.currentFilter, value.key];
            item.currentKeys = [...item.currentKeys, value.value];
          }
          newQuery.push(item);
        });
      } else {
        newQuery.push({
          currentKeys: [value.value],
          currentFilter: [value.key],
          field_name: 'intervention',
          label: 'intervention',
          name: 'intervention',
          value: 'intervention',
          type: 'autosuggest-multi',
        });
      }
    } else {
      let flag = true;
      q.forEach((item) => {
        if (item.name === 'intervention') {
          item.currentFilter = [...item.currentFilter, value.key];
          item.currentKeys = [...item.currentKeys, value.value];
          flag = false;
        }
        newQuery.push(item);
      });

      if (flag) {
        newQuery.push({
          currentKeys: [value.value],
          currentFilter: [value.key],
          field_name: 'intervention',
          label: 'intervention',
          name: 'intervention',
          value: 'intervention',
          type: 'autosuggest-multi',
        });
      }
    }

    Mixpanel.track('addToSearchIntervetionMeterEvent', {
      action: 'Intervention meter add to search tooltip click',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Intervention Meter',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
      intervention_data: {
        intervention_name: value.key,
        number_of_trials: value.key,
      },
      query: JSON.parse(semanticQuery || query),
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/analyticsResults?semanticquery=${JSON.stringify(p)}&query=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/analyticsResults?query=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const handleWordClick = (value) => {
    const newQuery = [];
    if (q) {
      newQuery.push({
        currentKeys: [value.value],
        currentFilter: [value.key],
        field_name: 'intervention',
        label: 'intervention',
        name: 'intervention',
        value: 'intervention',
      });
    }
    if (semanticQuery) {
      p.forEach((item) => {
        item.currentFilter = [value.key];
        newQuery.push(item);
      });
    }

    Mixpanel.track('interventionWidgetSearchEvent', {
      action: 'Intervention Meter search click on tooltip for search Term',
      search_type: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Intervention Meter',
      page: 'Analytics Page',
      terminal_name: 'Clinical Trials',
      intervention_data: {
        intervention_name: value.key,
        number_of_trials: value.count,
      },
      query: JSON.parse(semanticQuery || query),
    });

    if (semanticQuery) {
      navigate(`/clinicalTrials/analyticsResults?semanticquery=${JSON.stringify(newQuery)}`);
      navigate(0);
    } else {
      navigate(`/clinicalTrials/analyticsResults?query=${JSON.stringify(newQuery)}`);
      navigate(0);
    }
  };

  const handleShrinkClick = () => {
    setIsExpanded(false);
    setExpanded('');
    Mixpanel.track('collapseWidgetEvent', {
      action: 'collapse Phase widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const handleExpandClick = () => {
    setIsExpanded(true);
    setExpanded(selected.label);
    Mixpanel.track('expandWidgetEvent', {
      action: 'expand Phase widget',
      search_term: `${semanticQuery ? 'Semantic Search' : 'Power Search'}`,
      widget_name: 'Trial Phase Breakdown',
      terminal_name: 'Clinical Trials',
      page: 'Analytics Page',
      query: JSON.parse(semanticQuery || query),
    });
  };

  const renderTopDrugsText = () => {
    const length = interventionData?.data[0]?.topDrugs.length;
    if (length === 1) {
      return 'Top Intervention';
    }
    if (length === 2) {
      return 'Top 2 Interventions';
    }

    if (length === 0) {
      return null;
    }
    return 'Top Interventions';
  };

  if (currentExpanded !== '' && currentExpanded !== selected.label) {
    return null;
  }

  const handleMOuseOut = () => {
    setShowTooltip(false);
    setTooltipId('');
  };

  const renderStackBarTooltip = () => (
    <Tooltip id="tooltip-intervnetion-stack" isOpen={showTooltip} anchorSelect={`#${tooltipId}`} place="top">
      <div>
        <div className="tooltip tooltip-graph">
          <div className="tooltip-content tooltip-content-graph">
            <div className="tooltip-content-graph-content">
              <div className="tooltip-value">{stackBarTooltipData.trialCount}</div>
              <div className="tooltip-label">{stackBarTooltipData.phaseName}</div>
              <div className="tooltip-label">{stackBarTooltipData.interventionName}</div>
              <div className="tooltip-btn">
                <div aria-hidden className="arrow-button" type="submit" onClick={() => handleArrowClick(stackBarTooltipData)}>
                  <span className="right-arrow-black pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );

  const renderExpandedView = () => (
    <div className="Widget expanded intervention-widget-expanded" onMouseLeave={() => handleMOuseOut()}>
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            {selected.label}
          </div>
        </div>
        <div className="icons">
          <SvgLoader onClick={() => handleShrinkClick()} width={22} height={22} svgName="contract" hoverIconName="contract-hover" />
        </div>
      </div>
      <div id="intervention-stack-bar">
        {renderStackBarTooltip()}
      </div>
      <div className="drag-expaned-icon" />
    </div>
  );

  if (isExpanded) {
    return (
      <div className="expanded-widget">
        <Loader
          error={interventionData.error}
          loading={interventionData.loading}
          noData={interventionData.data[0]?.totalDrugs === 0 && interventionData.flag}
          height="400px"
          width="calc(100vw - 100px)"
          className="white-loader"
          message="No records found"
        >
          {renderExpandedView()}
        </Loader>
      </div>
    );
  }

  const renderTooltip = (val) => (
    <div className="intervention-tooltip-btn-wrap">
      <Button className="intervention-tooltip-btn" onClick={() => handleInterventionClick(val)}>Add to search</Button>
      <Button className="intervention-tooltip-btn" onClick={() => handleWordClick(val)}>Search</Button>
    </div>
  );

  const renderTopThee = () => interventionData?.data[0]?.topDrugs.map((value) => (
    <div key={value.key} className="top-three">
      <SvgLoader width={10.5} height={13.5} svgName="intervention-black" />
      <TooltipAntd overlayClassName="intervention-tooltip" title={renderTooltip(value)} placement="topLeft">
        <div className="drug-name pointer" title={value.key}>{value.key}</div>
      </TooltipAntd>
      <div className="pointer" role="presentation" onClick={() => handleCountClick(value)}>{modifeyNumber(value.count)}</div>
    </div>
  ));

  return (
    <div className="Widget">
      <div className="widget-header">
        <div className="widget-sub-header">
          <SvgLoader width={17} height={34} svgName="widget-header-icon" />
          <div className="header-text">
            <div>{selected.label}</div>
            <div className="partex-ai-icon ct-align-power-ai" />
          </div>
        </div>
        <div className="icons">
          {/* <SvgLoader width={18.7} height={18.7} svgName="info-icon" hoverIconName="info-icon-hover" /> */}
          {/* <SvgLoader width={22} height={22} svgName="widget-option" hoverIconName="widget-option-hover" /> */}
        </div>
      </div>
      <Loader
        error={interventionData.error}
        loading={interventionData.loading || searchResult.loading}
        noData={interventionData.data[0]?.totalDrugs === 0 && interventionData.flag}
        height="250px"
        className="white-loader"
        message="No records found"
      >
        <div className="total-drugs-info">
          <div aria-hidden className="count pointer" onClick={(() => handleExpandClick())}>{modifeyNumber(interventionData?.data[0]?.totalDrugs)}</div>
          <div className="sub-text-1"> Unique Interventions</div>
          <span className="sub-text">Studied </span>
        </div>
        <div className="meter-card">
          <div className="top-three-drugs">
            <div className="top-three-container">
              <div className="top-three-text">{renderTopDrugsText()}</div>
              <div className="top-three-container-content">
                <div className="top-three-header">
                  <div className="intervention-header">Intervention</div>
                  <span className="endpoint-type">#Trials</span>
                </div>
                {renderTopThee()}
                {
                  interventionData?.data[0]?.topDrugs.length === 0
                    ? <div className="no-interventions">No other interventions studied</div> : null
                }
              </div>
            </div>
            <div className="intervention-block">
              {/* <SvgLoader width={67.67} height={87} svgName="intervention" /> */}
            </div>
          </div>
        </div>
        <div className="drag-expaned-icon" />
      </Loader>
    </div>
  );
}

InterventionMeter.propTypes = {
  params: PropTypes.PropTypes.shape({
    headers: PropTypes.PropTypes.shape({}).isRequired,
  }).isRequired,
  selected: PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
  currentExpanded: PropTypes.string.isRequired,
};

export default InterventionMeter;
