import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_SPONSOR_DEEP_DIVE_DATA = 'GET_SPONSOR_DEEP_DIVE_DATA';
const GET_SPONSOR_DEEP_DIVE_DATA_SUCCESS = 'GET_SPONSOR_DEEP_DIVE_DATA_SUCCESS';
const GET_SPONSOR_DEEP_DIVE_DATA_FAILURE = 'GET_SPONSOR_DEEP_DIVE_DATA_FAILURE';

export const getSponsorDeepDiveDataAction = createAction(GET_SPONSOR_DEEP_DIVE_DATA);

const getSponsorDeepDiveDataSuccess = createAction(GET_SPONSOR_DEEP_DIVE_DATA_SUCCESS);

const getSponsorDeepDiveDataFaliure = createAction(GET_SPONSOR_DEEP_DIVE_DATA_FAILURE);

export const getSponsorDeepDiveDataEpic = (actions$) => actions$.pipe(
  ofType(GET_SPONSOR_DEEP_DIVE_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}${action?.payload?.apiUrl}?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getSponsorDeepDiveDataSuccess(res)),
    catchError((err) => of(getSponsorDeepDiveDataFaliure(err))),
  )),
);

const ongetSponsorDeepDiveDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const ongetSponsorDeepDiveDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetSponsorDeepDiveDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

export const getSponsorDeepDiveDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getSponsorDeepDiveDataAction, (state) => ongetSponsorDeepDiveDataAction(state))
      .addCase(getSponsorDeepDiveDataSuccess, (state, action) => ongetSponsorDeepDiveDataSuccess(state, action.payload))
      .addCase(getSponsorDeepDiveDataFaliure, (state) => ongetSponsorDeepDiveDataFailure(state))
      .addDefaultCase((state) => state);
  },
);
