import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_QUESTIONS_DATA = 'GET_QUESTIONS_DATA';
const GET_QUESTIONS_DATA_SUCCESS = 'GET_QUESTIONS_DATA_SUCCESS';
const GET_QUESTIONS_DATA_FAILURE = 'GET_QUESTIONS_DATA_FAILURE';
const GET_QUESTIONS_DATA_REFRESH = 'GET_QUESTIONS_DATA_REFRESH';

export const getQuestionsDataAction = createAction(GET_QUESTIONS_DATA);

const getQuestionsDataSuccess = createAction(GET_QUESTIONS_DATA_SUCCESS);

const getQuestionsDataFaliure = createAction(GET_QUESTIONS_DATA_FAILURE);

export const getQuestionsDataRefresh = createAction(GET_QUESTIONS_DATA_REFRESH);

export const getQuestionsDataEpic = (actions$) => actions$.pipe(
  ofType(GET_QUESTIONS_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/explore/v0/analytics/next-possible-que?${action.payload.headers}`, 'POST', action.payload.body)).pipe(
    map((res) => getQuestionsDataSuccess(res)),
    catchError((err) => of(getQuestionsDataFaliure(err))),
  )),
);

const ongetQuestionsDataAction = (state) => ({
  ...state,
  loading: true,
  error: false,
  flag: false,
});

const ongetQuestionsDataSuccess = (state, { response }) => ({
  ...state,
  data: [...response.data],
  loading: false,
  flag: true,
});

const ongetQuestionsDataFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const ongetQuestionsDataRefresh = () => ({
  data: [],
  loading: false,
  error: false,
  flag: false,
});

export const getQuestionsDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getQuestionsDataAction, (state) => ongetQuestionsDataAction(state))
      .addCase(getQuestionsDataSuccess, (state, action) => ongetQuestionsDataSuccess(state, action.payload))
      .addCase(getQuestionsDataFaliure, (state) => ongetQuestionsDataFailure(state))
      .addCase(getQuestionsDataRefresh, () => ongetQuestionsDataRefresh())
      .addDefaultCase((state) => state);
  },
);
