/*eslint-disable*/
import { createAction, createReducer } from '@reduxjs/toolkit';
import {
    map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// const GET_PROJECTS = 'GET_PROJECTS';
// const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
// const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
// const GET_PROJECTS_REFRESH = 'GET_PROJECTS_REFRESH';

// export const getProjectsAction = createAction(GET_PROJECTS);

// const getProjectsSuccess = createAction(GET_PROJECTS_SUCCESS);

// const getProjectsFaliure = createAction(GET_PROJECTS_FAILURE);
// export const getProjectsRefreshAction = createAction(GET_PROJECTS_REFRESH);

// export const getProjectsEpic = (actions$) => actions$.pipe(
//   ofType(GET_PROJECTS),
//   // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/getProject', 'GET', action.payload.params)).pipe(
//   switchMap(() => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/getProject`, 'get')).pipe(

//     map((res) => getProjectsSuccess(res)),
//     catchError((err) => of(getProjectsFaliure(err))),
//   )),
// );

// const ongetProjectsAction = (state) => ({
//   ...state,
//   loading: true,
//   error: false,
// });

// const ongetProjectsSuccess = (state, { response }) => ({
//   ...state,
//   data: [...response.data],
//   loading: false,
//   flag: true,
// });

// const ongetProjectsFaliure = (state) => ({
//   ...state,
//   flag: false,
//   loading: false,
//   showNotification: true,
//   error: true,
// });
// const onGetProjectsRefresh = () => ({
//   data: [],
//   loading: false,
//   error: false,
//   flag: false,
// });

// const getProjectsReducer = createReducer(
//   {
//     data: [],
//     loading: false,
//     error: false,
//     flag: false,
//   },
//   (builder) => {
//     builder.addCase(getProjectsAction, (state) => ongetProjectsAction(state))
//       .addCase(getProjectsSuccess, (state, action) => ongetProjectsSuccess(state, action.payload))
//       .addCase(getProjectsFaliure, (state) => ongetProjectsFaliure(state))
//       .addCase(getProjectsRefreshAction, () => onGetProjectsRefresh())
//       .addDefaultCase((state) => state);
//   },
// );

const CREATE_SAVED_SEARCH = 'CREATE_SAVED_SEARCH';
const CREATE_SAVED_SEARCH_SUCCESS = 'CREATE_SAVED_SEARCH_SUCCESS';
const CREATE_SAVED_SEARCH_FAILURE = 'CREATE_SAVED_SEARCH_FAILURE';
const CREATE_SAVED_SEARCH_REFRESH = 'CREATE_SAVED_SEARCH_REFRESH';

export const createSavedSearchAction = createAction(CREATE_SAVED_SEARCH);

const createSavedSearchSuccess = createAction(CREATE_SAVED_SEARCH_SUCCESS);

const createSavedSearchFailure = createAction(CREATE_SAVED_SEARCH_FAILURE);

export const createSavedSearchRefresh = createAction(CREATE_SAVED_SEARCH_REFRESH);

export const createSavedSearchEpic = (actions$) => actions$.pipe(
    ofType(CREATE_SAVED_SEARCH),
    // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/createProject', 'POST', action.payload.body)).pipe(
    switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/saveSearch/createSaveSearch`, 'POST', action.payload.body)).pipe(

        map((res) => createSavedSearchSuccess(res)),
        catchError((err) => of(createSavedSearchFailure(err))),
    )),
);

const onCreateSavedSearchAction = (state) => ({
    ...state,
    loading: true,
    error: false,
});

const onCreateSavedSearchSuccess = (state, { response }) => ({
    ...state,
    data: { ...response.data },
    loading: false,
    showNotification: true,
    flag: true,
});

const onCreateSavedSearchFailure = (state, { response }) => ({
    ...state,
    flag: false,
    loading: false,
    showNotification: true,
    error: true,
    data: { ...response },
});

const onCreateSavedSearchRefresh = () => ({
    data: [],
    loading: false,
    error: false,
    flag: false,
});

const createSavedSearchReducer = createReducer(
    {
        data: [],
        loading: false,
        error: false,
        flag: false,
    },
    (builder) => {
        builder.addCase(createSavedSearchAction, (state) => onCreateSavedSearchAction(state))
            .addCase(createSavedSearchSuccess, (state, action) => onCreateSavedSearchSuccess(state, action.payload))
            .addCase(createSavedSearchFailure, (state, action) => onCreateSavedSearchFailure(state, action.payload))
            .addCase(createSavedSearchRefresh, () => onCreateSavedSearchRefresh())
            .addDefaultCase((state) => state);
    },
);

// const UPDATE_PROJECT = 'UPDATE_PROJECT';
// const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
// const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
// const UPDATE_PROJECTS_REFRESH = 'UPDATE_PROJECTS_REFRESH';

// export const updateProjectAction = createAction(UPDATE_PROJECT);

// const updateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS);

// const updateProjectFaliure = createAction(UPDATE_PROJECT_FAILURE);

// export const updateProjectRefreshAction = createAction(UPDATE_PROJECTS_REFRESH);

// export const updateProjectEpic = (actions$) => actions$.pipe(
//   ofType(UPDATE_PROJECT),
//   // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/updateProject', 'PATCH', action.payload.params)).pipe(
//   switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/updateProject`, 'PATCH', action.payload.params)).pipe(

//     map((res) => updateProjectSuccess(res)),
//     catchError((err) => of(updateProjectFaliure(err))),
//   )),
// );

// const onUpdateProjectAction = (state) => ({
//   ...state,
//   loading: true,
//   error: false,
// });

// const onUpdateProjectSuccess = (state, { response }) => ({
//   ...state,
//   data: { ...response.data },
//   loading: false,
//   showNotification: true,
//   flag: true,
// });

// const onUpdateProjectFaliure = (state, { response }) => ({
//   ...state,
//   flag: false,
//   loading: false,
//   showNotification: true,
//   error: true,
//   data: { ...response },
// });

// const onUpdateProjectRefresh = () => ({
//   data: [],
//   loading: false,
//   error: false,
//   flag: false,
// });

// const updateProjectReducer = createReducer(
//   {
//     data: [],
//     loading: false,
//     error: false,
//     flag: false,
//   },
//   (builder) => {
//     builder.addCase(updateProjectAction, (state) => onUpdateProjectAction(state))
//       .addCase(updateProjectSuccess, (state, action) => onUpdateProjectSuccess(state, action.payload))
//       .addCase(updateProjectFaliure, (state, action) => onUpdateProjectFaliure(state, action.payload))
//       .addCase(updateProjectRefreshAction, () => onUpdateProjectRefresh())
//       .addDefaultCase((state) => state);
//   },
// );

// const SHARE_PROJECT = 'SHARE_PROJECT';
// const SHARE_PROJECT_SUCCESS = 'SHARE_PROJECT_SUCCESS';
// const SHARE_PROJECT_FAILURE = 'SHARE_PROJECT_FAILURE';
// const SHARE_PROJECTS_REFRESH = 'SHARE_PROJECTS_REFRESH';

// export const shareProjectAction = createAction(SHARE_PROJECT);

// const shareProjectSuccess = createAction(SHARE_PROJECT_SUCCESS);

// const shareProjectFaliure = createAction(SHARE_PROJECT_FAILURE);

// export const shareProjectRefreshAction = createAction(SHARE_PROJECTS_REFRESH);

// export const shareProjectEpic = (actions$) => actions$.pipe(
//   ofType(SHARE_PROJECT),
//   // switchMap((action) => ajax(apiCall('http://192.168.9.236:3000/api/common/v0/workspace/project/shareProject', 'POST', action.payload.body)).pipe(
//   switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/workspace/project/shareProject`, 'POST', action.payload.body)).pipe(

//     map((res) => shareProjectSuccess(res)),
//     catchError((err) => of(shareProjectFaliure(err))),
//   )),
// );

// const onshareProjectAction = (state) => ({
//   ...state,
//   loading: true,
//   error: false,
// });

// const onshareProjectSuccess = (state, { response }) => ({
//   ...state,
//   data: { ...response },
//   loading: false,
//   showNotification: true,
//   flag: true,
// });

// const onshareProjectFaliure = (state, { response }) => ({
//   ...state,
//   flag: false,
//   loading: false,
//   showNotification: true,
//   error: true,
//   data: { ...response },
// });

// const onshareProjectRefresh = () => ({
//   data: [],
//   loading: false,
//   error: false,
//   flag: false,
// });

// const shareProjectReducer = createReducer(
//   {
//     data: [],
//     loading: false,
//     error: false,
//     flag: false,
//   },
//   (builder) => {
//     builder.addCase(shareProjectAction, (state) => onshareProjectAction(state))
//       .addCase(shareProjectSuccess, (state, action) => onshareProjectSuccess(state, action.payload))
//       .addCase(shareProjectFaliure, (state, action) => onshareProjectFaliure(state, action.payload))
//       .addCase(shareProjectRefreshAction, () => onshareProjectRefresh())
//       .addDefaultCase((state) => state);
//   },
// );

export {
    createSavedSearchReducer,
};
